import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getMonthlyInactivityFee } from '../../../../redux/monthlyInactivityFee/monthlyInactivityFeeActions'; 
import { formatDate } from '../../../../helpers/formatDate';

export function MonthlyInactivityFee() {
  const dispatch = useDispatch();
  const { allUserFee: { paginatedData, totalCount } } = useSelector((state) => state.monthlyInactivityFee);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        setTableLoading(true);
  
        await dispatch(getMonthlyInactivityFee({ id, limit: rowsPerPage, page }));
  
        setTableLoading(false);
      }
    }

    fetchData();
  }, [id, page, rowsPerPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
  };

  const columns = [
    {
      name: 'From Account',
      cell: ({ accounts: { accountType, currencySymbol } }) => `${accountType} ${currencySymbol}`,
    },
    {
      name: 'Brand',
      cell: ({ brandName }) => brandName,
    },
    {
      name: 'Fee in percentage',
      cell: ({ feeInPercent }) => `${feeInPercent}%`,
    },
    {
      name: 'Fee in USD',
      cell: ({ feeInUsd }) => feeInUsd,
    },
    {
      name: 'Time initiated',
      cell: ({ updatedAt }) => formatDate(new Date(updatedAt)),
    },
  ];

  return (
    <div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={paginatedData}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          paginationDefaultPage={page}
          persistTableHead
          highlightOnHover
          progressPending={tableLoading}
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}
