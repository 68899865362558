import React, { useEffect, useState } from 'react';
import * as Flags from 'react-flags-select';
import { ProgressBar } from './ProgressBar';
import { toFixed, calculatePercentage } from '../../../helpers/utils';
import style from '../AffiliatesDashboard.module.css';

const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

export function CellWithProgressBar({
  name, countFTD, percent, withFlag, flag,
}) {
  const [FlagIcon, setFlagIcon] = useState(null);

  useEffect(() => {
    if (withFlag && flag) {
      const flagTitleCase = titleCase(flag);
      setFlagIcon(() => Flags[flagTitleCase]);
    }
  }, [withFlag, flag]);


  return (
    <div className={style.allDepositsStatisticsTableCell}>
      {FlagIcon && <div className={style.roundedFlag}><FlagIcon /></div>}
      <p className={style.allDepositsStatisticsTableTitle}>{ name }</p>
      <div className={style.allDepositsStatisticsBar}>
        <p className={style.allDepositsStatisticsBarAmount}>{countFTD}</p>
        <ProgressBar percent={percent} />
        <p className={style.allDepositsStatisticsBarPercentage}>{`${percent}%`}</p>
      </div>
    </div> 
  );
}
