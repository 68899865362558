import { useState, useEffect } from 'react';
import { apiHelper } from '../redux/apiHelper';

const useBlobImage = (imageSrc) => {
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true);
      try {
        const response = await apiHelper('get', `/images/${imageSrc}`, '', '', 'blob');
        if (!(response.data instanceof Blob)) {
          throw new Error('The response data is not a Blob.');
        }
        const url = URL.createObjectURL(response.data);
        setImage(url);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (imageSrc) {
      fetchImage();
    }

    // Cleanup
    return () => {
      if (image) URL.revokeObjectURL(image);
    };
  }, [imageSrc]);

  return { image, isLoading, error };
};

export default useBlobImage;
