import { GET_CALL_HISTORY, GET_MORE_CALL_HISTORY } from './userCallHistoryTypes';

const initialState = {
  history: [],
  isNeedToLoad: true,
  totalCount: 0,
};

const userCallHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALL_HISTORY:
      return {
        history: action.payload.history,
        isNeedToLoad: action.payload.length > 0,
        totalCount: action.payload.totalCount,
      };
    case GET_MORE_CALL_HISTORY:
      return {
        history: [...state.history, ...action.payload],
        isNeedToLoad: action.payload.length > 0,
      };
    default:
      return state;
  }
};

export default userCallHistoryReducer;
