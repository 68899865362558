import React from 'react';
import moment from 'moment';
import styles from './style.module.css';

export function UpdateCrmNotificationMessage({ version }) {
  const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.time}>{time}</span>
      </div>
      <div className={styles.body}>
        <span>
          {`New version (v${version}) is available. Click on this notification to update the CRM`}
        </span>
      </div>
    </div>
  );
}
