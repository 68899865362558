import React, { useState } from 'react';
import {
  styled, TextField, InputAdornment, IconButton, 
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styles from './InputComponent.module.css';


export const CustomizedInput = styled(TextField)(({ error }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    paddingLeft: '12px',
    paddingRight: '8px',
    height: '40px',
    borderRadius: '5px',
    border: '1px solid #9193A8',
    outline: 'unset',

    ' .MuiInputBase-input': {
      WebkitTextFillColor: 'unset',
    },
    '&:hover': {
      border: '1px solid #9BACFF',
    },
    '&.Mui-error': {
      border: '1px solid var(--RX-System-Error)',
      margin: 0,
      '& .MuiSvgIcon-root': {
        color: error ? 'var(--RX-System-Error)' : '#C4C6D0',
      },
    },
    '&.Mui-focused': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: error ? '3px solid var(--RX-System-Error)' : '3px solid #9BACFF',
      },
      '& .MuiSvgIcon-root': {
        color: error ? 'var(--RX-System-Error)' : '#C4C6D0',
      },
    },
    '&.Mui-disabled': {
      border: '1px solid var(--RX-System-Outline)',
      opacity: '0.3',
      WebkitTextFillColor: 'unset',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '& .MuiSvgIcon-root': {
    color: '#C4C6D0',
    width: '24px',
    height: '24px',
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: '#C4C6D0;',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
}));

export function InputComponent({
  title,
  id,
  value,
  setValue,
  placeholder,
  error = false,
  disabled,
  supportTextDynamic,
  sizeInput = 'small',
  errorText,
  required,
  searchIcon = false,
  onChange,
  isCopy = false,
  isPassword = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  let fontSize = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const handleClear = () => {
    if (!disabled && setValue != null) {
      setValue('');
    }
  };

  const handleChange = (event) => {
    if (setValue != null) setValue(event.target.value);
    if (onChange) onChange(event);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const renderErrorText = () => {
    if (Array.isArray(errorText)) {
      return errorText.join(', ');
    }
    return errorText;
  };

  return (
    <div className={styles.inputContainer}>
      <p className={`${styles.inputTitle} ${(disabled ?? false) ? styles.disabled : ''}`}>{title}</p>
      <CustomizedInput
        id={id}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        size={sizeInput}
        required={required}
        type={isPassword && !showPassword ? 'password' : 'text'}
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--RX-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text',
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value.length > 0 && (
                <IconButton onClick={handleClear} sx={{ padding: '0 5px' }}>
                  <CancelOutlinedIcon sx={{ cursor: 'pointer' }} fontSize={fontSize} />
                </IconButton>
              )}
              {error && (
                <ErrorOutlinedIcon color="error" fontSize={fontSize} />
              )}
              {isCopy && (
                <IconButton onClick={() => handleCopy(value)} sx={{ padding: '0 5px' }}>
                  <ContentCopyIcon sx={{ cursor: 'pointer' }} fontSize={fontSize} />
                </IconButton>
              )}
              {isPassword && (
                <IconButton onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                  {showPassword ? <VisibilityOutlinedIcon fontSize={fontSize} /> : <VisibilityOffOutlinedIcon fontSize={fontSize} />}
                </IconButton>
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              {searchIcon ? <SearchOutlinedIcon sx={{ cursor: 'pointer' }} fontSize={fontSize} /> : null}
            </InputAdornment>
          ),
        }}
      />
      {supportTextDynamic && value.length > 0 && !error ? (
        <p className={`${styles.supportingText} ${(disabled ?? false) ? styles.disabled : ''}`}>{supportTextDynamic}</p>
      ) : null}
      {error && renderErrorText() ? (
        <p className={styles.supportingTextError}>{renderErrorText()}</p>
      ) : null}
    </div>
  );
}
