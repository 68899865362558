import React from 'react';
import PendingWithdraws from '../PendingWithdraws/PendingWithdraws';
import ExternalTransactions from './ExternalTransactions/ExternalTransactions';

function DeletedTransactions() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <PendingWithdraws isDeleted />
      <ExternalTransactions isDeleted />
    </div>
  );
};

export default DeletedTransactions;
