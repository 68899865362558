import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Markdown from 'markdown-to-jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import {
  getUserKYCComments, deleteUserKYCComment, updateUserKYCComment, createUserKYCComment, 
} from '../../../../redux/kycComment/kycCommentActions';
import { formatDate } from '../../../../helpers/formatDate';

function KYCComments({ userId }) {
  const dispatch = useDispatch();
  const [editingFromData, setEditingFromData] = useState({
    id: '',
    text: '',
  });
  const [newComment, setNewComment] = useState('');
  const kycComments = useSelector((state) => state.kycComments) ?? [];
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions) ?? [];

  useEffect(() => {
    if (userId) {
      dispatch(getUserKYCComments(userId));
    }
  }, [userId]);

  const handleCancelEdit = () => {
    setEditingFromData(
      {
        id: '',
        text: '',
      },
    );
  };

  const handleDeleteSingleComment = async (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserKYCComment(id));
      }
    });
  };

  const handleEditComment = (id) => {
    const comment = kycComments.find((comment) => comment._id === id);

    if (comment) {
      setEditingFromData({
        id: comment._id,
        text: comment.text,
      });
    }
  };

  const handleSaveEditedComment = (id) => {
    const authorId = JSON.parse(localStorage.getItem('userId'));
    dispatch(updateUserKYCComment(id, { text: editingFromData.text, authorId }));
    handleCancelEdit();
  };

  const handleCommentSubmit = () => {
    if (!newComment) {
      toast.error('Comment is required');

      return;
    }

    const authorId = JSON.parse(localStorage.getItem('userId'));
    const data = {
      text: newComment,
      authorId,
      userId,
    };
    
    setNewComment('');
    dispatch(createUserKYCComment(data));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h5>KYC</h5>
      </div>
      <div className="form-group col-md-12 pt-2 mb-4">
        <textarea 
          rows="10" 
          placeholder="Type your comment...." 
          className="form-control" 
          name="kycComment"
          value={newComment}
          onChange={({ target: { value } }) => setNewComment(value)}
        />
      </div>
      <div className="add-comment d-flex justify-content-end">
        <button type="button" className="btn btn-default" onClick={handleCommentSubmit}>Add KYC Comment</button>
      </div>
      <div className="form-border-wrapper">
        {kycComments.map((comment) => (
          comment._id === editingFromData.id ? (
            <div key={`comment-${comment._id}`} className="form-check form-group input-wrapper input-border d-flex mb-3" style={{ padding: '0 0 10px 0' }}>
              {/* Edit Mode */}
              <div className="info-content" style={{ width: '100%' }}>
                <h5 className="mb-1">
                  {` ${formatDate(new Date(comment.createdAt))}, ${comment.author.firstName} ${comment.author.lastName} `}
                </h5>
                <textarea 
                  rows="4" 
                  className="form-control edit-field"
                  value={editingFromData.text}
                  onChange={({ target: { value } }) => setEditingFromData((prev) => ({ ...prev, text: value }))}
                />
                <button 
                  type="button" 
                  className="btn btn-default edit-button mt-2 me-2" 
                  onClick={() => handleSaveEditedComment(comment._id)}
                >
                  Save
                </button>
                <button 
                  type="button" 
                  className="btn btn-default edit-button mt-2" 
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div key={`comment-${comment._id}`} className="form-check form-group input-wrapper input-border d-flex mb-3">
              {/* View Mode */}
              {permissionName && (permissionName.includes('delete_comment') || permissionName.includes('edit_comment')) ? (
                <div className="checkboxes me-3" style={{ display: 'flex', gap: '5px' }}>
                  {permissionName.includes('delete_comment')
                              && (
                              <FontAwesomeIcon 
                                icon={faTrash} 
                                className="del-icon" 
                                onClick={(e) => handleDeleteSingleComment(e, comment._id)}
                              />
                              )}
                  {permissionName.includes('edit_comment') 
                              && (
                              <FontAwesomeIcon 
                                icon={faEdit} 
                                className="edit-icon" 
                                onClick={() => handleEditComment(comment._id)}
                              />
                              )}
                </div>
              ) : null}
              <div className="info-content">
                <h5 className="mb-1">
                  {` ${formatDate(new Date(comment?.updatedAt))}, ${comment?.author?.firstName} ${comment?.author?.lastName} `}
                  {comment?.updatedAt !== comment?.createdAt && '(Edited)'}
                </h5>
                <label className="control-label px-2">
                  <Markdown
                    style={{
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all', 
                      whiteSpace: 'normal',
                      fontWeight: 400,
                    }}
                    options={{
                      overrides: {
                        ol: {
                          // eslint-disable-next-line react/no-unstable-nested-components
                          component: ({ children, ...props }) => (
                            <ol
                              {...props}
                              style={{ 
                                listStyleType: 'decimal', 
                                marginLeft: '15px',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-all', 
                                whiteSpace: 'normal',
                              }}
                            >
                              {children}
                            </ol>
                          ),
                        },
                        ul: {
                          // eslint-disable-next-line react/no-unstable-nested-components
                          component: ({ children, ...props }) => (
                            <ul
                              {...props}
                              style={{ 
                                listStyleType: 'decimal', 
                                marginLeft: '15px',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-all', 
                                whiteSpace: 'normal',
                              }}
                            >
                              {children}
                            </ul>
                          ),
                        },
                      },
                    }}
                  >
                    {comment?.text ?? ''}
                  </Markdown>
                </label>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
}

export default KYCComments;
