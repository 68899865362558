/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { getWalletsByAdminAddress } from '../../redux/adminAddress/adminAddressActions';

export function WalletAddressesModal({ isShown = false, hideModal, adminAddressId }) {
  const dispatch = useDispatch();
  const wallets = useSelector((state) => state.adminAddress.wallets);

  useEffect(() => {
    if (adminAddressId && adminAddressId.length) {
      dispatch(getWalletsByAdminAddress(adminAddressId));
    }
  }, [adminAddressId]);

  const columns = useMemo(() => [
    {
      name: 'Address',
      cell: ({ address }) => address,
    },
    {
      name: 'User',
      cell: ({ user }) => (user && user.length ? (
        <Link to={`user-detail/${user[0]._id}`}>
          {user[0].firstName} 
          {' '}
          {user[0].lastName}
        </Link>
      ) : '-'),
    },
    {
      name: 'Amount',
      cell: ({ amount }) => amount,
    },
    {
      name: 'Used',
      cell: ({ inWork }) => (inWork ? 'Yes' : 'No'),
    },
  ], []);

  return (
    <Modal show={isShown} onHide={() => hideModal()} className="widthdrawal-modal">
      <Modal.Body>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={wallets}
            theme="solarizedd"
            pagination
            paginationRowsPerPageOptions={[10, 50, 200, 500]}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
