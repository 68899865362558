import React from 'react';

function PageNotFound() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Page Not Found</h3>
      </div>
    </div>
  );
}

export default PageNotFound;
