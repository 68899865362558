import React from 'react';
import { useParams } from 'react-router-dom';
import { OrdersHistoryTable } from '../../../components/OrdersHistoryTable/OrdersHistoryTable';

function TradeHistory() {
  const { id } = useParams();

  const filtersStorageName = 'DataTable_user_orders/user-order-history_filters';
  const filterIdStorageName = 'DataTable_user_orders/user-order-history_filter_id'; 
  const columnsStorageName = 'DataTable_user_orders/user-order-history_columns';
  const paginationStorageName = 'DataTable_user_orders/user-order-history_pagination';
  
  const localStorageConfig = {
    filtersStorageName,
    filterIdStorageName,
    columnsStorageName,
    paginationStorageName,
  };

  return (
    <div>
      <OrdersHistoryTable localStorageConfig={localStorageConfig} userId={id} isPositionUnset />
    </div>
  );
}

export default TradeHistory;
