import React from 'react';
import { Chart } from 'react-google-charts';


const options = {
  colorAxis: { colors: ['#5A6D91', '#415F91', '#9BBDFF'] },
  backgroundColor: 'transparent',
  datalessRegionColor: '#333C50',
  defaultColor: '#333C50',
  legend: 'none',
};

export function GeoChart({ data }) {
  return (
    <Chart
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      height="290px"
      data={data}
      options={options}
    />
  );
}
