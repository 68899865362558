import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { getAllClosedOrdersSum, getAllOpenOrders } from '../../../redux/cfdOrder/cfdOrderActions';
import { toFixed } from '../../../helpers/utils';
import socket from '../../../services/socket';

import style from './SalesAnalysis.module.css';

export function SalesAnalysis() {
  const dispatch = useDispatch();
  const { salesAnalysis } = useSelector((state) => state.salesDashboard.salesDashboard);
  const allClosedOrders = useSelector((state) => state.cfdOrders.allOrdersSum);
  const openOrders = useSelector((state) => state.cfdOrders.openOrders);
  const [openPLPrice, setOpenPLPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getAllClosedOrdersSum()),
        dispatch(getAllOpenOrders()),
      ]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const uniqueId = Date.now();

    socket.emit('getOpenOrderMarket', { orders: openOrders, uniqueId });

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      const sumAllPNL = Object.values(data).reduce((accum, { pnl }) => {
        const numPNL = toFixed(pnl, 2);
        return accum + numPNL;
      }, 0);
      setOpenPLPrice(toFixed(sumAllPNL, 2));
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      socket.emit('removeOpenOrdersMarketListener');
      setOpenPLPrice(0);
    };
  }, [openOrders]);
  
  return (
    <>
      {/* <h1 className={style.pageTitle}>Sales Dashboard</h1> */}
      <div className={style.salesAnalysis}>
        <div className={style.salesAnalysisTitleWrapper}>
          <h3 className={style.salesAnalysisTitle}>Sales analysis</h3>
          {/* <button type="button" onClick={downloadSalesAnalysisHandler} className={style.salesAnalysisBtn}> */}
          {/* <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} /> */}
          {/* </button> */}
        </div>
        <div className={style.salesAnalysisContainer}>
          <div className={style.salesAnalysisRow}>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitle}>FTD / Clients:</p>
              <p className={style.salesAnalysisCellAmount}>
                {salesAnalysis?.ftds ?? 0}
                / 
                {salesAnalysis?.totalClients ?? 0}
              </p>
            </div>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitle}>Deposits:</p>
              <p className={style.salesAnalysisCellAmount}>{`$${salesAnalysis?.totalDepsInUSDT ?? 0}`}</p>
            </div>
            <div className={style.mobileDivider} />
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitle}>Closed P&L:</p>
              <p className={style.salesAnalysisCellAmount}>{`$${allClosedOrders}`}</p>
            </div>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitle}>Withdrawals:</p>
              <p className={style.salesAnalysisCellAmount}>{`$${salesAnalysis?.totalWithdrawalsInUSDT ?? 0}`}</p>
            </div>
            <div className={style.mobileDivider} />
          </div>
          <div className={style.salesAnalysisRow}>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitleLower}>Real-time online:</p>
              <p className={style.salesAnalysisCellAmountLower}>{`${salesAnalysis?.totalOnlineClients ?? 0}`}</p>
            </div>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitleLower}>Net Deposits:</p>
              <p className={style.salesAnalysisCellAmountLower}>{`$${salesAnalysis?.netDeposits ?? 0}`}</p>
            </div>
            <div className={style.mobileDivider} />
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitleLower}>Open P&L:</p>
              <p className={style.salesAnalysisCellAmountLower}>{`$${openPLPrice}`}</p>
            </div>
            <div className={style.salesAnalysisCell}>
              <p className={style.salesAnalysisCellTitleLower}>Withdrawals rate:</p>
              <p className={style.salesAnalysisCellAmountLower}>{`${salesAnalysis?.withdrawalRatePercentage ?? 0}%`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
