import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { format, isSameDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import styles from './styles.module.css';
import {
  selectNextDay, selectNextMonth, selectNextWeek, selectPrevDay, selectPrevMonth, selectPrevWeek, 
} from '../../helpers/helper';

export function CalendarSubHeader({
  selectedDate, setSelectedDate, calendarStyle, setCalendarStyle, 
}) {
  const selectedMonth = format(selectedDate, calendarStyle === 'day' ? 'dd MMM YYY' : 'MMM yyyy', { locale: enUS });
    
  const selectNextPeriod = () => {
    switch (calendarStyle) {
      case 'month':
        selectNextMonth(selectedDate, setSelectedDate);
        break;
      case 'week':
        selectNextWeek(selectedDate, setSelectedDate);
        break;
      case 'day':
        selectNextDay(selectedDate, setSelectedDate);
        break;

      default:
        break;
    }
  };

  const selectPrevPeriod = () => {
    switch (calendarStyle) {
      case 'month':
        selectPrevMonth(selectedDate, setSelectedDate);
        break;
      case 'week':
        selectPrevWeek(selectedDate, setSelectedDate);
        break;
      case 'day':
        selectPrevDay(selectedDate, setSelectedDate);
        break;

      default:
        break;
    }
  };

  const selectToday = () => {
    if (!isSameDay(selectedDate, new Date())) setSelectedDate(new Date());        
  };

  return (
    <div className={styles.calendarSubHeader}>
      <h6>{selectedMonth}</h6>
      <div className={styles.subHeaderButtons}>
        <div 
          className={styles.viewButton}
          onClick={() => setCalendarStyle('month')}
          style={{
            backgroundColor: calendarStyle === 'month' ? '#515d6c' : '',
          }}
        >
          Month
        </div>

        <div 
          className={styles.viewButton}
          onClick={() => setCalendarStyle('week')}
          style={{
            backgroundColor: calendarStyle === 'week' ? '#515d6c' : '',
          }}
        >
          Week
        </div>

        <div 
          className={styles.viewButton}
          onClick={() => setCalendarStyle('day')}
          style={{
            backgroundColor: calendarStyle === 'day' ? '#515d6c' : '',
          }}
        >
          Day
        </div>
      </div>
      <div className={styles.subHeaderButtons}>
        <div 
          className={styles.todayButton}
          onClick={selectToday}
          style={{
            backgroundColor: isSameDay(selectedDate, new Date()) ? '#6e7f956b' : '',
          }}
        >
          Today
        </div>
        <FontAwesomeIcon icon={faArrowLeft} size="sm" className={styles.arrowButtons} onClick={selectPrevPeriod} />
        <FontAwesomeIcon icon={faArrowRight} size="sm" className={styles.arrowButtons} onClick={selectNextPeriod} />
      </div>
    </div>
  );
}
