export const GET_ADMIN_ADDRESSES = 'GET_ADMIN_ADDRESSES';
export const GET_ADMIN_ADDRESSES_NETWORK = 'GET_ADMIN_ADDRESSES_NETWORK';
export const GET_ADMIN_ADDRESS = 'GET_ADMIN_ADDRESS';
export const GET_WALLETS_BY_ADDRESS = 'GET_WALLETS_BY_ADDRESS';
export const ADD_ADMIN_ADDRESS = 'ADD_ADMIN_ADDRESS';
export const EDIT_ADMIN_ADDRESS = 'EDIT_ADMIN_ADDRESS';
export const DELETE_ADMIN_ADDRESS = 'DELETE_ADMIN_ADDRESS';
export const SET_DEFAULT_ADMIN_ADDRESS = 'SET_DEFAULT_ADMIN_ADDRESS';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_PSP_USERS_LOADING = 'SET_IS_PSP_USERS_LOADING';
export const SET_USERS_BY_PSP = 'SET_USERS_BY_PSP';
export const TOGGLE_STATE = 'TOGGLE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
