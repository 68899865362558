import {
  GET_USER_KYC_COMMENTS,
  DELETE_USER_KYC_COMMENT,
  UPDATE_USER_KYC_COMMENT,
  CREATE_USER_KYC_COMMENT,
} from './kycCommentTypes';


export default (state = [], action) => {
  switch (action.type) {
    case GET_USER_KYC_COMMENTS:
      return action.payload;
    case CREATE_USER_KYC_COMMENT:
      return [action.payload, ...state];
    case UPDATE_USER_KYC_COMMENT:
      return state.map((comment) => (comment._id === action.payload._id ? action.payload : comment));
    case DELETE_USER_KYC_COMMENT:
      return state.filter((comment) => (comment._id !== action.payload._id));
    default:
      return state;
  }
};
