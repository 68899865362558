import { toast } from 'react-toastify';
import { GET_SALESDASHBOARD } from './salesDashboardTypes';
import { apiHelper } from '../apiHelper';

export const getSalesDashboard = (requestData) => async (dispatch) => {
  try {
    let url = '?';

    Object.entries(requestData).forEach(([key, value]) => {
      if (key === 'period') {
        value = (value.length && value[2] !== 'All') ? JSON.stringify(value) : '';
      }

      url += `${key}=${value}&`;
    });

    if (url.endsWith('&')) {
      url = url.slice(0, -1);
    }

    const res = await apiHelper('get', `/api/dashboard/sales_dashboard${url}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_SALESDASHBOARD,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};
