import React, {
  useEffect, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditorComponent from '../EditorComponent/EditorComponent';
import { editEmailTemplate, getEmailTemplate, setEmailTemplateCreator } from '../../../redux/emailTemplates/emailTemplatesActions';
import { selectEmailTemplate, selectIsEmailTemplateLoading } from '../../../redux/emailTemplates/emailTemplatesSelectors';
import s from './EmailTemplateEdit.module.css';

function EmailTemplatesEdit({ id }) {
  const dispatch = useDispatch();

  const [event, setEvent] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);
  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState('');
  const [mailJsonBodyStringified, setMailJsonBodyS] = useState('');

  const emailTemplate = useSelector(selectEmailTemplate);
  const isEmailTemplateLoading = useSelector(selectIsEmailTemplateLoading);

  const { handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getEmailTemplate(id));
    return () => dispatch(setEmailTemplateCreator({}));
  }, [id]);

  useEffect(() => {
    const status = emailTemplate?.status ?? false;

    setEvent(emailTemplate.event);
    setTitle(emailTemplate.title);
    setStatus(status);
  }, [emailTemplate]);

  const handleSave = async () => {
    const data = {
      event,
      title,
      status,
      mailBodyJson: mailJsonBodyStringified,
      mailBodyHtml: mailHtmlBodyStringified,
    };

    dispatch(editEmailTemplate(id, data));
  };

  if (isEmailTemplateLoading) {
    return <>Loading</>;
  }

  return (
    <div className={s.wrapper}>
      <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>

        <div className="form-group">
          <label className="lead">Event</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter here..."
            name="event"
            value={event}
            onInput={(e) => setEvent(e.target.value)}
          />
        </div>
        <br />

        <div className="form-group">
          <label className="lead">Title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Title"
            name="title"
            value={title}
            onInput={(e) => setTitle(e.target.value)}
          />
        </div>
        <br />

        <div className="form-group">
          <label className="lead">Status</label>
          <select
            className="form-control user-status-select"
            name="showFtd"
            value={status}
            onChange={({ target: { value } }) => setStatus(value === 'true')}
          >
            <option value>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        <br />

        <EditorComponent
          template={emailTemplate}
          setMailHtmlBodyS={setMailHtmlBodyS}
          setMailJsonBodyS={setMailJsonBodyS}
        />

        <br />
        <div className="d-flex justify-content-end">
          <button className="btn-default" type="submit">Update Email Template</button>
        </div>
      </form>
    </div>
  );
}

export default EmailTemplatesEdit;
