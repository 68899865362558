export const dateOptions = [
  {
    id: '1',
    name: 'Today',
    date: new Date(),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59);
    },
  },
  {
    id: '2',
    name: 'Yesterday',
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()); 
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59);
    },
  },
  {
    id: '3',
    name: 'This Week',
    date: new Date(),
    get start() { 
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay())); 
    },
    get end() {
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6));
    },
  },
  {
    id: '4',
    name: 'Past Week',
    date: new Date(),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7); 
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 1);
    },
  },
  {
    id: '5',
    name: 'This Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    },
  },
  {
    id: '6',
    name: 'Past Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1);
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    },
  },
  {
    id: '7',
    name: 'Last 7 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7);
    },
    get end() {
      return this.date;
    },
  },
  {
    id: '8',
    name: 'Last 30 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 30);
    },
    get end() {
      return this.date;
    },
  },
];
