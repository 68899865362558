import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faClone } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Switch from '@mui/material/Switch';
import { ModalContext } from '../../../../context';
import { getUserPsps, deleteUserPsp, updateUserPsp } from '../../../../redux/userPsp/userPspActions';
import CreatePSPModal from './CreatePSPModal';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from '../../../../DataTableSchemas/helper';
import { TooltipComponent } from '../../../../components/TooltipComponent/TooltipComponent';

function PSP() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const { id } = useParams();
  const { psps: userPsps } = useSelector((state) => state.userPsps);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddPSP = permissionName && permissionName.length > 0 && permissionName.includes('user_psp_add');
  const isUserCanDeletePSP = permissionName && permissionName.length > 0 && permissionName.includes('user_psp_delete');
  const isUserCanUpdatePSP = permissionName && permissionName.length > 0 && permissionName.includes('user_psp_update');

  const deletePSP = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteUserPsp(id));
        }
      });
  };

  useEffect(() => {
    if (id) dispatch(getUserPsps(id));
  }, [id]);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const setColumnName = (pspId, type) => {
    const name = pspId.name ?? pspId.address ?? pspId.label;

    if (type === 'wallet') {
      const isBitcoinXpubWallet = name.startsWith('xpub');

      return isBitcoinXpubWallet ? 'Bitcoin Xpub Wallet' : name;
    }

    return name;
  };

  const columns = [
    {
      name: 'Type',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ type }) => (
        <span>{ String(type ?? '').toUpperCase() }</span>
      ),
    },
    {
      name: 'Name/Adress',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ pspId = {}, type }) => {
        const namePsp = setColumnName(pspId, type);

        if (namePsp.length >= valueOutputCutLength.pspAddress) {
          return (
            <TooltipComponent
              title={namePsp}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={namePsp}>
                    <span style={{ cursor: 'pointer' }}>      
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(namePsp, 'pspAddress')}
                    </span>
                  </CopyToClipboard>
                </div>
            )}
            />
          ); 
        }

        return (
          <div>
            <CopyToClipboard text={namePsp}>
              <span style={{ cursor: 'pointer' }}>      
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {namePsp}
              </span>
            </CopyToClipboard>
          </div>
        );
      },
      minWidth: '100px',
    },
    {
      name: 'Enabled',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ isEnabled, _id }) => (
        <div>
          {
            isUserCanUpdatePSP ? (
              <Switch checked={isEnabled} color="success" onChange={() => { dispatch(updateUserPsp(_id, { isEnabled: !isEnabled })); }} />
            ) : (
              isEnabled ? '+' : '-'
            )
          }
        </div>
      ),
    },
    {
      name: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ _id }) => (
        <div>
          {
            isUserCanDeletePSP && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => deletePSP(_id)}
              >
                <FontAwesomeIcon icon={faTrash} className="header-icon" />
              </button>
            )
          }
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {
            isUserCanAddPSP && (
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={() => showModal({
                  headerContent: <h3>Add PSP</h3>,
                  bodyContent: <CreatePSPModal closeModal={hideModal} userId={id} />,
                })}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="sm"
                />
                Add New
              </button>
            )
          }
          </div>
        </div>
      </div>

      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        <DataTable
          columns={columns}
          data={userPsps}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationRowsPerPageOptions={[5, 10, 15]}
          persistTableHead
          highlightOnHover
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}

export default PSP;
