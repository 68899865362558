import React from 'react';

function FilterComponent({ filterText, onFilter, onClear }) {
  return (
    <div className="d-flex user-details-search">
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search here..."
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        autoComplete="off"
      />
      <button type="button" className="btn btn-default" onClick={onClear}>Clear</button>
    </div>
  );
}

export default FilterComponent;
