import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { createDatafeedSetting } from '../../../redux/datafeeds/datafeedsActions';
import { getCfdSettings } from '../../../redux/cfdSettings/cfdActions';
import { ModalContext } from '../../../context';

export function AddDatafeedSettingModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);

  const [formData, setFormData] = useState({ assetIds: [] });

  const settings = useSelector((state) => state.cfdSettings);
  const options = settings.map(({ label, _id }) => ({
    key: _id,
    value: _id,
    label,
  }));

  useEffect(() => {
    dispatch(getCfdSettings());
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = { 
      ...formData, 
      assetIds: formData.assetIds.map(({ value }) => value),
      isActive: formData.isActive ? JSON.parse(formData.isActive) : false,
    };

    await dispatch(createDatafeedSetting(data));
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      assetIds: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mt-2">
        <span>Name</span>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <span>Api Url</span>
        <input
          type="text"
          id="apiUrl"
          name="apiUrl"
          value={formData.apiUrl}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <span>Api Key</span>
        <input
          type="text"
          id="apiKey"
          name="apiKey"
          value={formData.apiKey}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <span>Socket Url</span>
        <input
          type="text"
          id="socketUrl"
          name="socketUrl"
          value={formData.socketUrl}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <span>Socket Key</span>
        <input
          type="text"
          id="socketKey"
          name="socketKey"
          value={formData.socketKey}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <span>Active</span>
        <select 
          name="isActive"
          className="form-control header-smtp-boxes-selector"
          style={{ width: '100%' }}
          value={formData.isActive}
          onChange={handleInputChange}
        >
          <option />
          <option value>
            yes
          </option>
          <option value={false}>
            no
          </option>
        </select>
      </div>

      <div className="form-group mt-2 custom-milti-select">
        <span>Assets</span>
        <MultiSelect
          name="assetIds"
          options={options}
          value={formData.assetIds}
          onChange={handleMultiSelectChange}
          labelledBy="Select"
          includeSelectAllOption="false"
        />
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Add
        </button>
      </div>
    </form>
  );
}
