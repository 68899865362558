import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';


export function ChipsButtonComponent({
  icon, onClick, onSelected, isSelected, ...rest 
}) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const isSelectedState = isSelected !== undefined ? isSelected : false;
    setSelected(isSelectedState);
  }, [isSelected]);

  const handleClick = () => {
    if (onSelected !== undefined) onSelected(!selected);
    if (onClick !== undefined) onClick(!selected);
  };

  const CustomChip = styled(Chip)(() => ({
    textTransform: 'capitalize',
    backgroundColor: selected ? '#3E4759' : '#1d1f38',
    boxShadow: selected 
      ? ('0px 1px 2px 0px  rgba(0, 0, 0, 0.15), 0px 1px 3px 1px rgba(0, 0, 0, 0.30)') 
      : ('0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)'),
    color: selected ? '#DAE2F9' : '#C4C6D0',
    fontFamily: 'Play',
    maxHeight: '33px',
    minHeight: '33px',
    marginTop: ' 4px',
    marginBottom: ' 4px',
    marginRight: ' 8px',
    paddingRight: '8px',
    borderRadius: '8px',
    ' svg': {
      marginLeft: '8px',
    },
    '&:hover': {
      backgroundColor: selected ? '#4A5366' : 'rgba(196, 198, 208, 0.08)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15),0px 2px 6px 2px rgba(0, 0, 0, 0.30)',
    },
    '&:focus': {
      backgroundColor: 'rgba(196, 198, 208, 0.12)',
    },
    '&.MuiChip-outlined': {
      borderColor: '#C4C6D0',
    },
    '&:hover.MuiChip-outlined': {
      borderColor: '#C4C6D0',
    },
    '&.Mui-disabled': {
      backgroundColor: '#E2E2E9',
      opacity: 0.5,
      color: 'var(--CFD-theme-System-OnSurface)',
      '&.MuiChip-outlined': {
        borderColor: '#C4C6D0',
      },
    },
  }));

  const PropsIcon = icon;
  const Icon = selected
    ? <CheckIcon style={{ color: '#DBE2F9' }} />
    : icon !== undefined ? <PropsIcon style={{ color: '#DBE2F9' }} /> : null;

  return (
    <CustomChip
      icon={Icon}
      onClick={handleClick}
      {...rest}
    />
  );
}
