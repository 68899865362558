export const cfdAccountsColumns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Assigned To',
    field: 'user.manager.firstName,user.manager.firstName',
    selected: true,
  },
  {
    name: 'Status',
    field: 'user.status.name',
    selected: true,
  },
  {
    name: 'Type',
    field: 'type.name',
    selected: true,
  },
  {
    name: 'Amount',
    field: 'total',
    selected: true,
  },
  {
    name: 'Currency',
    field: 'cfdAccountCurrency.symbol',
    selected: true,
  },
  {
    name: 'Credit',
    field: 'credit',
    selected: true,
  },
  {
    name: 'Balance',
    field: 'balance',
    selected: true,
  },
  {
    name: 'Leverage',
    field: 'leverage',
    selected: true,
  },
  {
    name: 'Lot Step',
    field: 'lotStep',
    selected: true,
  },
  {
    name: 'Lot Size',
    field: 'lotSize',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Swap Long',
    field: 'swapLong',
    selected: true,
  },
  {
    name: 'Swap Short',
    field: 'swapShort',
    selected: true,
  },
  {
    name: 'Margin',
    field: 'margin',
    selected: true,
  },
  {
    name: 'Margin Level',
    field: 'marginLevel',
    selected: true,
  },
  {
    name: 'Free Margin',
    field: 'freeMargin',
    selected: true,
  },
  {
    name: 'Created',
    field: 'cfdAccountCurrency.createdAt',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
  
