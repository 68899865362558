import React from 'react';
import ClientLoginActivity from './ClientLoginActivity';
import ClientActivityTable from './ClientActivityTable';

function ClientActivity() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <ClientActivityTable />
      <ClientLoginActivity />
    </div>
  );
}

export default ClientActivity;
