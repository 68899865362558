import React, {
  useState, useEffect, useImperativeHandle, forwardRef, 
} from 'react';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import { dateOptions } from './dateOptions';
import './style.css';

export const TimeInitiatedFilter = forwardRef(({
  data = [], setFilter, setToAllFilters, allFilters = {}, storageName, tabName = 'Time Initiated', 
}, ref) => {
  const storage = localStorage.getItem(storageName);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filtredItems, setFilteredItems] = useState(dateOptions);

  const filterDataBySelectedTime = (ids) => {
    if (!ids?.length) return [];
        
    const selectedDates = dateOptions.filter((option) => ids.includes(option.id));
    const filtered = [];

    if (selectedDates.length) {
      selectedDates.forEach(({ start, end }) => {
        data.forEach((item) => {
          const itemDate = new Date(item.createdAt);

          if (itemDate >= start && itemDate <= end) {
            if (!filtered.includes(item._id)) filtered.push(item._id);
          }
        });
      });
    }

    return filtered.length ? filtered : null;
  };

  const setDataFromStorage = () => {
    if (storage) {
      const parsedStorage = JSON.parse(storage);
      const ids = parsedStorage.timeOptionIds || [];
      setSelectedOptions(ids);
      setFilter(filterDataBySelectedTime(ids));
    }
  };

  useImperativeHandle(ref, () => ({
    clear() {
      setSelectedOptions([]);
    },
    set(ids) {
      setSelectedOptions(ids);
      setFilter(filterDataBySelectedTime(ids));
    },
  }));

  const handleFilter = () => {
    const filteredResult = dateOptions.filter((option) => option.name.toLowerCase().includes(filterText.toLowerCase()));

    if (filteredResult.length) {
      setFilteredItems(filteredResult);
    }
  };

  useEffect(() => {
    setDataFromStorage();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filterText]);

  const handleSelect = (id) => {
    let ids = [];

    if (selectedOptions.includes(id)) {
      ids = selectedOptions.filter((option) => option !== id);
    } else {
      ids = [...selectedOptions, id];
    }

    setSelectedOptions(ids);
    setFilter(filterDataBySelectedTime(ids));
    setToAllFilters({ ...allFilters, timeOptionIds: ids });
  };

  const handleSelectAll = () => {
    const ids = dateOptions.map((item) => item.id);

    setSelectedOptions(ids);
    setFilter(filterDataBySelectedTime(ids));
    setToAllFilters({ ...allFilters, timeOptionIds: ids });
  };

  const handleClearAll = () => {
    const ids = [];

    setSelectedOptions(ids);
    setFilter(filterDataBySelectedTime(ids));
    setToAllFilters({ ...allFilters, timeOptionIds: ids });
  };

  return (
    <div className="time-filter__container">
      <div
        className="time-filter__tab-name" 
        onClick={() => setShowFilter(!showFilter)}
      >
        {tabName}
        {!showFilter ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {showFilter ? (
        <div className="leads-modal-filter">
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => { setShowFilter(false); }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search"
            className="leads-search-bar-filter"
            value={filterText}
            onChange={({ target: { value } }) => setFilterText(value)}
          />
          <button type="button" onClick={() => handleSelectAll()}>Check all</button>
          <button type="button" onClick={() => handleClearAll()}>Uncheck all</button>
          <ul className="time-filter__list">
            {filtredItems.map((item) => (
              <li
                className="time-filter__option"
                style={{ backgroundColor: selectedOptions.includes(item.id) ? '#061426' : '' }}
                onClick={() => handleSelect(item.id)}
                key={item.id}
              >
                {selectedOptions.includes(item.id) ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#6E7F95"
                  />
                ) : null}
                <span className="time-filter__option-name">{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
});
