export const userOpenOrdersColumns = [
  {
    name: 'ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'Type',
    field: 'tradeType',
    selected: true,
  },
  {
    name: 'Symbol',
    field: 'setting.symbol',
    selected: true,
  },
  {
    name: 'Open Price',
    field: 'tradeStartPrice',
    selected: true,
  },
  {
    name: 'Volume',
    field: 'position',
    selected: true,
  },
  {
    name: 'Time Opened',
    field: 'timeOpened',
    selected: true,
  },
  {
    name: 'TP',
    field: 'takeProfitPrice',
    selected: true,
  },
  {
    name: 'SL',
    field: 'stopLossPrice',
    selected: true,
  },
  {
    name: 'PNL',
    field: '',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'swap',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
  
