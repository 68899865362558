import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_EVENTS, DELETE_EVENT, UPDATE_EVENT, ADD_EVENT, 
} from './eventTypes';

export const getEvents = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/event/${id}`, '');

    if (res && res.data && res.data.events) {
      dispatch({
        type: GET_EVENTS,
        payload: res.data.events,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getEventsByDateRange = (query) => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/event/date', '', query);

    if (res && res.data && res.data.events) {
      dispatch({
        type: GET_EVENTS,
        payload: res.data.events,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getEventsByUserId = (id, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/event/${id}/${userId}`, '');

    if (res && res.data && res.data.events) {
      dispatch({
        type: GET_EVENTS,
        payload: res.data.events,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const addEvent = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/event/', data);

    if (res && res.data && res.data.event) {
      dispatch({
        type: ADD_EVENT,
        payload: res.data.event,
      });
      toast.success('Event was successfully created');
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateEventById = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/event/${id}`, data);

    if (res && res.data && res.data.event) {
      dispatch({
        type: UPDATE_EVENT,
        payload: res.data.event,
      });
      toast.success('Event was successfully updated');
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/event/${id}`, '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_EVENT,
        payload: id,
      });
      toast.success('Event was successfully deleted');
    }
  } catch (error) {
    toast.error(error.message);
  }
};
