import {
  React, useState, useEffect, useMemo,
  useRef, 
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { updateClosedCfdOrder } from '../../redux/cfdOrder/cfdOrderActions';
import { getFormattedTimeZoneOutput } from '../../helpers/helper';

export function EditOrderModal({ order = {}, hideModal }) {
  const dispatch = useDispatch();
  const [updatedOrder, setUpdatedOrder] = useState({});
  const previousOrderRef = useRef({});

  useEffect(() => {
    const {
      tradeStartPrice, 
      tradeEndPrice,
      exitPrice, 
      position, 
      timeOpened, 
      closedAt, 
    } = order;

    setUpdatedOrder({
      tradeStartPrice,
      tradeEndPrice,
      position,
      exitPrice,
      timeOpened: moment(timeOpened).format('YYYY-MM-DD HH:mm:ss'),
      closedAt: moment(closedAt).format('YYYY-MM-DD HH:mm:ss'),
    });
  }, [order]);

  useEffect(() => {
    let affectedField;

    // define the field that was affected
    if (previousOrderRef.current && updatedOrder) {
      Object.keys(updatedOrder).forEach((key) => {
        if (previousOrderRef.current[key] !== updatedOrder[key]) {
          affectedField = key;
        }
      });
    }

    // update prev order data ref after affectedField defined
    previousOrderRef.current = updatedOrder;

    if (
      !updatedOrder
      || Object.keys(updatedOrder).length === 0
      || updatedOrder.exitPrice === undefined || updatedOrder.exitPrice === null
      || updatedOrder.position === undefined || updatedOrder.position === null
      || updatedOrder.tradeStartPrice === undefined || updatedOrder.tradeStartPrice === null
      || updatedOrder.tradeEndPrice === undefined || updatedOrder.tradeEndPrice === null
    ) {
      return;
    }

    const {
      exitPrice, position, tradeStartPrice, tradeEndPrice, 
    } = updatedOrder;
    
    if (affectedField === 'exitPrice') {
      const newTradeEndPrice = +parseFloat(tradeStartPrice + exitPrice / position).toFixed(8);

      if (+tradeEndPrice.toFixed(8) !== newTradeEndPrice) {
        previousOrderRef.current = {
          ...updatedOrder,
          tradeEndPrice: newTradeEndPrice,
        };
        setUpdatedOrder((prevOrder) => ({
          ...prevOrder,
          tradeEndPrice: newTradeEndPrice,
        }));
      }
    } else if (affectedField !== undefined) {
      const calculatedProfit = parseFloat((tradeEndPrice - tradeStartPrice).toFixed(8)) * position;
    
      if (calculatedProfit.toFixed(8) !== exitPrice.toFixed(8)) {
        previousOrderRef.current = {
          ...updatedOrder,
          exitPrice: +calculatedProfit.toFixed(8),
        };
        setUpdatedOrder((prevOrder) => ({
          ...prevOrder,
          exitPrice: +calculatedProfit.toFixed(8),
        }));
      }
    }
  }, [updatedOrder.position, updatedOrder.tradeStartPrice, updatedOrder.exitPrice, updatedOrder.tradeEndPrice]);

  const numericFields = useMemo(() => [
    'exitPrice',
    'position',
    'tradeEndPrice',
    'tradeStartPrice',
  ], []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isNumber = numericFields.includes(name);

    const validatedValue = isNumber ? (value === '' ? 1 : parseFloat(value)) : value;

    setUpdatedOrder((prev) => ({
      ...prev,
      [name]: validatedValue,
    }));
  };

  const handleClose = () => {
    setUpdatedOrder({});
    hideModal();
  };

  const updateOrder = () => {
    if (!order._id) return;

    dispatch(updateClosedCfdOrder(order._id, updatedOrder));
    handleClose();
  };

  return (
    !!Object.keys(updatedOrder).length && (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeStartPrice}
            name="tradeStartPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Close</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Price Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeEndPrice}
            name="tradeEndPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            name="position"
            value={updatedOrder.position}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Profit</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Profit"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.exitPrice}
            name="exitPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Opened</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Opened"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.timeOpened}
            name="timeOpened"
            onChange={handleChange}
          />
          <div>
            { `Time zone: ${getFormattedTimeZoneOutput()}` }
          </div>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Closed</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.closedAt}
            name="closedAt"
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={updateOrder}>Save</button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
      </div>
    </>
    )
  );
}
