import React, {
  useState, useEffect, useContext, useCallback, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Button, Row, Col, InputGroup, Form, 
} from 'react-bootstrap';
import { closeSavingInvestment } from '../../redux/savingInvestment/savingInvestmentActions';
import { getAccountOptionsByUserId } from '../../redux/cfdAccount/cfdAccountActions';
import { getCurrencyRates } from '../../redux/currencyRate/currencyRateActions';
import { ModalContext } from '../../context';
import { toFixed } from '../../helpers/utils';
    
export function StopSavingModal({ data = {} }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [formData, setFormData] = useState({
    amount: 0,
    accountId: '',
  });

  const accountOptions = useSelector((state) => state.cfdAccounts.options);
  const currencyRates = useSelector((state) => state.currencyRates);

  const isBtnDisabled = !String(formData.investedAmount).length || !String(formData.accountId).length;

  useEffect(() => {
    if (!formData.accountId) return;
    const symbol = accountOptions.find(({ value }) => value === formData.accountId)?.symbol;

    if (symbol) dispatch(getCurrencyRates(symbol));
  }, [formData.accountId]);
    
  useEffect(() => {
    if (Object.keys(data).length) {
      setFormData({
        amount: data.finalEarnings ?? 0,
        accountId: '',
      });

      dispatch(getAccountOptionsByUserId(data.userId));
      console.log(data);
    }
  }, [data]);
    
  const closeModal = () => {
    hideModal();
  };
    
  const submit = async () => {
    const payload = {
      amount: formData.amount,
      accountId: formData.accountId,
    };
    
    await dispatch(closeSavingInvestment(data._id, payload));
    closeModal();
  };
    
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !isBtnDisabled) {
      submit();
    }
  }, [isBtnDisabled, formData]);
    
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  
  const handleChangeField = (field, value) => {
    if (field === 'amount' && value < 0) return;

    setFormData((prev) => ({
      ...prev,
      [field]: (field === 'amount' && value < 0) ? 0 : value,
    }));
  };

  const showConvertedAmount = () => {
    if (
      !formData.amount 
        || !formData.accountId 
        || !data.currency 
        || !currencyRates 
        || !currencyRates[data.currency.symbol]
    ) return '';

    const fromSymbol = data.currency.symbol;
    const accountSymbol = accountOptions.find(({ value }) => value === formData.accountId)?.symbol;
    const convertedAmount = formData.amount / currencyRates[fromSymbol];

    return `${formData.amount} ${fromSymbol} = ${toFixed(convertedAmount, 2)} ${accountSymbol}`;
  };
    
  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">{`User Invested (in ${data?.currency?.symbol ?? ''}) :`}</label>
            <InputGroup className="mb-3 t-settings_input-group" disabled>
              <Form.Control 
                disabled
                value={data.investedAmount} 
                type="number" 
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">{`User Receive (in ${data?.currency?.symbol ?? ''}) :`}</label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <InputGroup className="t-settings_input-group">
                <Form.Control 
                  value={formData.amount} 
                  onChange={({ target: { value } }) => handleChangeField('amount', value)}
                  onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">To CFD Account: </label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <div className="input-wrapper">
                <Form.Select name="account" value={formData.accountId} onChange={({ target: { value } }) => handleChangeField('accountId', value)}>
                  <option value="">Select Account</option>
                  { 
                    accountOptions.map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))
                }
                </Form.Select>
                <span style={{ fontSize: '12px' }}>
                  { showConvertedAmount() }
                </span>
                
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Close</Button>
        </Col>
      </Row>
    </div>
  );
};
    
  
