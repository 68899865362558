import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import { ModalContext } from '../../context';
import SmsServiceEdit from '../../pages/SmsServices/SmsServiceEdit/SmsServiceEdit';
import { selectIsSuperUser } from '../../redux/emailTemplates/emailTemplatesSelectors';
import {
  deleteSmsServiceById, setConfigNameFilter, setIsActiveFilter, setServiceNameFilter, 
} from '../../redux/smsServices/smsServicesActions';
import {
  selectConfigNameFilter, selectIntegratedServices, selectIsActiveFilter, selectServiceNameFilter, 
} from '../../redux/smsServices/smsServicesSelectors';
import styles from './SmsServicesColumns.module.css';

export const SmsServicesColumns = () => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditTemplatePermission = permissions && permissions.length > 0 && permissions.includes('edit_sms_service'); 

  const integratedServices = useSelector(selectIntegratedServices);

  // filters variables
  const configNameFilter = useSelector(selectConfigNameFilter);
  const serviceNameFilter = useSelector(selectServiceNameFilter);
  const isActiveFilter = useSelector(selectIsActiveFilter);

  const isSuperUser = useSelector(selectIsSuperUser);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSmsServiceById(id));
      }
    });
  };

  const editAction = (id) => {
    showModal({
      headerContent: <h3>Edit Sms Service</h3>,
      bodyContent: <SmsServiceEdit id={id} />,
    });
  };

  const copyMethod = async (value) => {
    await navigator.clipboard.writeText(value);
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Config Name
          <input
            type="text"
            name="configName"
            autoComplete="off"
            value={configNameFilter}
            placeholder="Filter by config name"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setConfigNameFilter(e.target.value))}
          />
        </div>
      ),
      selector: (row) => (
        <span>
          {row?.config_name}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Service Name
          <select
            name="status"
            value={serviceNameFilter}
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setServiceNameFilter(value))}
          >
            <option value="">All</option>
            {integratedServices.map((service) => <option key={service}>{service}</option>)}
          </select>
        </div>
      ),
      width: '250px',
      selector: ({ service_name }) => `${service_name}`,
      cell: ({ service_name }) => (
        <>
          <span data-tip={service_name}>
            {service_name}
          </span>
          <ReactTooltip />
        </>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Status
          <select
            name="status"
            value={isActiveFilter}
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setIsActiveFilter(value))}
          >
            <option value="">All</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
      ),
      selector: ({ is_active }) => `${is_active}`,
      cell: ({ is_active }) => (
        <span data-tip={is_active}>
          {is_active ? 'active' : 'inactive'}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Auth Data
        </div>
      ),
      cell: ({ auth_data = {} }) => (
        <div className={styles.authDataCell}>
          {
              Object.entries(auth_data).map(([key, value]) => {
                const cutValue = value.length > 10 ? `${value.slice(0, 10)}...` : value;

                return (
                  <span
                    onClick={() => copyMethod(value)}
                    data-tip={`${key}: ${value}`}
                  >
                    {`${key}: ${cutValue}`} 
                  </span>
                );
              })
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Additional Params
        </div>
      ),
      cell: ({ additional_params = {} }) => (
        <div className={styles.additionalParamsCell}>
          {
              Object.entries(additional_params).map(([key, value]) => {
                const cutValue = value.length > 10 ? `${value.slice(0, 10)}...` : value;

                return (
                  <span
                    onClick={() => copyMethod(value)}
                    data-tip={`${key}: ${value}`}
                  >
                    {`${key}: ${cutValue}`} 
                  </span>
                );
              })
            }
        </div>
      ),
    },
    {
      name: 'Actions',
      cell: ({ _id }) => (
        <>
          {isUserHasEditTemplatePermission ? (
            <button
              className="btn btn-warning btn-sm me-1 p-1"
              type="button"
              onClick={() => editAction(_id)}
            >
              Edit
            </button>
          ) : null}
          {isSuperUser ? (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(_id)}
            >
              <BsTrashFill size={18} />
            </button>
          ) : null}
        </>
      ),
    },
  ];

  return columns;
};
