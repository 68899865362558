import React, {
  useContext, useEffect, useRef, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { WalletAddressesModal } from './WalletAddressesModal';
import { getAdminAddresses } from '../../redux/adminAddress/adminAddressActions';
import { AdminAddressPspSchema } from '../../DataTableSchemas/AdminAddressPspSchema';
import { useObjectDebounce } from '../../hooks/useDebounce';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { AdminWalletsColumns } from '../../columnsDefaultConfig/AdminWalletsColumns';
import { DatatableFilters } from '../../components';
import { ModalContext } from '../../context';

function AdminAddress() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const { showModal: showModalContext } = useContext(ModalContext);
  const datatableFiltersRef = useRef(null);

  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState();

  const filtersStorageName = 'DataTable_adminWallet/filters'; 
  const filterIdStorageName = 'DataTable_adminWallet/filter_id'; 
  const columnsStorageName = 'DataTable_adminWallet/columns';
  const paginationStorageName = 'DataTable_adminWallet/pagination';

  const filtersJSON = localStorage.getItem(filtersStorageName);
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const [columnConfig, setColumnConfig] = useState(AdminWalletsColumns);
  const [walletsAccountFilters, setWalletsAccountFilters] = useState({});
  const [tempFilters, setTempFilters] = useState({});

  const tableLoading = useSelector((state) => state?.adminAddress?.isLoading);
  const adminWalletsAccountsCount = useSelector((state) => state?.adminAddress?.totalCount);
  const adminAddresses = useSelector((state) => state?.adminAddress?.adminAddresses);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const columns = AdminAddressPspSchema({
    permissionName,
    setIsModalShown,
    setSelectedAddressId,
    columnConfig,
    tempFilters,
    setTempFilters,
    showModalContext,
  });

  const setStoredPagination = () => {
    const rowsJSON = localStorage.getItem(paginationStorageName);
    if (rowsJSON) {
      const filterRows = JSON.parse(rowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const refresh = async () => {
    await dispatch(getAdminAddresses(page, rowsPerPage, walletsAccountFilters));
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handlePageChange = async (page) => setPage(page);

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    setPage(page);
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handleClear = () => {
    setTempFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setCRMFilters = (filters) => {
    setTempFilters({
      network: filters.fullName || '',
      address: filters.email || '',
      currency: filters.phone || '',
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filters._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.network || '',
      email: filters.address || '',
      phone: filters.currency || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);

      const data = {
        fullName: filters.network || '',
        email: filters.address || '',
        phone: filters.currency || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setTempFilters(filters || {});
    }
  };

  useEffect(() => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(walletsAccountFilters));
      dispatch(getAdminAddresses(page, rowsPerPage, walletsAccountFilters));
    } else {
      setStoredPagination();
      setStoredColumnsData();
      setStoredFilterData();
    }
  }, [isPaginationDT, page, rowsPerPage, walletsAccountFilters]);

  useObjectDebounce(tempFilters, 300, (newFilters) => {
    setWalletsAccountFilters((prev) => ({ ...prev, ...newFilters }));
  });

  const hideModal = (id) => {
    setIsModalShown(false);
    setSelectedAddressId();
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Admin Wallets</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {
                permissionName && permissionName.length > 0 && permissionName.includes('add_admin_address')
                  ? (
                    <Link to="/add-admin-address">
                      <button type="button" className="btn-primary_light">
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                        Add Wallet
                      </button>
                    </Link>
                  )
                  : null
              }
            </div>
            <div className="secondary_btn-row">
              {crmFilters && (
                <DatatableFilters
                  ref={datatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                />
              )}

              <Dropdown className="leads-columns-dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="btn-secondary_dark"
                >
                  Columns
                </Dropdown.Toggle>
                <Dropdown.Menu className="primary-menu">
                  <ul className="leads-columns-list">
                    {columnConfig.map((column) => (
                      <li
                        key={column.name}
                        className="leads-columns-option"
                        onClick={() => toggleColumn(column.name)}
                      >
                        {column.selected ? (
                          <FontAwesomeIcon
                            size="xs"
                            icon={faCheck}
                            color="#6E7F95"
                          />
                        ) : null}
                        <span className="leads-columns-option__name">
                          {column.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              
              <button
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>

              <button 
                type="button"
                className="btn-secondary_dark iconed" 
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>
        {
          isPaginationDT && (
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
              <DataTable
                columns={columns}
                data={adminAddresses}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={adminWalletsAccountsCount}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[25, 50, 200, 500]}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                persistTableHead
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
              <WalletAddressesModal isShown={isModalShown} hideModal={hideModal} adminAddressId={selectedAddressId} />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default AdminAddress;
