import React, { useState } from 'react';

import styles from '../AffiliateDashboardFilter.module.css';

export function DatePickerComponent({
  valueDatePicker, 
  disabled, 
  labelTitle, 
  datePickerId, 
  timePickerId, 
  valueTimePicker, 
  setDate,
  setTime,
}) {
  return (
    <div className={styles.wrapperFilterDashboardDatePicker}>
      <label className={styles.filterDashboardPeriodTitle} htmlFor={datePickerId}>
        {`${labelTitle}:`}
      </label>
      <div className={styles.containerFilterDashboardDatePicker}>
        <input 
          type="date" 
          name={datePickerId} 
          id={datePickerId} 
          className={styles.filterDashboardDatePicker} 
          value={valueDatePicker} 
          onChange={(e) => setDate(e.target.value)} 
          disabled={disabled}
        />
        <input 
          type="time" 
          name={datePickerId} 
          id={timePickerId} 
          className={styles.filterDashboardDatePicker} 
          value={valueTimePicker} 
          onChange={(e) => setTime(e.target.value)} 
          disabled={disabled}
        />
      </div>
    </div>
  );
}

