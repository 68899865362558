import React from 'react';
import { Chart } from 'react-google-charts';

export function DonutChart({ data, colors, isMobile = false }) {
  const options = {
    backgroundColor: 'transparent',
    chartArea: {
      left: isMobile ? 60 : 122,
      top: 40,
      width: '53%',
      height: '53%', 
    },
    pieHole: 0.4,
    is3D: false,
    legend: { 
      position: isMobile ? 'bottom' : 'right', 
      textStyle: { 
        color: '#E2E2E9', 
        fontSize: 14, 
      }, 
    },
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
}
