import {
  GET_INDICES_SETTINGS,
  GET_INDICES_SETTING,
  ADD_INDICES_SETTING,
  EDIT_INDICES_SETTING,
  DELETE_INDICES_SETTING,
  TOGGLE_INDICES_SETTING_STATE,
  ERROR_INDICES_SETTING_STATE,
} from './indicesSettingTypes';
  
const initialState = {
  indicesSettings: [],
  indicesSetting: {},
  indicesSettingsFetched: false,
  indicesSettingAdded: false,
  indicesSettingFetched: false,
  indicesSettingEditted: false,
  indicesSettingDeleted: false,
  indicesSettingError: false,
};
  
// eslint-disable-next-line default-param-last
const indicesSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDICES_SETTINGS:
      return {
        ...state,
        indicesSettings: action.payload,
        indicesSettingsFetched: true,
      };
    case GET_INDICES_SETTING:
      return {
        ...state,
        indicesSetting: action.payload,
        indicesSettingFetched: true,
      };
    case ADD_INDICES_SETTING:
      return {
        ...state,
        indicesSettings: [state.indicesSettings, action.payload],
        indicesSettingAdded: true,
      };
    case EDIT_INDICES_SETTING:
      return {
        ...state,
        indicesSetting: action.payload,
        indicesSettingEditted: true,
      };
    case DELETE_INDICES_SETTING:
      return {
        ...state,
        indicesSettings: state.indicesSettings.filter((item) => item._id !== action.payload),
        indicesSettingDeleted: true,
      };
    case TOGGLE_INDICES_SETTING_STATE:
      return {
        ...state,
        indicesSettingsFetched: false,
        indicesSettingAdded: false,
        indicesSettingFetched: false,
        indicesSettingEditted: false,
        indicesSettingDeleted: false,
        indicesSettingError: false,
      };
    case ERROR_INDICES_SETTING_STATE:
      return {
        ...state,
        indicesSettingError: true,
      };
    default:
      return state;
  }
};
  
export default indicesSettingReducer;
