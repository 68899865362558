import React, {
  useEffect, useState, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  selectEmailTemplates, selectEventSearchFilter, selectIsEmailTemplatesLoading, selectIsSuperUser, selectStatusSearchFilter, selectTitleSearchFilter, selectTotalCount, 
} from '../../redux/emailTemplates/emailTemplatesSelectors';
import { getEmailTemplates, makeSuperUserCheck } from '../../redux/emailTemplates/emailTemplatesActions';
import { EmailTemplatesColumns } from '../../DataTableSchemas/EmailTemplatesColumns';
import EmailTemplateCreate from './EmailTemplateCreate/EmailTemplateCreate';
import { ModalContext } from '../../context';
import { useMultiDebounce } from '../../hooks/useMultiDebounce';

const useInitEffect = (setStoredPagination) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeSuperUserCheck());
    setStoredPagination();
  }, []);
};

function EmailTemplates() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const paginationStorageName = 'DataTable_emailTemplates/listing_pagination';

  // templates variables
  const isSuperUser = useSelector(selectIsSuperUser);
  const emailTemplates = useSelector(selectEmailTemplates);
  const isEmailTemplatesLoading = useSelector(selectIsEmailTemplatesLoading);

  // filters variables
  const eventSearchFilter = useSelector(selectEventSearchFilter);
  const titleSearchFilter = useSelector(selectTitleSearchFilter);
  const statusSearchFilter = useSelector(selectStatusSearchFilter);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create Email Template</h3>,
      bodyContent: <EmailTemplateCreate hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  useEffect(() => {
    dispatch(getEmailTemplates(page, rowsPerPage, {
      event: eventSearchFilter,
      title: titleSearchFilter,
      status: statusSearchFilter,
    }));
  }, [page, rowsPerPage, statusSearchFilter]);

  useMultiDebounce([
    eventSearchFilter, 
    titleSearchFilter, 
  ], 500, () => {
    if (eventSearchFilter !== null || titleSearchFilter !== null) {
      dispatch(getEmailTemplates(page, rowsPerPage, {
        event: eventSearchFilter,
        title: titleSearchFilter,
        status: statusSearchFilter,
      }));
    }
  });

  useInitEffect(setStoredPagination);

  const emailTemplatesCol = EmailTemplatesColumns();

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Email Templates Details</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isSuperUser && (
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={handleCreateBtn}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </button>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={emailTemplatesCol}
                  data={emailTemplates}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isEmailTemplatesLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default EmailTemplates;
