import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  CREATE_SPREAD_SETTING, GET_SPREAD_SETTINGS_BY_USER_ID, DELETE_SPREAD_SETTING, UPDATE_SPREAD_SETTING,
} from './userSpreadTypes';

export const getSpreadSettingsByUserId = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-spread/user/${id}`, '');
  
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_SPREAD_SETTINGS_BY_USER_ID,
        payload: res.data.settings,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const createSpreadSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/user-spread', data);
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: CREATE_SPREAD_SETTING,
        payload: res.data.setting,
      });
    
      toast.success('Spread setting was successfully created');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateSpreadSetting = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/user-spread/${id}`, data);
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_SPREAD_SETTING,
        payload: res.data.setting,
      });
    
      toast.success('Spread setting was successfully updated');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSpreadSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/user-spread/${id}`, '');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_SPREAD_SETTING,
        payload: id,
      });
      
      toast.success('Spread setting was successfully deleted');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const searchSettings = (userId, pairName) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-spread/search?userId=${userId}&pairName=${pairName}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_SPREAD_SETTINGS_BY_USER_ID,
        payload: res.data.settings,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
