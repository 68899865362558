import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Row, Col, 
} from 'react-bootstrap';
import { ModalContext } from '../../../../context';
import { updateUserAccount, getCfdAccountsByQuery } from '../../../../redux/cfdAccount/cfdAccountActions';
import { geAccountCurrencies } from '../../../../redux/cfdAccountCurrency/cfdAccountCurrencyActions';
import { getCFDAccountTypes } from '../../../../redux/cfdAccountType/cfdAccountTypeActions';

const enabledOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

function EditAccountModal({ row }) {
  const dispatch = useDispatch();
  const [currentAccountCurrency, setCurrentAccountCurrency] = useState(row.cfdAccountCurrency.symbol);
  const [currentTypeAccount, setCurrentTypeAccount] = useState(row.type.name);
  const { hideModal } = useContext(ModalContext);

  const user = useSelector((state) => state?.users?.user);
  const accountCurrencies = useSelector((state) => state.cfdAccountCurrencies);
  const accountTypes = useSelector((state) => state.cfdAccountTypes);

  const [formData, setFormData] = useState(
    {
      leverage: row.leverage,
      lotSize: row.lotSize,
      lotStep: row.lotStep,
      commission: row.commission,
      swapLong: row.swapLong,
      swapShort: row.swapShort,
      isEnabled: row.isEnabled,
      userId: row.userId,
      accountCurrency: row.cfdAccountCurrency._id,
      accountType: row.type._id,
    }, 
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(updateUserAccount(row._id, formData));
    await dispatch(getCfdAccountsByQuery({ page: 1, limit: 5, query: { userId: row.userId, isSaving: JSON.stringify([false, true]) } }));
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
   
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1'),
    }));    
  };

  useEffect(() => {
    Promise.allSettled([
      dispatch(geAccountCurrencies()),
      dispatch(getCFDAccountTypes()),
    ]);
  }, []);

  const currencyAccountHandler = (currency) => {
    setFormData((prevState) => ({ ...prevState, accountCurrency: currency._id }));
    setCurrentAccountCurrency(currency.symbol);
  };

  const typeAccountHandler = (type) => {
    setFormData((prevState) => ({ ...prevState, accountType: type._id }));
    setCurrentTypeAccount(type.name);
  };

  return (
    <form onSubmit={handleSubmit}>

      { (user.clientType === 1 && user.deposits.length === 0)
      && (
      <>
        <Row className="mb-15">
          <Col md={12}>
            <div className="form-group me-2">
              <label className="control-label mb-2 label-with-info">
                <span>Account Type</span>
              </label>
              <Dropdown className="t-settings__dropdown">
                <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{currentTypeAccount}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {accountTypes.map((el) => (
                    <Dropdown.Item key={el._id} onClick={() => typeAccountHandler(el)}>{el.name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <Row className="mb-15">
          <Col md={12}>
            <div className="form-group me-2">
              <label className="control-label mb-2 label-with-info">
                <span>Account Currency</span>
              </label>
              <Dropdown className="t-settings__dropdown">
                <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{currentAccountCurrency}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {accountCurrencies.map((el) => (
                    <Dropdown.Item key={el._id} onClick={() => currencyAccountHandler(el)}>{el.symbol}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </>
      ) }
      
      <div className="form-group mb-2">
        <label htmlFor="leverage">Leverage</label>
        <input
          type="text"
          id="leverage"
          name="leverage"
          className="form-control"
          onChange={handleInputChange}
          value={formData.leverage ?? 0}
        />
      </div>
      
      <div className="form-group mb-2">
        <label htmlFor="lotStep">Lot Step</label>
        <input
          type="text"
          id="lotStep"
          name="lotStep"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotStep ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="lotSize">Lot Size</label>
        <input
          type="text"
          id="lotSize"
          name="lotSize"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotSize ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="commission">Commission %</label>
        <input
          type="text"
          id="commission"
          name="commission"
          className="form-control"
          onChange={handleInputChange}
          value={formData.commission ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="swapLong">Swap Long %</label>
        <input
          type="text"
          id="swapLong"
          name="swapLong"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapLong ?? 0}
        />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="swapShort">Swap Short %</label>
        <input
          type="text"
          id="swapShort"
          name="swapShort"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapShort ?? 0}
        />
      </div>

      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Enabled Trading</span>
            </label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{formData.isEnabled ? 'Yes' : 'No'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {enabledOptions.map((el) => (
                  <Dropdown.Item key={el.label} onClick={() => setFormData((prevState) => ({ ...prevState, isEnabled: el.value }))}>{el.label}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Save
        </button>
      </div>
    </form>
  );
}

export default EditAccountModal;
