/* eslint-disable default-param-last */
import {
  GET_CLIENT_LOGINS,
  GET_CLIENT_LOGIN,
  ADD_CLIENT_LOGIN,
  EDIT_CLIENT_LOGIN,
  DELETE_CLIENT_LOGIN,
  TOGGLE_CLIENT_LOGIN_STATE,
  ERROR_CLIENT_LOGIN_STATE,
} from './clientLoginTypes';

const initialState = {
  clientLogins: [],
  clientLogin: {},
  clientLoginsFetched: false,
  clientLoginAdded: false,
  clientLoginFetched: false,
  clientLoginEditted: false,
  clientLoginDeleted: false,
  clientLoginError: false,
};

const clientLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_LOGINS:
      return {
        ...state,
        clientLogins: action.payload,
        clientLoginsFetched: true,
      };
    case GET_CLIENT_LOGIN:
      return {
        ...state,
        clientLogin: action.payload,
        clientLoginFetched: true,
      };
    case ADD_CLIENT_LOGIN:
      return {
        ...state,
        clientLogins: [state.clientLogins, action.payload],
        clientLoginAdded: true,
      };
    case EDIT_CLIENT_LOGIN:
      return {
        ...state,
        clientLogin: action.payload,
        clientLoginEditted: true,
      };
    case DELETE_CLIENT_LOGIN:
      return {
        ...state,
        clientLogins: state.clientLogins.filter((item) => item._id !== action.payload),
        clientLoginDeleted: true,
      };
    case TOGGLE_CLIENT_LOGIN_STATE:
      return {
        ...state,
        clientLoginsFetched: false,
        clientLoginAdded: false,
        clientLoginFetched: false,
        clientLoginEditted: false,
        clientLoginDeleted: false,
        error: false,
      };
    case ERROR_CLIENT_LOGIN_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default clientLoginReducer;
