const padTo2Digits = (num) => num.toString().padStart(2, '0');

export const formatDate = (date) => (
  `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-')
  } ${
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')}`
);

export const formatTime = (hours, minutes) => {
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return 'Invalid time';
  }

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
