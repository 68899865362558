import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChatList, ActiveChat } from './components';
import socket from '../../services/socket';
import {
  getChats, 
  getChat, 
  sendMessage, 
  getMessage, 
  readMessages, 
  clearActiveChat,
} from '../../redux/chat/chatActions';
import './style.css';

function ChatBox() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newMessage, setNewMessage] = useState('');
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);

  const chats = useSelector((state) => state.chatsInfo.chats);
  const activeChat = useSelector((state) => state.chatsInfo.activeChat);

  const userId = localStorage.getItem('userId');
  const chatBoxRef = useRef(null);
  const fileInputRef = useRef(null);

  const scrollDown = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const subscribe = (chats) => {
    chats.forEach((chat) => {
      socket.on(`getChatMessage/${chat._id}/${chat.crmUser}`, (response) => {
        dispatch(getMessage(chat.crmUser, response));
        scrollDown();
      });
    });
  };

  const unsubscribe = (chats) => {
    chats.forEach((chat) => {
      socket.off(`getChatMessage/${chat._id}/${chat.crmUser}`);
    });
  };

  useEffect(async () => {
    if (userId) {
      setLoader(true);
      await dispatch(getChats(JSON.parse(userId)));
      setLoader(false);
      if (chatBoxRef.current) scrollDown(); 
    } 
  }, []);

  useEffect(() => {
    Object.keys(chats).forEach((crmUserId) => {
      const crmUserChats = chats[crmUserId];
      subscribe(crmUserChats);
    });

    return () => {
      Object.keys(chats).forEach((crmUserId) => {
        const crmUserChats = chats[crmUserId];
        unsubscribe(crmUserChats);
      });
    };
  }, [chats]);

  useEffect(() => {
    if (chatBoxRef.current) scrollDown(); 
  }, [activeChat]);

  const getActiveChat = (id) => {
    dispatch(getChat(id));
    history.replace(`/chatbox/${id}`);
  };

  useEffect(() => {
    if (id && id.length && typeof id !== 'undefined') {
      getActiveChat(id);

      return;
    } 

    dispatch(clearActiveChat());
  }, [id]);

  const handleSendMessage = async (event) => {
    if (event) event.preventDefault();

    const formData = new FormData();

    if (fileInputRef.current.files.length) {
      const file = fileInputRef.current.files[0];
      formData.append('file', file, file.name);
    }

    if (newMessage.trim().length) formData.append('content', newMessage);

    if (formData.get('content') || formData.get('file')) {
      formData.append('sender', activeChat.crmUser);
      setNewMessage('');
      setFile(null);
      fileInputRef.current.value = null;
      dispatch(sendMessage(activeChat._id, formData));
    }
  };

  const handleReadMessages = (chatId, sender) => {
    dispatch(readMessages({ chatId, sender }));
  };

  return (
    <div className="chatbox-container right-content-wrapper">
      <div className="d-flex h-100" style={{ paddingLeft: '30px' }}>
        <ChatList 
          userId={JSON.parse(userId)}
          chats={chats}
          setActiveChat={getActiveChat}
        />
        <ActiveChat
          activeChat={activeChat} 
          setNewMessage={setNewMessage} 
          handleSendMessage={handleSendMessage} 
          handleReadMessages={handleReadMessages}
          chatBoxRef={chatBoxRef}
          fileInputRef={fileInputRef}
          newMessage={newMessage}
          file={file}
          setFile={setFile}
        />
      </div>
    </div>
  );
}

export default ChatBox;
