import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { deleteEvent } from '../../redux/event/eventActions';
import { useClickOutside } from '../../hooks/useClickOutside';

export function EventModal({ setShowEditEventModal, focusedEvent, onClickOutside }) {
  const dispatch = useDispatch();
  const { _id } = focusedEvent;
  const ref = useRef(null);

  const handleEditButtonClick = (e) => {
    setShowEditEventModal(true);
    onClickOutside();
  };

  const handleDelete = async () => {
    await dispatch(deleteEvent(_id));
    onClickOutside();
  };

  useClickOutside({ onClickOutside, ref });

  return (
    <div className={styles.eventWrapper} ref={ref}>
      <Button
        variant="success"
        onClick={handleEditButtonClick}
      >
        Edit
      </Button>
      <Button variant="danger" onClick={handleDelete}>
        Delete
      </Button>
    </div>
  );
}
