import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addAffiliate, updateAffiliateState } from '../../../redux/affiliate/affiliateActions';
import { ModalContext } from '../../../context';

export function AddAffiliateModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);

  const [showFtd, setShowFtd] = useState(true);

  const registered = useSelector((state) => state.affiliate?.affiliateAdded);
  const error = useSelector((state) => state.affiliate?.error);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const addUser = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
  };

  const handleAddUser = (formData) => {
    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      email: formData.email,
      showFtd,
      status: true,
    };
    dispatch(addAffiliate(data));
    hideModal();
  };

  useEffect(() => {
    dispatch(updateAffiliateState());
  }, [registered]);

  useEffect(() => {
    if (error) {
      dispatch(updateAffiliateState());
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit(handleAddUser)}>
      <div className="form-group col-md-12">
        <label className="control-label">First Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter First name"
          {...register('firstname', addUser.firstname)}
          name="firstname"
        />
        {errors?.firstname && <span className="errMsg">{errors.firstname.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Last Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Last name"
          {...register('lastname', addUser.lastname)}
          name="lastname"
        />
        {errors?.lastname && <span className="errMsg">{errors.lastname.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter Email"
          {...register('email', addUser.email)}
          name="email"
        />
        {errors?.email && <span className="errMsg">{errors.email.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">FTD data in API</label>
        <select className="form-control user-status-select" name="type" value={showFtd} onChange={(e) => setShowFtd(e.target.value)}>
          <option value>Show</option>
          <option value={false}>Hide</option>
        </select>
      </div>

      <div>
        <button className="btn btn-default" type="submit">Save</button>
      </div>
    </form>
  );
};
