import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { 
  setUserNameFilterCreator,
  setVerifyStatusFilterCreator,
  setTimeUploadedFilterCreator, 
} from '../redux/kycAml/kycAmlActions';
import { 
  selectUserNameSearchFilter,
  selectVerifyStatusSearchFilter,
  selectTimeUploadedFilter,
  selectKycStatuses, 
} from '../redux/kycAml/kycAmlSelectors';
import { ModalContext } from '../context';
import KycAmlRequests from '../pages/KycAml/KycAmlRequests/KycAmlRequests';
import {
  omitColumn, formatDate, dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength, 
} from './helper';
import { CopyToClipboardCmp, ErrorSpan, ModalFilters } from '../components';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';

export const KycAmlColumns = ({ columnConfig, setKycQuery, kycQuery }) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  
  // filters variables
  const nameSearchFilter = useSelector(selectUserNameSearchFilter);
  const timeUploadedFilter = useSelector(selectTimeUploadedFilter);
  const verifyStatusSearchFilter = useSelector(selectVerifyStatusSearchFilter);
  const verifiedStatuses = useSelector(selectKycStatuses);

  const openVerifyRequestsPage = (userId) => {
    showModal({
      headerContent: <h3>KYC/AML Requests</h3>,
      bodyContent: <KycAmlRequests userId={userId} />,
    });
  };

  const columns = [
    {
      name: 'ID',
      minWidth: '80px',
      cell: ({ customId }) => <span><CopyToClipboardCmp label={`${customId}`} /></span>,
      omit: omitColumn(columnConfig, 'ID'), 
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column pt-2" style={{ alignItems: 'start' }}>
          Client
          <input
            type="text"
            name="name"
            autoComplete="off"
            value={nameSearchFilter || ''}
            placeholder="Filter by user name"
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setUserNameFilterCreator(value))}
          />
          <ErrorSpan filter={nameSearchFilter} message="Please enter at least 3 characters" />
        </div>
      ),
      minWidth: '200px',
      cell: ({ userInfo: { firstName, lastName }, userId }) => {
        const fullName = `${firstName} ${lastName}`;
        return (
          <div>
            {
              fullName.length >= valueOutputCutLength.client
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/user-detail/${userId}`} target="blank" className="text-white" style={{ whiteSpace: 'nowrap' }}>
                        <FontAwesomeIcon icon={faExternalLink} size="xs" />
                        <span 
                          className="cfdSccounts-row_fullName"
                        >
                          {prettyCutOutputByCellWidth(fullName, 'client')}
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/user-detail/${userId}`} target="blank" className="text-white" style={{ whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faExternalLink} size="xs" />
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>
        );
      },
      omit: omitColumn(columnConfig, 'Client'), 
    },
    {
      name: (
        <ModalFilters 
          data={verifiedStatuses}
          filters={verifyStatusSearchFilter}
          setFilters={(value) => dispatch(setVerifyStatusFilterCreator(value))}
          tabName="Status" 
          searchParams={{ id: 'value', params: ['name'], optionsName: ['name'] }}
          usersFilters={kycQuery}
          setUserFilters={setKycQuery}
        />
      ),
      minWidth: '190px',
      cell: ({ verifiedStatus }) => (
        verifiedStatus.length >= valueOutputCutLength.status
          ? (
            <TooltipComponent
              title={verifiedStatus}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span 
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {prettyCutOutputByCellWidth(verifiedStatus, 'status')}
                </span>
                )}
            />
          )
          : (
            <span 
              style={{ whiteSpace: 'nowrap' }}
            >
              {prettyCutOutputByCellWidth(verifiedStatus, 'status')}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'Type'), 
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeUploadedFilter} 
          setFilters={(value) => dispatch(setTimeUploadedFilterCreator(value))} 
          tabName="Time Uploaded" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={kycQuery}
          setUserFilters={setKycQuery}
          isDateOptions
        />
      ),
      cell: ({ createdAt }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {createdAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(createdAt)), 'localTime') 
            : '-'}
        </span>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Time Uploaded'), 
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start', whiteSpace: 'nowrap' }}>
          Time Processed
        </div>
      ),
      cell: ({ updatedAt }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {updatedAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(updatedAt)), 'localTime') 
            : '-'}
        </span>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Time Processed'),
      selector: (row) => row.updatedAt,
      sortable: true,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start' }}>
          Edit Action
        </div>
      ),
      cell: ({ userId }) => (
        <button 
          type="button" 
          className="btn btn-success btn-sm me-1 p-1"
          onClick={() => openVerifyRequestsPage(userId)}
        >
          <FontAwesomeIcon icon={faPencil} size="sm" style={{ marginRight: '5px' }} />
          Edit
        </button>
      ),
      minWidth: '100px',
      omit: omitColumn(columnConfig, 'Actions'), 
    },
  ];

  return columns;
};
