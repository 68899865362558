import { toast } from 'react-toastify';
import {
  SET_IS_DATA_LOADING,
  SET_TOTAL_PAGES,
  SET_EMAIL_HISTORY,
  ADD_EMAIL_HISTORY, 
  ADD_LAST_EMAIL_HISTORY, 
} from './userSentEmailHistoryTypes';
import { apiHelper } from '../apiHelper';

// action creators below

export const setEmailHistoryCreator = (status) => ({
  type: SET_EMAIL_HISTORY,
  payload: status,
});

const addEmailHistoryCreator = (data) => ({
  type: ADD_EMAIL_HISTORY,
  payload: data,
});

const addOneEmailHistoryCreator = (data) => ({
  type: ADD_LAST_EMAIL_HISTORY,
  payload: data,
});


const setIsEmailHistoryLoadingCreator = (status) => ({
  type: SET_IS_DATA_LOADING,
  payload: status,
});

const setTotalPagesCreator = (status) => ({
  type: SET_TOTAL_PAGES,
  payload: status,
});

// Thunks below

export const createUserSentEmailHistory = (data) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/user-sent-email-history/', data);

    if (response?.data) {
      const { result } = response.data;

      if (result) {
        dispatch(addOneEmailHistoryCreator(result));
      }
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getUserSentEmailHistory = (userId, page = 1) => async (dispatch) => {
  dispatch(setIsEmailHistoryLoadingCreator(true));

  try {
    const response = await apiHelper('get', `/api/user-sent-email-history/${userId}`, undefined, { page });

    if (response?.data && response?.data?.result) {
      const result = response?.data?.result ?? [];
      const pagination = response?.data?.pagination ?? {};

      dispatch(setTotalPagesCreator(pagination.totalPages || 10));
      dispatch(addEmailHistoryCreator(result));
    } else {
      dispatch(setEmailHistoryCreator([]));
    }

    dispatch(setIsEmailHistoryLoadingCreator(false));
  } catch (error) {
    toast.error(error.response.message);
    dispatch(setIsEmailHistoryLoadingCreator(false));
  }
};
