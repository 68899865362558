import React, {
  useState, useEffect, useRef, useMemo, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ModalContext } from '../../context';
import { MassAddShiftModal } from './modals/MassAddShiftModal';
import { SelectComponent } from '../SelectComponent/SelectComponent';
import { SearchSelectComponent } from '../SearchSelectComponent';
import { createUserStatusChangesHistory } from '../../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import './style.css';

export function DatatableActions({ 
  ids, 
  managers, 
  statuses, 
  countries, 
  deleteUsers,  
  deleteComments,
  moveUsersToManagers,
  editUsers,
  clientType, 
}) {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  const defaultDropdownName = 'Select';
  const [dropdownName, setDropdownName] = useState(defaultDropdownName);
  const [statusDropdownName, setStatusDropdownName] = useState(defaultDropdownName);
  const [countryDropdownName, setCountryDropdownName] = useState(defaultDropdownName);
  const [managersDropdownName, setManagersDropdownName] = useState(defaultDropdownName);
  const [isAssignTo, setIsAssignTo] = useState(false);
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isChangeCountry, setIsChangeCountry] = useState(false);
  const [isRemovedComments, setIsRemovedComments] = useState(false);
  const [isChangeSource, setIsChangeSource] = useState(false);
  const [isAddShift, setIsAddShift] = useState(false);
  const [statusId, setStatusId] = useState('');
  const [country, setCountry] = useState({});
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedManagerNames, setSelectedManagerNames] = useState([]);
  const funnelNameRef = useRef('');

  // filtered data
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);

  const users = useSelector((state) => state.users.users);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanDeleteUsers = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('delete_user');
  const isUserCanAssignToAgent = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('assign_to_agent');
  const isUserCanDeleteComments = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('delete_comment');
  const isUserCanPromote = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('client_promote');
  const isUserCanDemote = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('client_demote');
    
  const isLeadType = useMemo(() => clientType === '1', [clientType]);

  const reversedType = useMemo(() => (isLeadType ? '2' : '1'), [isLeadType]);

  useEffect(() => {
    setFilteredManagers(managers);
    setFilteredCountries(countries);
    setFilteredStatuses(statuses);
  }, [managers, countries, statuses]);

  useEffect(() => {
    if (selectedManagerNames.length) {
      const name = selectedManagerNames.length > 2 ? `${selectedManagerNames.length} items selected` : selectedManagerNames.toString();
      setManagersDropdownName(name);
    } else {
      setManagersDropdownName(defaultDropdownName);
    }
  }, [selectedManagerNames]);

  const hideAllCmp = () => {
    setIsChangeStatus(false);
    setIsRemoved(false);
    setIsPromote(false);
    setIsChangeCountry(false);
    setIsRemovedComments(false);
    setIsChangeSource(false);
    setIsAssignTo(false);
    setIsAddShift(false);
  };

  const showCmp = (name, event) => {
    hideAllCmp();
    event(true);
    setDropdownName(name);
  };

  const selectStatus = (id) => {
    const status = filteredStatuses.find((status) => status._id === id);

    setStatusDropdownName(status?.name ?? '');
    setStatusId(id);
  };

  const selectCountry = (id) => {
    const country = filteredCountries.find((country) => country._id === id);

    setCountryDropdownName(country?.nicename ?? '');
    setCountry(country);
  };

  const selectManager = (ids) => {
    const managers = filteredManagers.filter((manager) => ids.includes(manager._id));
    const managerNames = managers.map((manager) => `${manager.firstName} ${manager.lastName}`);

    setSelectedManagerNames(managerNames);
    setSelectedManagers(ids);
  };

  const handleChangeStatus = () => {
    if (!statusId.length) {
      toast.error('Select status to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      salesStatusId: statusId,
    };

    const usersData = users.filter((user) => ids.includes(user._id));
    const statusTitle = statuses.find((status) => status._id === statusId)?.name || '';

    editUsers(data, 'Are you sure you want to change status for this user(s)?')
      .then((res) => {
        if (res) {
          usersData.forEach((user) => {
            const oldStatus = user?.salesStatus?.name || '';

            dispatch(createUserStatusChangesHistory({
              user: user._id,
              agent: crmUser._id,
              agentName: `${crmUser.firstName} ${crmUser.lastName}`,
              previousStatus: oldStatus,
              actualStatus: statusTitle,
            }));
          });
          setStatusId('');
          setStatusDropdownName(defaultDropdownName);
        }
      });
  };

  const handleChangeCountry = () => {
    if (!Object.keys(country).length) {
      toast.error('Select country to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      countryCode: country.iso,
      country,
    };

    editUsers(data, 'Are you sure you want to change country for this user(s)?')
      .then((res) => {
        if (res) {
          setCountry([]);
          setCountryDropdownName(defaultDropdownName);
        }
      });
  };

  const moveUsersToCManagers = () => {
    if (!selectedManagers.length) {
      toast.error('Select manager(s) to complete this action', {
        autoClose: 1000,
      });

      return;
    };

    moveUsersToManagers(selectedManagers)
      .then((res) => {
        if (res) {
          setSelectedManagers([]);
          setSelectedManagerNames([]);
        }
      });
  };

  const onChangeFunnelName = async () => {
    if (!funnelNameRef.current.value.length) {
      toast.error('Enter new funnel name', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      source: funnelNameRef.current.value,
    };

    editUsers(data, 'Are you sure you want to change funnel name for this user(s)?')
      .then((res) => {
        if (res) {
          funnelNameRef.current.value = '';
        }
      });
  };

  const setDefaultOptions = () => {
    const options = [
      { value: 'Select', label: 'Select' },
      { value: 'Change Status', label: 'Change Status' },
      { value: 'Change Country', label: 'Change Country' },
      { value: 'Change Funnel Name', label: 'Change Funnel Name' },
      { value: 'Add Shift', label: 'Add Shift' },
    ];

    if (isUserCanAssignToAgent) options.push({ value: 'Assign to Manager', label: 'Assign to Manager' });
    if (isUserCanDeleteUsers) options.push({ value: 'Remove', label: 'Remove' });
    if (isUserCanDeleteComments) options.push({ value: 'Remove Users Comments', label: 'Remove Users Comments' });
    if (isLeadType && isUserCanPromote) options.push({ value: 'Promote', label: 'Promote' });
    if (!isLeadType && isUserCanDemote) options.push({ value: 'Demote', label: 'Demote' });

    return options;
  };

  const defaultOptions = setDefaultOptions();

  const handleActionChange = (value) => {
    const valueToAction = {
      'Change Status': setIsChangeStatus,
      'Change Country': setIsChangeCountry,
      'Change Funnel Name': setIsChangeSource,
      'Add Shift': setIsAddShift,
      Remove: setIsRemoved,
      'Remove Users Comments': setIsRemovedComments,
      Promote: setIsPromote,
      Demote: setIsPromote,
      'Assign to Manager': setIsAssignTo,
    };

    showCmp(value, valueToAction[value]);
  };

  return (
    <div className="dt-actions__container">
      <span className="dt-actions__selected-counter">{`Actions: selected ${ids.length} ${ids.length > 1 ? 'records' : 'record'}`}</span>
      <div className="main_actions-row">
        <SelectComponent
          value={dropdownName}
          options={defaultOptions}
          onChange={({ target: { value } }) => handleActionChange(value)}
        />
        {
          isAssignTo && (
            <>
              <Dropdown className="dt-actions__dropdown">
                <Dropdown.Toggle variant="" className="btn-secondary_dark">{managersDropdownName}</Dropdown.Toggle>
                <Dropdown.Menu className="primary_menu">
                  <SearchSelectComponent
                    onChange={(ids) => selectManager(ids)}
                    isMultiple
                    variants={filteredManagers.map((manager) => ({ value: manager._id, label: `${manager.firstName} ${manager.lastName}` }))}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <button className="secondary-btn" type="button" onClick={moveUsersToCManagers}>Apply</button>
            </>
          )
        }
        {
          isChangeStatus && (
            <>
              <Dropdown className="dt-actions__dropdown">
                <Dropdown.Toggle variant="" className="btn-secondary_dark">{statusDropdownName}</Dropdown.Toggle>
                <Dropdown.Menu className="primary_menu">
                  <SearchSelectComponent
                    onChange={(id) => selectStatus(id)}
                    variants={filteredStatuses.map((status) => ({ value: status._id, label: status.name }))}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <button className="secondary-btn" type="button" onClick={handleChangeStatus}>Apply</button>
            </>
          )
        }
        {
          isChangeCountry && (
            <>
              <Dropdown className="dt-actions__dropdown">
                <Dropdown.Toggle variant="" className="btn-secondary_dark">{countryDropdownName}</Dropdown.Toggle>
                <Dropdown.Menu className="primary_menu">
                  <SearchSelectComponent
                    onChange={(id) => selectCountry(id)}
                    variants={filteredCountries.map((country) => ({ value: country._id, label: country.nicename }))}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <button className="secondary-btn" type="button" onClick={handleChangeCountry}>Apply</button>
            </>
          )
        }
        {
          isChangeSource && (
            <>
              <input className="text-input" autoComplete="off" type="text" ref={funnelNameRef} placeholder="Enter Funnel Name" />
              <button className="secondary-btn" type="button" onClick={onChangeFunnelName}>Apply</button>
            </>
          )
        }
        {isRemoved ? <button className="secondary-btn" type="button" onClick={deleteUsers}>Remove</button> : null}
        {isRemovedComments ? <button className="secondary-btn" type="button" onClick={deleteComments}>Apply</button> : null}
        {
                isPromote ? (
                  <button 
                    className="secondary-btn"
                    type="button" 
                    onClick={() => editUsers({ ids, clientType: reversedType }, `Are you sure you want to move user(s) to ${isLeadType ? 'client' : 'lead'} category?`)}
                  >
                    {isLeadType ? 'Promote' : 'Demote'}
                  </button> 
                ) : null
            }
        {
              isAddShift && (
                <button 
                  className="secondary-btn" 
                  style={{ display: 'flex', alignItems: 'center' }}
                  type="button" 
                  disabled={!ids.length}
                  onClick={() => showModal({
                    headerContent: <h3>Mass Add Shift</h3>,
                    bodyContent: <MassAddShiftModal userIds={ids} />,
                  })}
                >
                  Add
                </button> 
              )
      }
      </div>
    </div>
  );
};
