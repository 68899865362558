
import {
  SET_ANALYSIS,
  SET_FIRST_TIME_DEPOSITS_DATA,
  SET_FIRST_TIME_DEPOSITS_TOTAL,
  SET_FUNNEL_NAME_DATA,
  SET_FUNNEL_NAME_TOTAL,
  SET_COUNTRY_MAP_DATA,
  SET_TOTAL_FTD_BY_COUNTRY,
  SET_STATISTIC_BY_COUNTRY,
  SET_DATA_BY_STATUS,
  SET_COLORS_DATA_BY_STATUS,
  SET_AFFILIATE_FILTER_OPTIONS,
  SET_FUNNEL_NAME_FILTER_OPTIONS,
  SET_IS_LOADING,
  SET_STATUS_OPTION,
} from './affiliatesDashboardTypes';

const initialState = {
  isLoading: false,
  analysis: {
    leads: 0,
    firstTimeDeposits: 0,
    clientRate: 0,
    netDeposits: 0,
  },
  firstTimeDeposits: {
    data: null,
    total: 0,
  },
  funnelName: {
    data: null,
    total: 0,
  },
  countryMapData: null,
  totalFtdByCountry: 0,
  statisticByCountry: null,
  ftdDataByStatus: null,
  colorFtdDataByStatus: null,
  affiliateFilterOptions: null,
  funnelNameFilterOptions: null,
  statusOption: 'All Statuses',
};

const smtpPresetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS_OPTION:
      return {
        ...state,
        statusOption: action.payload,
      };
    case SET_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
    case SET_FIRST_TIME_DEPOSITS_DATA:
      return {
        ...state,
        firstTimeDeposits: {
          ...state.firstTimeDeposits,
          data: action.payload,
        },
      };
    case SET_FIRST_TIME_DEPOSITS_TOTAL:
      return {
        ...state,
        firstTimeDeposits: {
          ...state.firstTimeDeposits,
          total: action.payload,
        },
      };
    case SET_FUNNEL_NAME_DATA:
      return {
        ...state,
        funnelName: {
          ...state.funnelName,
          data: action.payload,
        },
      };
    case SET_FUNNEL_NAME_TOTAL:
      return {
        ...state,
        funnelName: {
          ...state.funnelName,
          total: action.payload,
        },
      };
    case SET_COUNTRY_MAP_DATA:
      return {
        ...state,
        countryMapData: action.payload,
      };
    case SET_TOTAL_FTD_BY_COUNTRY:
      return {
        ...state,
        totalFtdByCountry: action.payload,
      };
    case SET_STATISTIC_BY_COUNTRY:
      return {
        ...state,
        statisticByCountry: action.payload,
      };
    case SET_DATA_BY_STATUS:
      return {
        ...state,
        ftdDataByStatus: action.payload,
      };
    case SET_COLORS_DATA_BY_STATUS:
      return {
        ...state,
        colorFtdDataByStatus: action.payload,
      };
    case SET_AFFILIATE_FILTER_OPTIONS:
      return {
        ...state,
        affiliateFilterOptions: action.payload,
      };
    case SET_FUNNEL_NAME_FILTER_OPTIONS:
      return {
        ...state,
        funnelNameFilterOptions: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default smtpPresetsReducer;
