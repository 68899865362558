import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getWhitelistIPs, deleteWhitelistIP, updateState } from '../../redux/security/whitelistIPActions';
import { AddWhitelistIPModal, EditWhitelistIPModal } from './components';
import { ModalContext } from '../../context';

function WhitelistIP() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [loader, setLoader] = useState(false);

  const whitelistIPs = useSelector((state) => state?.whitelistIP?.whitelistIPs);
  const whitelistIPsFetched = useSelector((state) => state?.whitelistIP?.whitelistIPsFetched);
  const whitelistIPDeleted = useSelector((state) => state?.whitelistIP?.whitelistIPDeleted);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddIP = permissionName && !!permissionName.length && permissionName.includes('add_whitelist_ip');
  const isUserCanEditIP = permissionName && !!permissionName.length && permissionName.includes('edit_whitelist_ip');
  const isUserCanDeleteIP = permissionName && !!permissionName.length && permissionName.includes('delete_whitelist_ip');

  useEffect(() => {
    if (whitelistIPsFetched || whitelistIPDeleted) {
      dispatch(updateState());
      setLoader(false);
    }
  }, [whitelistIPsFetched, whitelistIPDeleted]);

  useEffect(() => {
    setLoader(true);

    async function fetchData() {
      await dispatch(getWhitelistIPs());
    }
    fetchData();
  }, []);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        setLoader(true);
        await dispatch(deleteWhitelistIP(id));
      }
    });
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>IP Whitelist</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            {isUserCanAddIP
                  && (
                  <button 
                    type="button"
                    className="btn-primary_light"
                    onClick={() => showModal({ 
                      bodyContent: <AddWhitelistIPModal />, 
                      headerContent: <h3>Add IP to Whitelist</h3>, 
                    })}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    Add New
                  </button>
                  )}
          </div>
        </div>


        <div className="mt-3 table-responsive">
          <table className="table">
            <thead className="table_head">
              <tr>
                <th>CRM Users</th>
                <th>Name</th>
                <th>IP Address</th>
                {isUserCanEditIP && isUserCanDeleteIP && <th>Action(s)</th>}
              </tr>
            </thead>
            <tbody>
              {whitelistIPs?.length > 0 && whitelistIPs?.map((whitelistIP) => (
                <tr key={whitelistIP._id}>
                  <td>{whitelistIP?.manager && Object.keys(whitelistIP.manager)?.length > 0 ? `${whitelistIP?.manager?.firstName} ${whitelistIP?.manager?.lastName}` : 'All'}</td>
                  <td>{whitelistIP.name}</td>
                  <td>{whitelistIP.ip}</td>
                  <td className="action-buttons">
                    {isUserCanEditIP
                          && (
                            <button 
                              type="button"
                              className="btn btn-default"
                              onClick={() => showModal({ 
                                bodyContent: <EditWhitelistIPModal whitelistIP={whitelistIP} />, 
                                headerContent: <h5>Edit IP To Whitelist</h5>, 
                              })}
                            >
                              Edit
                            </button>
                          )}
                    {isUserCanDeleteIP
                          && (
                          <button 
                            type="button"
                            className="btn btn-danger me-2"
                            onClick={() => deleteAction(whitelistIP._id)}
                          >
                            Delete
                          </button>
                          )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WhitelistIP;
