export const selectKycAmlData = (state) => state?.kycAml?.kycAmlData ?? [];
export const selectKycAmlDataTotalCount = (state) => state?.kycAml?.totalCount ?? 0;
export const selectUserNameSearchFilter = (state) => state?.kycAml?.userNameSearchFilter ?? null;
export const selectUserEmailSearchFilter = (state) => state?.kycAml?.userEmailSearchFilter ?? null;
export const selectUserPhoneSearchFilter = (state) => state?.kycAml?.userPhoneSearchFilter ?? null;
export const selectTimeUploadedFilter = (state) => state?.kycAml?.timeUploadedFilter ?? null;
export const selectVerifyStatusSearchFilter = (state) => state?.kycAml?.verifyStatusSearchFilter ?? '';
export const selectIsKycAmlDataLoading = (state) => state?.kycAml?.isKycAmlDataLoading ?? null;
export const selectIsKycAmlRequestsLoading = (state) => state?.kycAml?.isKycAmlRequestsLoading ?? false;
export const selectKycStatuses = (state) => state?.kycAml?.kycStatuses ?? null;

export const selectKycAmlRequests = (state) => {
  const data = state?.kycAml?.kycAmlRequests ?? [];
  data.sort((a, b) => {
    if (a.verifiedStatus === 'pending' && b.verifiedStatus !== 'pending') return -1;
    if (a.verifiedStatus !== 'pending' && b.verifiedStatus === 'pending') return 1;
    return 0;
  });
  return data;
};
