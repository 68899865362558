import React, { useMemo } from 'react';
import userPlaceholder from '../../../assets/images/user-placeholder.jpg';

export function UserPhoto({ imageId }) {
  const url = useMemo(() => (imageId ? `${process.env.REACT_APP_SERVER_URL}/images/${imageId}` : userPlaceholder), [imageId]);

  return (
    <img
      className="profile-image"
      src={url}
      alt="profilePhoto"
    />
  );
};
