import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_IS_CRM_USER_IN_SCOPE, GET_IS_USER_IN_SCOPE } from './hierarchyTypes';

export const getIsCRMUserInScope = (crmUserId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/hierarchy/check-crm-user?crmUserId=${crmUserId}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_IS_CRM_USER_IN_SCOPE,
        payload: res.data.isCRMUserInScope,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getIsUserInScope = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/hierarchy/check-user?userId=${userId}`, '');
  
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_IS_USER_IN_SCOPE,
        payload: res.data.isUserInScope,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const setUserIsInScopeByType = ({ type, value }) => async (dispatch) => {
  dispatch({
    type,
    payload: value,
  });
};
