import React, {
  useState, useEffect, useContext, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { ModalContext } from '../../../context';
import { addSalesStatus, updateState } from '../../../redux/salesStatus/salesStatusActions';
import { useClickOutside } from '../../../hooks/useClickOutside';

export function AddSalesStatusModalBody() {
  const dispatch = useDispatch();
  const hexColorPickerRef = useRef(null);
  const { hideModal } = useContext(ModalContext);

  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [color, setColor] = useState('#aabbcc');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [nameErr, setNameErr] = useState('');
  const [colorErr, setColorErr] = useState('');
  
  const salesStatusAdded = useSelector((state) => state?.salesStatus?.salesStatusAdded);
  const error = useSelector((state) => state?.salesStatus?.error);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === '') {
      setNameErr('Status name is required');
    } else if (color === '') {
      setColorErr('Status color is required');
    } else {
      setDisplayColorPicker(false);
      const formData = {
        name,
        color,
        type,
      };
      dispatch(addSalesStatus(formData));
      hideModal();
    }
  };

  useEffect(() => {
    if (salesStatusAdded) {
      dispatch(updateState());
    }
  }, [salesStatusAdded]);

  useEffect(() => {
    if (error) {
      dispatch(updateState());
    }
  }, [error]);

  useClickOutside({ onClickOutside: () => setDisplayColorPicker(false), ref: hexColorPickerRef });

  return (
    <form>
      <div className="form-group col-md-12">
        <label className="control-label">Status Name</label>
        <input
          type="text"
          required="required"
          className="form-control"
          name="name"
          value={name}
          placeholder="Enter status name"
          onChange={(e) => {
            if (e.target.value) {
              setName(e.target.value);
              setNameErr('');
            } else {
              setName(e.target.value);
              setNameErr('Status name is required');
            }
          }}
        />
        {nameErr ? (<span className="errMsg">{nameErr}</span>) : ('')}
      </div>

      <div className="form-group col-md-12">
        <label className="control-label">
          Select Type
        </label>
        <select className="form-control" required="required" name="type" value={type} onChange={(e) => setType(e.target.value)}>
          <option value={0}>Sales</option>
          <option value={1}>Retention</option>
          <option value={2}>Both</option>
        </select>
      </div>

      <div className="form-group col-md-12">
        <label className="control-label">Status Color</label>  
        <div>
          <button 
            className="btn btn-secondary" 
            onClick={() => setDisplayColorPicker(!displayColorPicker)} 
            type="button"
          >
            Pick Color
          </button>
          {displayColorPicker && (
            <div ref={hexColorPickerRef} style={{ position: 'absolute' }}>
              <HexColorPicker 
                color={color} 
                onChange={(e) => {
                  setColor(e);
                }}
              />
              <HexColorInput 
                color={color} 
                onChange={setColor} 
                placeholder="Type a color" 
                prefixed 
                alpha
              />
            </div>
          )}
          <span className="picked-value" style={{ borderLeftColor: color }}>
            Picked color is
            {color}
          </span>
        </div>
        {colorErr ? (<span className="errMsg">{colorErr}</span>) : ('')}
      </div>
      <div>
        <button type="button" className="btn btn-default mt-3" onClick={handleSubmit}>Save</button>
      </div>
    </form>
  ); 
};
