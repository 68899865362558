import { GET_PAIRS } from './pairTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_PAIRS:
      return action.payload;
    default:
      return state;
  }
};
