import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faColumns } from '@fortawesome/free-solid-svg-icons';
import './style.css';

export function DatatableColumns({ columnConfig, setColumns }) {
  const toggleColumn = (name) => {
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }
      
      return column;
    });

    setColumns(updatedColumns);
  };

  return (
    <Dropdown className="columns-dropdown">
      <Dropdown.Toggle variant="" className="btn-secondary_dark">
        Columns
      </Dropdown.Toggle>
      <Dropdown.Menu className="primary-menu">
        <ul className="columns-list">
          {columnConfig.map((column) => (
            <li className="columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
              {column.selected ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  color="#6E7F95"
                />
              ) : null}
              <span className="columns-option__name">{column.name}</span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};
