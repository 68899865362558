import { toast } from 'react-toastify';
import { GET_CFD_ACCOUNT_TYPES } from './cfdAccountTypeTypes';
import { apiHelper } from '../apiHelper';

export const getCFDAccountTypes = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-account-type', '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_ACCOUNT_TYPES,
        payload: res.data.types,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
