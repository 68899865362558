import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export function InputPassword({
  placeholder, onChange = () => {}, value, name, inpuRef, register = {}, 
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ position: 'relative' }}>
      <input
        type={showPassword ? 'text' : 'password'}
        className="form-control"
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        value={value}
        name={name}
        ref={inpuRef}
        {...register}
      />
      <FontAwesomeIcon
        icon={showPassword ? faEye : faEyeSlash}
        onClick={() => handleShowPassword()}
        style={{ 
          position: 'absolute', 
          right: 10, 
          top: 15, 
          cursor: 'pointer', 
        }}
        color="white"
      />
    </div>
  );
};
