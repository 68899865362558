import React, { useState, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { SelectComponent } from '../SelectComponent/SelectComponent';
import { SearchSelectComponent } from '../SearchSelectComponent';
import './style.css';

export function DatatableCRMActions({ 
  ids, 
  templates, 
  editCRMUsers,
}) {
  const defaultDropdownName = 'Select';
  const [dropdownName, setDropdownName] = useState(defaultDropdownName);
  const [templateDropdownName, setTemplateDropdownName] = useState(defaultDropdownName);
  const [isChangeTemplate, setIsChangeTemplate] = useState(false);
  const [template, setTemplate] = useState({});

  // filtered data
  const [searchValue, setSearchValue] = useState('');
  const filteredTemplates = useMemo(() => {
    const value = searchValue.toLowerCase();
    return templates.filter((temp) => (
      temp.name.toLowerCase().includes(value) || temp._id.toLowerCase().includes(value)
    ));
  }, [templates, searchValue]);

  const showCmp = (name, event) => {
    event(true);
    setDropdownName(name);
  };

  const selectTemplate = (id) => {
    const template = filteredTemplates.find((temp) => temp._id === id);

    if (!template) return;

    setTemplateDropdownName(template.name);
    setTemplate(template);
  };

  const handleChangeTemplate = () => {
    if (!Object.keys(template).length) {
      toast.error('Select rights template name to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      permissionIds: template.permissions,
    };

    editCRMUsers(data, 'Are you sure you want to set rights for this CRM user(s)?')
      .then((res) => {
        if (res) {
          setTemplate({});
          setTemplateDropdownName(defaultDropdownName);
        }
      });
  };

  const setDefaultOptions = () => {
    const options = [
      { value: 'Select', label: 'Select' },
      { value: 'Set Rights', label: 'Set Rights' },
    ];

    return options;
  };

  const handleActionChange = (value) => {
    const valueToAction = {
      Select: null,
      'Set Rights': setIsChangeTemplate,
    };

    showCmp(value, valueToAction[value]);
  };

  const defaultOptions = setDefaultOptions();

  return (
    <div className="dt-actions__container">
      <span className="dt-actions__selected-counter">{`Actions: selected ${ids.length} ${ids.length > 1 ? 'records' : 'record'}`}</span>
      <div className="main_actions-row">
        <SelectComponent
          value={dropdownName}
          options={defaultOptions}
          onChange={({ target: { value } }) => handleActionChange(value)}
        />
        {
        isChangeTemplate && (
        <>
          <Dropdown className="dt-actions__dropdown ms-1">
            <Dropdown.Toggle variant="" className="btn-secondary_dark">{templateDropdownName}</Dropdown.Toggle>
            <Dropdown.Menu className="primary_menu">
              <SearchSelectComponent
                onChange={(id) => selectTemplate(id)}
                variants={filteredTemplates.map((template) => ({ value: template._id, label: template.name }))}
              />
            </Dropdown.Menu>
          </Dropdown>
          <button className="secondary-btn" type="button" onClick={handleChangeTemplate}>Apply</button>
        </>
        )
      }
      </div>
    </div>
  );
};
