import React, {
  useCallback, useContext, useEffect,
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ModalContext } from '../context';
import {
  selectWithdrawalMethodsOptions,
} from '../redux/withdrawalMethods/withdrawalMethodsSelectors';
import {
  fetchWithdrawalMethodsOptions,
  setBrandFilterCreator,
  setCountriesIncludedFilterCreator,
  setWithdrawalMethodFilterCreator,
  updateWithdrawalMethod,
} from '../redux/withdrawalMethods/withdrawalMethodsActions';
import { getBrands } from '../redux/brand/brandActions';
import { fetchCounties } from '../redux/countries/countriesActions';
import EditWithdrawalMethods from '../pages/WithdrawalMethods/EditWithdrawalMethods/EditWithdrawalMethods';
import { ModalFilters } from '../components';
import { stringToBoolean } from '../helpers/helper';

export const WithdrawalMethodsColumns = ({
  withdrawalMethodsQuery,
  setWithdrawalMethodsQuery,
  brandSearchFilter,
  countriesIncludedSearchFilter,
  withdrawalMethodSearchFilter,
}) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditPermission = permissions && permissions.length > 0 && permissions.includes('withdrawal_methods_edit'); 

  const brands = useSelector((state) => state.brands);
  const countries = useSelector((state) => state.countries.countries);
  const withdrawalMethodsOptions = useSelector(selectWithdrawalMethodsOptions);
  
  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    if (!countries.length) dispatch(fetchCounties());
    if (!withdrawalMethodsOptions.length) dispatch(fetchWithdrawalMethodsOptions());
  }, []);

  const getAllBrandsOptions = useCallback((brands) => brands.map(({ _id, name }) => ({
    _id,
    name,
  })), []);

  const getAllIncludedCountryOptions = useCallback((countries) => countries.map(({ _id, nicename }) => ({
    _id,
    nicename,
  })), []);

  const [brandsOptions, setBrandsOptions] = useState([]);
  const [includedCountriesOptions, setIncludedCountriesOptions] = useState([]);

  useEffect(() => {
    const options = getAllBrandsOptions(brands);
    setBrandsOptions(options);
  }, [brands]);
  
  useEffect(() => {
    const options = getAllIncludedCountryOptions(countries);
    setIncludedCountriesOptions(options);
  }, [countries]);

  const handleBrandSearchFilterChange = (value) => {
    dispatch(setBrandFilterCreator(value));
  };

  const handleWithdrawalMethodChange = (value) => {
    dispatch(setWithdrawalMethodFilterCreator(value));
  };

  const handleIncludedCountiesChange = (value) => {
    dispatch(setCountriesIncludedFilterCreator(value));
  };

  const editAction = ({ _id, name, ...rest }) => {
    showModal({
      headerContent: <h3>{`Edit "${name}" Withdrawal Method`}</h3>,
      bodyContent: (
        <EditWithdrawalMethods
          id={_id}
          name={name}
          {...rest}
        />
      ),
    });
  };


  const handleStatusChange = async (_id, name, status) => {
    await dispatch(
      updateWithdrawalMethod(_id, {
        name,
        status: stringToBoolean(status),
      }),
    );
  };

  const columns = [
    // {
    //   name: (
    //     <ModalFilters
    //       data={brandsOptions} 
    //       filters={brandSearchFilter} 
    //       setFilters={handleBrandSearchFilterChange} 
    //       tabName="Brand" 
    //       searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
    //       usersFilters={withdrawalMethodsQuery}
    //       setUserFilters={setWithdrawalMethodsQuery}
    //     />
    //   ),
    //   selector: (row) => (
    //     <span>
    //       {row?.brand_details.map(({ name }) => name).join(', ') || 'All'}
    //     </span>
    //   ),
    // },
    {
      name: (
        <ModalFilters
          data={withdrawalMethodsOptions} 
          filters={withdrawalMethodSearchFilter} 
          setFilters={handleWithdrawalMethodChange} 
          tabName="Withdrawal Method" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={withdrawalMethodsQuery}
          setUserFilters={setWithdrawalMethodsQuery}
        />
      ),
      minWidth: '210px',
      selector: ({ name }) => `${name}`,
      cell: ({ name }) => (
        <span>
          {name}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'end', width: '100%' }}>
          <div className="newTablePositionHeader">
            Status
          </div>
        </div>
      ),
      selector: ({ status }) => `${status}`,
      cell: ({ _id, name, status }) => (
        <div className="withdrawalNewHeaderStatus">
          <span className="withdrawalNewHeaderStatusSelectContainer">
            <select
              className="form-control user-status-select leads-status-select"
              style={{ width: '100px' }}
              name="status"
              value={status}
              onChange={({ target }) => handleStatusChange(_id, name, target.value)}
            >
              <option value selected={status}>Active</option>
              <option value={false} selected={!status}>Inactive</option>
            </select>
          </span>
        </div>
      ),
      minWidth: '100px',
    },
    // {
    //   name: (
    //     <ModalFilters
    //       data={includedCountriesOptions} 
    //       filters={countriesIncludedSearchFilter} 
    //       setFilters={handleIncludedCountiesChange} 
    //       tabName="Countries included" 
    //       searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
    //       usersFilters={withdrawalMethodsQuery}
    //       setUserFilters={setWithdrawalMethodsQuery}
    //     />
    //   ),
    //   cell: (row) => {
    //     const includedCountriesDetails = row?.included_countries_details ?? [];
    //     const includedCountries = includedCountriesDetails.map(({ nicename }) => nicename);
    //     return (
    //       <span>
    //         {includedCountries.join(', ') || 'All'}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: (
    //     <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
    //       Excluded countries
    //     </div>
    //   ),
    //   cell: (row) => {
    //     const excludedCountriesDetails = row?.excluded_countries_details ?? [];
    //     const excludedCountries = excludedCountriesDetails.map(({ nicename }) => nicename);
    //     return (
    //       <span>
    //         {excludedCountries.join(', ') || 'No'}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: 'Actions',
    //   cell: (row) => (
    //     isUserHasEditPermission ? (
    //       <button
    //         className="btn btn-warning btn-sm me-1 p-1"
    //         type="button"
    //         onClick={() => editAction(row)}
    //       >
    //         Edit
    //       </button>
    //     ) : null
    //   ),
    // },
  ];

  return columns;
};
