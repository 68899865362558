import { GET_ACCOUNT_CURRENCIES } from './cfdAccountCurrencyTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_ACCOUNT_CURRENCIES:
      return action.payload;
    default:
      return state;
  }
};
