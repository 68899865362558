import React from 'react';
import { useSelector } from 'react-redux';
import { selectStatusChangesHistory } from '../../../../redux/userStatusChangesHistory/userStatusChangesHistorySelectors';
import styles from './UserStatusHistory.module.css';

export function UserStatusHistory() {
  const history = useSelector(selectStatusChangesHistory);

  const dateOptions = {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h5>Status changes</h5>
      </div>
      <div className={styles.bodyWrapper}>
        {
          history && history.length
            ? history.map((data) => {
              const date = new Date(data.createdAt);
              return (
                <span className={styles.statusHistoryRow}>
                  {date.toLocaleDateString('en-US', dateOptions)}
                  {' | '}
                  Status changed 
                  {' ('}
                  {data.previousStatus}
                  {' -> '}
                  {data.actualStatus}
                  {') by '}
                  {data.agentName}
                </span>
              );
            })
            : 'There hasn\'t been any activity yet'
        }
      </div>
    </div>
  );
}
