import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';
import { getMoreUserCallHistory, getUserCallHistory } from '../../../../redux/userCallHistory/userCallHistoryActions';
import { formatDate } from '../../../../helpers/formatDate';

export function UserCallHistory() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const bodyWrapperRef = useRef(null);
  
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);

  const { history, isNeedToLoad } = useSelector((state) => state.callHistory);

  const handleScroll = () => {
    const {
      scrollTop, scrollHeight, clientHeight, 
    } = bodyWrapperRef.current;

    if (scrollTop + clientHeight >= scrollHeight) {
      setLoader(true);
      setPage((prevPage) => prevPage + 1);
      const prevScrollHeight = bodyWrapperRef.current.scrollHeight;
      const newScrollHeight = bodyWrapperRef.current.scrollHeight;
      bodyWrapperRef.current.scrollTop += newScrollHeight - prevScrollHeight;
    }
  };

  useEffect(async () => {
    if (id && page > 1 && loader) {
      await dispatch(getMoreUserCallHistory(id, page));
      setLoader(false);
    }
  }, [page, id, loader]);

  useEffect(async () => {
    if (id && page) {
      setLoader(true);
      await dispatch(getUserCallHistory(id, page));
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    const bodyWrapper = bodyWrapperRef.current;
    if (bodyWrapper && isNeedToLoad) {
      bodyWrapper.addEventListener('scroll', handleScroll);
    }

    return () => bodyWrapper.removeEventListener('scroll', handleScroll);
  }, [isNeedToLoad]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h5>Call History</h5>
      </div>
      <div className={styles.bodyWrapper} ref={bodyWrapperRef}>
        {
          history && history.length > 0
          && history.map((item) => {
            const date = new Date(item.createdAt);
            return (
              <div className={styles.callHistoryWrapper}>
                <div>
                  <FontAwesomeIcon icon={faPhone} size="small" />
                  <span>{item.description}</span>
                </div>
                <span className={styles.eventTime}>{formatDate(date)}</span>
              </div>
            );
          })
        }

        {
          !history.length && (<span>There hasn&apos;t been any activity yet</span>)
        }

        {loader 
        && (
        <>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
          <div className={styles.callHistoryWrapper}>
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-textBody']}`} />
            <div className={`${styles.skeleton} ${styles['skeleton-text']} ${styles['skeleton-footer']}`} />
          </div>
        </>
        )}
      </div>
    </div>
  );
}
