import React from 'react';

export function NoDataAvailableTableIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="82" viewBox="0 0 96 82" fill="none">
      <g clipPath="url(#clip0_55068_81360)">
        <path d="M95.4078 -0.300781H12.229V82.1344H95.4078V-0.300781Z" fill="#262C38" />
        <path d="M46.7669 27.3521H27.0464V28.4538H46.7669V27.3521Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M46.7669 20.7964H27.0464V21.8981H46.7669V20.7964Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M37.7329 14.2417H27.0464V15.3434H37.7329V14.2417Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M23.4113 14.2417H20.9875V15.3434H23.4113V14.2417Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M23.4111 20.7969H20.9873V21.8986H23.4111V20.7969Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M23.4111 27.3521H20.9873V28.4538H23.4111V27.3521Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M23.4111 33.8516H20.9873V34.9533H23.4111V33.8516Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M23.4111 40.4072H20.9873V41.5089H23.4111V40.4072Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M84.5011 46.9067H50.899V48.0084H84.5011V46.9067Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M87.6958 53.4624H59.1066V54.5641H87.6958V53.4624Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M55.416 53.4624H37.7336V54.5641H55.416V53.4624Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M76.6239 60.0171H37.7336V61.1188H76.6239V60.0171Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M64.5056 66.5176H48.4207V67.6193H64.5056V66.5176Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M57.8941 33.8521H39.4406V34.9538H57.8941V33.8521Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M57.8941 40.4072H39.4406V41.5089H57.8941V40.4072Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M8.13483 68.9316L-0.0449219 77.1113L2.29215 79.4484L10.4719 71.2686L8.13483 68.9316Z" fill="#8E9099" />
        <path d="M29.4517 65.0312H19.6465V68.3364H29.4517V65.0312Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M29.3057 54.6743H19.5005V57.9794H29.3057V54.6743Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M28.865 44.2637H19.5005V47.5688H28.865V44.2637Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M21.6042 74.5423C30.9588 74.5423 38.5423 66.9588 38.5423 57.6042C38.5423 48.2495 30.9588 40.666 21.6042 40.666C12.2495 40.666 4.66602 48.2495 4.66602 57.6042C4.66602 66.9588 12.2495 74.5423 21.6042 74.5423Z" fill="#262C38" />
        <path d="M29.6013 64.7681H19.7161V68.1002H29.6013V64.7681Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M29.6013 54.3276H19.7161V57.6597H29.6013V54.3276Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path d="M29.157 43.8315H19.7161V47.1636H29.157V43.8315Z" fill="#E2E2E9" fillOpacity="0.16" />
        <path
          opacity="0.2" 
          d="M24.1032 40.833C19.2862 40.993 14.6838 42.8641 11.1216 46.1108C7.55951 49.3574 5.27077 53.767 4.66602
           58.5486C4.85246 61.8969 6.02861 65.1146 8.04544 67.7938C10.0623 70.473 12.829 72.4933 15.9951 73.5986C14.2388
            72.5011 12.7231 71.0591 11.5396 69.3596C10.356 67.6601 9.52912 65.7384 9.10885 63.7104C8.68858 61.6825 8.68364
            59.5905 9.09433 57.5606C9.50501 55.5307 10.3228 53.6051 11.4983 51.9C12.6738 50.1949 14.1827 48.7458 15.9338
            47.64C17.685 46.5342 19.642 45.7948 21.6868 45.4664C23.7316 45.138 25.8218 45.2273 27.8311 45.7291C29.8404 
            46.2308 31.7272 47.1346 33.3776 48.3857C35.3018 49.6877 36.8984 51.4174 38.0425 53.4394C37.2035 50.2067 
            35.4365 47.2904 32.9594 45.0502C30.4824 42.81 27.4037 41.344 24.1032 40.833Z" 
          fill="#111318"
          fillOpacity="0.16"
        />
        <path 
          d="M21.6042 76.2084C17.9246 76.2084 14.3277 75.1173 11.2683 73.073C8.20882 71.0288 5.82428 68.1232
            4.41617 64.7237C3.00806 61.3242 2.63964 57.5836 3.35748 53.9747C4.07533 50.3658 5.84721 47.0509 8.44905 
            44.449C11.0509 41.8472 14.3658 40.0753 17.9747 39.3575C21.5836 38.6396 25.3242 39.0081 28.7237 40.4162C32.1232 
            41.8243 35.0288 44.2088 37.073 47.2683C39.1173 50.3277 40.2084 53.9246 40.2084 57.6042C40.2084 62.5383 38.2483 
            67.2704 34.7594 70.7594C31.2704 74.2483 26.5383 76.2084 21.6042 76.2084ZM21.6042 42.3321C18.5837 42.3321 15.631
            43.2278 13.1195 44.9059C10.608 46.584 8.65053 48.9692 7.49462 51.7598C6.33871 54.5504 6.03627 57.6211 6.62555 
            60.5836C7.21483 63.5461 8.66936 66.2674 10.8052 68.4032C12.941 70.539 15.6623 71.9936 18.6248 72.5828C21.5873 
            73.1721 24.658 72.8697 27.4486 71.7138C30.2392 70.5579 32.6244 68.6004 34.3025 66.0889C35.9806 63.5774 36.8763 
            60.6247 36.8763 57.6042C36.8763 53.5538 35.2673 49.6693 32.4032 46.8052C29.5391 43.9411 25.6546 42.3321 21.6042 
            42.3321Z" 
          fill="#8E9099"
        />
      </g>
      <defs>
        <clipPath id="clip0_55068_81360">
          <rect width="95.6667" height="82" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
