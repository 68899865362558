import {
  ADD_SAVING,
  DELETE_SAVING,
  EDIT_SAVING,
  GET_SAVING,
  GET_SAVINGS,
  GET_SAVING_OPTIONS,
} from './savingTypes';

const initialState = {
  savings: [],
  saving: {},
  options: [],
};

const savingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVINGS:
      return {
        ...state,
        savings: action.payload,
      };
    case GET_SAVING:
      return {
        ...state,
        saving: action.payload,
      };
    case GET_SAVING_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case ADD_SAVING:
      return {
        ...state,
        savings: [...state.savings, action.payload],
      };
    case EDIT_SAVING:
      return {
        ...state,
        savings: state.savings.map((item) => {
          if (item._id === action.payload._id) {
            return { ...action.payload };
          }
          return item;
        }),
      };
    case DELETE_SAVING:
      return {
        ...state,
        savings: state.savings.filter((item) => item._id !== action.payload),
      };
    default:
      return state;
  }
};

export default savingReducer;
