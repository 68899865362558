/* eslint-disable default-param-last */
import {
  REGISTER_USER, LOGIN_ADMIN, REGISTER_SUB_ADMIN, REGISTER_ADMIN, TOGGLE_AUTH_STATE, ERROR_STATE, TWO_FA_STATUS, TWO_FA_STATUS_CHANGED, TWO_FA_QR_IMAGE, TOGGLE_TWO_FA_STATE, TWO_FA_ERROR_STATE,
} from './authTypes';

const initialState = {
  user: [],
  subAdmin: [],
  admin: [],
  success: false,
  registered: false,
  registeredAdmin: false,
  error: false,
  twoFAQRImage: '',
  twoFAStatus: false,
  twoFAStatusFetched: false,
  twoFAStatusChanged: false,
  twoFAQRImageFetched: false,
  twoFAError: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUB_ADMIN:
      return {
        ...state,
        subAdmin: action.payload,
        success: true,
        registered: true,
      };
    case REGISTER_ADMIN:
      return {
        ...state,
        admin: action.payload,
        registeredAdmin: true,
      };
    case REGISTER_USER:
      return {
        ...state,
        user: action.payload,
        success: true,
        registered: true,
        error: false,
      };
    case LOGIN_ADMIN:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case TOGGLE_AUTH_STATE:
      return {
        ...state,
        registered: false,
        registeredAdmin: false,
        error: false,
      };
    case TWO_FA_STATUS:
      return {
        ...state,
        twoFAStatus: action.payload,
        twoFAStatusFetched: true,
      };
    case TWO_FA_QR_IMAGE:
      return {
        ...state,
        twoFAQRImage: action.payload,
        twoFAQRImageFetched: true,
      };
    case TWO_FA_STATUS_CHANGED:
      return {
        ...state,
        twoFAStatusChanged: true,
      };
    case TOGGLE_TWO_FA_STATE:
      return {
        ...state,
        twoFAStatusFetched: false,
        twoFAStatusChanged: false,
        twoFAQRImageFetched: false,
        twoFAError: false,
      };
    case TWO_FA_ERROR_STATE:
      return {
        ...state,
        twoFAError: true,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default authReducer;
