import { GET_SALESDASHBOARD } from './salesDashboardTypes';

const initialState = {
  salesDashboard: [],
  success: false,
};


const salesDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESDASHBOARD:
      return {
        ...state,
        salesDashboard: action.payload,
        success: true,
      };
    default:
      return state;
  }
};
export default salesDashboardReducer;
