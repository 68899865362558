import { toast } from 'react-toastify';
import {
  ADD_PERMISSION, 
  DISPLAY_PERMISSIONS, 
  DELETE_PERMISSION, 
  TOGGLE_STATE, 
  ERROR_STATE, 
  ADD_PERMISSION_TEMPLATE, 
  PERMISSION_TEMPLATES,
  DELETE_PERMISSION_TEMPLATE,
  UPDATE_PERMISSION_TEMPLATE_STATE,
} from './permissionTypes';
import { apiHelper } from '../apiHelper';

export const displayPermissions = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/permission', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_PERMISSIONS,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const addPermission = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/permission/add', data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: ADD_PERMISSION,
        payload: data,
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_STATE,
    });
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deletePermission = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/permission/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: DELETE_PERMISSION,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

export const addPermissionTemplate = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/permission/template', data);
    if (res?.data?.data) {
      dispatch({
        type: ADD_PERMISSION_TEMPLATE,
        payload: res.data.data,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    } else {
      toast.error('Something went wrong', {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.response.message, {
      autoClose: 1000,
    });
  }
};

export const getAllPermissionTemplates = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/permission/template');
    dispatch({
      type: PERMISSION_TEMPLATES,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    toast.error(error.response.message, {
      autoClose: 1000,
    });
  }
};

export const deletePermissionTemplate = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/permission/template/${id}`, '');
    if (res?.data?.templateId) {
      dispatch({
        type: DELETE_PERMISSION_TEMPLATE,
        payload: res.data.templateId,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.response.message, {
      autoClose: 1000,
    });
  }
};

export const updatePermissionTemplateState = () => (dispatch) => {
  dispatch({
    type: UPDATE_PERMISSION_TEMPLATE_STATE,
    payload: false,
  });
};
