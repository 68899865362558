export const SET_IS_PRESETS_LOADING = 'SET_IS_PRESETS_LOADING';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const ADD_SMTP_PRESET = 'ADD_SMTP_PRESET';
export const DELETE_SMTP_PRESET = 'DELETE_SMTP_PRESET';
export const DELETE_USER_SMTP_PRESET = 'DELETE_USER_SMTP_PRESET';
export const UPDATE_SMTP_PRESET = 'UPDATE_SMTP_PRESET';
export const GET_SMTP_PRESETS = 'GET_SMTP_PRESETS';
export const SET_SMTP_PRESETS = 'SET_SMTP_PRESETS';
export const SET_USER_SMTP_PRESETS = 'SET_USER_SMTP_PRESETS';
export const ADD_USER_SMTP_PRESETS = 'ADD_USER_SMTP_PRESETS';
export const SET_SELECTED_USER_SMTP_PRESET = 'SET_SELECTED_USER_SMTP_PRESET';

// filters
export const SET_SMTP_AGENT_SEARCH_FILTER = 'SET_SMTP_AGENT_SEARCH_FILTER';
export const SET_SMTP_PROVIDER_SEARCH_FILTER = 'SET_SMTP_PROVIDER_SEARCH_FILTER';
export const SET_SMTP_EMAIL_SEARCH_FILTER = 'SET_SMTP_EMAIL_SEARCH_FILTER';
export const SET_SMTP_PASSWORD_SEARCH_FILTER = 'SET_SMTP_PASSWORD_SEARCH_FILTER';
export const SET_SMTP_DESCRIPTION_SEARCH_FILTER = 'SET_SMTP_DESCRIPTION_SEARCH_FILTER';
