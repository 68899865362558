export const getRateInUsdt = (coinSymbol, amount, currencyRates) => {
  if (currencyRates && Object.keys(currencyRates).length) {
    const totalInUsdt = parseFloat(
      parseFloat(1 / currencyRates[coinSymbol])
              * parseFloat(amount),
    );
    
    return !Number.isNaN(totalInUsdt) ? totalInUsdt : 0;    
  }

  return 0;
};
