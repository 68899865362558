import {
  GET_METALS_SETTINGS,
  GET_METALS_SETTING,
  ADD_METALS_SETTING,
  EDIT_METALS_SETTING,
  DELETE_METALS_SETTING,
  TOGGLE_METALS_SETTING_STATE,
  ERROR_METALS_SETTING_STATE,
} from './metalsSettingTypes';
  
const initialState = {
  metalsSettings: [],
  metalsSetting: {},
  metalsSettingsFetched: false,
  metalsSettingAdded: false,
  metalsSettingFetched: false,
  metalsSettingEditted: false,
  metalsSettingDeleted: false,
  metalsSettingError: false,
};
  
// eslint-disable-next-line default-param-last
const metalsSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_METALS_SETTINGS:
      return {
        ...state,
        metalsSettings: action.payload,
        metalsSettingsFetched: true,
      };
    case GET_METALS_SETTING:
      return {
        ...state,
        metalsSetting: action.payload,
        metalsSettingFetched: true,
      };
    case ADD_METALS_SETTING:
      return {
        ...state,
        metalsSettings: [state.metalsSettings, action.payload],
        metalsSettingAdded: true,
      };
    case EDIT_METALS_SETTING:
      return {
        ...state,
        metalsSetting: action.payload,
        metalsSettingEditted: true,
      };
    case DELETE_METALS_SETTING:
      return {
        ...state,
        metalsSettings: state.metalsSettings.filter((item) => item._id !== action.payload),
        metalsSettingDeleted: true,
      };
    case TOGGLE_METALS_SETTING_STATE:
      return {
        ...state,
        metalsSettingsFetched: false,
        metalsSettingAdded: false,
        metalsSettingFetched: false,
        metalsSettingEditted: false,
        metalsSettingDeleted: false,
        metalsSettingError: false,
      };
    case ERROR_METALS_SETTING_STATE:
      return {
        ...state,
        metalsSettingError: true,
      };
    default:
      return state;
  }
};
  
export default metalsSettingReducer;
