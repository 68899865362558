import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { ModalContext } from '../context';
import EmailTemplatesEdit from '../pages/EmailTemplates/EmailTemplateEdit/EmailTemplateEdit';
import EmailTemplateShow from '../pages/EmailTemplates/EmailTemplateShow/EmailTemplateShow';
import {
  deleteEmailTemplate, setEventFilterCreator, setStatusFilterCreator, setTitleFilterCreator, 
} from '../redux/emailTemplates/emailTemplatesActions';
import { 
  selectEventSearchFilter, selectIsSuperUser, selectStatusSearchFilter, selectTitleSearchFilter,
} from '../redux/emailTemplates/emailTemplatesSelectors';

export const EmailTemplatesColumns = () => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditTemplatePermission = permissions && permissions.length > 0 && permissions.includes('edit_email_template'); 

  // filters variables
  const eventSearchFilter = useSelector(selectEventSearchFilter);
  const titleSearchFilter = useSelector(selectTitleSearchFilter);
  const statusSearchFilter = useSelector(selectStatusSearchFilter);

  const isSuperUser = useSelector(selectIsSuperUser);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEmailTemplate(id));
      }
    });
  };

  const editAction = (id) => {
    showModal({
      headerContent: <h3>Edit Email Template</h3>,
      bodyContent: <EmailTemplatesEdit id={id} />,
    });
  };

  const showAction = (id) => {
    showModal({
      headerContent: <h3>Email Template viewer</h3>,
      bodyContent: <EmailTemplateShow id={id} />,
    });
  }; 

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Event
          <input
            type="text"
            name="event"
            autoComplete="off"
            value={eventSearchFilter}
            placeholder="Filter by event"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setEventFilterCreator(e.target.value))}
          />
        </div>
      ),
      selector: (row) => (
        <span>
          {row?.event}
        </span>
      ),
      minWidth: '170px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Title
          <input
            type="text"
            name="title"
            autoComplete="off"
            value={titleSearchFilter}
            placeholder="Filter by title"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setTitleFilterCreator(e.target.value))}
          />
        </div>
      ),
      minWidth: '200px',
      selector: ({ title }) => `${title}`,
      cell: ({ title }) => (
        <span data-tip={title}>
          {title}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Status
          <select
            name="status"
            value={statusSearchFilter}
            className="email-templates-search-bar-filter"
            style={{ width: '100px' }}
            onChange={({ target: { value } }) => dispatch(setStatusFilterCreator(value))}
          >
            <option value="all">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
      selector: ({ status }) => `${status}`,
      cell: ({ status }) => (
        <span data-tip={status}>
          {status ? 'active' : 'inactive'}
        </span>
      ),
      minWidth: '112px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'end', width: '100%' }}>
          <div className="newTablePositionHeaderAffiliate">
            Actions
          </div>
        </div>
      ),
      minWidth: '250px',
      cell: ({ _id }) => (
        <div className="newTableActionsBtn">
          <button
            className="btn btn-success btn-sm me-1 p-1"
            type="button"
            onClick={() => showAction(_id)}
          >
            Show
          </button>
          {isUserHasEditTemplatePermission ? (
            <button
              className="btn btn-warning btn-sm me-1 p-1"
              type="button"
              onClick={() => editAction(_id)}
            >
              Edit
            </button>
          ) : null}
          {isSuperUser ? (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(_id)}
            >
              <BsTrashFill size={18} />
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  return columns;
};
