import React, { useState, useEffect, useMemo } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSelector } from 'react-redux';

import { FormControlWithRadioButtons } from '../components/FormControl';
import { CellWithProgressBar } from '../components/CellWithProgressBar';
import { GeoChart } from '../Charts/GeoChart/GeoChart';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import { countryOptions } from '../utils/optionsHelper';

import style from './DepositsByCountryDashboard.module.css';


export function DepositsByCountryDashboard() {
  const {
    salesAnalysis, statisticsByCountryNumber, statisticsByCountryAmount, statisticsByCountry, 
  } = useSelector((state) => state.salesDashboard.salesDashboard);
  const [selectedCountryDepositsOptions, setSelectedCountryDepositsOptions] = useState(countryOptions[0].ticker);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    if (selectedCountryDepositsOptions === 'Number') {
      setTotalDeposits(salesAnalysis?.totalNumberOfDeposits ?? 0);
      setCountryData(statisticsByCountryNumber);
    } 
    if (selectedCountryDepositsOptions === 'Amount') {
      setTotalDeposits(salesAnalysis?.totalDepsInUSDT ?? 0);
      setCountryData(statisticsByCountryAmount);
    }  
  }, [selectedCountryDepositsOptions, salesAnalysis, statisticsByCountryAmount, statisticsByCountryNumber]);

  const sortedCountryData = useMemo(
    () => statisticsByCountry?.sort((a, b) => (selectedCountryDepositsOptions === 'Amount'
      ? b.amountOfDepsByCountry - a.amountOfDepsByCountry
      : b.numberOfDepsByCountry - a.numberOfDepsByCountry)), 
    [statisticsByCountry, selectedCountryDepositsOptions],
  );

  function renderCountryDashboard(deposits) {
    return deposits.map((el) => (
      <CellWithProgressBar 
        key={el.country}
        totalAmount={el.amountOfDepsByCountry} 
        name={el.country} 
        totalNumber={el.numberOfDepsByCountry} 
        selectedOptions={selectedCountryDepositsOptions} 
        totalDeposits={totalDeposits}
        withFlag
        flag={el.isoCountryCode}
      />
    ));
  }

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDeposits}>
        <div className={style.allDepositsTitleContainer}>
          <h3 className={style.allDepositsTitle}>Deposits by countries</h3>
          {/* <button type="button" onClick={downloadDepositsByCountryHandler} className={style.allDepositsAnalysisBtn}> */}
          {/* <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} /> */}
          {/* </button> */}
        </div>
        <div className={style.allDepositsStatisticDeposit}>
          <div className={style.radioContainer}>
            <FormControlWithRadioButtons options={countryOptions} selectedOptions={selectedCountryDepositsOptions} setSelectedOptions={setSelectedCountryDepositsOptions} />
          </div>
          <div className={style.depositsByCountryMapContainer}>
            { (countryData?.length > 1) 
              ? <GeoChart data={countryData} />
              : ( 
                <div className={style.noDataWrapper}> 
                  <NoDataAvailableTableIcon />
                  <p className={style.noDataWrapperTitle}>No data available</p>
                </div>
              )}  

          </div>
          <div className={style.allDepositTotal}>
            <p className={style.totalAmountTitle}>Total:</p>
            <p className={style.totalAmount}>{selectedCountryDepositsOptions === 'Amount' ? `$${totalDeposits}` : totalDeposits}</p>
          </div>
        </div>
        <div className={style.allDepositsStatisticsTable}>
          {(statisticsByCountry && statisticsByCountry.length > 0)
            ? renderCountryDashboard(sortedCountryData)
            : (
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
