import React, { useState, useEffect } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSelector } from 'react-redux';

import { FormControlWithRadioButtons } from '../components/FormControl';
import { DonutChart } from '../Charts/DonutChart/DonutChart';
import { statusOptions } from '../utils/optionsHelper';

import style from './DepositsByStatusDashboard.module.css';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';



export function DepositsByStatusDashboard() {
  const { statisticsByClient, statisticsByLeads, colorStatusObj } = useSelector((state) => state.salesDashboard.salesDashboard);
  const [selectedStatusDepositsOptions, setSelectedStatusDepositsOptions] = useState(statusOptions[0].ticker);
  const [donutData, setDonutData] = useState([]);

  useEffect(() => {
    setDonutData(selectedStatusDepositsOptions === 'Leads' ? statisticsByLeads : statisticsByClient);
  }, [statisticsByLeads, statisticsByClient, selectedStatusDepositsOptions]);

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDepositsTitleContainer}>
        <h3 className={style.allDepositsTitle}>Deposits by status</h3>
        {/* <button type="button" onClick={downloadDepositsByStatusHandler} className={style.allDepositsAnalysisBtn}> */}
        {/* <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} /> */}
        {/* </button> */}
      </div>
      <div className={style.allDepositsStatisticDeposit}>
        <div className={style.radioContainer}>
          <FormControlWithRadioButtons options={statusOptions} selectedOptions={selectedStatusDepositsOptions} setSelectedOptions={setSelectedStatusDepositsOptions} />
        </div>
      </div>
      <div className={style.allDepositsStatisticDepositCont}>
        { (donutData?.length > 1) 
          ? <DonutChart data={donutData} colors={colorStatusObj} />
          : ( 
            <div className={style.noDataWrapper}> 
              <NoDataAvailableTableIcon />
              <p className={style.noDataWrapperTitle}>No data available</p>
            </div>
          )}  
      </div>
    </div>
  );
}
