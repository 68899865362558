/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { deleteSetting, getSettings, searchSettings } from '../../../../redux/tradingSetting/tradingSettingActions';
import { EditTradingSettingsModal } from './EditTradingSettingsModal';
import { AddTradingSettingsModal } from './AddTradingSettingsModal';
import { ModalContext } from '../../../../context';
import { useDebounce } from '../../../../hooks/useDebounce';

export function ShiftSettings() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { showModal } = useContext(ModalContext);
  const [searchByPairName, setSearchByPairName] = useState();

  const settings = useSelector((state) => state.tradingSettings);

  const search = (value) => {
    dispatch(searchSettings(id, value));
  };

  useDebounce(searchByPairName, 300, search);

  useEffect(() => {
    dispatch(getSettings(id));
  }, []);

  const deleteTradingSetting = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteSetting(id));
        }
      });
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <span className="d-flex ">Pair Name</span>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="pairNane"
            value={searchByPairName}
            minLength="3"
            onChange={({ target: { value } }) => setSearchByPairName(value)}
          />
        </div>
      ),
      cell: ({ pairName }) => pairName,
    },
    {
      name: 'Shift Value',
      selector: ({ isPositive, shift }) => (isPositive ? shift : `-${shift}`),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: ({
        pairName, shift, isPositive, _id, 
      }) => (
        <>
          <button
            type="button"
            onClick={() => {
              showModal({
                headerContent: <h3>EDIT SETTING</h3>,
                bodyContent: <EditTradingSettingsModal
                  userId={id}
                  data={{
                    pairName, shift, isPositive, _id, 
                  }}
                />,
              });
            }}
            className="btn btn-warning btn-sm me-1 p-1"
          >
            <FontAwesomeIcon icon={faPencil} className="header-icon" />
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteTradingSetting(_id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </>

      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            <button
              type="button"
              className="btn-primary_light"
              onClick={() => {
                showModal({
                  headerContent: <h3>CREATE SETTING</h3>,
                  bodyContent: <AddTradingSettingsModal userId={id} />,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add
            </button>

          </div>
        </div>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper pl-30 mt-3">
        <DataTable
          columns={columns}
          data={settings}
          theme="solarizedd"
          persistTableHead
        />
      </div>
    </div>
  );
};
