import { apiHelper } from '../apiHelper';
import { GET_CRM_OPTIONS } from './crmTypes';

export const getCRMOptions = () => async (dispatch) => {
  const res = await apiHelper('get', '/api/crm/options');

  if (res && res.data && res.data.success) {
    dispatch({
      type: GET_CRM_OPTIONS,
      payload: res.data.options,
    });
  }
};

export const goToCrm = async (id) => {
  const res = await apiHelper('get', `/api/crm/link/${id}`);
  
  if (res && res.data && res.data.success) {
    window.open(res.data.url);
  }
};
  
