export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES';
export const EDIT_EMAIL_TEMPLATES = 'EDIT_EMAIL_TEMPLATES';
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export const SET_EMAIL_TEMPLATE = 'SET_EMAIL_TEMPLATE';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_EVENT_SEARCH_FILTER = 'SET_EVENT_SEARCH_FILTER';
export const SET_TITLE_SEARCH_FILTER = 'SET_TITLE_SEARCH_FILTER';
export const SET_STATUS_SEARCH_FILTER = 'SET_STATUS_SEARCH_FILTER';
export const SET_IS_SUPER_USER = 'SET_IS_SUPER_USER';
export const SET_IS_TEMPLATES_LOADING = 'SET_IS_TEMPLATES_LOADING';
export const SET_IS_TEMPLATE_LOADING = 'SET_IS_TEMPLATE_LOADING';
