/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  ADD_ROLE, DISPLAY_ROLES, DISPLAY_ADMIN_ROLES, DISPLAY_CLIENT_ROLES, GET_ROLE, EDIT_ROLE, DELETE_ROLE, GET_PER_ROLE, TOGGLE_ROLE_STATE, ERROR_STATE,
} from './roleTypes';
import { apiHelper } from '../apiHelper';

export const displayRoles = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/role', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_ROLES,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const displayClientRoles = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/role/client-roles', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_CLIENT_ROLES,
        payload: data?.roles,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const displayAdminRoles = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/role/admin-roles', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_ADMIN_ROLES,
        payload: data?.roles,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getRole = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/role/${id}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ROLE,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getPerRole = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/role/${id}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_PER_ROLE,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addRole = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/role/add', data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: ADD_ROLE,
        payload: data,
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const editRole = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/role/${data.id}`, data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: EDIT_ROLE,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteRole = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/role/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: DELETE_ROLE,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateRoleState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_ROLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
