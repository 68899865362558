import { ENV } from '../config/config';

const columns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
  },
  {
    name: 'Full Name',
    field: 'firstName,lastName',
    selected: true,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
  },
  {
    name: 'Phone Number',
    field: 'phone',
    selected: true,
  },
  {
    name: 'Country',
    field: 'country.nicename',
    selected: true,
  },
  {
    name: 'Created',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Affiliate Name',
    field: 'affiliator.firstName,affiliator.lastName',
    selected: true,
    permissionName: 'affiliates',
  },
  {
    name: 'Funnel Name',
    field: 'source',
    selected: true,
  },
  {
    name: 'Assigned To',
    field: '',
    selected: true,
  },
  {
    name: 'Status',
    field: 'salesStatus.name',
    selected: true,
  },
  {
    name: 'Balance',
    field: 'account.balance',
    selected: true,
  },
  {
    name: 'Credit',
    field: 'account.credit',
    selected: true,
  },
  {
    name: 'Net Deposits',
    field: 'account.netDeposits',
    selected: true,
  },
  {
    name: 'Online',
    field: 'lastLoginAt,isLogin',
    selected: true,
  },
  {
    name: 'Last Login Date',
    field: 'lastLoginAt',
    selected: true,
  },
  {
    name: 'Last Comment',
    field: 'adminComments[0].createdAt',
    selected: true,
  },
  {
    name: 'Local Time',
    field: 'countryCode',
    selected: true,
  },
  {
    name: 'Delete User',
    field: '',
    selected: true,
  },
];

const brandConfig = {
  name: 'Brand',
  field: 'brand.name',
  selected: true,
};

if (ENV.hasMultibrand) columns.push(brandConfig);

export const leadsColumns = (permissions) => columns.filter((column) => !column.permissionName || permissions.includes(column.permissionName));
