/* eslint-disable default-param-last */
import {
  SHOW_ALL_AFFILIATE_RULES,
  GET_AFFILIATE_RULES,
  GET_AFFILIATE_RULE,
  ADD_AFFILIATE_RULE,
  EDIT_AFFILIATE_RULE,
  DELETE_AFFILIATE_RULE,
  TOGGLE_AFFILIATE_RULE_STATE,
  ERROR_AFFILIATE_RULE_STATE,
} from './affiliateRuleTypes';

const initialState = {
  affiliateRules: [],
  affiliateRule: {},
  affiliateRulesFetched: false,
  affiliateRuleFetched: false,
  affiliateRuleAdded: false,
  affiliateRuleEdited: false,
  affiliateRuleDeleted: false,
  error: false,
};

const affiliateRuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALL_AFFILIATE_RULES:
      return {
        ...state,
        affiliateRules: action.payload,
        affiliateRulesFetched: true,
      };
    case GET_AFFILIATE_RULES:
      return {
        ...state,
        affiliateRules: action.payload,
        affiliateRulesFetched: true,
      };
    case GET_AFFILIATE_RULE:
      return {
        ...state,
        affiliateRule: action.payload,
        affiliateRuleFetched: true,
      };
    case ADD_AFFILIATE_RULE:
      return {
        ...state,
        affiliateRules: [...state.affiliateRules, action.payload],
        affiliateRuleAdded: true,
      };
    case DELETE_AFFILIATE_RULE:
      return {
        ...state,
        affiliateRules: state.affiliateRules.filter((item) => item._id !== action.payload),
        affiliateRuleDeleted: true,
      };
    case EDIT_AFFILIATE_RULE:
      return {
        ...state,
        affiliateRules: action.payload,
        affiliateRuleEdited: true,
      };
    case TOGGLE_AFFILIATE_RULE_STATE:
      return {
        ...state,
        affiliateRulesFetched: false,
        affiliateRuleFetched: false,
        affiliateRuleAdded: false,
        affiliateRuleEdited: false,
        affiliateRuleDeleted: false,
        error: false,
      };
    case ERROR_AFFILIATE_RULE_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default affiliateRuleReducer;
