export const userWithdrawalsColumns = [
  {
    name: 'Transaction ID',
    selected: true,
  },
  {
    name: 'Amount',
    selected: true,
  },
  {
    name: 'Currency',
    selected: true,
  },
  {
    name: 'In USD',
    selected: true,
  },
  {
    name: 'Transaction Type',
    selected: true,
  },
  {
    name: 'Status',
    selected: true,
  },
  {
    name: 'Time Initiated',
    selected: true,
  },
  {
    name: 'Real/Fake',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
