export const orderHistoryColumns = [
  {
    name: 'User',
    field: 'user.username',
    selected: true,
  },
  {
    name: 'ID',
    field: 'customId',
    selected: true,
  },
  {
    name: 'Trading Pair',
    field: 'primary.symbol,secondary.symbol',
    selected: true,
  },
  {
    name: 'Trading Type',
    field: 'tradeType',
    selected: true,
  },
  {
    name: 'Price Start',
    field: 'tradeStartPrice',
    selected: true,
  },
  {
    name: 'Volume',
    field: 'position',
    selected: true,
  },
  {
    name: 'Price End',
    field: 'tradeEndPrice',
    selected: true,
  },
  {
    name: 'Margin',
    field: 'userInvestedAmount',
    selected: true,
  },
  {
    name: 'Profit/Loss',
    field: 'exitPrice',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'endSwap',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'endCommission',
    selected: true,
  },
  {
    name: 'Start Time',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'End Time',
    field: 'closedAt',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
