import { toast } from 'react-toastify';
import {
  GET_CFD_ORDERS, 
  UPDATE_CFD_ORDER, 
  REVERT_CFD_ORDER, 
  STOP_CFD_ORDER, 
  MASS_CFD_ORDER_CREATION, 
  MASS_CFD_ORDER_STOP, 
  MASS_CFD_ORDER_EDIT,
  All_CLOSED_ORDERS_SUM, 
  All_OPEN_ORDERS,
  OPEN_CFD_ORDER,
} from './cfdOrderTypes';
import { apiHelper } from '../apiHelper';

export const getAllOpenOrders = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-order/all-open-orders');

    if (res && res.data && res.data.success) {
      dispatch({
        type: All_OPEN_ORDERS,
        payload: res.data.openOrders,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getAllClosedOrdersSum = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-order/sum-all-closed-orders');

    if (res && res.data && res.data.success) {
      dispatch({
        type: All_CLOSED_ORDERS_SUM,
        payload: res.data.closedOrdersSum,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getCfdOrderHistory = ({
  page, rowsPerPage, filters = {}, sort = {}, userId,
}) => async (dispatch) => {
  try {
    let url = `/api/cfd-order/history?page=${page}&perPage=${rowsPerPage}`;

    if (filters && filters['trading type']) {
      url += `&tradeType=${filters['trading type']}`;
    }

    if (filters && filters['start time']) {
      url += `&timeOpened=${filters['start time']}`;
    }

    if (filters && filters['end time']) {
      url += `&closedAt=${filters['end time']}`;
    }

    if (filters && filters['trading pair']) {
      url += `&pairName=${filters['trading pair']}`;
    }

    if (filters && filters.user) {
      url += `&user=${filters.user}`;
    }

    if (sort && Object.keys(sort).length) {
      url += `&sort=${JSON.stringify(sort)}`;
    }

    if (userId && userId.length) {
      url += `&userId=${userId}`;
    }

    const res = await apiHelper('get', url);

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_ORDERS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getCfdOpenOrders = ({
  page, 
  rowsPerPage, 
  filters = {}, 
  sort = {}, 
  userId = '',
}) => async (dispatch) => {
  try {
    let url = `/api/cfd-order/open?page=${page}&perPage=${rowsPerPage}`;

    if (filters && filters['trading type']) {
      url += `&tradeType=${filters['trading type']}`;
    }

    if (filters && filters['assigned to']) {
      url += `&assignedTo=${filters['assigned to']}`;
    }

    if (filters && filters['time opened']) {
      url += `&timeOpened=${filters['time opened']}`;
    }

    if (filters && filters.clientName) {
      url += `&user=${filters.clientName}`;
    }

    if (filters && filters.customId) {
      url += `&customId=${filters.customId}`;
    }

    if (filters && filters.symbol) {
      url += `&pairName=${filters.symbol}`;
    }

    if (sort && Object.keys(sort).length) {
      url += `&sort=${JSON.stringify(sort)}`;
    }

    if (userId && userId.length) {
      url += `&userId=${userId}`;
    }

    const res = await apiHelper('get', url);

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_ORDERS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const openCFDOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/cfd-order/admin-open', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: OPEN_CFD_ORDER,
        payload: res.data.order,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const massOrderCreation = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/cfd-order/mass-open', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: MASS_CFD_ORDER_CREATION,
        payload: res.data.results,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const massEditOrders = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/cfd-order/mass-edit', data);

    if (res && res.data && res.data.success) {
      const { results, orders } = res.data;
      dispatch({
        type: MASS_CFD_ORDER_EDIT,
        payload: { results, orders },
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateClosedCfdOrder = (id, data) => async (dispatch) => {
  try {
    const { timeOpened, closedAt } = data;

    if (timeOpened) {
      data.timeOpened = new Date(timeOpened);
    }
    
    if (closedAt) {
      data.closedAt = new Date(closedAt);
    }

    const res = await apiHelper('patch', `/api/cfd-order/history/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_CFD_ORDER,
        payload: res.data.order,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateOpenedCfdOrder = (id, data) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const res = await apiHelper('patch', `/api/cfd-order/open/${id}`, data, {}, 'application/json', { 'x-timezone': timezone });

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_CFD_ORDER,
        payload: res.data.order,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const revertCfdOrder = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/cfd-order/${id}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: REVERT_CFD_ORDER,
        payload: res.data.order,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const exportFilteredDataToFile = async (filteredItems, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/cfd-order/export', {
      filteredItems, columns, fileType,
    });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const stopOrderById = (id, actualPrice) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/cfd-order/stop/${id}`, { actualPrice, stopByClick: false });

    if (res && res.data && res.data.success) {
      dispatch({
        type: STOP_CFD_ORDER,
        payload: res.data.order,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const massStopOrders = (stopOrdersData) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/cfd-order/mass-stop', { stopOrdersData });

    if (res && res.data && res.data.success) {
      dispatch({
        type: MASS_CFD_ORDER_STOP,
        payload: { ids: Object.keys(stopOrdersData) },
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
