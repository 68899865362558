import { getCountry, getTimezone } from 'countries-and-timezones';

export const getLocalDataByCountryCode = (iso) => {
  const date = new Date();
  const country = getCountry(iso);
  let localDate = null;

  if (country && country.timezones?.length) {
    const timezone = getTimezone(country.timezones[0]);
    const invdate = new Date(date.toLocaleString('en-US', {
      timeZone: timezone.name,
    }));
    const diff = date.getTime() - invdate.getTime();
    localDate = new Date(date.getTime() - diff);
  }

  return localDate;
};
