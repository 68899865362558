const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const getTimeInUTCFormat = (weekday, hour) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const dayIndex = days.indexOf(weekday);
  const dayDifference = dayIndex - currentDay;
  currentDate.setDate(currentDate.getDate() + dayDifference);
  currentDate.setHours(hour);
  currentDate.setMinutes(0);
  const utcDay = days[currentDate.getUTCDay()];
  const utcHour = (`0${currentDate.getUTCHours()}`).slice(-2);

  return `${utcDay} ${utcHour}`;
};

export const hoursHeader = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export const setScheduleDates = () => {
  const dates = {};

  days.forEach((day) => {
    dates[day] = hoursHeader.map((hours) => (
      {
        label: hours,
        weekday: day,
        selected: false,
        utc: getTimeInUTCFormat(day, hours),
      }
    ));
  });

  return dates;
};
