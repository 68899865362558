import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { formatTime } from '../../../../helpers/formatDate';
import styles from './styles.module.css';
import { EventModal } from '../../../../components';

export function EventList({
  events, setShowEditEventModal, focusedEvent, setFocusedEvent, setDraggableEvent, 
}) {
  const [showEventModal, setShowEventModal] = useState(false);
  return (
    <div className={styles.eventList}>
      {!!events.length && events.map((event, key = 0) => {
        const { name, time, userId } = event;
        return (
          <Link 
            className={styles.event}
            key={key}
            style={events.length > 1 
              ? { 
                flexDirection: 'row', 
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '20px', 
              } 
              : {}}
            to={`/user-detail/${userId}`}
            draggable
            onDragStart={() => {
              setDraggableEvent(event);
            }}
          >    
            <div className={styles.eventHeader}>
              <span>{name}</span>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className={styles.moreButton}
                data-testid="moreButton"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEventModal(true);
                  setFocusedEvent(event);
                }}
              />
            </div>
            <span className={styles.eventTime}>
              {formatTime(new Date(time).getHours(), new Date(time).getMinutes())}
            </span>
          </Link>
        ); 
      })}
      {showEventModal && (
        <EventModal 
          setShowEditEventModal={setShowEditEventModal}
          focusedEvent={focusedEvent}
          onClickOutside={() => setShowEventModal(false)}
        />
      )}
    </div>
  );
};
