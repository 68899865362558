import { GET_IS_CRM_USER_IN_SCOPE, GET_IS_USER_IN_SCOPE } from './hierarchyTypes';

const initialState = {
  isCRMUserInScope: undefined,
  isUserInScope: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_IS_CRM_USER_IN_SCOPE:
      return {
        ...state,
        isCRMUserInScope: action.payload,
      };
    case GET_IS_USER_IN_SCOPE:
      return {
        ...state,
        isUserInScope: action.payload,
      };
    default:
      return state;
  }
};
