import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { getEventsByUserId } from '../../../../redux/event/eventActions';
import { EditEventModal, AddEventModal } from '../../../../components';
import { Event } from './Event';

export function UserEvents({ userId }) {
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditEventModal] = useState(false);
  const [focusedEvent, setFocusedEvent] = useState('');
    
  const events = useSelector((state) => state.events);
  const uid = useSelector((state) => state.crmUser.crmUserInfo._id);
    
  useEffect(() => {
    if (uid && userId) {
      dispatch(getEventsByUserId(uid, userId));
    }
  }, [userId, uid]);

  return (
    <div className={styles.eventsWrapper}>
      <div className={styles.eventsHeaderWrapper}>
        <h5>Events</h5>
        <button 
          type="button" 
          className="btn btn-default icon-btn btn-bg-green"
          onClick={() => setShowAddModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
          Add
        </button>
      </div>
      <div className={styles.eventsBodyWrapper}>
        {!!events.length && events.map((event) => (
          <Event 
            key={event._id}
            event={event}
            setFocusedEvent={setFocusedEvent}
            focusedEvent={focusedEvent}
            setShowEditEventModal={setShowEditEventModal}
          />
        ))}
      </div>
      {showAddModal && <AddEventModal setShowModal={setShowAddModal} showModal={showAddModal} userId={userId} />}
      {showEditModal && <EditEventModal setShowModal={setShowEditEventModal} showModal={showEditModal} focusedEvent={focusedEvent} />}
    </div>
  );
}
