import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendSmsAction } from '../../../../redux/smsServices/smsServicesActions';
import s from './SendSmsModal.module.css';

function SendSmsModal({ handleClose, toPhoneNumber }) {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState('Stage Support Team');
  const [smsText, setSmsText] = useState('');
  
  const handleSubjectChange = (event) => setSubject(event.target.value);
  const handleSmsTextChange = (event) => setSmsText(event.target.value);

  const handleSubmitSms = () => {
    dispatch(sendSmsAction({
      smsText,
      toPhoneNumber,
    }, handleClose));
  };

  return (
    <>
      <div className={s.wrapper}>
        {/* <div className="form-group me-2">
          <label className="control-label mb-2">Subject</label>
          <div className="input-wrapper">
            <input 
              type="text" 
              className="form-control" 
              name="smsSubject" 
              value={subject} 
              onChange={handleSubjectChange}
            />
          </div>
        </div> */}
        <div className="form-group me-2">
          <label className="control-label mb-2">SMS Body</label>
          <div className="input-wrapper">
            <input
              type="text" 
              className="form-control" 
              name="smsText" 
              value={smsText} 
              onChange={handleSmsTextChange}
            />
          </div>
        </div>
      </div>
      <div className={s.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitSms}>
          Send Email
        </Button>
      </div>
    </>
  );
}

export default SendSmsModal;
