import {
  ADD_COUNTRY, REMOVE_COUNTRY, SET_COUNTRIES, UPDATE_COUNTRY, 
} from './countiesTypes';

const initialState = {
  countries: [],
};

function countryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case ADD_COUNTRY:
      return {
        ...state,
        countries: [...state.countries, action.payload],
      };
    case UPDATE_COUNTRY:
      return {
        ...state,
        countries: state.countries.map((country) => (country.id === action.payload.id ? { ...country, ...action.payload.updates } : country)),
      };
    case REMOVE_COUNTRY:
      return {
        ...state,
        countries: state.countries.filter((country) => country.id !== action.payload),
      };
    default:
      return state;
  }
}

export default countryReducer;

