import { apiHelper } from '../apiHelper';
import { 
  GET_DATAFEED_SETTINGS, 
  CREATE_DATAFEED_SETTING, 
  TOOGLE_ACTIVE_DATAFFED,
  DELETE_DATAFEED_SETTING,
  UPDATE_DATAFEED_SETTING,
} from './datafeedsTypes';

export const getDatafeedSettings = () => async (dispatch) => {
  const res = await apiHelper('get', '/api/datafeed-setting');

  if (res && res.data && res.data.success) {
    dispatch({
      type: GET_DATAFEED_SETTINGS,
      payload: res.data.settings,
    });
  }
};

export const createDatafeedSetting = (data) => async (dispatch) => {
  const res = await apiHelper('post', '/api/datafeed-setting', data);

  if (res && res.data && res.data.success) {
    dispatch({
      type: CREATE_DATAFEED_SETTING,
      payload: res.data.setting,
    });
  }
};

export const toogleActiveDatafeed = (id, data) => async (dispatch) => {
  const res = await apiHelper('patch', `/api/datafeed-setting/active/${id}`, data);

  if (res && res.data && res.data.success) {
    dispatch({
      type: TOOGLE_ACTIVE_DATAFFED,
      payload: res.data.setting,
    });
  }
};

export const deleteDatafeedSetting = (id) => async (dispatch) => {
  const res = await apiHelper('delete', `/api/datafeed-setting/${id}`);

  if (res && res.data && res.data.success) {
    dispatch({
      type: DELETE_DATAFEED_SETTING,
      payload: res.data.setting,
    });
  }
};

export const updateDatafeedSetting = (id, data) => async (dispatch) => {
  const res = await apiHelper('patch', `/api/datafeed-setting/${id}`, data);

  if (res && res.data && res.data.success) {
    dispatch({
      type: UPDATE_DATAFEED_SETTING,
      payload: res.data.setting,
    });
  }
};
  
