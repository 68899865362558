import { REVERT_EXCHANGE_ORDER, GET_EXCHANGE_ORDER, DELETE_EXCHANGE_ORDER } from './exchangeOrderTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_EXCHANGE_ORDER:
      return action.payload;
    case REVERT_EXCHANGE_ORDER:
      return state.map((order) => {
        if (order._id === action.payload._id) {
          return action.payload;
        }

        return order;
      });
    case DELETE_EXCHANGE_ORDER:
      return state.filter((order) => order._id !== action.payload._id);
    default:
      return state;
  }
};
