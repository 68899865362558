export function getDateRange(label) {
  const today = new Date();
  let startDate;
  let endDate;

  const setToStartOfDay = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const offset = d.getTimezoneOffset();
    d.setMinutes(d.getMinutes() - offset);
    return d;
  };

  const setToEndOfDay = (date) => {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    const offset = d.getTimezoneOffset();
    d.setMinutes(d.getMinutes() - offset);
    return d;
  };

  switch (label) {
    case 'Today':
      startDate = setToStartOfDay(today);
      endDate = setToEndOfDay(today);
      break;
    case 'Yesterday':
      startDate = setToStartOfDay(today);
      startDate.setDate(startDate.getDate() - 1);
      endDate = setToEndOfDay(startDate);
      break;
    case 'This Week':
      startDate = setToStartOfDay(today);
      startDate.setDate(today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1));
      endDate = setToEndOfDay(today);
      endDate.setDate(today.getDate() + (today.getDay() === 0 ? 0 : 7 - today.getDay() + 1));
      break;
    case 'This Month':
      startDate = setToStartOfDay(new Date(today.getFullYear(), today.getMonth(), 1));
      endDate = setToEndOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0));
      break;
    case 'Past Week':
      startDate = setToStartOfDay(new Date(today));
      const dayIndex = today.getDay();
      if (dayIndex === 0) { 
        startDate.setDate(today.getDate() - 13); 
      } else {
        startDate.setDate(today.getDate() - dayIndex + 1 - 7); 
      }
    
      endDate = setToEndOfDay(new Date(today));
      if (dayIndex === 0) { 
        endDate.setDate(today.getDate() - 7); 
      } else {
        endDate.setDate(today.getDate() - dayIndex + 1);
      }
      break;
    case 'Past Month':
      startDate = setToStartOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1));
      endDate = setToEndOfDay(new Date(today.getFullYear(), today.getMonth(), 0));
      break;
    case 'Last 7 days':
      startDate = setToStartOfDay(new Date(today));
      startDate.setDate(startDate.getDate() - 6);
      endDate = setToEndOfDay(today);
      break;
    case 'Last 30 days':
      startDate = setToStartOfDay(new Date(today));
      startDate.setDate(startDate.getDate() - 29);
      endDate = setToEndOfDay(today);
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
}

export const periodOptions = [
  { label: 'All' },
  { label: 'Today' },
  { label: 'Yesterday' },
  { label: 'This Week' },
  { label: 'This Month' },
  { label: 'Past Week' },
  { label: 'Past Month' },
  { label: 'Last 7 days' },
  { label: 'Last 30 days' },
  { label: 'Custom' },
];

export function parseISOString(stringDate) {
  const splitString = stringDate.split(/\D+/); 
  const date = new Date(Date.UTC(splitString[0], --splitString[1], splitString[2], splitString[3], splitString[4], splitString[5], splitString[6]));

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  const dateStr = `${year}-${month}-${day}`;
  const timeStr = `${hours}:${minutes}`;

  return [dateStr, timeStr];
}
