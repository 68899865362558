import {
  GET_ORDER, CLEAR_ORDER, GET_USER_ORDER, ORDER_ERROR, STOP_ORDER, ADD_ORDER, REVERT_ORDER, EDIT_HISTORY_ORDER, ALL_ACTIVE_ORDERS, EXPORT_FILTERED_DATA_TO_FILE, TOGGLE_ORDER_STATE, UPDATE_SOCKET_ORDER, UPDATE_ACTIVE_ORDER, START_ORDER,
} from './leverageOrderTypes';

const initialState = {
  orders: [],
  userOrders: [],
  order: [],
  allActiveOrders: [],
  started: false,
  success: false,
  error: false,
  updatedOrder: false,
  edittedHistoryOrder: false,
  exportFilterdDataToFile: false,
};

// eslint-disable-next-line default-param-last
const LeverageOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SOCKET_ORDER: {
      const updatedOrderId = action.payload?._id;
      const updatedIndex = state.userOrders.findIndex((obj) => obj._id === updatedOrderId);

      let updatedUserOrders;
      if (updatedIndex !== -1) {
        updatedUserOrders = [...state.userOrders];
        updatedUserOrders[updatedIndex] = action.payload;
      } else {
        updatedUserOrders = [action.payload, ...state.userOrders];
      }

      return {
        ...state,
        userOrders: updatedUserOrders,
      };
    }
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
        edittedHistoryOrder: false,
      };
    case GET_USER_ORDER:
      return {
        ...state,
        userOrders: action.payload,
        edittedHistoryOrder: false,
      };
    case UPDATE_ACTIVE_ORDER:
      return {
        ...state,
        allActiveOrders: {
          ...state.allActiveOrders,
          orders: state.allActiveOrders.orders.map((order) => {
            if (order._id === action.payload._id) {
              return { ...action.payload, user: order.user };
            }

            return order;
          }),
        },
      };
    case ADD_ORDER:
      return {
        ...state,
        order: action.payload,
        success: true,
        error: false,
        updatedOrder: true,
        edittedHistoryOrder: false,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        started: false,
        success: false,
        error: false,
        updatedOrder: false,
        edittedHistoryOrder: false,
      };
    case START_ORDER:
      return {
        ...state,
        started: true,
      };
    case STOP_ORDER:
      return {
        ...state,
        success: false,
        error: false,
        updatedOrder: true,
        edittedHistoryOrder: false,
      };
    case REVERT_ORDER:
      return {
        ...state,
        userOrders: state.userOrders.filter((item) => item._id !== action.payload._id),
        orders: state.orders.filter((item) => item._id !== action.payload._id),
        edittedHistoryOrder: false,
      };
    case EDIT_HISTORY_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order._id === action.payload._id) {
            return { ...action.payload, user: order.user };
          }

          return order;
        }), 
        edittedHistoryOrder: true,
      };
    case ALL_ACTIVE_ORDERS:
      return {
        ...state,
        allActiveOrders: action.payload,
        edittedHistoryOrder: false,
      };
    case TOGGLE_ORDER_STATE:
      return {
        ...state,
        exportFilterdDataToFile: false,
      };
    case EXPORT_FILTERED_DATA_TO_FILE:
      return {
        ...state,
        exportFilterdDataToFile: action.payload,
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default LeverageOrderReducer;
