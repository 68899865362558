import { ENV } from '../config/config';

export const savingsInvestmentColumns = [
  {
    name: 'ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'User',
    field: 'user',
    selected: true,
  },
  {
    name: 'Invested Amount',
    field: 'investedAmount',
    selected: true,
  },
  {
    name: 'Final Earning',
    field: 'finalEarnings',
    selected: true,
  },
  {
    name: 'Period',
    field: 'period',
    selected: true,
  },
  {
    name: 'Percent',
    field: 'pct',
    selected: true,
  },
  {
    name: 'Start Date',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Release Date',
    field: 'releaseTime',
    selected: true,
  },
  {
    name: 'Flexible',
    field: 'isFlexible',
    selected: true,
  },
  {
    name: 'Closed',
    field: 'isReleased',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
