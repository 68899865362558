import React, { useState } from 'react';
import {
  Modal, Button, ModalHeader, ModalBody, 
} from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import transition from 'react-element-popper/animations/transition';
import opacity from 'react-element-popper/animations/opacity';
import 'react-multi-date-picker/styles/colors/teal.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { addEvent } from '../../redux/event/eventActions';

export function AddEventModal({ showModal, setShowModal, userId }) {
  const dispatch = useDispatch();

  const [time, setTime] = useState(new Date());
  const [eventName, setEventName] = useState('');
  const [duration, setDuration] = useState(5);
  const [remind, setRemind] = useState(5);
  const [description, setDescription] = useState('');
  const uid = useSelector((state) => state.crmUser.crmUserInfo._id);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    const data = {
      name: eventName,
      crmUserId: uid,
      userId,
      time: new Date(time).valueOf(),
      duration,
      description,
      remind,
    };
        
    await dispatch(addEvent(data));
    handleCloseModal();
  };

  return (
    <Modal
      className="widthdrawal-modal"
      centered
      show={showModal}
      onHide={setShowModal}
    >
      <ModalHeader closeButton />

      <ModalBody>
        <div>
          <div className={styles.eventModalNameWrapper}>
            Event Name
            <input 
              type="text" 
              className={styles.nameInput}
              placeholder="Event Name..."
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </div>
          <div className={styles.modalTime}>
            Time
            <DatePicker 
              value={time}
              onChange={setTime}
              format="MM/DD/YYYY HH:mm"
              className="bg-dark teal" 
              inputClass={styles.modalTimeInput}
              animations={[
                opacity(), 
                transition({ from: 35, duration: 800 }),
              ]}
              plugins={[
                <TimePicker hideSeconds />,
              ]}
            />
          </div>
          <div className={styles.modalDuration}>
            Duration
            <Select 
              className={styles.modalDurationSelector} 
              style={{ color: '#fff' }}
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <MenuItem value={5}>5 minutes</MenuItem>
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={120}>2 hour</MenuItem>
              <MenuItem value={160}>3 hour</MenuItem>
              <MenuItem value={180}>6 hour</MenuItem>
            </Select>
          </div>

          <div className={styles.modalDescriptionWrapper}>
            Description
            <textarea 
              className={styles.eventModalDescription}
              placeholder="Event Description..."
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
                    
          <div className={styles.modalDuration}>
            Remind me at
            <Select 
              className={styles.modalDurationSelector} 
              style={{ color: '#fff' }}
              value={remind}
              onChange={(e) => setRemind(e.target.value)}
            >
              <MenuItem value={5}>5 minutes</MenuItem>
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={120}>2 hour</MenuItem>
              <MenuItem value={160}>3 hour</MenuItem>
              <MenuItem value={180}>6 hour</MenuItem>
            </Select>
          </div>
        </div>
      </ModalBody>

      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>

    </Modal>
  );
};
