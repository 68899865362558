import React from 'react';
import {
  RadioGroup, FormControlLabel, FormControl, styled, 
} from '@mui/material';

import { RadioButton } from './RadioButton';

import style from '../SalesDashboard.module.css';


export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '8px',
  boxSizing: 'border-box',
}));

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '20px',
  height: '70px',
  overflow: 'auto',
}));

export function FormControlWithRadioButtons({ options, selectedOptions, setSelectedOptions }) {
  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={selectedOptions}
        onChange={handleChange}
      >
        {options.map((el) => (
          <FormControlLabel
            sx={{
              marginLeft: '0',
              marginRight: '0',
              minWidth: '80px',
            }}
            key={el.ticker}
            value={el.ticker}
            control={(
              <RadioButton
                checked={selectedOptions === el.ticker}
                onChange={handleChange}
                value={el.ticker}
              />
              )}
            label={(
              <div className={style.formControlDiv}>
                <p className={style.formControlLabel}>{el.ticker}</p>
              </div>
            )}
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  );
}
