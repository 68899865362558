import React, { useEffect, useState } from 'react';
import styles from './AdditionalParamsComponent.module.css';


const convertObjectToArray = (obj) => Object.entries(obj).map(([key, value]) => ({ key, value }));

function AdditionalParamsComponent({
  onAdditionalParamsChange,
  defaultAdditionalParams = [],
}) {
  const [authData, setAuthData] = useState(convertObjectToArray(defaultAdditionalParams));

  useEffect(() => {
    const authDataObject = authData.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    onAdditionalParamsChange(authDataObject);
  }, [authData]);

  const handleKeyChange = (index, newKey) => {
    const newAuthData = [...authData];
    newAuthData[index].key = newKey;
    setAuthData(newAuthData);
  };

  const handleValueChange = (index, newValue) => {
    const newAuthData = [...authData];
    newAuthData[index].value = newValue;
    setAuthData(newAuthData);
  };

  const handleAddData = () => {
    const newAuthData = [...authData, { key: '', value: '' }];
    setAuthData(newAuthData);
  };

  return (
    <div className="form-group">
      <label className="lead">
        Additional Params
        <button
          className={styles.addBtn}
          type="button"
          onClick={handleAddData}
        >
          Add+
        </button>
      </label>
      <div className={styles.wrapper}>
        {
          authData.map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.itemWrapper}>
              <input
                type="text"
                className={styles.inputKey}
                value={data.key}
                placeholder="Key"
                onChange={(e) => handleKeyChange(index, e.target.value)}
              />
              <input
                type="text"
                className={styles.inputValue}
                value={data.value}
                placeholder="Value"
                onChange={(e) => handleValueChange(index, e.target.value)}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default AdditionalParamsComponent;
