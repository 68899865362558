export const AdminBanksColumns = [
  {
    name: 'Name',
    field: 'name',
    selected: true,
  },
  {
    name: 'IBAN',
    field: 'iban',
    selected: true,
  },
  {
    name: 'Account Number',
    field: 'accountNumber',
    selected: true,
  },
  {
    name: 'Bank Address',
    field: 'bankAddress',
    selected: true,
  },
  {
    name: 'Swift Code',
    field: 'swiftCode',
    selected: true,
  },
  {
    name: 'In Use',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];

