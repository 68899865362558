export const colourStyles = {
  control: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    background: isDisabled ? '#aaa' : '#374057',
    color: isDisabled ? '#aaa' : 'fff',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    border: '1px solid #374057',
    boxShadow: isSelected ? 'none' : 'none',
    borderColor: isSelected ? '#374057' : '#374057',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#374057',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? '#16202e'
      : isSelected
        ? '#16202e'
        : undefined,
    color: 'fff',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    zIndex: 1,
    '&:hover': {
      background: isDisabled ? '#aaa' : '#16202e',
    },
  }),
};
