import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ShiftSettings } from './ShiftSettings';
import { SpreadSettings } from './SpreadSettings';

export function TradingSettings() {
  const [value, setValue] = useState('shift');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Component = () => {
    const valueToComponent = {
      shift: <ShiftSettings />,
      spread: <SpreadSettings />,
    };

    return valueToComponent[value];
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box sx={{ borderBottom: 1, borderColor: '#374057', marginBottom: '25px' }}>
        <Tabs onChange={handleChange} value={value}>
          <Tab sx={{ color: 'white' }} label="Shift Settings" value="shift" />
          <Tab sx={{ color: 'white' }} label="Spread Settings" value="spread" />
        </Tabs>
      </Box>

      <Component />
    </Box>
  );
};
