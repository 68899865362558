import React, {
  useContext, useEffect, useMemo, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getAffiliates, updateAffiliateState } from '../../redux/affiliate/affiliateActions';
import FilterComponent from '../../components/FilterComponent';
import { AffiliateColumns } from '../../DataTableSchemas/AffiliateColumns';
import { ModalContext } from '../../context';
import { AddAffiliateModal } from './components';

function Affiliate() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [loader, setLoader] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const paginationStorageName = 'DataTable_affiliate/listing_pagination';

  const users = useSelector((state) => state?.affiliate?.affiliates);
  const usersFetched = useSelector((state) => state?.affiliate?.affiliatesFetched);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddUsers = permissionName && permissionName.length && permissionName.includes('affiliate_add');

  const filteredItems = users?.filter(
    (item) => (item.firstName
        && item.firstName.toLowerCase().includes(filterText.toLowerCase()))
      || (item.email
        && item.email.toLowerCase().includes(filterText.toLowerCase()))
      || (item.userType
        && item.userType.toLowerCase().includes(filterText.toLowerCase())),
  );
        
  const setStoredPagination = () => {
    const affiliateRowsJSON = localStorage.getItem(paginationStorageName);
    if (affiliateRowsJSON) {
      const filterRows = JSON.parse(affiliateRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };
    
  const handleRowsPerPageChange = async (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      await dispatch(getAffiliates());
    };
    fetchData();
    setStoredPagination();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (usersFetched) {
        dispatch(updateAffiliateState());
        setLoader(false);
      }
    };
    fetchData();
  }, [usersFetched]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const affiliateCol = AffiliateColumns();

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Affiliate Details</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddUsers && (
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={() => {
                  showModal({
                    headerContent: <h3>Add Affiliate</h3>,
                    bodyContent: <AddAffiliateModal />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </button>
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={affiliateCol}
                  data={filteredItems}
                  pagination
                  highlightOnHover
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                />
              )
          }
        </div>
      </div>
    </div>
  );
}

export default Affiliate;
