import { GET_BRANDS } from './brandTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_BRANDS:
      return action.payload;
    default:
      return state;
  }
};
