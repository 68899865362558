import React from 'react';
import { Dropdown } from 'react-bootstrap';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export function DownloadFile({ handleExport, isPositionUnset = false }) {
  return (
    <Dropdown style={{ position: isPositionUnset ? 'unset' : 'absolute' }} className="container-btn-primary_transparent">
      <Dropdown.Toggle 
        variant="" 
        className="btn-primary_transparent iconed" 
        id="dropdown-basic"
      >
        <FileDownloadOutlinedIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className="primary-menu">
        <div className="import-csv-btn text-center">
          <button type="button" className="menu-text" onClick={() => handleExport('xlsx')}>Excel</button>
        </div>
        <div className="import-csv-btn text-center">
          <button type="button" className="menu-text" onClick={() => handleExport('csv')}>CSV</button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
