import { toast } from 'react-toastify';
import {
  SET_EMAIL_TEMPLATES,
  SET_EMAIL_TEMPLATE,
  SET_TOTAL_COUNT, 
  SET_EVENT_SEARCH_FILTER,
  SET_TITLE_SEARCH_FILTER,
  SET_STATUS_SEARCH_FILTER,
  EDIT_EMAIL_TEMPLATES,
  ADD_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  SET_IS_SUPER_USER,
  SET_IS_TEMPLATES_LOADING,
  SET_IS_TEMPLATE_LOADING,
} from './emailTemplatesTypes';
import { apiHelper } from '../apiHelper';

// action creators below

const setIsTemplatesLoadingCreator = (status) => ({
  type: SET_IS_TEMPLATES_LOADING,
  payload: status,
});

const setIsTemplateLoadingCreator = (status) => ({
  type: SET_IS_TEMPLATE_LOADING,
  payload: status,
});

const setIsSuperUserCreator = (status) => ({
  type: SET_IS_SUPER_USER,
  payload: status,
});

const setTotalCountCreator = (totalCount) => ({
  type: SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setEventFilterCreator = (value) => ({
  type: SET_EVENT_SEARCH_FILTER,
  payload: value,
});

export const setTitleFilterCreator = (value) => ({
  type: SET_TITLE_SEARCH_FILTER,
  payload: value,
});

export const setStatusFilterCreator = (value) => ({
  type: SET_STATUS_SEARCH_FILTER,
  payload: value,
});

const addEmailTemplateCreator = (template) => ({
  type: ADD_EMAIL_TEMPLATE,
  payload: template,
});

export const setEmailTemplateCreator = (template) => ({
  type: SET_EMAIL_TEMPLATE,
  payload: template,
});

const editEmailTemplatesCreator = (template) => ({
  type: EDIT_EMAIL_TEMPLATES,
  payload: template,
});

const setEmailTemplatesCreator = (templates) => ({
  type: SET_EMAIL_TEMPLATES,
  payload: templates,
});

const deleteEmailTemplatesCreator = (id) => ({
  type: DELETE_EMAIL_TEMPLATE,
  payload: id,
});


// Thunks below

export const makeSuperUserCheck = () => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/email-templates/is-create-available', '');
    if (response?.data) {
      const status = response?.data?.status ?? false;
      dispatch(setIsSuperUserCreator(status));
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const getEmailTemplates = (page = 1, rowsPerPage = 25, filters = {}) => async (dispatch) => {
  dispatch(setIsTemplatesLoadingCreator(true));

  const params = {
    page,
    limit: rowsPerPage,
  };

  if (filters.title) params.title = filters.title ?? '';
  if (filters.event) params.event = filters.event ?? '';
  if (filters.status) params.status = filters.status ?? 'active';

  try {
    const response = await apiHelper('get', '/api/email-templates', '', params);

    if (response?.data && response?.data?.allEmailTemplates) {
      const result = response?.data?.allEmailTemplates[0] ?? {};

      const allEmailTemplates = result?.paginatedResult ?? [];
      const totalCount = result?.totalCount[0]?.count ?? 25;

      dispatch(setTotalCountCreator(totalCount));
      dispatch(setEmailTemplatesCreator(allEmailTemplates));
    } else {
      dispatch(setEmailTemplatesCreator([]));
    }

    dispatch(setIsTemplatesLoadingCreator(false));
  } catch (error) {
    toast.error(error.response.message);
    dispatch(setIsTemplatesLoadingCreator(false));
  }
};

export const getEmailTemplate = (id) => async (dispatch) => {
  dispatch(setIsTemplateLoadingCreator(true));

  try {
    const response = await apiHelper('get', `/api/email-templates/${id}`, '');
    if (response?.data && response?.data?.template) {
      const emailTemplate = response?.data?.template;

      dispatch(setEmailTemplateCreator(emailTemplate));
    }

    dispatch(setIsTemplateLoadingCreator(false));
  } catch (error) {
    toast.error(error.response.message);
    dispatch(setIsTemplateLoadingCreator(false));
  }
};

export const createEmailTemplate = (data, hideModal) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/email-templates/', data);

    if (response?.data) {
      const { template, message } = response.data;

      hideModal();
      toast.success(message);
      dispatch(addEmailTemplateCreator(template));
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editEmailTemplate = (id, data) => async (dispatch) => {
  try {
    const response = await apiHelper('put', `/api/email-templates/${id}`, data);

    if (response?.data) {
      const { template, message } = response.data;

      toast.success(message);
      dispatch(editEmailTemplatesCreator(template));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteEmailTemplate = (id) => async (dispatch) => {
  try {
    const response = await apiHelper('delete', `/api/email-templates/${id}`, '');

    if (response?.data) {
      const {
        result: { _id }, 
        message,
      } = response.data;

      toast.success(message);
      dispatch(deleteEmailTemplatesCreator(_id));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
