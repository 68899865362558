import {
  ADD_PERMISSION, 
  DISPLAY_PERMISSIONS, 
  DELETE_PERMISSION, 
  TOGGLE_STATE, 
  ERROR_STATE, 
  ADD_PERMISSION_TEMPLATE, 
  PERMISSION_TEMPLATES,
  DELETE_PERMISSION_TEMPLATE,
  UPDATE_PERMISSION_TEMPLATE_STATE,
} from './permissionTypes';

const initialState = {
  permissions: [],
  permissionTemplate: {},
  permissionTemplates: [],
  success: false,
  fetched: false,
  error: false,
  updatePermissionTemplate: false,
};

// eslint-disable-next-line default-param-last
const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
        success: false,
        fetched: true,
      };
    case ADD_PERMISSION:
      return {
        ...state,
        permissions: action.payload.permissions,
        fetched: true,
        success: true,
      };
    case DELETE_PERMISSION:
      return {
        ...state,
        permissions: action.payload.permissions,
        success: true,
        fetched: true,
      }; 
    case ADD_PERMISSION_TEMPLATE:
      return {
        ...state,
        permissionTemplate: action.payload,
        permissionTemplates: [...state.permissionTemplates, action.payload],
        updatePermissionTemplate: true,
      };
    case PERMISSION_TEMPLATES:
      return {
        ...state,
        permissionTemplates: action.payload,
      };
    case DELETE_PERMISSION_TEMPLATE:
      return {
        ...state,
        permissionTemplates: state.permissionTemplates.filter((template) => template._id !== action.payload),
      };
    case UPDATE_PERMISSION_TEMPLATE_STATE:
      return {
        ...state,
        updatePermissionTemplate: action.payload,
      };
    case TOGGLE_STATE:
      return {
        ...state,
        fetched: false,
        success: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export default permissionReducer;
