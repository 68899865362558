import React, { useState, useEffect } from 'react';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { createWeek } from '../../helpers/calendar';
import styles from './styles.module.css';
import { CalendarHeader } from '../CalendarHeader/CalendarHeader';
import { getEventsByDateRange } from '../../../../redux/event/eventActions';
import { CalendarCell } from '../CalendarCell/CalendarCell';

export function WeekCalendar({
  selectedDate, setShowEditEventModal, setFocusedEvent, focusedEvent, 
}) {
  const dispatch = useDispatch();
  const [draggableEvent, setDraggableEvent] = useState();

  const currentDate = new Date();
  const start = startOfWeek(selectedDate);
  const end = endOfWeek(selectedDate);
  const events = useSelector((state) => state.events);
  const weekData = createWeek(start, currentDate, selectedDate, events);
  const uid = useSelector((state) => state.crmUser.crmUserInfo._id);

  useEffect(() => {
    if (uid && end && start) {
      dispatch(getEventsByDateRange(
        { 
          id: uid, 
          end: new Date(end).valueOf(), 
          start: new Date(start).valueOf(), 
        },
      ));
    }
  }, [uid]);

  return (
    <div className={styles.calendar}>
      <CalendarHeader />

      <div className={styles.calendarBody}>
        <div className={styles.week}>
          {weekData.map((day, key = 0) => (
            <CalendarCell 
              key={`day-${key}`}
              day={day}
              setShowEditEventModal={setShowEditEventModal}
              setFocusedEvent={setFocusedEvent}
              focusedEvent={focusedEvent}
              setDraggableEvent={setDraggableEvent}
              draggableEvent={draggableEvent}
            />
          ))}
        </div>
      </div>

    </div>
  );
}
