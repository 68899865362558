import {
  GET_COMMODITIES_SETTINGS,
  GET_COMMODITIES_SETTING,
  ADD_COMMODITIES_SETTING,
  EDIT_COMMODITIES_SETTING,
  DELETE_COMMODITIES_SETTING,
  TOGGLE_COMMODITIES_SETTING_STATE,
  ERROR_COMMODITIES_SETTING_STATE,
} from './commoditiesSettingTypes';
  
const initialState = {
  commoditiesSettings: [],
  commoditiesSetting: {},
  commoditiesSettingsFetched: false,
  commoditiesSettingAdded: false,
  commoditiesSettingFetched: false,
  commoditiesSettingEditted: false,
  commoditiesSettingDeleted: false,
  commoditiesSettingError: false,
};
  
// eslint-disable-next-line default-param-last
const commoditiesSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMODITIES_SETTINGS:
      return {
        ...state,
        commoditiesSettings: action.payload,
        commoditiesSettingsFetched: true,
      };
    case GET_COMMODITIES_SETTING:
      return {
        ...state,
        commoditiesSetting: action.payload,
        commoditiesSettingFetched: true,
      };
    case ADD_COMMODITIES_SETTING:
      return {
        ...state,
        commoditiesSettings: [state.commoditiesSettings, action.payload],
        commoditiesSettingAdded: true,
      };
    case EDIT_COMMODITIES_SETTING:
      return {
        ...state,
        commoditiesSetting: action.payload,
        commoditiesSettingEditted: true,
      };
    case DELETE_COMMODITIES_SETTING:
      return {
        ...state,
        commoditiesSettings: state.commoditiesSettings.filter((item) => item._id !== action.payload),
        commoditiesSettingDeleted: true,
      };
    case TOGGLE_COMMODITIES_SETTING_STATE:
      return {
        ...state,
        commoditiesSettingsFetched: false,
        commoditiesSettingAdded: false,
        commoditiesSettingFetched: false,
        commoditiesSettingEditted: false,
        commoditiesSettingDeleted: false,
        commoditiesSettingError: false,
      };
    case ERROR_COMMODITIES_SETTING_STATE:
      return {
        ...state,
        commoditiesSettingError: true,
      };
    default:
      return state;
  }
};
  
export default commoditiesSettingReducer;
