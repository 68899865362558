import {
  GET_EVENTS, DELETE_EVENT, UPDATE_EVENT, ADD_EVENT, 
} from './eventTypes';

const eventReducer = (state = [], action) => {
  switch (action.type) {
    case GET_EVENTS:
      return action.payload;
    
    case DELETE_EVENT:
      return state.filter((event) => event._id !== action.payload);
        
    case UPDATE_EVENT:
      return state.map((item) => {
        if (item._id === action.payload._id) {
          return { ...action.payload };
        }
        return item;
      });
        
    case ADD_EVENT:
      return [...state, action.payload];
    
    default:
      return state;
  }
};

export default eventReducer;
