import React, { useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DropDownIcon from './icons/dropDown.png';
import styles from './CustomSelect.module.css';

function CustomSelect({
  options, placeholder, defaultValue = null, setSelectedOptions,
}) {
  const findSelectedOptions = (defaultValue) => {
    if (defaultValue !== null) {
      return options.filter((option) => option.value === defaultValue);
    }
    return [];
  };

  const selectedOptions = findSelectedOptions(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownDirection, setDropdownDirection] = useState('downwards');
  const buttonRef = useRef(null);
  

  const handleSelect = (option) => {
    setSelectedOptions(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const button = buttonRef.current;
      const rect = button.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 280;
      if (spaceBelow < dropdownHeight && rect.top > dropdownHeight) {
        setDropdownDirection('upwards');
      } else {
        setDropdownDirection('downwards');
      }
    }
  };

  return (
    <div className={styles.customSelect} ref={buttonRef}>
      <div
        className={styles.customSelectTrigger}
        onClick={() => {
          toggleDropdown();
          setIsOpen(!isOpen);
        }}
      >
        {
          selectedOptions.length > 0 && <CheckIcon style={{ color: '#DBE2F9', width: '18px', height: '18px' }} />
        }
        <span className={styles.selectedOptionTitle}>
          {selectedOptions.length ? selectedOptions.map((opt) => opt.label).join(', ') : placeholder}
        </span>
        <img src={DropDownIcon} alt="#" />
      </div>
      {
        /** #252B37 */
        isOpen && (
          <div className={`${styles.customSelectOptions} ${dropdownDirection === 'upwards' ? styles.upwards : ''}`}>
            {options.map((option) => (
              <div
                key={option.value}
                className={
                  option.value === selectedOptions[0].value 
                    ? styles.customSelectOptionSelected 
                    : styles.customSelectOption
                }
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
}

export default CustomSelect;
