import React from 'react';
import { SavingInvestmentsDatatable } from './SavingInvestmentsDatatable';

function SavingHistory() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4 className="mb-3 text-capilatize">Saving History</h4>
        <SavingInvestmentsDatatable />
      </div>
    </div>
  );
};

export default SavingHistory;
