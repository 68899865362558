export const AdminWalletsColumns = [
  {
    name: 'Address',
    field: 'address',
    selected: true,
  },
  {
    name: 'Currency',
    field: 'currency',
    selected: true,
  },
  {
    name: 'Network',
    field: 'network',
    selected: true,
  },
  {
    name: 'In Use',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];

