export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_SUB_ADMIN = 'REGISTER_SUB_ADMIN';
export const REGISTER_ADMIN = 'REGISTER_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const TOGGLE_AUTH_STATE = 'TOGGLE_AUTH_STATE';
export const ERROR_STATE = 'ERROR_STATE';
export const TWO_FA_STATUS = 'TWO_FA_STATUS';
export const TWO_FA_QR_IMAGE = 'TWO_FA_QR_IMAGE';
export const TWO_FA_STATUS_CHANGED = 'TWO_FA_STATUS_CHANGED';
export const TOGGLE_TWO_FA_STATE = 'TOGGLE_TWO_FA_STATE';
export const TWO_FA_ERROR_STATE = 'TWO_FA_ERROR_STATE';
