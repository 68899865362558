/* eslint-disable default-param-last */
import {
  SET_IS_DATA_LOADING,
  SET_STATUS_HISTORY,
  ADD_STATUS_HISTORY,
} from './userStatusChangesHistoryTypes';

const initialState = {
  data: [],
  isLoading: false,
};

const userStatusChangesHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_STATUS_HISTORY:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_STATUS_HISTORY:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload,
        ],
      };
    default:
      return {
        ...state,
      };
  }
};

export default userStatusChangesHistoryReducer;
