import {
  format, addDays, isSameMonth, isSameDay, isSameHour, 
} from 'date-fns';

export const createDay = (date, currentDate, selectedDate, events) => {
  const day = addDays(date, 0);
  const formattedDay = format(day, 'd');
    
  const dayEvents = events.filter((event) => isSameDay(event.time, day));

  return {
    date: day,
    isCurrentMonth: isSameMonth(day, selectedDate),
    isToday: isSameDay(day, currentDate),
    dayNumber: formattedDay,
    events: dayEvents,
  };
};

export const createWeek = (startDate, currentDate, selectedDate, events) => Array(7).fill(null).map((_, i) => createDay(addDays(startDate, i), currentDate, selectedDate, events));

export const monthData = (start, end, currentDate, selectedDate, events) => {
  let date = start;
  const monthArray = [];

  while (date <= end) {
    monthArray.push(createWeek(date, currentDate, selectedDate, events));
    date = addDays(date, 7);
  }

  return monthArray;
};

export const parseDayIntoHours = (date, events = []) => {
  const dayStart = new Date(date);
  dayStart.setHours(0, 0, 0, 0);

  const hours = [];

  const hourEvents = (hour) => {
    const matchingEvents = events.filter((event) => {
      const comparison = isSameHour(new Date(event.time), hour);
      return comparison;
    });
    return matchingEvents;
  };

  for (let i = 0; i < 24; i += 1) {
    const hourTime = new Date(dayStart);
    hourTime.setHours(i);
    const hour = {
      time: hourTime,
      events: hourEvents(hourTime),
    };
    hours.push(hour);
  }

  return hours;
};
