import { GET_MONTHLY_INACTIVITY_FEE_BY_ID } from './monthlyInactivityFeeTypes';

const initialState = {
  allUserFee: { paginatedData: [], totalCount: 0 },
};

const monthlyInactivityFeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MONTHLY_INACTIVITY_FEE_BY_ID:
      return {
        ...state,
        allUserFee: action.payload,
        success: true,
      };
    default:
      return state;
  }
};

export default monthlyInactivityFeeReducer;

