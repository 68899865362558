import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getRole, editRole } from '../../redux/roles/roleActions';
import { displayModulesWithPermissions } from '../../redux/permissionsModule/permissionsModuleActions';

function EditRole() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const roleData = useSelector((state) => state.role?.editRole);
  const allPermissions = useSelector((state) => state.role?.allPermissions);
  const modulesWithPermissions = useSelector((state) => state.permissionsModule?.modulesWithPermissions);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedPermissionModule, setSelectedPermissionModule] = useState([]);
  const [role, setRole] = useState('');
  const [, setOptions] = useState([]);
  const [selectedItems, setSelected] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const [roleErr, setRoleErr] = useState('');
  const [, setPermissionErr] = useState('');
  const history = useHistory();

  useEffect(() => {
    dispatch(displayModulesWithPermissions());
    dispatch(getRole(id));
  }, []);

  useEffect(() => {
    if (roleData && roleData.length > 0 && roleData[0].permissions && roleData[0].permissions.length > 0) {
      const tempRoles = [];

      roleData[0].permissions.forEach((item) => {
        tempRoles.push({
          value: item._id,
          label: item.name,
        });
      });

      setSelected(tempRoles);
    }
  }, [roleData]);

  const getRoleData = async () => {
    const roleName = roleData[0]?.name;
    setRole(roleName);
    setIsAdmin(roleData[0]?.isAdmin);
    setIsClient(roleData[0]?.isClient ? roleData[0]?.isClient : false);

    const optionsValue = allPermissions.map((permission) => ({
      value: permission._id,
      label: permission.name,
    }));
    setOptions(optionsValue);
  };

  useEffect(() => {
    async function fetchData() {
      const modperms = [...selectedPermissionModule];
      const perms = roleData?.[0]?.permissions?.map((permission) => permission._id);
      let isModChecked;
      if (perms?.length > 0) {
        await modulesWithPermissions?.forEach((modWithPerm) => {
          isModChecked = modWithPerm?.permissions?.every((res) => perms?.includes(res._id));
          if (isModChecked) {
            if (modperms.indexOf(modWithPerm._id) === -1) {
              modperms.push(modWithPerm._id);
            }
          }
        });
      }

      setSelectedPermissionModule([...modperms]);
      setSelectedPermissions(perms);
    }
    fetchData();
  }, [roleData, modulesWithPermissions]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const exp = /^[a-z A-Z_]+$/;
    if (!role) {
      setRoleErr('Role name is required');
    } else if (!role.match(exp)) {
      setRoleErr('Only Alphabets are allowed');
    } else {
      setLoader(true);
      const tempIds = [];
      selectedItems.forEach((item) => {
        tempIds.push(item.value);
      });
      const data = {
        name: role,
        permissionIds: selectedPermissions,
        id,
        isAdmin,
        isClient,
      };
      dispatch(editRole(data));
      setRole('');
      setRoleErr('');
      setPermissionErr('');
      history.goBack();
    }
  };

  useEffect(() => {
    getRoleData();
  }, [roleData]);

  const handleModWithPermChange = async (event, mod) => {
    const perms = [...selectedPermissions];
    const modperms = [...selectedPermissionModule];

    if (event.target.checked) {
      if (modperms.indexOf(mod._id) === -1) {
        await modperms.push(mod._id);
      }
      mod?.permissions?.forEach(async (elem) => {
        if (perms.indexOf(elem._id) === -1) {
          await perms.push(elem._id);
        }
      });
    } else {
      const modpermIndex = await modperms.indexOf(mod._id);
      if (modpermIndex > -1) {
        await modperms.splice(modpermIndex, 1);
      }

      const removeValFromIndex = await mod?.permissions?.map((perm) => perms.indexOf(perm._id));
      for (let i = removeValFromIndex.length - 1; i >= 0; i -= 1) perms.splice(removeValFromIndex[i], 1);
    }
    setSelectedPermissionModule([...modperms]);
    setSelectedPermissions([...perms]);
  };

  const handlePermissionsChange = async (event, perm, mod) => {
    const perms = [...selectedPermissions];
    const modperms = [...selectedPermissionModule];

    if (event.target.checked) {
      if (perms.indexOf(perm._id) === -1) {
        await perms.push(perm._id);
      }
    } else {
      const permIndex = await perms.indexOf(perm._id);
      if (permIndex > -1) {
        await perms.splice(permIndex, 1);
      }
    }

    const isModChecked = mod?.permissions?.every((res) => perms.includes(res._id));
    if (isModChecked) {
      if (modperms.indexOf(mod._id) === -1) {
        await modperms.push(mod._id);
      }
    } else {
      const modpermIndex = await modperms.indexOf(mod._id);
      if (modpermIndex > -1) {
        await modperms.splice(modpermIndex, 1);
      }
    }

    setSelectedPermissionModule([...modperms]);
    setSelectedPermissions([...perms]);
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        <h3>Edit Role</h3>
        <form>
          <div className="form-group col-md-12">
            <label className="control-label">Role</label>
            <input
              type="text"
              required="required"
              className="form-control"
              name="role"
              value={role || ''}
              placeholder="Enter role"
              onChange={(e) => {
                if (e.target.value) {
                  setRole(e.target.value);
                  setRoleErr('');
                } else {
                  setRole(e.target.value);
                  setRoleErr('Role name is required');
                }
              }}
            />
            {roleErr ? (<span className="errMsg">{roleErr}</span>) : ('')}
          </div>
          <div className="form-group col-md-12">
            <label className="control-label">CRM Role</label>
            <select className="form-control user-status-select" name="type" value={isAdmin} onChange={(e) => setIsAdmin(e.target.value)}>
              <option value>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="form-group col-md-12">
            <label className="control-label">Client Role</label>
            <select className="form-control user-status-select" name="type" value={isClient} onChange={(e) => setIsClient(e.target.value)}>
              <option value>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="form-group col-md-12 pt-2">
            <h5>Edit Permissions</h5>
            <div className="add-permissions-container">
              <div className="row">
                {modulesWithPermissions?.length > 0 ? modulesWithPermissions?.map((modWithPerm) => (
                  <div key={modWithPerm?._id} className="col-md-6">
                    <div className="checkboxes-wrapper">
                      <h6 className="d-flex align-items-center">
                        <input type="checkbox" className="me-2" checked={selectedPermissionModule?.includes(modWithPerm?._id) || false} onChange={(e) => handleModWithPermChange(e, modWithPerm)} />
                        {' '}
                        {modWithPerm.name}
                      </h6>
                      <div className="child-checkboxes">
                        {modWithPerm?.permissions?.length > 0 && modWithPerm?.permissions?.map((perm) => (
                          <p key={perm?._id} className="text-white d-flex align-items-center mb-0">
                            <input type="checkbox" className="me-1" checked={selectedPermissions?.includes(perm?._id) || false} onChange={(e) => handlePermissionsChange(e, perm, modWithPerm)} />
                            {perm?.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )) : null}
              </div>
            </div>
          </div>
          <div>
            <button type="button" className="btn-default btn" onClick={(e) => { handleSubmit(e); }}>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditRole;
