import { GET_SAVING_INVESTMENTS, DELETE_SAVING_INVESTMENT, UPDATE_SAVING_INVESTMENT } from './savingInvestmentsTypes';

const initialSate = {
  paginatedData: [], 
  totalCount: 0, 
};

// eslint-disable-next-line default-param-last
export default (state = initialSate, action) => {
  switch (action.type) {
    case GET_SAVING_INVESTMENTS:
      return action.payload;
    case DELETE_SAVING_INVESTMENT:
      return {
        ...state,
        paginatedData: state.paginatedData.filter((item) => item._id !== action.payload),
        totalCount: state.totalCount - 1,
      }; 
    case UPDATE_SAVING_INVESTMENT:
      return {
        ...state,
        paginatedData: state.paginatedData.map((item) => (item._id === action.payload._id ? action.payload : item)),
      }; 
    default:
      return state;
  }
};
