import React, { useState, useEffect, useMemo } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSelector } from 'react-redux';

import { FormControlWithRadioButtons } from '../components/FormControl';
import { CellWithProgressBar } from '../components/CellWithProgressBar';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import { countryOptions } from '../utils/optionsHelper';

import style from './DepositsTableDashboard.module.css';


export function DepositsTableDashboard() {
  const { depositDashboardResult, salesAnalysis } = useSelector((state) => state.salesDashboard.salesDashboard);
  const [selectedOptions, setSelectedOptions] = useState(countryOptions[0].ticker);
  const [totalDeposits, setTotalDeposits] = useState(0);

  useEffect(() => {
    setTotalDeposits(selectedOptions === 'Amount' ? (salesAnalysis?.totalDepsInUSDT ?? 0) : (salesAnalysis?.totalNumberOfDeposits ?? 0));
  }, [selectedOptions, salesAnalysis]);

  const sortedDeposits = useMemo(
    () => (depositDashboardResult || []).sort((a, b) => (selectedOptions === 'Amount'
      ? b.amountOfDeposits - a.amountOfDeposits
      : b.numberOfDeposits - a.numberOfDeposits)), 
    [depositDashboardResult, selectedOptions],
  );

  function renderDepositDashboard(deposits) {
    return deposits.map((el) => (
      <CellWithProgressBar 
        key={el.crmUserFullName}
        totalAmount={el.amountOfDeposits} 
        name={el.crmUserFullName} 
        totalNumber={el.numberOfDeposits} 
        selectedOptions={selectedOptions} 
        totalDeposits={totalDeposits}
      />
    ));
  }

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDeposits}>
        <div className={style.allDepositsTitleContainer}>
          <h3 className={style.allDepositsTitle}>Deposits</h3>
          {/* <button type="button" onClick={downloadDepositsHandler} className={style.allDepositsAnalysisBtn}> */}
          {/* <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} /> */}
          {/* </button> */}
        </div>
        <div className={style.allDepositsStatisticDeposit}>
          <div className={style.radioContainer}>
            <FormControlWithRadioButtons options={countryOptions} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
          </div>
          <div className={style.allDepositTotal}>
            <p className={style.totalAmountTitle}>Total:</p>
            <p className={style.totalAmount}>{selectedOptions === 'Amount' ? `$${totalDeposits}` : totalDeposits}</p>
          </div>
        </div>
        <div className={style.allDepositsStatisticsTable}>
          {(depositDashboardResult && depositDashboardResult.length > 0)
            ? renderDepositDashboard(sortedDeposits)
            : (
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
