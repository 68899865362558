import { toast } from 'react-toastify';
import {
  GET_CLIENT_ACTIVITIES,
  GET_CLIENT_ACTIVITY,
  ADD_CLIENT_ACTIVITY,
  EDIT_CLIENT_ACTIVITY,
  DELETE_CLIENT_ACTIVITY,
  TOGGLE_CLIENT_ACTIVITY_STATE,
  ERROR_CLIENT_ACTIVITY_STATE,
  GET_USER_ACTIVITY,
  EXPORT_USER_ACTIVITY,
} from './clientActivityTypes';
import { apiHelper } from '../apiHelper';

export const getClientActivities = ({
  page, rowsPerPage, userFilters, userId, 
}) => async (dispatch) => {
  try {
    let url = `/api/client-activity-log?page=${page}&rowsPerPage=${rowsPerPage}&filters=${JSON.stringify(userFilters)}`;

    if (userId) url += `&userId=${userId}`;

    const res = await apiHelper('get', url);
    if (res && res.data && res.data.success) {
      const { clientActivityLogs } = res.data;
      await dispatch({
        type: GET_CLIENT_ACTIVITIES,
        payload: clientActivityLogs,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getUserActivity = ({
  page, rowsPerPage, userId, query,
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&rowsPerPage=${rowsPerPage}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/client-activity-log/user-activity/${userId}${url}`);

    if (res && res.data && res.data.success) {
      const { activityByUserResult } = res.data;
      await dispatch({
        type: GET_USER_ACTIVITY,
        payload: activityByUserResult,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getClientActivity = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/client-activity-log/${id}`, '');
    if (res?.data && res?.data?.clientActivityLog) {
      const { data } = res;
      await dispatch({
        type: GET_CLIENT_ACTIVITY,
        payload: data?.clientActivityLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addClientActivity = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/client-activity-log/add', data);

    if (res?.data && res?.data?.clientActivityLog_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_CLIENT_ACTIVITY,
        payload: data?.clientActivityLog_,
      });
    } else {
      await dispatch({
        type: ERROR_CLIENT_ACTIVITY_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editClientActivity = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/client-activity-log/${id}`, data);
    if (res?.data && res?.data?.clientActivityLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_CLIENT_ACTIVITY,
        payload: data?.clientActivityLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteClientActivity = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/client-activity-log/${id}`, '');
    if (res?.data && res?.data?.clientActivityLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_CLIENT_ACTIVITY,
        payload: data?.clientActivityLog?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateClientActivityState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_CLIENT_ACTIVITY_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

// eslint-disable-next-line no-unused-vars
export const exportClientActivityToFile = (data) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const res = await apiHelper('post', '/api/client-activity-log/export', data, {}, 'application/json', { 'x-timezone': timezone });
    if (res?.data) {
      const { data } = res;
      if (data?.csvFile) {
        window.open(data?.csvFile);
      }
      if (data?.xslxFile) {
        window.open(data?.xslxFile);
      }
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const exportUserActivityToFile = (data) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const res = await apiHelper('post', '/api/client-activity-log/export-user-activity', data, {}, 'application/json', { 'x-timezone': timezone });
    if (res?.data) {
      const { data } = res;
      if (data?.fileUrl) {
        window.open(data?.fileUrl);
      } else {
        toast.error('Something went wrong.');
      }
    }
    dispatch({
      type: EXPORT_USER_ACTIVITY,
      payload: true,
    });
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

