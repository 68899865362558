import React from 'react';

export function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path 
        // eslint-disable-next-line max-len
        d="M10.2339 1.7625C9.14641 0.675 7.65391 0 5.99641 0C2.68141 0 0.00390625 2.685 0.00390625 6C0.00390625 9.315 2.68141 12 5.99641 12C8.79391 12 11.1264 10.0875 11.7939 7.5H10.2339C9.61891 9.2475 7.95391 10.5 5.99641 10.5C3.51391 10.5 1.49641 8.4825 1.49641 6C1.49641 3.5175 3.51391 1.5 5.99641 1.5C7.24141 1.5 8.35141 2.0175 9.16141 2.835L6.74641 5.25H11.9964V0L10.2339 1.7625Z" 
        fill="#E2E2E9"
      />
    </svg>
  );
}
