export const salesStatusOptions = [
  { label: 'Both', value: 3 },
  { label: 'Conversion', value: 1 },
  { label: 'Retention', value: 2 },
];

export const countryOptions = [
  { ticker: 'Amount' },
  { ticker: 'Number' },
];

export const statusOptions = [
  { ticker: 'Leads' },
  { ticker: 'Clients' },
];
