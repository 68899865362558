import {
  GET_CRYPTO_SETTINGS,
  GET_CRYPTO_SETTING,
  ADD_CRYPTO_SETTING,
  EDIT_CRYPTO_SETTING,
  DELETE_CRYPTO_SETTING,
  TOGGLE_CRYPTO_SETTING_STATE,
  ERROR_CRYPTO_SETTING_STATE,
} from './cryptoSettingTypes';
  
const initialState = {
  cryptoSettings: [],
  cryptoSetting: {},
  cryptoSettingsFetched: false,
  cryptoSettingAdded: false,
  cryptoSettingFetched: false,
  cryptoSettingEditted: false,
  cryptoSettingDeleted: false,
  cryptoSettingError: false,
};
  
// eslint-disable-next-line default-param-last
const cryptoSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CRYPTO_SETTINGS:
      return {
        ...state,
        cryptoSettings: action.payload,
        cryptoSettingsFetched: true,
      };
    case GET_CRYPTO_SETTING:
      return {
        ...state,
        cryptoSetting: action.payload,
        cryptoSettingFetched: true,
      };
    case ADD_CRYPTO_SETTING:
      return {
        ...state,
        cryptoSettings: [state.cryptoSettings, action.payload],
        cryptoSettingAdded: true,
      };
    case EDIT_CRYPTO_SETTING:
      return {
        ...state,
        cryptoSetting: action.payload,
        cryptoSettingEditted: true,
      };
    case DELETE_CRYPTO_SETTING:
      return {
        ...state,
        cryptoSettings: state.cryptoSettings.filter((item) => item._id !== action.payload),
        cryptoSettingDeleted: true,
      };
    case TOGGLE_CRYPTO_SETTING_STATE:
      return {
        ...state,
        cryptoSettingsFetched: false,
        cryptoSettingAdded: false,
        cryptoSettingFetched: false,
        cryptoSettingEditted: false,
        cryptoSettingDeleted: false,
        cryptoSettingError: false,
      };
    case ERROR_CRYPTO_SETTING_STATE:
      return {
        ...state,
        cryptoSettingError: true,
      };
    default:
      return state;
  }
};
  
export default cryptoSettingReducer;
