/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dropdown } from 'react-bootstrap';
import {
  faCheck, faMinus, faColumns, faClone, faTrash, faClose,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getUserSavingInvestments, getSavingInvestments, deleteSavingInvestment } from '../../redux/savingInvestment/savingInvestmentActions';
import { ModalFilters } from '../../components';
import {
  dateOptions, omitColumn, prettyCutOutputByCellWidth, valueOutputCutLength, 
} from '../../DataTableSchemas/helper';
import { savingsInvestmentColumns } from '../../columnsDefaultConfig/savingsInvestmetColumns';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { StopSavingModal } from './StopSavingModal';
import { ModalContext } from '../../context';
import { useDebounce } from '../../hooks/useDebounce';

export function SavingInvestmentsDatatable({ userId = null }) {
  const { showModal } = useContext(ModalContext);

  const filtersStorageName = 'DataTable_Savings_History/filters'; 
  const paginationStorageName = 'DataTable_Savings_History/pagination';
  const columnsStorageName = 'DataTable_Savings_History/savings-history_columns';
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState([]);
  const [endDateFilter, setEndDateFilter] = useState([]);
  const [clientFullname, setClientFullname] = useState(null);
  const [columnConfig, setColumnConfig] = useState(savingsInvestmentColumns);
  const { paginatedData, totalCount } = useSelector((state) => state.stakingInvestments);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0)) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(
    clientFullname, 
    1000,
    (value) => debounceCallback({ value, key: 'clientName' }),
  );

  const setStoredPagination = () => {
    const savingInvestmentJSON = localStorage.getItem(paginationStorageName);
    if (savingInvestmentJSON) {
      const filterRows = JSON.parse(savingInvestmentJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    const savingsHistoryColumnsStorage = localStorage.getItem(columnsStorageName);
    if (savingsHistoryColumnsStorage) {
      const columns = JSON.parse(savingsHistoryColumnsStorage);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    const savingsInvestmentFiltersJSON = localStorage.getItem(filtersStorageName);
    const filters = JSON.parse(savingsInvestmentFiltersJSON);
    setFilters(filters);
  };

  useEffect(() => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(filters));
        await dispatch(userId ? getUserSavingInvestments(userId, { page, rowsPerPage, filters }) : getSavingInvestments({ page, rowsPerPage, filters }));
      }
      setIsLoading(false);
    };

    fetchData();
  }, [userId, filters, isPaginationDT, page, rowsPerPage]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this record?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) dispatch(deleteSavingInvestment(id));
      });
  };
  const refresh = () => {
    setStoredFilterData();
    setStoredPagination();
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit: currentRowsPerPage }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit: rowsPerPage }));
    setPage(page);
  };

  const handleClear = () => {
    setStartDateFilter([]);
    setEndDateFilter([]);
    setFilters({});
    setClientFullname(null);
    localStorage.removeItem(filtersStorageName);
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleCloseInvestment = (data) => {
    showModal({ 
      bodyContent: <StopSavingModal data={data} />, 
      headerContent: <h5>Close Saving</h5>, 
    });
  };

  const columns = [
    {
      name: 'Id',
      cell: ({ customId = '' }) => (
        customId.length >= valueOutputCutLength.mongoId
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={customId}>
                    <span style={{
                      whiteSpace: 'nowrap', cursor: 'pointer', 
                    }}
                    >
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {customId}
                    </span>
                  </CopyToClipboard>
                </div>
              )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span style={{ margin: '5px', whiteSpace: 'nowrap' }}>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
      omit: omitColumn(columnConfig, 'ID'), 
      minWidth: '84px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          Full Name
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientFullname ?? ''}
            minLength="3"
            onChange={({ target: { value } }) => setClientFullname(value)}
          />
          {
          (clientFullname && clientFullname.length >= 1 && clientFullname.length < 3) && (
          <span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>
          )  
        }
        </div>
      ),
      cell: ({ user }) => {
        if (user) {
          const userFullName = `${user.firstName} ${user.lastName}`;
          return (
            userFullName.length >= valueOutputCutLength.userSaving
              ? (
                <TooltipComponent
                  title={userFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <Link className="text-white" to={`/user-detail/${user._id}`}>
                      <span className="full-name-text" style={{ whiteSpace: 'nowrap' }}>
                        {prettyCutOutputByCellWidth(userFullName, 'userSaving')}
                      </span>
                    </Link>
                )}
                />
              )
              : (
                <Link className="text-white" to={`/user-detail/${user._id}`}>
                  <span className="full-name-text" style={{ whiteSpace: 'nowrap' }}>
                    {userFullName}
                  </span>
                </Link>
              )
          );
        }
        return (
          '-'
        );
      },
      omit: omitColumn(columnConfig, 'User'), 
      minWidth: '126px',
    },
    {
      name: 'Invested Amount',
      cell: ({ investedAmount, currency }) => {
        const investedValue = `${investedAmount.toFixed(2)} ${currency.symbol}`;
        return (
          investedValue.length >= valueOutputCutLength.investedAmountSavings
            ? (
              <TooltipComponent
                title={investedValue}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(investedValue, 'investedAmountSavings')}</span>
            )}
              />
            )
            : (
              <span>{investedValue}</span>
            )
        );
      },
      omit: omitColumn(columnConfig, 'Invested Amount'), 
      minWidth: '154px',
    },
    {
      name: 'Final Earning',
      cell: ({ finalEarnings, currency }) => {
        if (finalEarnings) {
          const finalEarningsValue = `${finalEarnings.toFixed(2)} ${currency.symbol ?? ''}`;
          return (
            finalEarningsValue.length >= valueOutputCutLength.investedAmountSavings
              ? (
                <TooltipComponent
                  title={finalEarningsValue}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(finalEarningsValue, 'investedAmountSavings')}</span>
                )}
                />
              )
              : (
                <span>{finalEarningsValue}</span>
              )
          );
        }
        return (
          '-'
        );
      },
      omit: omitColumn(columnConfig, 'Final Earning'), 
      minWidth: '126px',
    },
    {
      name: 'Period',
      cell: ({ period, isFlexible }) => `${!isFlexible ? `Locked ${period}` : 'Flexible'}`,
      omit: omitColumn(columnConfig, 'Period'), 
      minWidth: '126px',
    },
    {
      name: 'Percent',
      omit: omitColumn(columnConfig, 'Percent'), 
      cell: ({ pct }) => `${pct} %`,
      minWidth: '84px',
    },
    {
      name: (
        <ModalFilters
          onClick={(e) => e.stopPropagation()}
          data={dateOptions}
          filters={startDateFilter}
          setFilters={setStartDateFilter}
          tabName="Start Date" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
          isDateOptions
        />
      ),
      cell: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      omit: omitColumn(columnConfig, 'Start Date'), 
      minWidth: '150px',
    },
    {
      name: (
        <ModalFilters
          onClick={(e) => e.stopPropagation()}
          data={dateOptions}
          filters={endDateFilter}
          setFilters={setEndDateFilter}
          tabName="Release Date" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
          isDateOptions
        />
      ),
      cell: ({ releaseTime }) => (releaseTime ? moment(releaseTime).format('YYYY-MM-DD HH:mm:ss') : <FontAwesomeIcon icon={faMinus} />), 
      omit: omitColumn(columnConfig, 'Release Date'), 
      minWidth: '150px',
    },
    {
      name: 'Flexible',
      omit: omitColumn(columnConfig, 'Flexible'), 
      cell: ({ isFlexible }) => <FontAwesomeIcon icon={isFlexible ? faCheck : faMinus} />,
      minWidth: '86px',
    },
    {
      name: 'Closed',
      omit: omitColumn(columnConfig, 'Closed'), 
      cell: ({ isReleased }) => <FontAwesomeIcon icon={isReleased ? faCheck : faMinus} />,
      minWidth: '56px',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {
              !row.isClaimed && (
                <button type="button" className="btn btn-warning btn-sm" onClick={() => handleCloseInvestment(row)}>
                  <FontAwesomeIcon icon={faClose} />
                </button>
              )
            }
          <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        
      ),
      omit: omitColumn(columnConfig, 'Actions'), 
      minWidth: '100px',
      width: '160px',
    },
  ];

  return (
    <>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            <Dropdown className="leads-columns-dropdown">
              <Dropdown.Toggle 
                variant="" 
                className="btn-secondary_dark"
              >
                <FontAwesomeIcon
                  icon={faColumns}
                  size="xs"
                  style={{ padding: '5px' }}
                />
                Columns
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul className="leads-columns-list">
                  {columnConfig.map((column) => (
                    <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                      {column.selected ? (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faCheck}
                          color="#6E7F95"
                        />
                      ) : null}
                      <span className="leads-columns-option__name">{column.name}</span>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <button 
              type="button"
              className="btn-secondary_dark iconed"
              onClick={handleClear}
            >
              <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button 
              type="button" 
              className="btn-secondary_dark iconed"
              onClick={refresh}
            >
              <RefreshIcon sx={{ fontSize: '20px' }} />
            </button>
          </div>
        </div>  
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        {
          isPaginationDT
          && (
          <DataTable
            columns={columns} 
            data={paginatedData} 
            pagination 
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100]} 
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            theme="solarizedd"
            persistTableHead
            progressPending={isLoading}
            progressComponent={<div className="datatable-loader__background" />}
          />
          )
        }
      </div>
    </>
  );
};
