let navigator;

export const setNavigator = (nav) => {
  navigator = nav;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('userId');

  navigator.push('/login'); 
};

export const login = () => {
  navigator.push('/');
};
