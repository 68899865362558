export const GET_ORDER = 'GET_ORDER';
export const GET_USER_ORDER = 'GET_USER_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const STOP_ORDER = 'STOP_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const REVERT_ORDER = 'REVERT_ORDER';
export const EDIT_HISTORY_ORDER = 'EDIT_HISTORY_ORDER';
export const START_ORDER = 'START_ORDER';
export const ALL_ACTIVE_ORDERS = 'ALL_ACTIVE_ORDERS';
export const EXPORT_FILTERED_DATA_TO_FILE = 'EXPORT_FILTERED_DATA_TO_FILE';
export const TOGGLE_ORDER_STATE = 'TOGGLE_ORDER_STATE';
export const UPDATE_SOCKET_ORDER = 'UPDATE_SOCKET_ORDER';
export const UPDATE_ACTIVE_ORDER = 'UPDATE_ACTIVE_ORDER';
