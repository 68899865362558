import {
  SET_SMS_SERVICES,
  SET_ACTIVE_SMS_SERVICE,
  ADD_SMS_SERVICE,
  UPDATE_SMS_SERVICE,
  DELETE_SMS_SERVICE,
  SET_IS_SERVICES_LOADING,
  SET_CONFIG_NAME_FILTER,
  SET_SERVICE_NAME_FILTER,
  SET_IS_ACTIVE_FILTER,
  SET_INTEGRATED_SERVICES,
  SET_AUTH_DATA_TYPES,
} from './smsServicesTypes';

const initialState = {
  configNameFilter: '',
  serviceNameFilter: '',
  isActiveFilter: '',
  isServicesLoading: false,
  integratedServices: [],
  authDataTypes: [],
  services: [],
  activeService: null,
};

const smsServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTEGRATED_SERVICES:
      return {
        ...state,
        integratedServices: action.payload,
      };
    case SET_AUTH_DATA_TYPES:
      return {
        ...state,
        authDataTypes: action.payload,
      };
    case SET_CONFIG_NAME_FILTER:
      return {
        ...state,
        configNameFilter: action.payload,
      };
    case SET_SERVICE_NAME_FILTER:
      return {
        ...state,
        serviceNameFilter: action.payload,
      };
    case SET_IS_ACTIVE_FILTER:
      return {
        ...state,
        isActiveFilter: action.payload,
      };
    case SET_IS_SERVICES_LOADING:
      return {
        ...state,
        isServicesLoading: action.payload,
      };
    case SET_SMS_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case SET_ACTIVE_SMS_SERVICE:
      return {
        ...state,
        activeService: action.payload,
      };
    case ADD_SMS_SERVICE:
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case UPDATE_SMS_SERVICE:
      return {
        ...state,
        services: state.services.map((service) => (service._id === action.payload._id ? action.payload : service)),
      };
    case DELETE_SMS_SERVICE:
      return {
        ...state,
        services: state.services.filter((service) => service._id !== action.payload),
      };
    default:
      return state;
  }
};

export default smsServicesReducer;
