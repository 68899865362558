import { useEffect } from 'react';

export const useDebounce = (value = null, delay, callback) => {
  useEffect(() => {
    if (typeof value === 'string' || Array.isArray(value)) {
      const handler = setTimeout(() => {
        callback(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);
};

export const useObjectDebounce = (value = null, delay, callback) => {
  useEffect(() => {
    if (typeof value === 'object') {
      const handler = setTimeout(() => {
        callback(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);
};
