import React, {
  useCallback, useContext, useEffect,
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from '../context';
import {
  selectDepositMethodsOptions,
} from '../redux/depositMethods/depositMethodsSelectors';
import {
  fetchDepositMethodsOptions,
  setBrandFilterCreator,
  setCountriesIncludedFilterCreator,
  setDepositMethodFilterCreator,
  updateDepositMethod,
} from '../redux/depositMethods/depositMethodsActions';
import { getBrands } from '../redux/brand/brandActions';
import { fetchCounties } from '../redux/countries/countriesActions';
import { ModalFilters } from '../components';
import { stringToBoolean } from '../helpers/helper';
  
export const DepositMethodsColumns = ({
  depositMethodsQuery,
  setDepositMethodsQuery,
  brandSearchFilter,
  countriesIncludedSearchFilter,
  depositMethodSearchFilter,
}) => {
  const dispatch = useDispatch();
  
  const { showModal } = useContext(ModalContext);
  
  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );
  
  const isUserHasEditPermission = permissions && permissions.length > 0 && permissions.includes('deposit_methods_edit'); 
  
  const brands = useSelector((state) => state.brands);
  const countries = useSelector((state) => state.countries.countries);
  const depositMethodsOptions = useSelector(selectDepositMethodsOptions);
    
  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    if (!countries.length) dispatch(fetchCounties());
    if (!depositMethodsOptions.length) dispatch(fetchDepositMethodsOptions());
  }, []);
  
  const getAllBrandsOptions = useCallback((brands) => brands.map(({ _id, name }) => ({
    _id,
    name,
  })), []);
  
  const getAllIncludedCountryOptions = useCallback((countries) => countries.map(({ _id, nicename }) => ({
    _id,
    nicename,
  })), []);
  
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [includedCountriesOptions, setIncludedCountriesOptions] = useState([]);
  
  useEffect(() => {
    const options = getAllBrandsOptions(brands);
    setBrandsOptions(options);
  }, [brands]);
    
  useEffect(() => {
    const options = getAllIncludedCountryOptions(countries);
    setIncludedCountriesOptions(options);
  }, [countries]);
  
  const handleBrandSearchFilterChange = (value) => {
    dispatch(setBrandFilterCreator(value));
  };
  
  const handleDepositMethodChange = (value) => {
    dispatch(setDepositMethodFilterCreator(value));
  };
  
  const handleIncludedCountiesChange = (value) => {
    dispatch(setCountriesIncludedFilterCreator(value));
  };
  
  
  const handleStatusChange = async (_id, name, status) => {
    await dispatch(
      updateDepositMethod(_id, {
        name,
        status: stringToBoolean(status),
      }),
    );
  };
  
  const columns = [
    {
      name: (
        <ModalFilters
          data={depositMethodsOptions} 
          filters={depositMethodSearchFilter} 
          setFilters={handleDepositMethodChange} 
          tabName="Deposit Method" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositMethodsQuery}
          setUserFilters={setDepositMethodsQuery}
        />
      ),
      minWidth: '210px',
      selector: ({ name }) => `${name}`,
      cell: ({ name }) => (
        <span>
          {name}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'end', width: '100%' }}>
          <div className="newTablePositionHeader">
            Status
          </div>
        </div>
      ),
      selector: ({ status }) => `${status}`,
      cell: ({ _id, name, status }) => (
        <div className="withdrawalNewHeaderStatus">
          <span className="withdrawalNewHeaderStatusSelectContainer">
            <select
              className="form-control user-status-select leads-status-select"
              style={{ width: '100px' }}
              name="status"
              value={status}
              onChange={({ target }) => handleStatusChange(_id, name, target.value)}
            >
              <option value selected={status}>Active</option>
              <option value={false} selected={!status}>Inactive</option>
            </select>
          </span>
        </div>
      ),
      minWidth: '100px',
    },
    // {
    //   name: (
    //     <ModalFilters
    //       data={includedCountriesOptions} 
    //       filters={countriesIncludedSearchFilter} 
    //       setFilters={handleIncludedCountiesChange} 
    //       tabName="Countries included" 
    //       searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
    //       usersFilters={depositMethodsQuery}
    //       setUserFilters={setDepositMethodsQuery}
    //     />
    //   ),
    //   cell: (row) => {
    //     const includedCountriesDetails = row?.included_countries_details ?? [];
    //     const includedCountries = includedCountriesDetails.map(({ nicename }) => nicename);
    //     return (
    //       <span>
    //         {includedCountries.join(', ') || 'All'}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: (
    //     <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
    //       Excluded countries
    //     </div>
    //   ),
    //   cell: (row) => {
    //     const excludedCountriesDetails = row?.excluded_countries_details ?? [];
    //     const excludedCountries = excludedCountriesDetails.map(({ nicename }) => nicename);
    //     return (
    //       <span>
    //         {excludedCountries.join(', ') || 'No'}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: 'Actions',
    //   cell: (row) => (
    //     isUserHasEditPermission ? (
    //       <button
    //         className="btn btn-warning btn-sm me-1 p-1"
    //         type="button"
    //         onClick={() => editAction(row)}
    //       >
    //         Edit
    //       </button>
    //     ) : null
    //   ),
    // },
  ];
  
  return columns;
};
  
