/* eslint-disable default-param-last */
import {
  GET_CRM_ACTIVITIES,
  GET_CRM_ACTIVITY,
  ADD_CRM_ACTIVITY,
  EDIT_CRM_ACTIVITY,
  DELETE_CRM_ACTIVITY,
  TOGGLE_CRM_ACTIVITY_STATE,
  ERROR_CRM_ACTIVITY_STATE,
  GET_CRM_USER_ACIVITY,
} from './crmActivityTypes';

const initialState = {
  crmActivities: [],
  crmActivity: {},
  crmUserActivity: { paginatedData: [], totalCount: 0 },
  crmActivitiesFetched: false,
  crmActivityAdded: false,
  crmActivityFetched: false,
  crmActivityEditted: false,
  crmActivityDeleted: false,
  crmActivityError: false,
};

const crmActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CRM_ACTIVITIES:
      return {
        ...state,
        crmActivities: action.payload,
        crmActivitiesFetched: true,
      };
    case GET_CRM_ACTIVITY:
      return {
        ...state,
        crmActivity: action.payload,
        crmActivityFetched: true,
      };
    case GET_CRM_USER_ACIVITY:
      return {
        ...state,
        crmUserActivity: action.payload,
        crmActivityFetched: true,
      };  
    case ADD_CRM_ACTIVITY:
      return {
        ...state,
        crmActivities: [state.crmActivities, action.payload],
        crmActivityAdded: true,
      };
    case EDIT_CRM_ACTIVITY:
      return {
        ...state,
        crmActivity: action.payload,
        crmActivityEditted: true,
      };
    case DELETE_CRM_ACTIVITY:
      return {
        ...state,
        crmActivities: state.crmActivities.filter((item) => item._id !== action.payload),
        crmActivityDeleted: true,
      };
    case TOGGLE_CRM_ACTIVITY_STATE:
      return {
        ...state,
        crmActivitiesFetched: false,
        crmActivityAdded: false,
        crmActivityFetched: false,
        crmActivityEditted: false,
        crmActivityDeleted: false,
        error: false,
      };
    case ERROR_CRM_ACTIVITY_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default crmActivityReducer;
