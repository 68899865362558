import React from 'react';
import { styled, Stack, LinearProgress } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  maxWidth: '170px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#262C38',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#9BBDFF',
  },
}));

export function ProgressBar({ percent = 0 }) {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1, maxWidth: '170px' }}>
      <BorderLinearProgress variant="determinate" value={percent} />
    </Stack>
  );
}
