export const SET_SMS_SERVICES = 'SET_SMS_SERVICES';
export const SET_ACTIVE_SMS_SERVICE = 'SET_ACTIVE_SMS_SERVICE';
export const ADD_SMS_SERVICE = 'ADD_SMS_SERVICE';
export const UPDATE_SMS_SERVICE = 'UPDATE_SMS_SERVICE';
export const DELETE_SMS_SERVICE = 'DELETE_SMS_SERVICE';
export const SET_IS_SERVICES_LOADING = 'SET_IS_SERVICES_LOADING';
export const SET_CONFIG_NAME_FILTER = 'SET_CONFIG_NAME_FILTER';
export const SET_SERVICE_NAME_FILTER = 'SET_SERVICE_NAME_FILTER';
export const SET_IS_ACTIVE_FILTER = 'SET_IS_ACTIVE_FILTER';
export const SET_INTEGRATED_SERVICES = 'SET_INTEGRATED_SERVICES';
export const SET_AUTH_DATA_TYPES = 'SET_AUTH_DATA_TYPES';
