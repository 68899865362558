import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsEmailHistoryLoading, selectSentEmailHistory, selectTotalPagesLoading } from '../../../../redux/userSentEmailHistory/userSentEmailHistorySelectors';
import { getUserSentEmailHistory, setEmailHistoryCreator } from '../../../../redux/userSentEmailHistory/userSentEmailHistoryActions';
import useInfiniteScroll from '../../../../hooks/useInfinityScroll';
import styles from './UserEmailHistory.module.css';

export function UserEmailHistory({ userId }) {
  const dispatch = useDispatch();

  const history = useSelector(selectSentEmailHistory);
  const isLoading = useSelector(selectIsEmailHistoryLoading);
  const totalPages = useSelector(selectTotalPagesLoading);

  const dateOptions = {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };

  const { loadMoreRef, page } = useInfiniteScroll(totalPages);

  useEffect(() => {
    if (userId) {
      dispatch(getUserSentEmailHistory(userId, page));
    }
  }, [dispatch, userId, page]);

  // unmount
  useEffect(() => () => {
    dispatch(setEmailHistoryCreator([]));
  }, [userId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h5>Email history</h5>
      </div>
      <div className={styles.bodyWrapper}>
        {
          history && history.length
            ? history.map((data) => {
              const date = new Date(data.createdAt);
              return (
                <span className={styles.statusHistoryRow}>
                  {date.toLocaleDateString('en-US', dateOptions)}
                  {' '}
                  Email was sent to the client by
                  {' '}
                  {data.agentName}
                </span>
              );
            })
            : 'There hasn\'t been any activity yet'
        }
        { page < totalPages && <div ref={loadMoreRef}>{isLoading && 'Loading...'}</div> }
      </div>
    </div>
  );
}
