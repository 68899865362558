import React, { useEffect } from 'react';
import AOS from 'aos';
import './FullPageTransparentLoader.css';

function FullPageTransparentLoader() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="center-body">
      <span className="loader" />
    </div>
  );
}

export default FullPageTransparentLoader;
