/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changePassword } from '../../redux/crmUser/crmUserActions';
import { ENV } from '../../config/config';
import { InputPassword } from '../../components';

function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialState = { oldPassword: '', password: '', confirmPassword: '' };
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');

  const passChanged = useSelector((state) => state.crmUser?.passwordChanged);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const { password, confirmPassword, oldPassword } = state;
    e.preventDefault();

    if (oldPassword === '') {
      setErrors('Enter your old password!');
    } else if (password === '') {
      setErrors('Enter your new password!');
    } else if (password.length < 6) {
      setErrors('Password selected must have at-least 6 characters!');
    } else if (password === oldPassword) {
      setErrors('Your new password should be different from your old password!');
    } else if (confirmPassword === '') {
      setErrors('Password confirmation is required!');
    } else if (password !== confirmPassword) {
      setErrors('Password do not match!');
    } else {
      const data = {
        oldPassword,
        password,
        confirmPassword,
      };
      dispatch(changePassword(id, data));
      setState(initialState);
    }
  };

  useEffect(() => {
    if (passChanged) {
      ENV.logout();
      history.push('/login');
    }
  }, [passChanged]);

  useEffect(() => {
    const loginUser = localStorage.getItem('userId');
    const id = JSON.parse(loginUser);
    setId(id);
  }, []);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Change Password</h3>
        <form>
          <div className="form-group col-md-12 mb-2">
            <label className="control-label">Old password</label>

            <InputPassword 
              onChange={(e) => handleChange(e)}
              placeholder="Enter your Password"
              value={state.oldPassword}
              name="oldPassword"
            />
          </div>
          <div className="form-group col-md-12 mb-2">
            <label className="control-label">New password</label>

            <InputPassword 
              name="password"
              value={state.password}
              onChange={(e) => handleChange(e)}
              placeholder="Enter your Password"
            />
          </div>
          <div className="form-group col-md-12 mb-3">
            <label className="control-label">Confirm password</label>

            <InputPassword 
              name="confirmPassword"
              value={state.confirmPassword}
              onChange={(e) => handleChange(e)}
              placeholder="Confirm password"
            />
          </div>
        </form>

        <div>
          {errors ? (
            <div
              style={{ color: '#FE6E00' }}
              className="alert alert-danger"
            >
              {errors}
            </div>
          ) : (
            ''
          )}
          <div>
            <button type="button" className="btn-default btn" onClick={(e) => handleSubmit(e)}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
