import {
  GET_ACTIVITY_TYPES,
  GET_ACTIVITY_TYPE,
  ADD_ACTIVITY_TYPE,
  EDIT_ACTIVITY_TYPE,
  DELETE_ACTIVITY_TYPE,
  TOGGLE_ACTIVITY_TYPE_STATE,
  ERROR_ACTIVITY_TYPE_STATE,
} from './activityTypeTypes';

const initialState = {
  activityTypes: [],
  activityType: {},
  activityTypesFetched: false,
  activityTypeAdded: false,
  activityTypeFetched: false,
  activityTypeEditted: false,
  activityTypeDeleted: false,
  activityTypeError: false,
};

// eslint-disable-next-line default-param-last
const activityTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_TYPES:
      return {
        ...state,
        activityTypes: action.payload,
        activityTypesFetched: true,
      };
    case GET_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: action.payload,
        activityTypeFetched: true,
      };
    case ADD_ACTIVITY_TYPE:
      return {
        ...state,
        activityTypes: [state.activityTypes, action.payload],
        activityTypeAdded: true,
      };
    case EDIT_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: action.payload,
        activityTypeEditted: true,
      };
    case DELETE_ACTIVITY_TYPE:
      return {
        ...state,
        activityTypes: state.activityTypes.filter((item) => item._id !== action.payload),
        activityTypeDeleted: true,
      };
    case TOGGLE_ACTIVITY_TYPE_STATE:
      return {
        ...state,
        activityTypesFetched: false,
        activityTypeAdded: false,
        activityTypeFetched: false,
        activityTypeEditted: false,
        activityTypeDeleted: false,
        error: false,
      };
    case ERROR_ACTIVITY_TYPE_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default activityTypeReducer;
