import React from 'react';
import { useParams } from 'react-router-dom';
import { OpenOrdersTable } from '../../../components/OpenOrdersTable/OpenOrdersTable';

function ActiveOrder() {
  const { id } = useParams();

  const filtersStorageName = 'DataTable_user_orders/open-user-orders_filters';
  const filterIdStorageName = 'DataTable_user_orders/open-user-orders_filter_id'; 
  const columnsStorageName = 'DataTable_user_orders/open-user-orders_columns';
  const paginationStorageName = 'DataTable_user_orders/open-user-orders_pagination';

  const localStorageConfig = {
    filtersStorageName,
    filterIdStorageName,
    columnsStorageName,
    paginationStorageName,
  };

  return (
    <div>
      <OpenOrdersTable localStorageConfig={localStorageConfig} userId={id} isPositionUnset />
    </div>
  );
}

export default ActiveOrder;
