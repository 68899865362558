import React from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { EventList } from '../EventsList/EventsList';
import { updateEventById } from '../../../../redux/event/eventActions';

export function HourCell({
  hour, setShowEditEventModal, setFocusedEvent, focusedEvent, draggableEvent, setDraggableEvent, 
}) {
  const dispatch = useDispatch();
  const { time, events } = hour;

  const allowToDrop = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const data = {
      name: draggableEvent.name,
      crmUserId: draggableEvent.crmUserId,
      userId: draggableEvent.userId,
      time: new Date(time).valueOf(),
      duration: draggableEvent.duration,
      description: draggableEvent.description,
    };

    dispatch(updateEventById(draggableEvent._id, data));
  };

  return (
    <div
      className={styles.hour}
      onDragOver={allowToDrop}
      onDrop={handleDrop}
    >
      <div className={styles.hourTime}>
        {format(time, 'hh:mm a')}
      </div>
      <EventList 
        events={events} 
        setShowEditEventModal={setShowEditEventModal}
        focusedEvent={focusedEvent}
        setFocusedEvent={setFocusedEvent}
        setDraggableEvent={setDraggableEvent}
      />
    </div>
  );
}
