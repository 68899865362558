import { apiHelper } from '../apiHelper';
import { GET_ASSET_QUOTE } from './assetQuoteTypes';

export const getAssetQuote = (brokerSymbol) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/cfd-setting/quote?symbol=${brokerSymbol.replace('#', '%23')}`, '');
  
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_ASSET_QUOTE,
        payload: res.data.quote,
      }); 
    }
  } catch (error) {
    console.error(error);
  }
};
