import { 
  GET_DATAFEED_SETTINGS, 
  UPDATE_DATAFEED_SETTING, 
  TOOGLE_ACTIVE_DATAFFED, 
  DELETE_DATAFEED_SETTING,
  CREATE_DATAFEED_SETTING,
} from './datafeedsTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_DATAFEED_SETTINGS:
      return action.payload;
    case CREATE_DATAFEED_SETTING:
      return [...state, action.payload];
    case TOOGLE_ACTIVE_DATAFFED:
      return state.map((item) => (item._id === action.payload._id ? action.payload : item));
    case UPDATE_DATAFEED_SETTING:
      return state.map((item) => (item._id === action.payload._id ? action.payload : item));
    case DELETE_DATAFEED_SETTING:
      return state.filter(({ _id }) => _id !== action.payload._id);
    default:
      return state;
  }
};
