export const SALES_STATUSES = {
  BOTH: 3,
  CONVERSION: 1,
  RETENTION: 2,
};

export const salesStatusOptions = [
  { label: 'Both', value: SALES_STATUSES.BOTH },
  { label: 'Conversion', value: SALES_STATUSES.CONVERSION },
  { label: 'Retention', value: SALES_STATUSES.RETENTION },
];

export const countryOptions = [
  { ticker: 'Amount' },
  { ticker: 'Number' },
];

export const statusOptions = [
  { ticker: 'Leads' },
  { ticker: 'Clients' },
];
