import { toast } from 'react-toastify';
import {
  GET_CFD_SETTINGS, UPDATE_CFD_SETTINGS, SEARCH_CFD_SETTINGS, MASS_UPDATE_CFD_SETTINGS, 
} from './cfdTypes';
import { apiHelper } from '../apiHelper';

export const getCfdSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-setting');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_SETTINGS,
        payload: res.data.cfdSettings,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateCfdSettings = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/cfd-setting/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_CFD_SETTINGS,
        payload: res.data.cfdSetting,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const massUpdateCfdSettings = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/cfd-setting/mass-update', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: MASS_UPDATE_CFD_SETTINGS,
        payload: data,
      });

      toast.success(res.data.message);
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCfdSettingsByQuery = (symbol, types) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/cfd-setting/query?symbol=${symbol}&types=${types}`);
    if (res && res.data && res.data.success) {
      dispatch({
        type: SEARCH_CFD_SETTINGS,
        payload: res.data.cfdSettings,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};
