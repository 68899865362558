import {
  GET_WHITELIST_IPS,
  GET_WHITELIST_IP,
  ADD_WHITELIST_IP,
  EDIT_WHITELIST_IP,
  DELETE_WHITELIST_IP,
  TOGGLE_WHITELIST_STATE,
  ERROR_WHITELIST_STATE,
} from './whitelistIPTypes';

const initialState = {
  whitelistIPs: [],
  whitelistIP: {},
  whitelistIPsFetched: false,
  whitelistIPAdded: false,
  whitelistIPFetched: false,
  whitelistIPEditted: false,
  whitelistIPDeleted: false,
  error: false,
};

// eslint-disable-next-line default-param-last
const whitelistIPReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WHITELIST_IPS:
      return {
        ...state,
        whitelistIPs: action.payload,
        whitelistIPsFetched: true,
      };
    case GET_WHITELIST_IP:
      return {
        ...state,
        whitelistIP: action.payload,
        whitelistIPFetched: true,
      };
    case ADD_WHITELIST_IP:
      return {
        ...state,
        whitelistIPs: [...state.whitelistIPs, action.payload],
        whitelistIPAdded: true,
      };
    case EDIT_WHITELIST_IP:
      return {
        ...state,
        whitelistIPs: state.whitelistIPs.map((IP) => {
          if (IP._id === action.payload._id) return { ...action.payload };
          return IP;
        }),
        whitelistIPEditted: true,
      };
    case DELETE_WHITELIST_IP:
      return {
        ...state,
        whitelistIPs: state.whitelistIPs.filter((item) => item._id !== action.payload),
        whitelistIPDeleted: true,
      };
    case TOGGLE_WHITELIST_STATE:
      return {
        ...state,
        whitelistIPsFetched: false,
        whitelistIPAdded: false,
        whitelistIPFetched: false,
        whitelistIPEditted: false,
        whitelistIPDeleted: false,
        error: false,
      };
    case ERROR_WHITELIST_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default whitelistIPReducer;
