import { toast } from 'react-toastify';
import { GET_CFD_MARKET_TYPES } from './cfdMarketTypes';
import { apiHelper } from '../apiHelper';

export const getCfdMarketTypes = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-type/settings');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_MARKET_TYPES,
        payload: res.data.cfdTypes,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};
