import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faClone, faPencil } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
// import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { editCRMUser, deleteCRMUser } from '../../redux/crmUser/crmUserActions';
import { ModalFilters } from '../../components';
import { formatDate, prettyCutOutputByCellWidth, valueOutputCutLength } from '../helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';

import styles from './styles.module.css';


export const CRMUsersSchema = ({
  fullname,
  searchEmail,
  agentRoles,
  rolesToShow, 
  setRolesToShow,
  crmUsersFilters, 
  setCrmUsersFilters,
  columnConfig,
  setFullname,
  setSearchEmail,
  inputFieldError,
  setInputFieldError,
  socketIdToCRMUserId,
}) => {
  const dispatch = useDispatch();
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const isUserCanBlockCrmUsers = permissionName && permissionName.includes('block_crm_user');
  const isUserCanEditCrmUsers = permissionName && permissionName.includes('edit_crm_user');
  const isUserCanDeleteCrmUsers = permissionName && permissionName.includes('delete_crm_user');

  const userAction = async (id, userActionType) => {
    Swal.fire({
      title: `Are you sure you want to ${userActionType && userActionType === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${userActionType && userActionType === 'block' ? 'Block' : 'Unblock'}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { status: !(userActionType && userActionType === 'block') };
    
        return Promise.resolve(
          dispatch(editCRMUser(id, data, false, false)),
        )
          .then(
            () => {
              //   console.log('inisde');
              const loginData = localStorage.getItem('user');
              const udata = JSON.parse(loginData);
              const rid = udata?.roleId;
              const uid = udata?._id;
            },
          ).catch((error) => {
            // console.log(error, 'getSubCRMUsers');
          });
      }
      return null;
    });
  };

  const deleteAction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteCRMUser(id));
      }
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const timePassed = (date) => {
    let status = '';
    if (date) {
      const first = new Date(date);
      const end = new Date(Date.now());
      const elapsed = (end - first);
      const CalcElapsed = elapsed / 1000;
      if (CalcElapsed >= 0 && CalcElapsed <= 10) {
        status = 'Online';
      } else {
        status = 'Offline';
      }
      return status;
    }
    return status;
  };

  const omitColumn = (columnName) => {
    let omit = false;
  
    columnConfig.forEach((column) => {
      if (column.name === columnName) {
        omit = !column.selected;
      }
    });
  
    return omit;
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));

    if (nameField === 'fullNameIsNotValid') setFullname(value);
    if (nameField === 'emailIsNotValid') setSearchEmail(value);
  };

  return [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pl-0 ${styles.wrapperInput}`}>
          User ID
        </div>
      ),
      minWidth: '110px',
      omit: omitColumn('User ID'),
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span>
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className={styles.marginRight} />
            {customId}
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column pt-2 ${styles.wrapperInput}`}>
          Full Name
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={fullname ?? ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.fullNameIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn('Full Name'),
      minWidth: '200px',
      cell: ({ firstName, lastName, _id }) => {
        const fullName = `${firstName} ${lastName}`;
        return (
          <div>
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/edit-admin/${_id}`}>
                        <span data-tip={fullName} className="full-name-text">
                          { prettyCutOutputByCellWidth(fullName, 'fullName') }
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/edit-admin/${_id}`}>
                    <span data-tip={fullName} className="full-name-text">
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>
        );
      },
      sortable: false,
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column pt-2 ${styles.wrapperInput}`}>
          Email
          <input
            type="text"
            className="leads-search-bar-filter"
            style={inputFieldError.emailIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            placeholder="Search"
            name="email"
            autoComplete="off"
            value={searchEmail ?? ''}
            onChange={(e) => handleValidationInputs(e, 'emailIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.emailIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn('Email'),
      minWidth: '185px',
      cell: ({ email }) => (
        email.length >= valueOutputCutLength.email 
          ? (
            <TooltipComponent
              title={email}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={email}>
                    <span style={{ margin: '5px', cursor: 'pointer', whiteSpace: 'nowrap' }} data-tip={email}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(email, 'email')}
                    </span>
                  </CopyToClipboard>
                </div>
                )}
            />
          )
          : (
            <CopyToClipboard text={email}>
              <span style={{ margin: '5px', cursor: 'pointer', whiteSpace: 'nowrap' }} data-tip={email}>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {email}
              </span>
            </CopyToClipboard>
          )
      ), 
      sortable: false,
    },
    
    {
      name: (
        <ModalFilters
          data={agentRoles}
          filters={rolesToShow}
          setFilters={setRolesToShow}
          tabName="Role"
          searchParams={{ id: '_id', params: ['_id'], optionsName: ['name'] }}
          usersFilters={crmUsersFilters}
          setUserFilters={setCrmUsersFilters}
        />
      ),
      minWidth: '110px',
      omit: omitColumn('Role'),
      cell: ({ userRole }) => {
        const userRoleName = userRole?.name;
        return (
          userRoleName.length >= valueOutputCutLength.userRole
            ? (
              <TooltipComponent
                title={userRoleName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span data-tip={userRoleName}>{userRoleName}</span>
              )}
              />
            )
            : (
              <span data-tip={userRoleName}>{userRoleName}</span>
            )
        );
      },
      sortable: false,
    },
    {
      name: (
        'Registration Date'
      ),
      omit: omitColumn('Registration Date'),
      minWidth: '176px',
      cell: ({ createdAt }) => (
        <span style={{ margin: '5px' }} data-tip={createdAt}>
          {createdAt 
            ? prettyCutOutputByCellWidth(formatDate(new Date(createdAt)), 'registerDate') 
            : '-'}
        </span>  
      ),
      sortable: false,
    },
    {
      name: 'Online',
      omit: omitColumn('Online'),
      minWidth: '80px',
      cell: ({ _id }) => (
        Object.values(socketIdToCRMUserId).includes(_id)
          ? <span className="online">Online</span>
          : <span className="offline">Offline</span>
      ),
      sortable: false,
    },
    {
      name: 'Actions',
      minWidth: '240px',
      omit: omitColumn('Actions'),
      cell: ({ _id, status }) => (
        <>
          {isUserCanEditCrmUsers && (
          <Link to={`/edit-admin/${_id}`} className="btn btn-success btn-sm me-1 p-1">
            <FontAwesomeIcon icon={faPencil} />
          </Link>
          )}
          {isUserCanDeleteCrmUsers && (
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(_id)}>
            <BsTrashFill role="button" size={18} />
          </button>
          )}
          {isUserCanBlockCrmUsers && (
            status
              ? <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(_id, 'block')}>Block</button>
              : <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(_id, 'unBlock')}>Unblock</button>
          )}
        </>
      ),
    },
  ];
};
