import { toast } from 'react-toastify';
import { GET_ACCOUNT_CURRENCIES } from './cfdAccountCurrencyTypes';
import { apiHelper } from '../apiHelper';

export const geAccountCurrencies = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-account-currency', '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_ACCOUNT_CURRENCIES,
        payload: res.data.cfdAccountCurrencies,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
