import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { cutId } from '../DataTableSchemas/helper';

export function CopyToClipboardCmp({ label = '', isNeedToCut = false }) {
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  return (
    <CopyToClipboard text={label}>
      <span className="text-white">
        {isNeedToCut ? cutId(label) : label}
        <FontAwesomeIcon icon={faClone} onClick={copyReferral} className="ms-2 pointer" />
      </span>
    </CopyToClipboard>
  );
};
