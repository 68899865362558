import React from 'react';
import { Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomizedRadio = styled(Radio)(() => ({
  color: '#9BBDFF',
  '&:hover': {
    backgroundColor: 'rgba(155, 189, 255, 0.08)',
  },
  '&.Mui-checked': {
    color: '#9BBDFF',
    '&:hover': {
      backgroundColor: 'rgba(155, 189, 255, 0.08)',
    },
  },
  '&.Mui-disabled': {
    color: '#C4C6D0',
    opacity: 0.38,
  },
}));

export function RadioButton({ disabled = false, ...rest }) {
  return (
    <CustomizedRadio
      disabled={disabled}
      {...rest}
    />
  );
}
