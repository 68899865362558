/* eslint-disable react/no-danger */
import React, {
  useEffect, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { getEmailTemplate } from '../../../redux/emailTemplates/emailTemplatesActions';
import { selectEmailTemplate, selectIsEmailTemplateLoading } from '../../../redux/emailTemplates/emailTemplatesSelectors';
import logoImage from '../../../assets/images/emailTemplates/logoPlaceholder.png';
import s from './EmailTemplateShow.module.css';

function EmailTemplateShow({ id }) {
  const dispatch = useDispatch();

  const emailTemplate = useSelector(selectEmailTemplate);
  const isEmailTemplateLoading = useSelector(selectIsEmailTemplateLoading);

  useEffect(() => {
    dispatch(getEmailTemplate(id));
  }, [dispatch, id]);

  const sanitizedHTML = DOMPurify.sanitize(emailTemplate?.mailBodyHtml);
  const HTMLWithEncodedBtn = sanitizedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const HTMLWithProperClass = HTMLWithEncodedBtn.replace(/class="button"/g, `class="${s.button}"`);

  if (isEmailTemplateLoading) {
    return <>Loading</>;
  }

  return (
    <div className={s.wrapper}>
      <div className="col-xl-12 col-lg-12">
        <table border="0" cellPadding="0" cellSpacing="0" width="100%">
          <tr>
            <td style={{ backgroundColor: '#F1F0EE' }}>
              <table align="center" border="0" cellPadding="0" cellSpacing="0" width="640" style={{ borderCollapse: 'collapse', backgroundColor: '#F2F2F2' }}>
                <tr>
                  <td className={s.header}>
                    <a
                      href="%siteURL%"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={logoImage}
                        alt="logo"
                        className={s.companyBigLogo}
                      />
                    </a>
                    <div className={s.avatar}><span>A</span></div>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px', backgroundColor: '#F2F2F2' }}>
                    <div dangerouslySetInnerHTML={{ __html: HTMLWithProperClass }} />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 32px 24px 32px', backgroundColor: '#F2F2F2' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td style={{ backgroundColor: '#E6E9F5', padding: '24px 24px 8px 24px', borderRadius: '10px' }}>
                          <div className={s.securityTipTitle}>
                            <span>Security Tips</span>
                          </div>
                          <ul className={s.securityTipsList}>
                            <li>Never give your password to anyone</li>
                            <li>Never call any phone number from someone claiming to be BrandName</li>
                            <li>Never send any money to anyone claiming to be a member of the BrandName team</li>
                            <li>Enable Google Two-Factor</li>
                            <li>Set up your anti-phishing code to add an extra layer of security to your account</li>
                          </ul>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr style={{
                      width: '576px', border: 'none', borderTop: '1px #BDBBD0 solid', height: '1px', 
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '24px 32px 10px 32px', backgroundColor: '#F2F2F2' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td style={{ color: '#153643', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>
                          <p style={{ margin: '0px', fontSize: '13px' }}>Don&#39;t recognize this activity?</p>
                          <p style={{ marginTop: '11px', fontSize: '12px' }}>
                            Please 
                            {' '}
                            <a href="#" style={{ color: '#34693F' }}>reset your password</a>
                            {' '}
                            and contact 
                            {' customer support '}
                            immediately.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr style={{
                      width: '576px', border: 'none', borderTop: '1px #BDBBD0 solid', height: '1px', 
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '16px 32px 32px 32px', backgroundColor: '#F2F2F2' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td align="center" style={{ padding: '8px 0 0 0' }}>
                          <p style={{
                            color: '#3D404F', fontSize: '12px', fontFamily: 'Arial, sans-serif', margin: 0, textAlign: 'left', 
                          }}
                          >
                            This is an automated message, please do not reply
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '21px 32px 27px 32px', backgroundColor: '#F1F0EE' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td align="center" style={{ padding: '8px 0 0 0' }}>
                          <div className={s.footerContentWrapper}>
                            <p style={{
                              color: '#6F6F80', fontSize: '12px', fontFamily: 'Arial, sans-serif', margin: 0, textAlign: 'left', 
                            }}
                            >
                              © 
                              {' '}
                              {(new Date()).getFullYear()}
                              {' '}
                              %companyName%. Brand are registered trademarks
                            </p>
                            <img className={s.companySmallLogo} src={logoImage} alt="$" />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default EmailTemplateShow;
