import React from 'react';
import { useSelector } from 'react-redux';
import downloadIcon from '../assets/download.png';
import styles from './Analysis.module.css';
import { selectAffiliatesDashboardAnalysis } from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';

function Analysis() {
  const {
    leads = 0, firstTimeDeposits = 0, clientRate = 0, netDeposits = 0, 
  } = useSelector(selectAffiliatesDashboardAnalysis);

  return (
    <div className={styles.analysis}>
      <div className={styles.analysisTitle}>
        <span>Affiliates analysis</span>
        {/* <img className={styles.downloadIcon} src={downloadIcon} alt="#" height={16} width={16} /> */}
      </div>
      <div className={styles.analysisData}>
        <Item title="Leads:" value={leads} />
        <Item title="FTD:" value={firstTimeDeposits} />
        <Item title="CR:" value={`${clientRate.toFixed(2)}%`} />
        <Item title="Net deposits:" value={`$ ${netDeposits}`} />
      </div>
    </div>
  );
}

function Item({ title, value }) {
  return (
    <div className={styles.analysisDataItem}>
      <div className={styles.itemTitle}>
        {title}
      </div>
      <div className={styles.itemValue}>
        {value}
      </div>
    </div>
  );
}

export default Analysis;
