import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { displayRoles, deleteRole, getRole } from '../../redux/roles/roleActions';

function Roles() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const roles = useSelector((state) => state.role?.roles?.roles);
  const success = useSelector((state) => state.role?.roles?.success);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddRole = permissionName && permissionName.includes('add_roles');
  const isUserCanEditRole = permissionName && permissionName.includes('edit_role');
  const isUserCanDeleteRole = permissionName && permissionName.includes('delete_role');
  const isUserCanSeePermissionsPerRole = permissionName && permissionName.includes('view_permissions_per_role');

  useEffect(() => {
    setLoader(true);
    dispatch(displayRoles());
    if (success) {
      setLoader(false);
    }
  }, [success]);

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    dispatch(getRole(id));
  }, []);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteRole(id));
      }
    });
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Roles Details</h3>
        {isUserCanAddRole && (<Link to="/add-role"><button type="button" className="btn btn-default">Add Role</button></Link>)}
        <div className="table mt-3 table-responsive">
          <table className="table">
            <thead className="table_head">
              <tr>
                <th>Role</th>
                <th>Action(s)</th>
              </tr>
            </thead>
            <tbody>
              {roles && roles.length > 0 && roles.map((role) => (
                <tr key={role?._id}>
                  <td>{role?.name}</td>
                  <td>
                    {isUserCanEditRole && (<Link to={`/edit-role/${role?._id}`} className="btn btn-outline-primary me-2 text-decoration-none text-light">Edit</Link>)}
                    {isUserCanSeePermissionsPerRole && (<Link to={`/permission-per-role/${role?._id}`} className="btn btn-outline-warning me-2 text-decoration-none text-light">View Details</Link>)}
                    {isUserCanDeleteRole && (<button type="button" className="btn btn-outline-danger me-2" onClick={() => deleteAction(role?._id)}>Delete</button>)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Roles;
