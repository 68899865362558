import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import permissionReducer from './permissions/permissionReducer';
import authReducer from './auth/authReducer';
import userReducer from './users/userReducer';
import permissionsModuleReducer from './permissionsModule/permissionsModuleReducer';
import roleReducer from './roles/roleReducer';
import currencyReducer from './currency/currencyReducer';
import fiatCurrencyReducer from './fiatCurrency/fiatCurrencyReducer';
import transactionFeeReducer from './transactionFee/transactionFeeReducer';
import transactionReducer from './transactions/transactionReducer';
import networkReducer from './network/networkReducer';
import withdrawFeeReducer from './withdrawFee/withdrawFeeReducer';
import leverageReducer from './leverage/leverageReducer';
import ExternalTransactionReducer from './ExternalTransactions/externalTransactionReducer';
import ExternalFiatTransactionReducer from './externalFiatTransactions/externalFiatTransactionReducer';
import ExternalBankTransactionReducer from './externalBankTransactions/externalBankTransactionReducer';
import settingsReducer from './settings/settingsReducer';
import LeverageOrderReducer from './leverageOrder/leverageOrderReducer';
import adminCommentReducer from './adminComment/adminCommentReducer';
import SpotOrderReducer from './spotOrder/spotOrderReducer';
import salesStatusReducer from './salesStatus/salesStatusReducer';
import adminBankAccountReducer from './psp/adminBankAccountReducer';
import adminAddressReducer from './adminAddress/adminAddressReducer';
import notificationReducer from './notifications/notificationReducer';
import whitelistIPReducer from './security/whitelistIPReducer';
import crmUserReducer from './crmUser/crmUserReducer';
import affiliateReducer from './affiliate/affiliateReducer';
import affiliateRuleReducer from './affiliateRule/affiliateRuleReducer';
import crmActivityReducer from './crmActivity/crmActivityReducer';
import activityTypeReducer from './activityType/activityTypeReducer';
import activityClientTypeReducer from './activityClientType/activityClientTypeReducer';
import clientActivityReducer from './clientActivity/clientActivityReducer';
import clientLoginReducer from './clientLogin/clientLoginReducer';
import savingReducer from './saving/savingReducer';
import forexCurrencyReducer from './forexCurrency/forexCurrencyReducer';
import stockSettingReducer from './stockSetting/stockSettingReducer';
import chatReducer from './chat/chatReducer';
import tradingSettingReducer from './tradingSetting/tradingSettingReducer';
import eventReducer from './event/eventReducer';
import commoditiesSettingReducer from './commoditiesSetting/commoditiesSettingReducer';
import indicesSettingReducer from './indicesSetting/indicesSettingReducer';
import cryptoSettingReducer from './cryptoSetting/cryptoSettingReducer';
import metalsSettingReducer from './metalSetting/metalsSettingReducer';
import brandReducer from './brand/brandReducer';
import savingInvestmentReducer from './savingInvestment/savingInvestmentsReducer';
import currencyRatesReducer from './currencyRate/currencyRateReducer';
import othersPspReducer from './pspOthers/pspOthersReducer';
import cfdSettingsReducer from './cfdSettings/cfdReducer';
import cfdMarketTypesReducer from './cfdMarketTypes/cfdMarketTypesReducer';
import tradeTypeReducer from './tradeType/tradeTypeReducer';
import pairReducer from './pair/pairReducer';
import staticComponentReducer from './staticComponent/staticComponentReducer';
import exchangeOrderReducer from './exchangeOrder/exchangeOrderReducer';
import cfdAccountReducer from './cfdAccount/cfdAccountReducer';
import cfdAccountCurrencyReducer from './cfdAccountCurrency/cfdAccountCurrencyReducer';
import cfdOrderReducer from './cfdOrder/cfdOrderReducer';
import emailTemplatesReducer from './emailTemplates/emailTemplatesReducer';
import withdrawalMethodsReducer from './withdrawalMethods/withdrawalMethodsReducer';
import smsServicesReducer from './smsServices/smsServicesReducer';
import smtpPresetsReducer from './smtpPresets/smtpPresetsReducer';
import userStatusChangesHistoryReducer from './userStatusChangesHistory/userStatusChangesHistoryReducer';
import userSentEmailHistoryReducer from './userSentEmailHistory/userSentEmailHistoryReducer';
import kycAmlReducer from './kycAml/kycAmlReducer';
import assetQuoteReducer from './assetQuote/assetQuoteReducer';
import cfdAccountTypeReducer from './cfdAccountType/cfdAccountTypeReducer';
import countriesReducer from './countries/countiesReducer';
import voipReducer from './voip/voipReducer';
import crmReducer from './crm/crmReducer';
import userCallHistoryReducer from './userCallHistory/userCallHistoryReducer';
import salesDashboardReducer from './salesDashboard/salesDashboardReducer';
import affiliatesDashboardReducer from './affiliatesDashboard/affiliatesDashboardReducer';
import hierarchyReducer from './hierarchy/hierarchyReducer';
import datafeedsReducer from './datafeeds/datafeedsReducer';
import userPspReducer from './userPsp/userPspReducer';
import variablesReducer from './variables/variablesReducer';
import monthlyInactivityFeeReducer from './monthlyInactivityFee/monthlyInactivityFeeReducer';
import userSpreadReducer from './userSpread/userSpreadReducer';
import depositMethodsReducer from './depositMethods/depositMethodsReducer';
import kycCommentReducer from './kycComment/kycCommentReducer';

const middleware = [thunk];
const reducer = combineReducers({
  users: userReducer,
  auth: authReducer,
  permission: permissionReducer,
  permissionsModule: permissionsModuleReducer,
  role: roleReducer,
  currency: currencyReducer,
  fiatCurrency: fiatCurrencyReducer,
  transactionFee: transactionFeeReducer,
  transaction: transactionReducer,
  network: networkReducer,
  withdrawFee: withdrawFeeReducer,
  leverages: leverageReducer,
  externalTransaction: ExternalTransactionReducer,
  externalFiatTransaction: ExternalFiatTransactionReducer,
  externalBankTransaction: ExternalBankTransactionReducer,
  LeverageOrders: LeverageOrderReducer,
  settings: settingsReducer,
  adminComment: adminCommentReducer,
  spotOrder: SpotOrderReducer,
  salesStatus: salesStatusReducer,
  adminBankAccount: adminBankAccountReducer,
  adminAddress: adminAddressReducer,
  notification: notificationReducer,
  whitelistIP: whitelistIPReducer,
  crmUser: crmUserReducer,
  affiliate: affiliateReducer,
  affiliateRule: affiliateRuleReducer,
  crmActivity: crmActivityReducer,
  clientActivity: clientActivityReducer,
  clientLogin: clientLoginReducer,
  activityType: activityTypeReducer,
  activityClientType: activityClientTypeReducer,
  forexCurrency: forexCurrencyReducer,
  stockSetting: stockSettingReducer,
  chatsInfo: chatReducer,
  tradingSettings: tradingSettingReducer,
  events: eventReducer,
  commoditiesSetting: commoditiesSettingReducer,
  indicesSetting: indicesSettingReducer,
  cryptoSetting: cryptoSettingReducer,
  metalsSetting: metalsSettingReducer,
  brands: brandReducer,
  stakingInvestments: savingInvestmentReducer,
  currencyRates: currencyRatesReducer,
  othersPsp: othersPspReducer,
  cfdSettings: cfdSettingsReducer,
  cfdMarketTypes: cfdMarketTypesReducer,
  tradeTypes: tradeTypeReducer,
  pairs: pairReducer,
  staticComponents: staticComponentReducer,
  exchangeOrders: exchangeOrderReducer,
  cfdAccounts: cfdAccountReducer,
  cfdAccountCurrencies: cfdAccountCurrencyReducer,
  cfdOrders: cfdOrderReducer,
  emailTemplates: emailTemplatesReducer,
  withdrawalMethods: withdrawalMethodsReducer,
  smsServices: smsServicesReducer,
  smtpPresets: smtpPresetsReducer,
  userStatusChangesHistory: userStatusChangesHistoryReducer,
  userSentEmailHistory: userSentEmailHistoryReducer,
  kycAml: kycAmlReducer,
  saving: savingReducer,
  assetQuote: assetQuoteReducer,
  cfdAccountTypes: cfdAccountTypeReducer,
  countries: countriesReducer,
  voip: voipReducer,
  crmOptions: crmReducer,
  callHistory: userCallHistoryReducer,
  salesDashboard: salesDashboardReducer,
  affiliatesDashboard: affiliatesDashboardReducer,
  hierarchy: hierarchyReducer,
  datafeeds: datafeedsReducer,
  userPsps: userPspReducer,
  variables: variablesReducer,
  monthlyInactivityFee: monthlyInactivityFeeReducer,
  userSpread: userSpreadReducer,
  depositMethods: depositMethodsReducer,
  kycComments: kycCommentReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
