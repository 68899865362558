import { toast } from 'react-toastify';
import {
  GET_CLIENT_ACTIVITY_TYPES,
  GET_CLIENT_ACTIVITY_TYPE,
  ADD_CLIENT_ACTIVITY_TYPE,
  EDIT_CLIENT_ACTIVITY_TYPE,
  DELETE_CLIENT_ACTIVITY_TYPE,
  TOGGLE_CLIENT_ACTIVITY_TYPE_STATE,
  ERROR_CLIENT_ACTIVITY_TYPE_STATE,
} from './activityClientTypeTypes';
import { apiHelper } from '../apiHelper';

export const getActivityClientTypes = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/activity-client-log-type', '');
    if (res?.data && res?.data?.activityClientLogTypes) {
      const { data } = res;
      await dispatch({
        type: GET_CLIENT_ACTIVITY_TYPES,
        payload: data?.activityClientLogTypes,
      });
    } else {
      await dispatch({
        type: GET_CLIENT_ACTIVITY_TYPES,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getActivityClientType = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/activity-client-log-type/${id}`, '');
    if (res?.data && res?.data?.activityClientLogType) {
      const { data } = res;
      await dispatch({
        type: GET_CLIENT_ACTIVITY_TYPE,
        payload: data?.activityClientLogType,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addActivityClientType = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/activity-client-log-type/add', data);

    if (res?.data && res?.data?.activityClientLogType_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_CLIENT_ACTIVITY_TYPE,
        payload: data?.activityClientLogType_,
      });
    } else {
      await dispatch({
        type: ERROR_CLIENT_ACTIVITY_TYPE_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editActivityClientType = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/activity-client-log-type/${id}`, data);
    if (res?.data && res?.data?.activityClientLogType) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_CLIENT_ACTIVITY_TYPE,
        payload: data?.activityClientLogType,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteActivityClientType = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/activity-client-log-type/${id}`, '');
    if (res?.data && res?.data?.activityClientLogType) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_CLIENT_ACTIVITY_TYPE,
        payload: data?.activityClientLogType?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateActivityClientTypeState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_CLIENT_ACTIVITY_TYPE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
