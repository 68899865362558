import { SET_IS_HEADER_MOUNTED, SET_IS_SIDEBAR_MOUNTED } from './staticComponentTypes';

export const setHeaderMounted = (value) => (dispatch) => {
  dispatch({
    type: SET_IS_HEADER_MOUNTED,
    payload: value,
  });
};

export const setSideBarMounted = (value) => (dispatch) => {
  dispatch({
    type: SET_IS_SIDEBAR_MOUNTED,
    payload: value,
  });
};
