import React, {
  useState, useMemo, useEffect, useContext, useCallback, 
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Dropdown, Button, Row, Col, InputGroup, DropdownButton, Form, 
} from 'react-bootstrap';
import { updateSpreadSetting } from '../../../../redux/userSpread/userSpreadActions';
import { ModalContext } from '../../../../context';
import './style.css';
  
export function EditSpreadSettingModal({ userId, data = {} }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [spreadSetting, setSpreadSetting] = useState({
    pairName: '',
    spreadMin: 0,
    spreadMax: 0,
  });

  const isBtnDisabled = !String(spreadSetting.spreadMin).length || !String(spreadSetting.spreadMax).length;
  
  useEffect(() => {
    if (Object.keys(data).length) {
      setSpreadSetting({
        pairName: data.pairName,
        spreadMin: data.spreadMin,
        spreadMax: data.spreadMax,
      });
    }
  }, [data]);
  
  const closeModal = () => {
    hideModal();
  };
  
  const submit = async () => {
    const payload = {
      userId,
      spreadMin: spreadSetting.spreadMin,
      spreadMax: spreadSetting.spreadMax,
    };
  
    await dispatch(updateSpreadSetting(data._id, payload));
    closeModal();
  };
  
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !isBtnDisabled) {
      submit();
    }
  }, [isBtnDisabled, spreadSetting]);
  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleChangeField = (field, value) => {
    const changedValue = value.replace(/[^-\d.]/g, '').replace(/^0+(\d)/, '$1');

    setSpreadSetting((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
  };
  
  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair Name</label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" disabled variant="">{data.pairName}</Dropdown.Toggle>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Spread Max</label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <InputGroup className="t-settings_input-group">
                <Form.Control 
                  value={spreadSetting.spreadMax} 
                  onChange={({ target: { value } }) => handleChangeField('spreadMax', value)}
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Spread Min</label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <InputGroup className="mb-3 t-settings_input-group">
                <Form.Control 
                  value={spreadSetting.spreadMin} 
                  onChange={({ target: { value } }) => handleChangeField('spreadMin', value)}
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </div>
  );
};
  
