import {
  CREATE_TRADING_SETTING, DELETE_TRADING_SETTING, UPDATE_TRADING_SETTING, GET_TRADING_SETTINGS, 
} from './tradingSettingTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_TRADING_SETTINGS:
      return action.payload;
    case DELETE_TRADING_SETTING:
      return state.filter((item) => item._id !== action.payload);
    case CREATE_TRADING_SETTING:
      return [...state, action.payload];
    case UPDATE_TRADING_SETTING:
      return state.map((item) => {
        if (item._id === action.payload._id) {
          return { ...action.payload };
        }

        return item;
      });
    default:
      return state;
  }
};
