import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersByPSP } from '../../redux/adminAddress/adminAddressActions';
import styles from './UsersInUseModal.module.css';

export function UsersInUseModal({ _id }) {
  const dispatch = useDispatch();

  const isUsersLoading = useSelector((state) => state?.adminAddress?.isPspUsersLoading);
  const pspUsers = useSelector((state) => state?.adminAddress?.pspUsers);

  useEffect(() => {
    if (_id) dispatch(getUsersByPSP(_id));
  }, [_id]);

  return (
    !isUsersLoading ? (
      <div className={styles.wrapper}>
        {pspUsers.map(({
          firstName, lastName, customId, phone, email, 
        }) => (
          <span className={styles.userName}>
            {`ID: ${customId} | ${firstName} ${lastName} - ${email}, (${phone})`}
          </span>
        ))}
        {pspUsers.length === 0 ? <h4>No one in use</h4> : ''}
      </div>
    ) : <>Loading...</>
  );
}
