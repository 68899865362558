import {
  GET_USER_PSP, GET_PSP_OPTIONS, CREATE_USER_PSP, DELETE_USER_PSP, UPDATE_USER_PSP,
} from './userPspTypes';

const initialState = {
  psps: [],
  options: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PSP:
      return { ...state, psps: action.payload };
    case GET_PSP_OPTIONS:
      return { ...state, options: action.payload };
    case CREATE_USER_PSP:
      return { ...state, psps: [...state.psps, action.payload] };
    case UPDATE_USER_PSP:
      return { 
        ...state, 
        psps: state.psps.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_USER_PSP:
      return { 
        ...state, 
        psps: state.psps.filter(({ _id }) => _id !== action.payload._id),
      };
    default:
      return state;
  }
};
