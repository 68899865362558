import { apiHelper } from '../apiHelper';
import {
  SET_WITHDRAWAL_METHODS,
  SET_IS_WITHDRAWAL_METHODS_LOADING,
  UPDATE_WITHDRAWAL_METHOD,
  SET_BRAND_SEARCH_FILTER,
  SET_WITHDRAWAL_METHOD_SEARCH_FILTER,
  SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  SET_TOTAL_COUNT_WITHDRAWAL_METHODS,
  SET_WITHDRAWAL_METHODS_OPTIONS,
} from './withdrawalMethodsTypes';

export const setWithdrawalMethodsOptionsCreator = (value) => ({
  type: SET_WITHDRAWAL_METHODS_OPTIONS,
  payload: value,
});

export const setBrandFilterCreator = (value) => ({
  type: SET_BRAND_SEARCH_FILTER,
  payload: value,
});

export const setWithdrawalMethodFilterCreator = (value) => ({
  type: SET_WITHDRAWAL_METHOD_SEARCH_FILTER,
  payload: value,
});

export const setCountriesIncludedFilterCreator = (value) => ({
  type: SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  payload: value,
});

export const setWithdrawalMethods = (methods) => ({
  type: SET_WITHDRAWAL_METHODS,
  payload: methods,
});

export const setTotalCount = (totalCount) => ({
  type: SET_TOTAL_COUNT_WITHDRAWAL_METHODS,
  payload: totalCount,
});

export const setIsWithdrawalMethodsLoading = (status) => ({
  type: SET_IS_WITHDRAWAL_METHODS_LOADING,
  payload: status,
});

export const fetchWithdrawalMethodsOptions = () => async (dispatch) => {
  try {
    const { data: result } = await apiHelper('get', '/api/withdrawal-types/get-all-withdrawal-types');

    if (result?.success && result?.data) {
      dispatch(setWithdrawalMethodsOptionsCreator(result.data));
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const fetchWithdrawalMethods = (
  page = 1,
  rowsPerPage = 25,
  filters = {},
) => async (dispatch) => {
  dispatch(setIsWithdrawalMethodsLoading(true));
  try {
    const { data: result } = await apiHelper('get', '/api/withdrawal-types', '', {
      page, limit: rowsPerPage, ...filters,
    });

    if (result?.success && result?.data) {
      dispatch(setWithdrawalMethods(result.data));
      dispatch(setTotalCount(result.totalCount));
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    dispatch(setIsWithdrawalMethodsLoading(false));
  }
};

export const updateWithdrawalMethod = (id, data) => async (dispatch) => {
  try {
    const { data: result } = await apiHelper('put', `/api/withdrawal-types/${id}`, data);
    if (result?.success && result?.withdrawalType) {
      dispatch({
        type: UPDATE_WITHDRAWAL_METHOD,
        payload: result.withdrawalType,
      });
    }
  } catch (error) {
    console.log(error.response);
  }
};
