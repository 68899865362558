import React, {
  useState, 
} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditorComponent from '../EditorComponent/EditorComponent';
import { createEmailTemplate } from '../../../redux/emailTemplates/emailTemplatesActions';

function EmailTemplateCreate({ hideModal }) {
  const dispatch = useDispatch();

  const [event, setEvent] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(true);
  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState('');
  const [mailJsonBodyStringified, setMailJsonBodyS] = useState('{"blocks":[{"text":""}],"entityMap":{}}');

  const { handleSubmit } = useForm();

  const handleSave = async () => {
    const data = {
      event,
      title,
      status,
      mailBodyJson: mailJsonBodyStringified,
      mailBodyHtml: mailHtmlBodyStringified,
    };

    dispatch(createEmailTemplate(data, hideModal));
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="col-xl-12 col-lg-12">
        <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>

          <div className="form-group">
            <label className="lead" htmlFor="label">Event</label>
            <input
              type="text"
              name="event"
              value={event}
              onInput={(e) => setEvent(e.target.value)}
              placeholder="Enter here..."
              className="form-control"
            />
          </div>
          <br />

          <div className="form-group">
            <label className="lead" htmlFor="label">Title</label>
            <input
              type="text"
              name="title"
              value={title}
              className="form-control"
              placeholder="Enter here..."
              onInput={(e) => setTitle(e.target.value)}
            />
          </div>
          <br />

          <div className="form-group">
            <label className="lead">Status</label>
            <select className="form-control user-status-select" name="showFtd" value={status} onChange={({ target: { value } }) => setStatus(value === 'true')}>
              <option value>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <br />

          <EditorComponent
            setMailHtmlBodyS={setMailHtmlBodyS}
            setMailJsonBodyS={setMailJsonBodyS}
          />

          <br />
          <div className="d-flex justify-content-end">
            <button className="btn-default" type="submit">Create Email Template</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmailTemplateCreate;
