import {
  Link, NavLink, useLocation, useHistory, 
} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import React, {
  useEffect, useState, useRef, useMemo, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faUsers,
  faArrowsRotate,
  faFolderOpen,
  faEnvelope,
  faGauge,
  faBell,
  faMoneyCheck,
  faClock,
  faIdCard,
  faExchange,
  faMoneyBill,
  faMoneyBillTransfer,
  faTrademark,
  faBars,
  faTimes,
  faUserLock,
  faList,
  faTrash,
  faComment,
  faCheckCircle,
  faBriefcase,
  faNetworkWired,
  faCalendar,
  faFileContract,
  faArrowTrendUp,
  faPenToSquare,
  faClockRotateLeft,
  faPhoneSquare,
  faComputer,
  faCommentSms,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Tooltip from '@mui/material/Tooltip';
import { getCRMUser } from '../redux/crmUser/crmUserActions';
import { getPendingWithdrawals, getPendingDeposits } from '../redux/transactions/transactionActions';
import { setSideBarMounted } from '../redux/staticComponent/staticComponentActions';
import { getChats } from '../redux/chat/chatActions';
import { setNavigator } from '../services/navigatorService';

function NavigationMenu() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [path, setPath] = useState('/leads');
  const [activeSidebar, setActiveSidebar] = useState(true);
  const fullWidthMenuJSON = localStorage.getItem('fullWidthMenu');
  const [fullWidthMenu, setFullWidthMenu] = useState(fullWidthMenuJSON ? JSON.parse(fullWidthMenuJSON) : true);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const chats = useSelector((state) => state.chatsInfo.chats);
  const allPendingWithdrawalsCount = useSelector(
    (state) => state?.transaction?.pendingWithdrawals?.pendingWithdrawals?.[0]?.total_count,
  );
  const allPendingDeposits = useSelector(
    (state) => state?.transaction?.pendingDeposits?.pendingDeposits?.[0]?.total_count,
  );
  const userData = useSelector((state) => state?.crmUser?.crmUserInfo);
  const isSideBarMounted = useSelector((state) => state.staticComponents.isSideBarMounted);

  const isUserCanSeePsp = permissionName && permissionName.includes('psp');
  const isUserCanSeePspOther = permissionName && permissionName.includes('psp_other');
  const isUserCanSeeSymbolSettings = permissionName && permissionName.includes('symbol_settings_management');
  const isUserCanSeeBankAccount = permissionName && permissionName.includes('bank_account');
  const isUserCanSeeAdminWallets = permissionName && permissionName.includes('admin_wallets');
  const isUserCanSeeDeposits = permissionName && permissionName.includes('deposits');
  const isUserCanSeeWithdrawals = permissionName && permissionName.includes('withdrawals');
  const isUserCanSeeKycAml = permissionName && permissionName.includes('kyc_aml');
  const isUserCanSeeRoles = permissionName && permissionName.includes('roles_management');
  const isUserCanSeePermissions = permissionName && permissionName.includes('permissions_management');
  const isUserCanManageLeverages = permissionName && permissionName.includes('leverage_management');
  const isUserCanSeeAffiliates = permissionName && permissionName.includes('affiliates');
  const isUserCanSeeEmailTemplates = permissionName && permissionName.includes('email_templates');
  const isUserCanSeeWithdrawalMethods = permissionName && permissionName.includes('withdrawal_methods');
  const isUserCanSeeDepositMethods = permissionName && permissionName.includes('deposit_methods');
  const isUserCanSeeSmsServices = permissionName && permissionName.includes('sms_services');
  const isUserCanSeeSmtpPresets = permissionName && permissionName.includes('smtp_presets');
  const isUserCanSeeVoIP = permissionName && permissionName.includes('voip');
  const isUserCanSeeStatuses = permissionName && permissionName.includes('statuses');
  const isUserCanSeeSecurity = permissionName && permissionName.includes('security');
  const isUserCanSeeSaving = permissionName && permissionName.includes('saving_management');
  const isUserCanSeeTradeSettings = permissionName && permissionName.includes('trade_management');
  const isUserCanSeeCrmUsers = permissionName && permissionName.includes('crm_users_management');
  const isUserCanSeeAffiliatesDashboard = permissionName && permissionName.includes('crm_affiliates_dashboard');
  const isUserCanSeeCrmUsersReport = permissionName && permissionName.includes('crm_users_report');
  const isUserCanSeeClientsReport = permissionName && permissionName.includes('clients_report');
  const isUserCanSeeNetworks = permissionName && permissionName.includes('networks');
  const isUserCanSeeDeletedTransactions = permissionName && permissionName.includes('delete_transaction');
  const isUserCanSeeFinanceBlock = permissionName && (permissionName.includes('delete_transaction') || permissionName.includes('deposits') || permissionName.includes('withdrawals'));
  const isUserCanSeeDashboard = permissionName && permissionName.includes('dashboard');
  const isUserCanSeeCfdAccounts = permissionName && permissionName.includes('cfd_accounts');
  const isUserCanSeeOpenOrders = permissionName && permissionName.length > 0 && permissionName.includes('open_orders');
  const isUserCanSeeOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history');
  const isUserCanSeeClients = permissionName && permissionName.length > 0 && permissionName.includes('clients');
  const isUserCanSeeLeads = permissionName && permissionName.length > 0 && permissionName.includes('leads');
  const isUserCanSeeVariables = permissionName && permissionName.includes('variables');
  const isSuperUser = userData?.role?.name === 'SuperAdmin';

  const messageCounter = useMemo(() => {
    let count = 0;

    if (Object.keys(chats).length) {
      Object.keys(chats).forEach((id) => {
        chats[id].forEach((chat) => {
          chat.messages.forEach((message) => {
            if (message.sender !== id && !message.isRead) count += 1;
          });
        });
      });
    }

    return count;
  }, [chats]);
  
  const tabRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavigator(history);
  }, [history]);

  const checkScreen = () => {
    if (window.innerWidth < 768 === true) {
      setActiveSidebar(false);
    } else {
      setActiveSidebar(true);
    }
  };

  useEffect(() => {
    checkScreen();
    const { pathname } = window.location;
    setPath(pathname);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const uId = data?._id;

    if (!isSideBarMounted) {
      Promise.allSettled([
        dispatch(getPendingWithdrawals()),
        dispatch(getPendingDeposits()),
        dispatch(getCRMUser(uId, true)),
        dispatch(getChats(uId)),
      ]);

      dispatch(setSideBarMounted(true));
    }
  }, [isSideBarMounted]);

  const showSidebar = () => {
    setActiveSidebar(!activeSidebar);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isTabActive = (match, location, tab) => {
    const isActive = match || location.pathname === tab;
    if (isActive) {
      setActiveTab(tab);
    }
    return isActive;
  };

  useEffect(() => {
    if (!isDropdownOpen && activeTab && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeTab, isDropdownOpen]);

  const toggleMenu = () => {
    setFullWidthMenu(!fullWidthMenu);
    localStorage.setItem('fullWidthMenu', !fullWidthMenu);
  };

  const getNavbarItem = (item, span) => {
    if (fullWidthMenu) return item; 

    return (
      <Tooltip title={span} placement="right">
        {item}
      </Tooltip>
    );
  };

  return (
    <>
      {activeSidebar ? (
        <div className="sidebar-is-active" />
      ) : (
        null
      )}
      <div
        className={
          activeSidebar
            ? `sidebar left-sidebar-fix show-sidenav ${!fullWidthMenu && 'half-width'}`
            : 'sidebar left-sidebar-fix hide-sidenav'
        }
        style={{ paddingBottom: '20px' }}
      >
        <div style={{ position: 'relative' }}>
          <div className="menu-toggle">
            {activeSidebar ? (
              <FontAwesomeIcon
                icon={faTimes}
                className="span-white sidebar-icon-hide"
                onClick={() => showSidebar()}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="span-white sidebar-icon-hide"
                onClick={() => showSidebar()}
              />
            )}
          </div>
        </div>
        <div className="admin-panel-btns">
          <button className="toggle-menu__btn" type="button" onClick={toggleMenu}>
            {
              fullWidthMenu ? <NavigateBeforeIcon /> : <NavigateNextIcon />
              }
          </button>
          <nav className="w-100">

            {isUserCanSeeDashboard && (
              <div className="menu-padding">
                {
                  getNavbarItem(
                    <Link
                      className={`admin-nav-link ${
                        pathname === '/' ? 'active' : 'unactive'
                      }`}
                      to="/"
                    >
                      <FontAwesomeIcon icon={faGauge} className="me-2" />
                      {fullWidthMenu && 'Dashboard'}
                    </Link>,
                    'Dashboard',
                  )
                }

              </div>
            )}
            {isUserCanSeeLeads && (
              getNavbarItem(
                <NavLink to="/leads" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faUsers} className="me-2" />
                  {fullWidthMenu && 'Leads'}
                </NavLink>,
                'Leads',
              )
            )}
            {isUserCanSeeClients && (
              getNavbarItem(
                <NavLink to="/users" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faUsers} className="me-2" />
                  {fullWidthMenu && 'Clients'}
                </NavLink>,
                'Clients',
              )
            )}
            {
              getNavbarItem(
                <NavLink to="/chatbox" className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}>
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  {fullWidthMenu && 'Chat'}
                  {(!!messageCounter && fullWidthMenu) && (<div className="status-bubble">{messageCounter}</div>)}
                </NavLink>,
                'Chat',
              )
            }
            {
              getNavbarItem(
                <NavLink to="/calendar" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faCalendar} className="me-2" />
                  {fullWidthMenu && 'Calendar'}
                </NavLink>,
                'Calendar',
              )
            }
            {(isUserCanSeeCrmUsers || isUserCanSeeRoles || isUserCanSeePermissions) && (
              <div className="menu-padding pb-3">
                <span className="menutitle">
                  User
                  {fullWidthMenu && ' Managment'}
                </span>
                {isUserCanSeeCrmUsers && (
                  getNavbarItem(
                    <NavLink
                      className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}
                      to="/crm-users"
                    >
                      <FontAwesomeIcon icon={faUsers} className="me-2" />
                      {fullWidthMenu && <span>CRM Users</span>}
                    </NavLink>,
                    'CRM Users',
                  )
                )}
                {isUserCanSeeCrmUsers && (
                  getNavbarItem(
                    <Link
                      className={`admin-nav-link ${
                        pathname === '/hierarchy' ? 'active' : 'unactive'
                      }`}
                      to="/hierarchy"
                    >
                      <FontAwesomeIcon icon={faBell} className="me-2" />
                      {fullWidthMenu && 'Hierarchy'}
                    </Link>,
                    'Hierarchy',
                  )
                )}
                {isUserCanSeeRoles && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      {
                        getNavbarItem(
                          <span className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}>
                            <FontAwesomeIcon icon={faBriefcase} className="me-2" /> 
                            {' '}
                            {fullWidthMenu && <span>Roles</span>}
                          </span>,
                          'Roles',
                        )
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={!!(path === '/roles' || path === '/add-role')}>
                      {
                        getNavbarItem(
                          <NavLink to="/roles" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                            {fullWidthMenu && 'Roles Details'}
                          </NavLink>,
                          'Roles Details',
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {isUserCanSeePermissions && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      {
                        getNavbarItem(
                          <span className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {fullWidthMenu && <span>Permissions</span>}
                          </span>,
                          'Permissions',
                        )
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={!!(path === '/permissions' || path === '/add-permission' || path === '/permissions-modules' || path === '/add-permissions-module')}>
                      {
                        getNavbarItem(
                          <NavLink to="/permissions" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                            {fullWidthMenu && 'Permissions Details'}
                          </NavLink>,
                          'Permissions Details',
                        )
                      }
                      {
                        getNavbarItem(
                          <NavLink to="/add-permission" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                            {fullWidthMenu && 'Add Permissions'}
                          </NavLink>,
                          'Add Permissions',
                        )
                      }
                      {
                        getNavbarItem(
                          <NavLink to="/permissions-modules" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                            {fullWidthMenu && 'Permissions Modules Detail'}
                          </NavLink>,
                          'Permissions Modules Detail',
                        )
                      }
                      {
                        getNavbarItem(
                          <NavLink to="/add-permissions-module" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                            {fullWidthMenu && 'Add Permissions Module'}
                          </NavLink>,
                          'Add Permissions Module',
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
            {isUserCanSeeFinanceBlock && (
            <div className="menu-padding pb-3">
              <span className="menutitle">Finance</span>
              {isUserCanSeeKycAml && (
                getNavbarItem(
                  <NavLink
                    className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}
                    to="/kyc-aml"
                  >
                    <FontAwesomeIcon icon={faIdCard} className="me-2" />
                    {fullWidthMenu && <span>KYC/AML</span>}
                  </NavLink>,
                  'KYC/AML',
                )
              )}
              {isUserCanSeeDeposits && (
                getNavbarItem(
                  <NavLink
                    className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}
                    to="/external-transactions"
                  >
                    <FontAwesomeIcon icon={faExchange} className="me-2" />
                    {fullWidthMenu && <span>Deposits</span>}
                    {(allPendingDeposits && fullWidthMenu) && (<div className="status-bubble">{allPendingDeposits}</div>)}
                  </NavLink>,
                  'Deposits',
                )
              )}
              {isUserCanSeeWithdrawals && (
                getNavbarItem(
                  <NavLink
                    className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}
                    to="/withdrawals"
                  >
                    <FontAwesomeIcon icon={faClock} className="me-2" />
                    {fullWidthMenu && <span>Withdrawals</span>}
                    {(allPendingWithdrawalsCount && fullWidthMenu) && (<div className="status-bubble">{allPendingWithdrawalsCount}</div>)}
                  </NavLink>,
                  'Withdrawals',
                )
              )}
              {isUserCanSeeDeletedTransactions && (
                getNavbarItem(
                  <NavLink className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`} to="/deleted-transactions">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    {fullWidthMenu && <span>Deleted Transactions</span>}
                  </NavLink>,
                  'Deleted Transactions',
                )
              )}
            </div>
            )}
            {(isUserCanSeeCfdAccounts || isUserCanSeeSymbolSettings || isUserCanSeeSaving || isUserCanSeeOrdersHistory || isUserCanSeeOrdersHistory) && (
              <div className="menu-padding pb-3">
                <span className="menutitle">
                  Trading
                  {fullWidthMenu && ' Management'}
                </span>
                {isUserCanSeeCfdAccounts && (
                  getNavbarItem(
                    <Link 
                      className={`admin-nav-link ${
                        pathname === '/cfd-accounts' ? 'active' : 'unactive'
                      }`}
                      to="/cfd-accounts"
                    >
                      <FontAwesomeIcon icon={faFileContract} className="me-2" />
                      {fullWidthMenu && 'Cfd Accounts'} 
                    </Link>,
                    'Cfd Accounts',
                  )

                )}
                {isUserCanSeeOpenOrders && (
                  getNavbarItem(
                    <NavLink 
                      to="/open-orders" 
                      className={`admin-nav-link ${pathname === '/open-orders' ? 'active' : 'unactive'}`}
                      isActive={(match, location) => isTabActive(match, location, '/open-orders')}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                      {fullWidthMenu && 'Open Orders'} 
                    </NavLink>,
                    'Open Orders',
                  )
                )}
                {isUserCanSeeOrdersHistory && (
                  getNavbarItem(
                    <NavLink 
                      to="/orders-history" 
                      className={`admin-nav-link ${pathname === '/orders-history' ? 'active' : 'unactive'}`}
                      isActive={(match, location) => isTabActive(match, location, '/orders-history')}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                      {fullWidthMenu && 'Orders History'} 
                    </NavLink>,
                    'Orders History',
                  )
                )}
                {isUserCanSeeSymbolSettings && (
                  getNavbarItem(
                    <NavLink
                      id="symbol-settings"
                      className={`admin-nav-link ${pathname === '/symbol-settings' ? 'active' : 'unactive'}`}
                      to="/symbol-settings"
                      isActive={(match, location) => isTabActive(match, location, '/symbol-settings')}
                      innerRef={tabRef}
                    >
                      <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                      {fullWidthMenu && 'Symbol Settings'} 
                    </NavLink>,
                    'Symbol Settings',
                  )
                )}
                {isUserCanSeeSaving && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      {
                        getNavbarItem(
                          <span className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}>
                            <FontAwesomeIcon icon={faArrowTrendUp} />
                            {fullWidthMenu && <span>Saving</span>} 
                          </span>,
                          'Saving',
                        )
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      show={
                        !!(path === '/saving' || path === '/saving-history')
                      }
                    >
                      {
                        getNavbarItem(
                          <NavLink to="/saving" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faPenToSquare} className="me-2" />
                            {fullWidthMenu && 'Edit Saving'} 
                          </NavLink>,
                          'Edit Saving',
                        )
                      }
                      {
                        getNavbarItem(
                          <NavLink to="/saving-history" className="admin-nav-link sub-menu-padding">
                            <FontAwesomeIcon icon={faClockRotateLeft} className="me-2" />
                            {fullWidthMenu && 'Saving History'} 
                          </NavLink>,
                          'Saving History',
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div> 
            )}
            {(isUserCanSeeAffiliatesDashboard || isUserCanSeeClientsReport || isUserCanSeeCrmUsersReport) && (
              <div className="menu-padding pb-3">
                <span className="menutitle">Reports</span>
                {isUserCanSeeAffiliatesDashboard && (
                  getNavbarItem(
                    <Link
                      className="admin-nav-link"
                      to="/affiliates-dashboard"
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" />
                      {fullWidthMenu && 'Affiliates Dashboard'} 
                    </Link>,
                    'Affiliates Dashboard',
                  )
                )}
                {isUserCanSeeClientsReport && (
                  getNavbarItem(
                    <Link
                      className={`admin-nav-link ${
                        pathname === '/client-activity' ? 'active' : 'unactive'
                      }`}
                      to="/client-activity"
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" />
                      {fullWidthMenu && 'Activity (Clients)'} 
                    </Link>,
                    'Activity (Clients)',
                  )
                )}
                {isUserCanSeeCrmUsersReport && (
                  getNavbarItem(
                    <Link
                      className={`admin-nav-link ${
                        pathname === '/crm-activity' ? 'active' : 'unactive'
                      }`}
                      to="/crm-activity"
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" />
                      {fullWidthMenu && 'Activity (CRM Users)'} 
                    </Link>,
                    'Activity (CRM Users)',
                  )
                )}
              </div>
            )}
            <div className="menu-padding pb-3">
              <span className="menutitle">
                {fullWidthMenu && 'General '}
                {' '}
                Settings
              </span>
              {
                getNavbarItem(
                  <Link
                    className={`admin-nav-link ${
                      pathname === '/notifications' ? 'active' : 'unactive'
                    }`}
                    to="/notifications"
                  >
                    <FontAwesomeIcon icon={faBell} className="me-2" />
                    {fullWidthMenu && 'Notifications'} 
                  </Link>,
                  'Notifications',
                )
              }
              {isUserCanSeeSecurity && (
                getNavbarItem(
                  <NavLink className="admin-nav-link" to="/whitelist-ip">
                    <FontAwesomeIcon icon={faUserLock} className="me-2" />
                    {fullWidthMenu && <span>Security</span>} 
                  </NavLink>,
                  'Security',
                )
              )}              
              {isUserCanSeePsp && (
                <Dropdown className="user-dropdown">
                  <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                    {
                      getNavbarItem(
                        <span className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`}>
                          <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                          {fullWidthMenu && <span>PSP</span>} 
                        </span>,
                        'PSP',
                      )
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    show={
                      !!(path === '/bank-accounts' || path === '/admin-addresses' || path === '/psp-others')
                    }
                  >
                    {isUserCanSeeBankAccount && (
                      getNavbarItem(
                        <NavLink className="admin-nav-link" to="/bank-accounts">
                          <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                          {fullWidthMenu && 'Bank Accounts'} 
                        </NavLink>,
                        'Bank Accounts',  
                      )
                    )}
                    {isUserCanSeeAdminWallets && (
                      getNavbarItem(
                        <NavLink className="admin-nav-link" to="/admin-addresses">
                          <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                          {fullWidthMenu && 'Admin Wallets'} 
                        </NavLink>,
                        'Admin Wallets',
                      )
 
                    )}
                    {isUserCanSeePspOther && (
                      getNavbarItem(
                        <NavLink className="admin-nav-link" to="/psp-others">
                          <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                          {fullWidthMenu && 'PSP Others'} 
                        </NavLink>,
                        'PSP Others',
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {isUserCanSeeWithdrawalMethods && (
                getNavbarItem(
                  <NavLink to="/withdrawal-methods" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="me-2" />
                    {fullWidthMenu && <span>Withdrawal Methods</span>} 
                  </NavLink>,
                  'Withdrawal Methods',
                )
              )}
              {isUserCanSeeWithdrawalMethods && (
                getNavbarItem(
                  <NavLink to="/deposit-methods" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="me-2" />
                    {fullWidthMenu && <span>Deposit Methods</span>} 
                  </NavLink>,
                  'Deposit Methods',
                )
              )}
              {isUserCanSeeStatuses && (
                getNavbarItem(
                  <NavLink className={`admin-nav-link ${fullWidthMenu && 'd-flex'} align-items-center`} to="/statuses">
                    <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
                    {fullWidthMenu && <span>Statuses</span>} 
                  </NavLink>,
                  'Statuses',
                )
              )}
              {isUserCanSeeAffiliates && (
                getNavbarItem(
                  <NavLink to="/affiliate" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                    {fullWidthMenu && <span>Affiliates</span>} 
                  </NavLink>,
                  'Affiliates',
                )
              )}
              {isUserCanSeeEmailTemplates && (
                getNavbarItem(
                  <NavLink to="/email-templates" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    {fullWidthMenu && <span>Email Templates</span>} 
                  </NavLink>,
                  'Email Templates',
                )
              )}
              {isUserCanSeeSmtpPresets && (
                getNavbarItem(
                  <NavLink to="/smtp-presets" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    {fullWidthMenu && <span>SMTP</span>} 
                  </NavLink>,
                  'SMTP',
                )
              )}
              {isUserCanSeeSmsServices && (
                getNavbarItem(
                  <NavLink to="/sms-services" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faCommentSms} className="me-2" />
                    {fullWidthMenu && <span>Sms Services</span>} 
                  </NavLink>,
                  'Sms Services',
                )
              )}
              {isUserCanSeeVoIP && (
                getNavbarItem(
                  <NavLink to="/voip" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faPhoneSquare} className="me-2" />
                    {fullWidthMenu && <span>VoIP</span>} 
                  </NavLink>,
                  'VoIP',
                )
              )}
              {isSuperUser && (
                getNavbarItem(
                  <NavLink to="/datafeed-settings" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faComputer} className="me-2" />
                    {fullWidthMenu && <span>Datafeed Settings</span>} 
                  </NavLink>,
                  'Datafeed Settings',
                )
              )}
              {isUserCanSeeVariables && (
                getNavbarItem(
                  <NavLink to="/variables" className="admin-nav-link sub-menu-padding">
                    <FontAwesomeIcon icon={faPen} className="me-2" />
                    {fullWidthMenu && <span>Variables</span>} 
                  </NavLink>,
                  'Variables',
                )
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
export default NavigationMenu;
