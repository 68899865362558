export const ADD_ROLE = 'ADD_ROLE';
export const DISPLAY_ROLES = 'DISPLAY_ROLES';
export const DISPLAY_ADMIN_ROLES = 'DISPLAY_ADMIN_ROLES';
export const DISPLAY_CLIENT_ROLES = 'DISPLAY_CLIENT_ROLES';
export const GET_ROLE = 'GET_ROLE';
export const GET_PER_ROLE = 'GET_PER_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const TOGGLE_ROLE_STATE = 'TOGGLE_ROLE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
