import {
  CREATE_SPREAD_SETTING, GET_SPREAD_SETTINGS_BY_USER_ID, DELETE_SPREAD_SETTING, UPDATE_SPREAD_SETTING,
} from './userSpreadTypes';
  
// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_SPREAD_SETTINGS_BY_USER_ID:
      return action.payload;
    case DELETE_SPREAD_SETTING:
      return state.filter((item) => item._id !== action.payload);
    case CREATE_SPREAD_SETTING:
      return [...state, action.payload];
    case UPDATE_SPREAD_SETTING:
      return state.map((item) => (item._id === action.payload._id ? action.payload : item));
    default:
      return state;
  }
};
  
