/* eslint-disable default-param-last */
import {
  SET_EMAIL_TEMPLATES,
  SET_EMAIL_TEMPLATE,
  SET_TOTAL_COUNT,
  SET_EVENT_SEARCH_FILTER,
  SET_TITLE_SEARCH_FILTER,
  SET_STATUS_SEARCH_FILTER,
  ADD_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATES,
  DELETE_EMAIL_TEMPLATE,
  SET_IS_SUPER_USER,
  SET_IS_TEMPLATES_LOADING,
  SET_IS_TEMPLATE_LOADING,
} from './emailTemplatesTypes';

const initialState = {
  emailTemplates: [],
  emailTemplate: {},
  totalCount: 0,
  eventSearchFilter: null,
  titleSearchFilter: null,
  statusSearchFilter: 'all',
  isSuperUser: false,
  isTemplatesLoading: false,
  isTemplateLoading: false,
};

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_TEMPLATES_LOADING:
      return {
        ...state,
        isTemplatesLoading: action.payload,
      };
    case SET_IS_TEMPLATE_LOADING:
      return {
        ...state,
        isTemplateLoading: action.payload,
      };
    case SET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
      };
    case EDIT_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: state.emailTemplates.map((item) => (item._id === action.payload?._id ? action.payload : item)),
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_EVENT_SEARCH_FILTER:
      return {
        ...state,
        eventSearchFilter: action.payload,
      };
    case SET_TITLE_SEARCH_FILTER:
      return {
        ...state,
        titleSearchFilter: action.payload,
      };
    case SET_STATUS_SEARCH_FILTER:
      return {
        ...state,
        statusSearchFilter: action.payload,
      };
    case SET_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplate: action.payload,
      };
    case ADD_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: [...state.emailTemplates, action.payload],
      };
    case DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: state.emailTemplates.filter((item) => item._id !== action.payload),
        totalCount: state.totalCount - 1,
      };
    case SET_IS_SUPER_USER: 
      return {
        ...state,
        isSuperUser: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default emailTemplatesReducer;
