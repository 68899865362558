import React from 'react';

function ErrorPage() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Access Denied</h3>
      </div>
    </div>
  );
}

export default ErrorPage;
