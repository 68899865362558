import React, { useEffect, useState } from 'react';
import {
  Modal, Button, Row, Col,
} from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import EditorComponent from '../../../EmailTemplates/EditorComponent/EditorComponent';
import PreviewComponent from './PreviewComponent/PreviewComponent';
import { selectSmtpSelectedUserPreset } from '../../../../redux/smtpPresets/smtpPresetsSelectors';
import { selectEmailTemplate, selectEmailTemplates } from '../../../../redux/emailTemplates/emailTemplatesSelectors';
import { getEmailTemplates, setEmailTemplateCreator } from '../../../../redux/emailTemplates/emailTemplatesActions';
import { sendEmail } from '../../../../redux/crmUser/crmUserActions';
import s from './SendSmtpEmailModal.module.css';

function SendSmtpEmailModal({ userId, handleClose }) {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState('Stage Support Team');
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);

  const emailTemplates = useSelector(selectEmailTemplates);
  const emailTemplate = useSelector(selectEmailTemplate);
  const selectedUserPreset = useSelector(selectSmtpSelectedUserPreset);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);
  const isEmailError = useSelector((state) => state.crmUser.isEmailError);

  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState('');
  const [mailJsonBodyStringified, setMailJsonBodyS] = useState('{"blocks":[{"text":""}],"entityMap":{}}');

  const sanitizedHTML = DOMPurify.sanitize(mailHtmlBodyStringified);
  const HTMLWithEncodedBtn = sanitizedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const HTMLWithProperClass = HTMLWithEncodedBtn.replace(/class="button"/g, `class="${s.button}"`);

  const handlePreview = () => setShowEmailPreview((isShow) => !isShow);

  const coolDownTimeMs = 15000;

  const customButtonTitles = [
    'firstName',
    'lastName',
    'fullName',
    'email', 
    'phone',
    'country',
    'companyName',
    'siteUrl',
  ];

  useEffect(() => {
    // fetch all active templates
    if (emailTemplates.length === 0) {
      dispatch(getEmailTemplates(1, 10000, { status: 'active' }));
    } else {
      // get manual template
      const manualSendTemplate = emailTemplates.find((template) => template.event === 'manualSend');
      
      if (manualSendTemplate) {
        dispatch(setEmailTemplateCreator(manualSendTemplate));
      }
    }
  }, [emailTemplates]);

  useEffect(() => {
    let timer;
    if (isCooldown) {
      if (isEmailError) {
        setIsCooldown(false);
      } else {
        timer = setTimeout(() => {
          setIsCooldown(false);
        }, coolDownTimeMs);
      }
    }
  
    return () => clearTimeout(timer);
  }, [isCooldown, isEmailError]);

  const handleSubjectChange = (event) => setSubject(event.target.value);

  const handleSubmitEmail = () => {
    const crmUserId = JSON.parse(localStorage.getItem('userId'));
    const emailBody = mailHtmlBodyStringified;
    setIsCooldown(true);

    dispatch(sendEmail({
      crmUserId,
      userId,
      subject,
      emailBody,
      selectedUserPreset,
    }, `${crmUser.firstName} ${crmUser.lastName}`));
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className="form-group me-2">
          <label className="control-label mb-2">Subject</label>
          <div className="input-wrapper">
            <input type="text" className="form-control" name="addAmountInfo" value={subject} onChange={handleSubjectChange} />
          </div>
        </div>
        <div className="form-group me-2">
          <label className="control-label mb-2">Email Body</label>
          <div className="input-wrapper">
            <EditorComponent
              template={emailTemplate}
              setMailHtmlBodyS={setMailHtmlBodyS}
              setMailJsonBodyS={setMailJsonBodyS}
              customButtonTitles={customButtonTitles}
            />
          </div>
        </div>
        {
          showEmailPreview && (
            <div className="form-group me-2">
              <label className="control-label mb-2">Preview</label>
              <div className="input-wrapper">
                <PreviewComponent HTMLWithProperClass={HTMLWithProperClass} />
              </div>
            </div>
          )
        }
      </div>
      <div className={s.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" onClick={handlePreview}>
          Preview
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitEmail} disabled={isCooldown}>
          Send Email
        </Button>
      </div>
    </>
  );
}

export default SendSmtpEmailModal;
