import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { editSaving, getSaving } from '../../redux/saving/savingActions';

function EditSaving() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const saving = useSelector((state) => state.saving.saving);

  useEffect(async () => {
    setLoader(true);
    await dispatch(getSaving(id));
    setLoader(false);
  }, [id]);

  const setNewValueToOptions = (formData = new FormData()) => {
    const options = saving.options.map((option) => {
      const optionValue = formData[option._id];
      return { ...option, percent: optionValue ? Number(optionValue) : option.percent };
    });
    
    return options;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    setLoader(true);

    const data = {
      options: setNewValueToOptions(formValues),
      minDeposit: formValues.minDeposit || saving.minDeposit,
    };

    await dispatch(editSaving(id, data));
    setLoader(false);

    history.goBack();
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <form onSubmit={handleSubmit}>
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={history.goBack} />
          <h3>{`Edit Settings For ${saving?.currency ? `${saving?.currency?.name} ${saving?.currency?.symbol}` : ''} Saving`}</h3>
          <div className="form-group col-md-12">
            <label className="control-label">Min Deposit</label>
            <input
              type="number"
              className="form-control"
              name="minDeposit"
              defaultValue={saving?.minDeposit}
            />
          </div>
          {saving.options?.map((option) => (
            <div className="form-group col-md-12 pt-2" key={option._id}>
              <label className="control-label">{option.label}</label>
              <input
                type="number"
                className="form-control"
                name={option._id}
                defaultValue={option.percent}
              />
            </div>
          ))}
          <div>
            <button className="btn btn-default" type="submit">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditSaving;
