import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../components/NotFound';
import routes from './routes';
import AuthLayout from '../layout/AuthLayout';
import PrivateLayout from '../layout/PrivateLayout';
import AffiliateLayout from '../layout/AffiliateLayout';
import AffiliateAuthLayout from '../layout/AffiliateAuthLayout';
import ErrorPage from '../pages/ErrorPage/ErrorPage';

function AuthLayoutRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  );
}

function PrivateLayoutRoute({
  component: Component, path, permissionName, ...rest 
}) {
  const permissions = useSelector((state) => state.crmUser?.currentUserPermissions);
  const pagePermission = permissions && permissions.includes(permissionName);

  if (permissionName !== undefined) {
    if (!permissions.length) {
      return (
        <Route
          {...rest}
          render={() => (
            <PrivateLayout />
          )}
        />
      );
    }

    if (!pagePermission) {
      return (
        <Route
          {...rest}
          render={() => (
            <PrivateLayout>
              <ErrorPage />
            </PrivateLayout>
          )}
        />
      );
    } 

    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <PrivateLayout>
            <Component {...matchProps} />
          </PrivateLayout>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <PrivateLayout>
          <Component {...matchProps} />
        </PrivateLayout>
      )}
    />
  );
}

function AffiliateAuthLayoutRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AffiliateAuthLayout>
          <Component {...matchProps} />
        </AffiliateAuthLayout>
      )}
    />
  );
}

function AffiliateLayoutRoute({ component: Component, path, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AffiliateLayout>
          <Component {...matchProps} />
        </AffiliateLayout>
      )}
    />
  );
}

function MainRoutes() {
  return (
    <Router>
      <Switch>
        {routes.filter((route) => route.layout === 'AuthLayoutRoute').map((route, index = 0) => (
          <AuthLayoutRoute exact={route.exact} path={route.path} component={route.component} key={index} />
        ))}
        {routes.filter((route) => route.layout === 'PrivateLayoutRoute').map((route, index = 0) => (
          <PrivateLayoutRoute exact={route.exact} path={route.path} component={route.component} key={index} permissionName={route.permissionName} />
        ))}
        {routes.filter((route) => route.layout === 'AffiliateAuthLayoutRoute').map((route, index = 0) => (
          <AffiliateAuthLayoutRoute exact={route.exact} path={route.path} component={route.component} key={index} />
        ))}
        {routes.filter((route) => route.layout === 'AffiliateLayoutRoute').map((route, index = 0) => (
          <AffiliateLayoutRoute exact={route.exact} path={route.path} component={route.component} key={index} />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>

    </Router>
  );
};

export default MainRoutes;
