import { apiHelper } from '../apiHelper';
import {
  ADD_COUNTRY, REMOVE_COUNTRY, SET_COUNTRIES, UPDATE_COUNTRY, 
} from './countiesTypes';

// Action Creators
export const addCountry = (country) => ({
  type: ADD_COUNTRY,
  payload: country,
});

export const updateCountry = (id, updates) => ({
  type: UPDATE_COUNTRY,
  payload: { id, updates },
});

export const removeCountry = (id) => ({
  type: REMOVE_COUNTRY,
  payload: id,
});

export const setCountries = (countries) => ({
  type: SET_COUNTRIES,
  payload: countries,
});


export const fetchCounties = () => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/country', '');

    if (response.data) {
      const countries = response.data.countries ?? [];
      dispatch(setCountries(countries));
    }
  } catch (error) {
    console.log('Error happened while fetching countries: ', error);
  }
};
