export const GET_ADMIN_BANK_ACCOUNTS = 'GET_ADMIN_BANK_ACCOUNTS';
export const GET_ADMIN_BANK_ACCOUNT = 'GET_ADMIN_BANK_ACCOUNT';
export const ADD_ADMIN_BANK_ACCOUNT = 'ADD_ADMIN_BANK_ACCOUNT';
export const EDIT_ADMIN_BANK_ACCOUNT = 'EDIT_ADMIN_BANK_ACCOUNT';
export const DELETE_ADMIN_BANK_ACCOUNT = 'DELETE_ADMIN_BANK_ACCOUNT';
export const SET_DEFAULT_ADMIN_BANK_ACCOUNT = 'SET_DEFAULT_ADMIN_BANK_ACCOUNT';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const TOGGLE_STATE = 'TOGGLE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
