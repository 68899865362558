import {
  GET_OTHER_PSP, UPDATE_OTHER_PSP, DELETE_OTHER_PSP, ADD_OTHER_PSP, 
} from './pspOthersTypes';

const othersPspReducer = (state = [], action) => {
  switch (action.type) {
    case GET_OTHER_PSP:
      return action.payload;

    case ADD_OTHER_PSP:
      return [...state, action.payload];

    case UPDATE_OTHER_PSP:
      return state.map((item) => {
        if (item._id === action.payload._id) {
          return { ...action.payload };
        } 
        return item;
      });

    case DELETE_OTHER_PSP: 
      return state.filter((item) => item._id !== action.payload);

    default:
      return state;
  }
};

export default othersPspReducer;
