import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Row, Col, Button,
} from 'react-bootstrap';
import { showAllCurrencies } from '../../../../redux/currency/currencyActions';
import { createSavingAccount } from '../../../../redux/cfdAccount/cfdAccountActions';

function CreateSavingAccountModal({ userId, closeModal }) {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const currencies = useSelector((state) => state.currency.currencies.allCurrencies);
  const isBtnDisabled = useMemo(() => (!selectedCurrency || !selectedCurrency._id), [selectedCurrency]);

  useEffect(() => {
    dispatch(showAllCurrencies());
  }, []);

  const submit = () => {
    const data = {
      userId,
      currencyId: selectedCurrency._id,
    };

    dispatch(createSavingAccount(data));
    closeModal();
  };

  return (
    <>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Currency</span>
            </label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{selectedCurrency ? selectedCurrency.symbol : 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {currencies && currencies.map((currency) => (
                  <Dropdown.Item key={currency._id} onClick={() => setSelectedCurrency(currency)}>{currency.symbol}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </>

  );
}

export default CreateSavingAccountModal;
