import React from 'react';
import styles from './styles.module.css';

export function CalendarHeader() {
  const dayNames = ['Mon', 'Tue', 'Wed', ' Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <div className={styles.calendarHeader}>
      {dayNames.map((day, key = 0) => <span key={key} className={styles.calendarHeaderCell}>{day}</span>)}
    </div>
  );
}
