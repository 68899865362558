import { toast } from 'react-toastify';
import { GET_MONTHLY_INACTIVITY_FEE_BY_ID } from './monthlyInactivityFeeTypes';
import { apiHelper } from '../apiHelper';


export const getMonthlyInactivityFee = ({ id, page, limit }) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/monthly-inactivity-fee/${id}?limit=${limit}&page=${page}`);
    if (res?.data) {
      const { data } = res;

      dispatch({
        type: GET_MONTHLY_INACTIVITY_FEE_BY_ID,
        payload: data.monthlyInactivityFee,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};
