import {
  GET_CLIENT_ACTIVITY_TYPES,
  GET_CLIENT_ACTIVITY_TYPE,
  ADD_CLIENT_ACTIVITY_TYPE,
  EDIT_CLIENT_ACTIVITY_TYPE,
  DELETE_CLIENT_ACTIVITY_TYPE,
  TOGGLE_CLIENT_ACTIVITY_TYPE_STATE,
  ERROR_CLIENT_ACTIVITY_TYPE_STATE,
} from './activityClientTypeTypes';

const initialState = {
  activityClientTypes: [],
  activityClientType: {},
  activityClientTypesFetched: false,
  activityClientTypeAdded: false,
  activityClientTypeFetched: false,
  activityClientTypeEditted: false,
  activityClientTypeDeleted: false,
  activityClientTypeError: false,
};

// eslint-disable-next-line default-param-last
const activityTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_ACTIVITY_TYPES:
      return {
        ...state,
        activityClientTypes: action.payload,
        activityClientTypesFetched: true,
      };
    case GET_CLIENT_ACTIVITY_TYPE:
      return {
        ...state,
        activityClientType: action.payload,
        activityClientTypeFetched: true,
      };
    case ADD_CLIENT_ACTIVITY_TYPE:
      return {
        ...state,
        activityClientTypes: [state.activityClientTypes, action.payload],
        activityClientTypeAdded: true,
      };
    case EDIT_CLIENT_ACTIVITY_TYPE:
      return {
        ...state,
        activityClientType: action.payload,
        activityClientTypeEditted: true,
      };
    case DELETE_CLIENT_ACTIVITY_TYPE:
      return {
        ...state,
        activityClientTypes: state.activityClientTypes.filter((item) => item._id !== action.payload),
        activityClientTypeDeleted: true,
      };
    case TOGGLE_CLIENT_ACTIVITY_TYPE_STATE:
      return {
        ...state,
        activityClientTypesFetched: false,
        activityClientTypeAdded: false,
        activityClientTypeFetched: false,
        activityClientTypeEditted: false,
        activityClientTypeDeleted: false,
        error: false,
      };
    case ERROR_CLIENT_ACTIVITY_TYPE_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default activityTypeReducer;
