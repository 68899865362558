import React, {
  useState, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  Form, Modal, Button, Row, Col, 
} from 'react-bootstrap';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  faSpinner, faRefresh, faTimes, faPlus, 
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { DepositColumnsSchema } from '../../../DataTableSchemas/DepositSchema';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import {
  addCurrencyAmountToUserAccount, resolveDepositTransaction, revertTransaction, getUserDetails, updateIsReal, deleteTransaction, 
} from '../../../redux/users/userActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { getAccountOptionsByUserId } from '../../../redux/cfdAccount/cfdAccountActions';
import { userDepositColumns } from '../../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters } from '../../../components';

function Deposit() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [, setLoader] = useState(false);
  const [, setUser] = useState('');
  // const [userDeposits, setUserDeposits] = useState([]);
  const [show, setShow] = useState(false);
  const [addAmountInfo, setAddAmountInfo] = useState('');
  const [addAmountInfoErr] = useState('');
  const [isReal, setIsReal] = useState('true');
  const [isRealErr, setIsRealErr] = useState('');
  const [isResolved, setIsResolved] = useState(1);
  const [isResolvedErr, setIsResolvedErr] = useState('');
  const [balanceType, setBalanceType] = useState(0);
  const [balanceTypeErr, setBalanceTypeErr] = useState('');
  const [accountErr, setAccountErr] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [currencyAccountSelectedErr, setCurrencyAccountSelectedErr] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');

  const [amountRange, setAmountRange] = useState([0, 1000000]);
  const [currencyFilter, setCurrencyFilter] = useState([]);
  const [statusesFilter, setStatusesFilter] = useState([]);
  const [depositTypeFilter, setDepositTypeFilter] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [depositCurrency, setDepositCurrency] = useState('');

  const columnsStorageName = 'DataTable_user/deposit_columns';
  const filtersStorageName = 'DataTable_user/deposit_filters';
  const filterIdStorageName = 'DataTable_user/deposit_filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(userDepositColumns);
  const [filters, setFilters] = useState();

  const datatableFiltersRef = useRef(null);

  const userData = useSelector((state) => state?.users?.user);
  const userDeposits = useSelector((state) => state.users?.user.deposits) || [];
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const currencyRates = useSelector((state) => state.currencyRates);
  const accountOptions = useSelector((state) => state.cfdAccounts.options);

  const handleShow = () => setShow(true);


  useEffect(() => {
    if (currencies && currencies.length) {
      const currency = currencies.find((currency) => currency.symbol === 'USD');

      setDepositCurrency({ value: currency._id, label: `${currency.name}  (${currency.symbol})` });
    }
  }, [currencies]);

  useEffect(() => {
    const usdAccount = accountOptions.find((account) => account.label.includes('USD'));

    if (usdAccount) {
      setSelectedAccount(usdAccount.value);

      return;
    } 

    setSelectedAccount(accountOptions[0]?.value);
  }, [accountOptions]);

  const {
    register: register1, handleSubmit: handleSubmit1, control: control1, reset: reset1, formState: { errors: errors1 },
  } = useForm();

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setAmountRange(filters.amountRange || [0, 1000000]);
      setCurrencyFilter(filters.currency || []);
      setStatusesFilter(filters.status || []);
      setDepositTypeFilter(filters.depositType || []);
    }
  };

  const handleClear = () => {
    setStatusesFilter([]);
    setAmountRange([0, 1000000]);
    setCurrencyFilter([]);
    setDepositTypeFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const setCRMFilters = (filter) => {
    const {
      amountRange, currency, status, depositType, 
    } = filter;

    setAmountRange(amountRange);
    setCurrencyFilter(currency);
    setStatusesFilter(status);
    setDepositTypeFilter(depositType);
    setFilters({
      amountRange,
      currency,
      status,
      depositType,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/deposit`,
      currency: filters.currency || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      depositType: filters.depositType || [],
      status: filters.status || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };
  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        depositType: filters.depositType || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
        currency: filters.currency || [],
        status: filters.status || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const search = () => {
    const filteredResults = userDeposits.filter(
      (item) => (!statusesFilter.length || statusesFilter.includes(String(item.isResolved)))
        && (!currencyFilter.length || currencyFilter.includes(item.currency))
        && (!depositTypeFilter.length || depositTypeFilter.includes(item.isReal)),
      // && (Number(item.amount) >= amountRange[0] && Number(item.amount) <= amountRange[1]),
    );

    setFilteredResult(filteredResults);
  };

  useEffect(() => {
    if (userDeposits.length) search();
    if (filters) localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  }, [statusesFilter, currencyFilter, amountRange, depositTypeFilter, userDeposits]);

  const handleClose = () => {
    setShow(false);
    setAddAmountInfo('');
    setIsRealErr('');
    setIsResolvedErr('');
    setBalanceTypeErr('');
    setCurrencyAccountSelectedErr('');
    setIsReal('true');
    setIsResolved(1);
    setBalanceType(0);
    setAccountErr('');
    reset1();
    setApiCall(false);
  };

  const handleCurrencyAmountSave = async (formData) => {
    let errorsOccured = false;

    if (!depositCurrency.value) {
      setCurrencyAccountSelectedErr('Select currency of transaction');
      errorsOccured = true;
    }

    if (balanceType === 3) {
      setBalanceTypeErr('Select type of transaction');
      errorsOccured = true;
    }

    if (isResolved === 3) {
      setIsResolvedErr('Select status of transaction');
      errorsOccured = true;
    }

    if (isReal === null) {
      setIsRealErr('Select if transaction is real or fake');
      errorsOccured = true;
    }

    if (!selectedAccount || !selectedAccount.length) {
      setAccountErr('Select account');
      errorsOccured = true;
    }

    if (!errorsOccured) {
      setApiCall(true);
      const data = {
        userId: id,
        currencyId: depositCurrency.value,
        amount: formData.amountForCurrency,
        additionalInfo: addAmountInfo,
        isReal,
        isResolved,
        selectedAccount,
        balanceType: Number(balanceType) === 0,
        addedBy: localStorage.getItem('userId').slice(1, -1),
      };
      await dispatch(addCurrencyAmountToUserAccount(data));

      setAddAmountInfo('');
      handleClose();
    }
  };

  const addCurrencyAmount = {
    currencyAccountSelected: {
      required: 'Please select currency',
    },
    amountForCurrency: {
      required: 'Amount is required',
      pattern: {
        value: /^-?[0-9]\d*(\.\d+)?$/,
        message: 'Only numbers and decimals are allowed',
      },
    },
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const getRateInUsdt = (coinSymbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const totalInUsdt = parseFloat(parseFloat((1 / currencyRates[coinSymbol === 'USDT' ? 'USD' : coinSymbol])) * parseFloat(amount));

      if (!Number.isNaN(totalInUsdt)) {
        return totalInUsdt;
      }

      return null;
    }

    return '-';
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  useEffect(async () => {
    setStoredColumnsData();
    setStoredFilterData();

    Promise.allSettled([
      dispatch(getCurrencyRates('USD')),
      dispatch(showAllCurrencies()),
    ]);
  }, []);

  const getDeposits = async () => {
    setLoader(true);
    await dispatch(getUserDetails(id));
    setLoader(false);
  };

  useEffect(() => {
    if (!userData._id) return;

    setUser(userData);
    dispatch(getAccountOptionsByUserId(userData._id));
  }, [userData]);

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId, crmUserId: loginUserId, resolvedStatus: status, comment: result.value ? result.value : '',
        };
        await dispatch(resolveDepositTransaction(rowId, data));
      }
    });
  };

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(deleteTransaction(id));
      });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(revertTransaction(rowId, { userId }));
      }
    });
  };
  const depositColumns = DepositColumnsSchema(
    formatDate, 
    getRateInUsdt, 
    copyReferral, 
    resolveCurrentDepositTransaction, 
    handleRevertTransaction,
    statusesFilter,
    setStatusesFilter,
    amountRange,
    setAmountRange,
    currencyFilter,
    setCurrencyFilter,
    depositTypeFilter,
    setDepositTypeFilter,
    currencies,
    columnConfig,
    filters,
    setFilters,
    permissionName,
    updateIsReal,
    handleDeleteTransaction,
  );

  const handleDepositCurrencyChange = (depositCurrencyChange) => {
    setDepositCurrency(depositCurrencyChange);
  };

  const colourStyles = {
    control: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      background: isDisabled ? '#aaa' : '#374057',
      color: isDisabled ? '#aaa' : 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: isDisabled ? '#aaa' : '#16202e',
      },
    }),
  };

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {permissionName && permissionName.length > 0 && permissionName.includes('deposit_manual')
              ? (
                <>
                  <button type="button" className="btn-primary_light" onClick={handleShow}>
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    Add New
                  </button>
                  <Modal show={show} onHide={handleClose} className="widthdrawal-modal">
                    <Modal.Header closeButton>
                      <Modal.Title className="text-white">ADD DEPOSIT</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit1(handleCurrencyAmountSave)} className="account-balance-currency me-0">
                      <Modal.Body>
                        <div className="withdrawal-data">
                          <Row>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Amount</label>
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter amount"
                                    {...register1('amountForCurrency', addCurrencyAmount?.amountForCurrency)}
                                    name="amountForCurrency"
                                    defaultValue=""
                                    control={control1}
                                  />
                                  {errors1?.amountForCurrency && <span className="errMsg">{errors1?.amountForCurrency?.message}</span>}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Currency</label>
                                <div className="input-wrapper">
                                  <Select
                                    styles={colourStyles}
                                    name="currencyAccountSelected"
                                    value={depositCurrency}
                                    options={currencies && currencies.length > 0 && currencies.map((currency) => ({
                                      value: currency._id,
                                      label: `${currency?.name}  (${currency?.symbol})`,
                                    }))}
                                    isSearchable
                                    onChange={handleDepositCurrencyChange}
                                  />
                                  {currencyAccountSelectedErr ? (<span className="errMsg">{currencyAccountSelectedErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Account</label>
                                <div className="input-wrapper">
                                  <Form.Select name="account" value={selectedAccount} onChange={({ target: { value } }) => setSelectedAccount(value)}>
                                    <option value="">Select Account</option>
                                    { 
                                  accountOptions.map(({ value, label }) => (
                                    <option key={value} value={value}>{label}</option>
                                  ))
                                }
                                  </Form.Select>
                                  {accountErr ? (<span className="errMsg">{accountErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Type</label>
                                <div className="input-wrapper">
                                  <Form.Select name="balanceType" value={balanceType} onChange={(event) => setBalanceType(event.target.value)}>
                                    <option value="3">Select Type</option>
                                    <option value="0">Balance</option>
                                    <option value="1">Credit</option>
                                  </Form.Select>
                                  {balanceTypeErr ? (<span className="errMsg">{balanceTypeErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Status</label>
                                <div className="input-wrapper">
                                  <Form.Select name="isResolved" value={isResolved} onChange={(event) => setIsResolved(event.target.value)}>
                                    <option value="3">Select Type</option>
                                    <option value="0">Pending</option>
                                    <option value="1">Approved</option>
                                    <option value="2">Declined</option>
                                  </Form.Select>
                                  {isResolvedErr ? (<span className="errMsg">{isResolvedErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Real</label>
                                <div className="input-wrapper">
                                  <Form.Select name="isReal" value={isReal} onChange={(event) => setIsReal(event.target.value)}>
                                    <option value="3">Select Transactions </option>
                                    <option value="true">Real</option>
                                    <option value="false">Fake</option>
                                  </Form.Select>
                                  {isRealErr ? (<span className="errMsg">{isRealErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group me-2">
                                <label className="control-label mb-2">Info</label>
                                <div className="input-wrapper">
                                  <input type="text" className="form-control" name="addAmountInfo" value={addAmountInfo} onChange={(event) => setAddAmountInfo(event.target.value)} />
                                  {addAmountInfoErr ? (<span className="errMsg">{addAmountInfoErr}</span>) : ('')}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        {apiCall
                          ? (
                            <Button variant="primary" style={{ padding: '8px 40px' }}>
                              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                            </Button>
                          )
                          : (
                            <Button variant="primary" type="submit">
                              Save Changes
                            </Button>
                          )}

                      </Modal.Footer>
                    </form>
                  </Modal>
                </>
              )
              : null}
          </div>
          <div className="secondary_btn-row">
            {
          crmFilters ? (
            <DatatableFilters 
              ref={datatableFiltersRef}
              filters={crmFilters} 
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={`${pathname}/deposit`}
            /> 
          ) : null
        }
            <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
              <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button type="button" className="btn-secondary_dark iconed" onClick={getDeposits}>
              <RefreshIcon sx={{ fontSize: '20px' }} />
            </button>
          </div>
          <div className="secondary_btn-row">
            <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          </div>
        </div>
      </div>

      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        {userDeposits && userDeposits.length
          ? (
            <DataTable
              columns={depositColumns}
              data={filteredResult || []}
              pagination
              paginationResetDefaultPage={false}
              fixedHeader
              persistTableHead
              theme="solarizedd"
            />
          )
          : (
            <div className="no-tbl-data">No Transactions Found!</div>
          )}
      </div>
    </div>
  );
}

export default Deposit;
