import { toast } from 'react-toastify';
import { GET_CALL_HISTORY, GET_MORE_CALL_HISTORY } from './userCallHistoryTypes';
import { apiHelper } from '../apiHelper';

const setCallHistoryCreator = (response) => ({
  type: GET_CALL_HISTORY,
  payload: response,
});

const addCallHistoryCreator = (response) => ({
  type: GET_MORE_CALL_HISTORY,
  payload: response,
});


export const getUserCallHistory = (userId, page) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/call-history/${userId}?page=${page}`);

    if (res && res.data && res.data.success) {
      const { history, totalCount } = res.data;

      dispatch(setCallHistoryCreator({ history, totalCount }));
    } else {
      dispatch(setCallHistoryCreator({ history: [], totalCount: 0 }));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getMoreUserCallHistory = (userId, page) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/call-history/${userId}?page=${page}`);

    if (res && res.data && res.data.success) {
      const { result } = res.data;

      dispatch(addCallHistoryCreator(result));
    } else {
      dispatch(addCallHistoryCreator([]));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

