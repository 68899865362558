/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { addAdminAddress, updateState } from '../../redux/adminAddress/adminAddressActions';
import { getCryptoCurrency } from '../../redux/currency/currencyActions';

function AddAdminAddress() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selected, setSelected] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [currencyErr, setCurrError] = useState('');
  const [loader, setLoader] = useState(false);

  const adminAddressAdded = useSelector((state) => state?.adminAddress?.adminAddressAdded);
  const error = useSelector((state) => state?.adminAddress?.error);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);

  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (currencies) {
      const currencyData = currencies.find((currency) => currency._id === selected.value);
      setNetworks(currencyData?.networks ?? []);
      setSelectedNetwork(null);
    }
  }, [selected]);

  useEffect(async () => {
    setLoader(true);
    await dispatch(getCryptoCurrency());
    setLoader(false);
  }, []);

  const colorStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  const options = useMemo(() => {
    if (!currencies) return [];

    return currencies.map((currency) => ({
      key: currency?._id,
      value: currency?._id,
      label: `${currency?.symbol} / ${currency?.name}`,
    }));
  }, [currencies]);

  const networkOptions = useMemo(() => {
    if (!networks) return [];

    return networks.map((network) => ({
      key: network,
      value: network,
      label: network,
    }));
  }, [networks]);

  const adminAddressData = {
    address: {
      required: 'Address is required',
    },
    currencyId: {
      required: 'Please select currency',
    },
  };

  const handleSave = (formData) => {
    if (!formData || !formData.address) return;
    if (!selected || !selected.value) {
      setCurrError(adminAddressData.currencyId.required);

      return;
    }

    const data = {
      address: formData.address,
      currencyId: selected.value,
    };

    if (selectedNetwork !== null) {
      data.network = selectedNetwork;
    }

    dispatch(addAdminAddress(data));
  };

  const selectCurrency = (option) => {
    setCurrError('');
    setSelected(option);
  };

  useEffect(() => {
    if (adminAddressAdded) {
      dispatch(updateState());
      history.goBack();
    }
  }, [adminAddressAdded]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateState());
    }
  }, [error]);

  const isSaveBtnAvailable = networks.length === 0 ? false : selectedNetwork === null;

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        <h5>Add Address</h5>
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="form-group col-md-12">
            <label className="control-label">Wallet Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Wallet Address"
              {...register('address', adminAddressData.address)}
              address="address"
              defaultValue=""
              control={control}
            />
            {errors?.address && <span className="errMsg">{errors.address.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">Select Currency</label>
            <Select 
              styles={colorStyles}
              name="options" 
              options={options} 
              onChange={(option) => selectCurrency(option)}
            />
            {currencyErr ? (<span className="errMsg">{currencyErr}</span>) : ('')}
          </div>
          {
                networkOptions.length > 0 && (
                  <div className="form-group col-md-12 pt-2">
                    <label className="control-label">Select Network</label>
                    <Select 
                      styles={colorStyles}
                      name="options" 
                      options={networkOptions} 
                      onChange={(option) => setSelectedNetwork(option.key)}
                    />
                  </div>
                )
              }
          <div>
            <button disabled={isSaveBtnAvailable} className="btn btn-default" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAdminAddress;
