import React from 'react';
import { OpenOrdersTable } from '../../components/OpenOrdersTable/OpenOrdersTable';

function OpenOrders() {
  const filtersStorageName = 'DataTable_orders/open-orders_filters';
  const filterIdStorageName = 'DataTable_orders/open-orders_filter_id'; 
  const columnsStorageName = 'DataTable_orders/open-orders_columns';
  const paginationStorageName = 'DataTable_users/open-orders_pagination';

  const localStorageConfig = {
    filtersStorageName,
    filterIdStorageName,
    columnsStorageName,
    paginationStorageName,
  };

  return (
    <div className="content-wrapper right-content-wrapper open-orders">
      <div className="content-box">
        <h3>Open Orders</h3>
        <OpenOrdersTable localStorageConfig={localStorageConfig} />
      </div>
    </div>
  );
}

export default OpenOrders;
