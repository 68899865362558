import {
  ADD_PERMISSIONS_MODULE, DISPLAY_PERMISSIONS_MODULES, DISPLAY_MODULES_WITH_PERMISSIONS, DELETE_PERMISSIONS_MODULE, TOGGLE_PERMISSION_MODULE_STATE, ERROR_STATE,
} from './permissionsModuleTypes';

const initialState = {
  permissionsModules: [],
  modulesWithPermissions: [],
  modulesWithPermissionsFetched: false,
  success: false,
  fetched: false,
  error: false,
};

// eslint-disable-next-line default-param-last
const permissionsModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_PERMISSIONS_MODULES:
      return {
        ...state,
        permissionsModules: action.payload.permissionsModules,
        success: false,
        fetched: true,
      };
    case DISPLAY_MODULES_WITH_PERMISSIONS:
      return {
        ...state,
        modulesWithPermissions: action.payload.modulesWithPermissions,
        success: false,
        modulesWithPermissionsFetched: true,
      };
    case ADD_PERMISSIONS_MODULE:
      return {
        ...state,
        permissionsModules: action.payload.permissionsModules,
        fetched: true,
        success: true,
      };
    case DELETE_PERMISSIONS_MODULE:
      return {
        ...state,
        permissionsModules: action.payload.permissionsModules,
        success: true,
        fetched: true,
      };
    case TOGGLE_PERMISSION_MODULE_STATE:
      return {
        ...state,
        fetched: false,
        success: false,
        modulesWithPermissionsFetched: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export default permissionsModuleReducer;
