import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getSalesStatuses, deleteSalesStatus, updateState } from '../../redux/salesStatus/salesStatusActions';
import { AddSalesStatusModalBody, EditSalesStatusModalBody } from './components';
import { ModalContext } from '../../context';

function SalesStatus() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const { showModal } = useContext(ModalContext);

  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const salesStatusesfetched = useSelector((state) => state?.salesStatus?.salesStatusesfetched);
  const salesStatusDeleted = useSelector((state) => state?.salesStatus?.salesStatusDeleted);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('add_sales_status');
  const isUserCanEditSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('edit_sales_status');
  const isUserCanDelteSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('delete_sales_status');

  useEffect(() => {
    async function fetchData() {
      if (salesStatusesfetched || salesStatusDeleted) {
        await dispatch(updateState());
        setLoader(false);
      }
    }
    fetchData();
  }, [salesStatusesfetched, salesStatusDeleted]);

  useEffect(() => {
    setLoader(true);

    async function fetchData() {
      await dispatch(getSalesStatuses());
    }
    fetchData();
  }, []);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        setLoader(true);
        await dispatch(deleteSalesStatus(id));
      }
    });
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Statuses</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddSalesStatuses
                      && (
                      <button 
                        type="button" 
                        className="btn-primary_light"
                        onClick={() => showModal({ 
                          bodyContent: <AddSalesStatusModalBody />, 
                          headerContent: <h3>Add Status</h3>, 
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                        Add New
                      </button>
                      )}
            </div>
          </div>
        </div>
        <div className="mt-3 table-responsive">
          <table className="table">
            <thead className="table_head">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Color</th>
                {(isUserCanEditSalesStatuses || isUserCanDelteSalesStatuses) && <th>Action(s)</th>}
              </tr>
            </thead>
            <tbody>
              {salesStatuses?.length > 0 && salesStatuses?.map((salesStatus) => (
                <tr key={salesStatus._id}>
                  <td>{salesStatus.name}</td>
                  <td>{salesStatus?.type === 2 ? 'Both' : (salesStatus?.type === 1 ? 'Retention' : 'Sales')}</td>
                  <td>
                    <span className="picked-value" style={{ borderLeftColor: salesStatus?.color ? salesStatus?.color : '#aabbcc' }}>
                      {salesStatus?.color ? salesStatus?.color : '#aabbcc'}
                    </span>
                  </td>
                  <td>
                    {isUserCanEditSalesStatuses 
                        && (
                          <button 
                            type="button" 
                            className="btn btn-primary me-2 text-decoration-none text-light"
                            onClick={() => showModal({ 
                              bodyContent: <EditSalesStatusModalBody id={salesStatus._id} />, 
                              headerContent: <h3>Edit Status</h3>, 
                            })}
                          >
                            Edit
                          </button>
                        )}
                    {isUserCanDelteSalesStatuses && <button type="button" className="btn btn-danger me-2" onClick={() => deleteAction(salesStatus._id)}>Delete</button>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SalesStatus;
