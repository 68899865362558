import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { ModalContext } from '../../../context';
import { updateCfdSettings } from '../../../redux/cfdSettings/cfdActions';

export function CfdSettingsModal({ row }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const fieldsThatCanBeNotPositive = ['shift', 'spreadMin', 'spreadMax'];

  const enabledOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const [formData, setFormData] = useState(
    {
      leverage: row.leverage,
      lotSize: row.lotSize,
      lotStep: row.lotStep,
      commission: row.commission,
      swapLong: row.swapLong,
      swapShort: row.swapShort,
      shift: row.shift,
      isFreeze: row.isFreeze,
      spreadMax: row.spreadMax,
      spreadMin: row.spreadMin,
    }, 
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(updateCfdSettings(row._id, formData));
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: !fieldsThatCanBeNotPositive.includes(name) ? value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1') : value.replace(/[^-\d.]/g, '').replace(/^0+(\d)/, '$1'),
    }));    
  };

  const handleRevert = () => {
    setFormData({
      leverage: 100,
      lotSize: 1,
      lotStep: 1,
      commission: 0.2,
      swapLong: 0,
      swapShort: 0,
      shift: 0,
      isFreeze: false,
      spreadMax: 0,
      spreadMin: 0,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <div className="form-group me-2">
        <label htmlFor="leverage">Leverage</label>
        <input
          type="text"
          id="leverage"
          name="leverage"
          className="form-control"
          onChange={handleInputChange}
          value={formData.leverage}
        />
      </div>
      
      <div className="form-group me-2">
        <label htmlFor="lotStep">Lot Step</label>
        <input
          type="text"
          id="lotStep"
          name="lotStep"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotStep}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="lotSize">Lot Size</label>
        <input
          type="text"
          id="lotSize"
          name="lotSize"
          className="form-control"
          onChange={handleInputChange}
          value={formData.lotSize}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="commission">Commission %</label>
        <input
          type="text"
          id="commission"
          name="commission"
          className="form-control"
          onChange={handleInputChange}
          value={formData.commission}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="swapLong">Swap Long %</label>
        <input
          type="text"
          id="swapLong"
          name="swapLong"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapLong}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="swapShort">Swap Short %</label>
        <input
          type="text"
          id="swapShort"
          name="swapShort"
          className="form-control"
          onChange={handleInputChange}
          value={formData.swapShort}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="shift">Shift</label>
        <input
          type="text"
          id="shift"
          name="shift"
          className="form-control"
          onChange={handleInputChange}
          value={formData.shift}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="spreadMin">Spread Min</label>
        <input
          type="text"
          id="spreadMin"
          name="spreadMin"
          className="form-control"
          onChange={handleInputChange}
          value={formData.spreadMin}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="spreadMax">Spread Max</label>
        <input
          type="text"
          id="spreadMax"
          name="spreadMax"
          className="form-control"
          onChange={handleInputChange}
          value={formData.spreadMax}
        />
      </div>

      <div className="form-group me-2">
        <label htmlFor="swapShort">Freeze</label>
        <Dropdown className="t-settings__dropdown">
          <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{formData.isFreeze ? 'Yes' : 'No'}</Dropdown.Toggle>
          <Dropdown.Menu>
            {enabledOptions.map((el) => (
              <Dropdown.Item key={el.label} onClick={() => setFormData((prevState) => ({ ...prevState, isFreeze: el.value }))}>{el.label}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default me-2" type="button" onClick={handleRevert}>
          Revert
        </button>
        <button className="btn btn-default" type="submit">
          Save
        </button>
      </div>
    </form>
  );
}
