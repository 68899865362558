import { GET_TRADING_TYPES } from './tradeTypeTypes';
  
// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_TRADING_TYPES:
      return action.payload;
    default:
      return state;
  }
};
  
