import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { editWhitelistIP, updateState } from '../../../redux/security/whitelistIPActions';
import { ModalContext } from '../../../context';
import { getCRMUsers } from '../../../redux/crmUser/crmUserActions';

export function EditWhitelistIPModal({ whitelistIP }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  
  const [whitelistIPData, setWhitelistIPData] = useState({ 
    name: whitelistIP.name,
    ip: whitelistIP.ip,
    managerId: whitelistIP.managerId, 
  });

  const { crmUsers } = useSelector((state) => state.crmUser);
  
  const {
    register, handleSubmit, reset, control, formState: { errors },
  } = useForm({
    defaultValues: whitelistIPData,
  });
  
  useEffect(() => {
    dispatch(getCRMUsers());
  }, []);
  
  useEffect(() => {
    if (Object.keys(whitelistIP)?.length > 0) {
      setWhitelistIPData(whitelistIP);
      reset(whitelistIP);
      dispatch(updateState());
    }
  }, [whitelistIP]);
  
  const editWhitelistIPData = {
    name: {
      required: 'Name is required',
    },
    ip: {
      required: 'IP Address is required',
      pattern: {
        value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: 'Please enter a valid IP Address',
      },
    },
  };
  
  const handleSave = (formData) => {
    const data = {
      name: formData.name,
      ip: formData.ip,
      managerId: formData.managerId,
    };
    dispatch(editWhitelistIP(whitelistIP._id, data));
    hideModal();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="form-group col-md-12">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Name"
          {...register('name', editWhitelistIPData.name)}
          name="name"
          control={control}
        />
        {errors?.name && <span className="errMsg">{errors.name.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">IP Address</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter IP Address"
          {...register('ip', editWhitelistIPData.ip)}
          name="ip"
          control={control}
        />
        {errors?.ip && <span className="errMsg">{errors.ip.message}</span>}
      </div>
      {crmUsers && crmUsers.length > 0 && (
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Manager</label>
          <Form.Select name="managerId" {...register('managerId')} control={control}>
            <option value="">All</option>
            {crmUsers.map(((agentUser) => (
              <option value={agentUser._id} key={agentUser._id}>{`${agentUser?.firstName} ${agentUser?.lastName}`}</option>
            )))}
          </Form.Select>
          {errors?.managerId && <span className="errMsg">{errors.managerId.message}</span>}
        </div>
      )}
      <div>
        <button className="btn btn-default" type="submit">Save</button>
      </div>
    </form>
  );
}
