import React from 'react';
import styles from './style.module.css';

export function LoadingDots() {
  return (
    <div className={styles.loadingDotsWrapper}>
      <div className={styles.loadingDots} />
    </div>
  );
}
