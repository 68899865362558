import { GET_ASSET_QUOTE } from './assetQuoteTypes';
  
const initialState = {
  symbol: '',
  ask: 0,
  bid: 0,
};
  
// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_QUOTE:
      return action.payload;
    default:
      return state;
  }
};
