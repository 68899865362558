import {
  GET_All_VARIABLES, CREATE_NEW_VARIABLES, UPDATE_ALERT_MESSAGE_VARIABLES, DELETE_ALERT_MESSAGE_VARIABLES, 
} from './variablesTypes';

const initialState = {
  variables: { paginatedData: [], totalCount: 0 },
};

const variablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_All_VARIABLES:
      return {
        ...state,
        variables: action.payload,
      };

    case CREATE_NEW_VARIABLES:
      return {
        ...state,
        variables: {
          totalCount: state.variables.totalCount + 1,
          paginatedData: [action.payload, ...state.variables.paginatedData],
        },
      };


    case UPDATE_ALERT_MESSAGE_VARIABLES:
      const updatedPaginatedData = state.variables.paginatedData.map((item) => (item._id === action.payload._id ? action.payload : item));
  
      return {
        ...state,
        variables: {
          ...state.variables,
          paginatedData: updatedPaginatedData,
        },
      };

    case DELETE_ALERT_MESSAGE_VARIABLES:
      const deletedResult = state.variables.paginatedData.filter(({ _id }) => (_id !== action.payload._id));
  
      return {
        ...state,
        variables: {
          totalCount: state.variables.totalCount - 1,
          paginatedData: deletedResult,
        },
      };
          
    default:
      return {
        ...state,
      };
  }
};

export default variablesReducer;
