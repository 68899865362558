import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_CURRENCY_RATES } from './currencyRateTypes';

export const getCurrencyRates = (symbol) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/currency-rate?symbol=${symbol}`, '');

    if (res && res.data && res.data.success) {
      await dispatch({
        type: GET_CURRENCY_RATES,
        payload: res.data.rates,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
