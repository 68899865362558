import {
  GET_CLIENT_ACTIVITIES,
  GET_CLIENT_ACTIVITY,
  ADD_CLIENT_ACTIVITY,
  EDIT_CLIENT_ACTIVITY,
  DELETE_CLIENT_ACTIVITY,
  TOGGLE_CLIENT_ACTIVITY_STATE,
  ERROR_CLIENT_ACTIVITY_STATE,
  GET_USER_ACTIVITY,
  EXPORT_USER_ACTIVITY,
} from './clientActivityTypes';

const initialState = {
  clientActivities: [],
  clientActivity: {},
  userActivity: {},
  clientActivitiesFetched: false,
  clientActivityAdded: false,
  clientActivityFetched: false,
  clientActivityEditted: false,
  clientActivityDeleted: false,
  clientActivityError: false,
  exportUserActivity: false,
};

// eslint-disable-next-line default-param-last
const clientActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_ACTIVITIES:
      return {
        ...state,
        clientActivities: action.payload,
        clientActivitiesFetched: true,
      };
    case GET_USER_ACTIVITY:
      return {
        ...state,
        userActivity: action.payload,
      };  
    case EXPORT_USER_ACTIVITY:
      return {
        ...state,
        exportUserActivity: action.payload,
      }; 
    case GET_CLIENT_ACTIVITY:
      return {
        ...state,
        clientActivity: action.payload,
        clientActivityFetched: true,
      };
    case ADD_CLIENT_ACTIVITY:
      return {
        ...state,
        clientActivities: [state.clientActivities, action.payload],
        clientActivityAdded: true,
      };
    case EDIT_CLIENT_ACTIVITY:
      return {
        ...state,
        clientActivity: action.payload,
        clientActivityEditted: true,
      };
    case DELETE_CLIENT_ACTIVITY:
      return {
        ...state,
        clientActivities: state.clientActivities.filter((item) => item._id !== action.payload),
        clientActivityDeleted: true,
      };
    case TOGGLE_CLIENT_ACTIVITY_STATE:
      return {
        ...state,
        clientActivitiesFetched: false,
        clientActivityAdded: false,
        clientActivityFetched: false,
        clientActivityEditted: false,
        clientActivityDeleted: false,
        error: false,
      };
    case ERROR_CLIENT_ACTIVITY_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default clientActivityReducer;
