import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SalesAnalysis } from './SalesAnalysis/SalesAnalysis';
import { DepositsTableDashboard } from './DepositsTableDashboard/DepositsTableDashboard';
import { DepositsByCountryDashboard } from './DepositsByCountryDashboard/DepositsByCountryDashboard';
import { DepositsByStatusDashboard } from './DepositsByStatusDashboard/DepositsByStatusDashboard';
import { SalesDashboardFilter } from './SalesDashboardFilter/SalesDashboardFilter';
import { getSalesDashboard } from '../../redux/salesDashboard/salesDashboardAction';
import { getDesksIbs } from '../../redux/crmUser/crmUserActions';
import { parseISOString } from './utils/timelineHelper';
import { FilterIcon } from './components/FilterIcon';
import { RefreshIcon } from './components/RefreshIcon';
import style from './SalesDashboard.module.css';


export function SalesDashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [salesDashboardFilter, setSalesDashboardFilter] = useState({});
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSalesStatus, setSelectedSalesStatus] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toDate, setToDate] = useState('');
  const [toTime, setToTime] = useState('');
  const [desksIbsItems, setDesksIbsItems] = useState(new Set());
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [flagReload, setFlagReload] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [isMobileFilterActive, setIsMobileFilterActive] = useState(false);

  const salesDashboardFilterStorage = 'Sales_Dashboard/filters'; 

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanSeeDashboard = permissionName && permissionName.includes('dashboard');

  const setStoredFilterData = () => {
    const salesDashboardStorage = localStorage.getItem(salesDashboardFilterStorage);
    if (salesDashboardStorage) {
      const filters = JSON.parse(salesDashboardStorage);
      setSalesDashboardFilter(filters || {});
      setSelectedSalesStatus(filters.managerStatus || 3);
      setSelectedBrand(filters.brand || null);
      setSelectedPeriod(filters?.period?.[2] || 'All');
      setDesksIbsItems(new Set(filters.deskRole) || new Set());
      if (filters?.period?.length) {
        const [fromDateParsed, fromTimeParsed] = parseISOString(filters.period[0]);
        const [toDateParsed, toTimeParsed] = parseISOString(filters.period[1]);
        setFromDate(fromDateParsed);
        setFromTime(fromTimeParsed);
        setToDate(toDateParsed);
        setToTime(toTimeParsed);
      }
      setFlagReload(true);
    }
  };

  useEffect(() => {
    let fromDateFormated;
    let toDateFormated;
    if ((fromDate.length) && (fromTime.length)) {
      const date = new Date(`${fromDate}T${fromTime}:00Z`);
      fromDateFormated = date.toISOString();
    }

    if ((toDate.length) && (toTime.length)) {
      const date = new Date(`${toDate}T${toTime}:00Z`);
      toDateFormated = date.toISOString();
    }

    const custom = localStorage.getItem('SalesDashboard/Custom');
    if (fromDateFormated && toDateFormated && JSON.parse(custom)) setSalesDashboardFilter((prevState) => ({ ...prevState, period: [fromDateFormated, toDateFormated, 'Custom'] }));
  }, [fromDate, fromTime, toTime, toDate]);

  useEffect(() => {
    const fetchData = () => {
      if (flagReload) {
        localStorage.setItem(salesDashboardFilterStorage, JSON.stringify(salesDashboardFilter));
        dispatch(getSalesDashboard(salesDashboardFilter));
      } 
    };
    fetchData();

    if (Object.keys(salesDashboardFilter)?.length) {
      const deskRoleLength = salesDashboardFilter?.deskRole?.length;
      if (deskRoleLength > 1) setAmountOfFilters((Object.keys(salesDashboardFilter).length + deskRoleLength) - 1);
      else setAmountOfFilters(Object.keys(salesDashboardFilter).length);
    }
  }, [salesDashboardFilter]);

  useEffect(() => {
    const salesDashboardStorage = localStorage.getItem(salesDashboardFilterStorage);
    if (!salesDashboardStorage) {
      localStorage.setItem(salesDashboardFilterStorage, JSON.stringify({}));
    }
    dispatch(getDesksIbs());
    setStoredFilterData();
  }, []);

  useEffect(() => {
    if (permissionName && permissionName.length && !permissionName.includes('dashboard')) {
      history.push('/leads');
    }
  }, [permissionName]);

  const closeMobileFilter = () => {
    setIsMobileFilterActive(false);
  };

  return (
    <div className={`${style.pageWrapper} right-content-wrapper page-padding__30`}>
      <div className={style.pageDirection}>
        <h1 className={style.pageTitle}>Sales Dashboard</h1>
        <div className={style.actionBtnRow}>
          <button 
            className={`${style.mobileFilterIcon} ${!!amountOfFilters && style.mobileFilterIconActive}`} 
            type="button" 
            onClick={() => setIsMobileFilterActive(!isMobileFilterActive)}
          >
            <FilterIcon />
            Filter

            {!!amountOfFilters && <span className={style.mobileAmountOfFilters}>{amountOfFilters}</span>}
          </button>
          <button 
            className={style.refreshButton} 
            type="button"
            onClick={setStoredFilterData}
          >
            <RefreshIcon />
          </button>
        </div>
        <SalesAnalysis />
        <div className={style.tablesWrapper}>
          <DepositsTableDashboard />
          <DepositsByCountryDashboard />
        </div>
        <DepositsByStatusDashboard /> 
      </div>

      <div className={`${style.filterWrapper} ${isMobileFilterActive && style.mobileFilterWrapper}`}>
        <SalesDashboardFilter 
          setFilterObject={setSalesDashboardFilter} 
          fromDate={fromDate} 
          fromTime={fromTime}
          setFromDate={setFromDate}
          setFromTime={setFromTime}
          toTime={toTime}
          toDate={toDate}
          setToDate={setToDate}
          setToTime={setToTime}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          selectedSalesStatus={selectedSalesStatus}
          setSelectedSalesStatus={setSelectedSalesStatus}
          desksIbsItems={desksIbsItems}
          setDesksIbsItems={setDesksIbsItems}
          amountOfFilters={amountOfFilters}
          setAmountOfFilters={setAmountOfFilters}
          refresh={setStoredFilterData}
          selectedPeriod={selectedPeriod} 
          setSelectedPeriod={setSelectedPeriod}
          closeMobileFilter={closeMobileFilter}
        />
      </div>
    </div>
  );
}
