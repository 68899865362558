import {
  GET_CRM_USERS,
  GET_UPLINE_USERS,
  GET_MANAGERS,
  GET_HIERARCHY_ORDER,
  GET_CRM_USER,
  GET_CRM_USER_INFO,
  NULLIFY_CRM_USER_INFO,
  ADD_CRM_USER,
  EDIT_CRM_USER,
  DELETE_CRM_USER,
  TOGGLE_CRM_USER_STATE,
  ERROR_CRM_USER_STATE,
  CURRENT_USER_PERMISSIONS,
  CHANGE_PASSWORD,
  GET_REG_UPINE_USERS,
  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  CREATE_CRM_FILTER,
  UPDATE_CRM_FILTER,
  DELETE_CRM_FILTER,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_CRM_USERS_BY_QUERY,
  GET_DESK_IBS_USERS,
  SET_EMAIL_SUCCESS,
} from './crmUserTypes';

const initialState = {
  crmUsers: [],
  tableCrmUsers: { paginatedData: [], totalCount: 0 },
  uplineUsers: [],
  deskIbs: [],
  managers: [],
  hierarchyOrder: [],
  currentUserPermissions: [],
  crmUser: {},
  crmUserInfo: {},
  crmUsersFetched: false,
  crmUserAdded: false,
  uplineUsersFetched: false,
  managersFetched: false,
  hierarchyOrderFetched: false,
  crmUserFetched: false,
  crmUserEditted: false,
  crmUserDeleted: false,
  emailSent: false,
  isEmailError: false,
  emailError: null,
  error: false,
  currentUserPermissionsFetched: false,
  passwordChanged: false,
  exportFilterdDataToFile: false,
};

// eslint-disable-next-line default-param-last
const crmUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DESK_IBS_USERS:
      return {
        ...state,
        deskIbs: action.payload,
      };
    case SET_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailError: false,
      };
    case CURRENT_USER_PERMISSIONS:
      return {
        ...state,
        currentUserPermissions: action.payload,
        currentUserPermissionsFetched: true,
      };
    case GET_CRM_USER_INFO:
      return {
        ...state,
        crmUserInfo: action.payload,
        crmUserInfoFetched: true,
      };
    case NULLIFY_CRM_USER_INFO:
      return {
        ...state,
        crmUserInfo: {},
        crmUserInfoFetched: false,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        passwordChanged: true,
      };
    case GET_CRM_USERS:
      return {
        ...state,
        crmUsers: action.payload,
        crmUsersFetched: true,
      };
    case GET_MANAGERS:
      return {
        ...state,
        managers: action.payload,
        managersFetched: true,
      };
    case GET_UPLINE_USERS:
      return {
        ...state,
        uplineUsers: action.payload,
        uplineUsersFetched: true,
      };
    case GET_HIERARCHY_ORDER:
      return {
        ...state,
        hierarchyOrder: action.payload,
        hierarchyOrderFetched: true,
      };
    case GET_CRM_USER:
      return {
        ...state,
        crmUser: action.payload,
        crmUserFetched: true,
      };
    case GET_REG_UPINE_USERS:
      return {
        ...state,
        uplineUsers: action.payload,
        uplineUsersFetched: true,
      };
    case ADD_CRM_USER:
      return {
        ...state,
        crmUsers: [state.crmUsers, action.payload],
        tableCrmUsers: {
          ...state.tableCrmUsers,
          paginatedData: [...state.tableCrmUsers.paginatedData, action.payload],
        },
        crmUserAdded: true,
      };
    case EDIT_CRM_USER:
      return {
        ...state,
        crmUser: action.payload,
        tableCrmUsers: {
          ...state.tableCrmUsers,
          paginatedData: state.tableCrmUsers?.paginatedData?.map((crmUser) => {
            if (crmUser._id === action.payload._id) {
              return { ...crmUser, status: action.payload.status };
            }
            return crmUser;
          }),
        },
        crmUserEditted: true,
      };
    case GET_CRM_USERS_BY_QUERY:
      return {
        ...state,
        tableCrmUsers: action.payload,
        crmUserFetched: true,
      };  
    case DELETE_CRM_USER:
      return {
        ...state,
        crmUsers: state.crmUsers.filter((item) => item._id !== action.payload),
        tableCrmUsers: {
          totalCount: state.tableCrmUsers.totalCount - 1,
          paginatedData: state?.tableCrmUsers?.paginatedData?.filter((item) => item._id !== action.payload),
        },
        crmUserDeleted: true,
      };
    case SEND_EMAIL: 
      return {
        ...state,
        emailSent: true,
        isEmailError: false,
      };
    case SEND_EMAIL_ERROR: 
      return {
        ...state,
        emailSent: false,
        isEmailError: true,
        emailError: action.payload,
      };
    case TOGGLE_CRM_USER_STATE:
      return {
        ...state,
        crmUsersFetched: false,
        managersFetched: false,
        uplineUsersFetched: false,
        hierarchyOrderFetched: false,
        crmUserAdded: false,
        crmUserFetched: false,
        crmUserEditted: false,
        crmUserDeleted: false,
        error: false,
      };
    case ERROR_CRM_USER_STATE:
      return {
        ...state,
        error: true,
      };
    case CREATE_CRM_FILTER:
      return {
        ...state,
        crmUserInfo: {
          ...state.crmUserInfo,
          filters: [...state.crmUserInfo.filters, action.payload],
        },
      };
    case DELETE_CRM_FILTER:
      return {
        ...state,
        crmUserInfo: {
          ...state.crmUserInfo,
          filters: state.crmUserInfo.filters.filter((item) => item._id !== action.payload._id),

        },
      };
    case UPDATE_CRM_FILTER:
      return {
        ...state,
        crmUserInfo: {
          ...state.crmUserInfo,
          filters: state.crmUserInfo.filters.map((item) => {
            if (item._id === action.payload._id) {
              return { ...action.payload };
            }
            return item;
          }),
        },
      };
    case EXPORT_FILTERED_DATA_TO_FILE:
      return {
        ...state,
        exportFilterdDataToFile: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default crmUserReducer;
