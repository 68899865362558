import React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 

import styles from './SelectComponent.module.css';

const CustomizedMenuItem = styled(MenuItem)(({ selected }) => ({
  color: '#DAE2F9',
  // width: '100%',
  minHeight: '20px',
  fontFamily: 'Play',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px', /* 142.857% */
  letterSpacing: '0.25px',
  padding: '10px 14.5px',
  backgroundColor: selected ? '#2D3152 !important' : '#232640 !important',
  '&:hover': {
    backgroundColor: '#22253C',
    opacity: '0.92',
  },
  '&.Mui-selected': {
    backgroundColor: '#232934',
    opacity: '0.88',
    '&:hover': {
      backgroundColor: '#262C38',
      opacity: '0.92',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#222731',
    opacity: '0.88',
  },
}));

function CustomArrowDropDownIcon(props) {
  return <ArrowDropDownIcon {...props} style={{ color: '#C4C6D0', width: '18px' }} />;
}

export function SelectComponent({
  id, labelId, value: propsValue, onChange, options, dropDownWidth, 
}) {
  return (
    <FormControl sx={{ height: '32px' }}>
      <Select
        labelId={labelId}
        id={id}
        value={propsValue}
        onChange={onChange}
        MenuProps={{ 
          MenuListProps: { 
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              minWidth: dropDownWidth ? `${dropDownWidth}px !important` : '100px !important', 
              background: '#232640',

            },
          }, 
        }}
        IconComponent={CustomArrowDropDownIcon}
        renderValue={(selected) => {
          const selectedOption = options.find((option) => option.value === selected);
          return (
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', 
            }}
            >
              {/* { propsValue !== '' && <CheckIcon sx={{ width: '18px' }} /> }  */}
              <span>{selectedOption?.label}</span>
            </div>
          );
        }}
        sx={{
          height: '32px',
          padding: '4px 8px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '20px', 
          letterSpacing: '0.1px',
          textAlign: 'center',
          border: 'none',
          maxWidth: 'fit-content',
          width: 'fit-content',
          outline: 'none',
          //   boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
          backgroundColor: propsValue !== '' ? 'transparent' : 'transparent',
          color: '#C4C6D0',
          ' .MuiSelect-select': {
            padding: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            outline: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover': {
            backgroundColor: 'rgba(226,226,233,0.08)',
            opacity: '0.92',
          },
        }}
      >
        {options?.map(({ value, label }) => (
          <CustomizedMenuItem
            key={value}
            value={value}
            selected={value === propsValue}
          >
            <p className={styles.title}>{ label }</p>
          </CustomizedMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
