import {
  ADD_ROLE, DISPLAY_ROLES, DISPLAY_ADMIN_ROLES, DISPLAY_CLIENT_ROLES, GET_ROLE, GET_PER_ROLE, EDIT_ROLE, DELETE_ROLE, TOGGLE_ROLE_STATE, ERROR_STATE,
} from './roleTypes';

const initialState = {
  roles: [],
  adminRoles: [],
  clientRoles: [],
  role: [],
  editRole: [],
  allPermissions: [],
  success: false,
  fetched: false,
  fetchedAdminRoles: false,
  fetchedclientRoles: false,
};

// eslint-disable-next-line default-param-last
const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_ROLES:
      return {
        ...state,
        roles: action.payload,
        success: false,
        fetched: true,
      };
    case DISPLAY_ADMIN_ROLES:
      return {
        ...state,
        adminRoles: action.payload,
        fetchedAdminRoles: true,
      };
    case DISPLAY_CLIENT_ROLES:
      return {
        ...state,
        clientRoles: action.payload,
        fetchedclientRoles: true,
      };
    case GET_ROLE:
      return {
        ...state,
        editRole: action.payload.role,
        allPermissions: action.payload.allPermissions,
        fetched: true,
      };
    case GET_PER_ROLE:
      return {
        ...state,
        role: action.payload.role,
      };
    case ADD_ROLE:
      return {
        ...state,
        roles: action.payload.roles,
        success: true,
      };
    case EDIT_ROLE:
      return {
        ...state,
        roles: action.payload.roles,
        success: true,
      };
    case DELETE_ROLE:
      return {
        ...state,
        roles: action.payload.roles,
        success: true,
      };
    case TOGGLE_ROLE_STATE:
      return {
        ...state,
        success: false,
        fetchedAdminRoles: false,
        fetchedclientRoles: false,
        error: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export default roleReducer;
