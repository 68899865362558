/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../hooks/useDebounce';
import { ModalFilters } from '../components';
import { getCfdSettingsByQuery } from '../redux/cfdSettings/cfdActions';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
 
export const CfdSettingsSchema = (showModal, CfdSettingsModal) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const [searchByAlias, setSearchByAlias] = useState(null);
  const [marketTypesFilter, setMarketTypesFilter] = useState([]);  
  const [cfdSettingsFilter, setCfdSettingsFilter] = useState([]);

  const cfdMarketTypes = useSelector((state) => state.cfdMarketTypes);

  useDebounce(searchByAlias, 300, () => dispatch(getCfdSettingsByQuery(searchByAlias, marketTypesFilter)));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      dispatch(getCfdSettingsByQuery(searchByAlias, marketTypesFilter));
    }
  }, [marketTypesFilter]);
  
  const columns = [
    {
      name: 'Symbol',
      cell: (row) => row.primary,
      minWidth: '80px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start' }}>
          <span className="d-flex ">Alias</span>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={searchByAlias}
            minLength="3"
            onChange={(e) => setSearchByAlias(e.target.value)}
          />
        </div>
      ),
      cell: (row) => row.alias,
      minWidth: '120px',
    },
    {
      name: 'Base Currency',
      cell: (row) => row.secondary,
      minWidth: '132px',
    },
    {
      name: (
        <ModalFilters 
          data={cfdMarketTypes}
          filters={marketTypesFilter} 
          setFilters={setMarketTypesFilter} 
          usersFilters={cfdSettingsFilter}
          setUserFilters={setCfdSettingsFilter}
          tabName="Market" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        />
      ),
      cell: (row) => row.type.name,
      minWidth: '100px',
    },
    {
      name: 'Shift',
      selector: ({ shift }) => (
        shift?.toString().length >= valueOutputCutLength.shift
          ? (
            <TooltipComponent
              title={shift?.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(shift?.toString(), 'shift')}
                </span>
            )}
            />
          )
          : (
            shift
          )
      ),
      sortable: true,
      minWidth: '80px',
    },
    {
      name: 'Leverage',
      selector: ({ leverage }) => (
        leverage.toString().length >= valueOutputCutLength.leverage
          ? (
            <TooltipComponent
              title={leverage.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(leverage.toString(), 'leverage')}
                </span>
            )}
            />
          )
          : (
            leverage
          )
      ),
      sortable: true,
      minWidth: '112px',
    },
    {
      name: 'Lot Step',
      selector: (row) => row.lotStep,
      sortable: true,
      minWidth: '104px',
    },
    {
      name: 'Lot Size',
      selector: (row) => row.lotSize,
      sortable: true,
      minWidth: '104px',
    },
    {
      name: 'Commission %',
      selector: (row) => row.commission,
      sortable: true,
      minWidth: '146px',
    },
    {
      name: 'Swap Long %',
      selector: (row) => row.swapLong,
      sortable: true,
      minWidth: '136px',
    },
    {
      name: 'Swap Short %',
      selector: (row) => row.swapShort,
      sortable: true,
      minWidth: '144px',
    },
    {
      name: 'Spread Max',
      selector: (row) => row.spreadMax,
      sortable: true,
      width: '155px',
    },
    {
      name: 'Spread Min',
      selector: (row) => row.spreadMin,
      sortable: true,
      width: '155px',
    },
    {
      name: 'Actions',
      minWidth: '100px',
      cell: (row) => (
        <div className="action-btn">
          <button
            type="button"
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({
              headerContent: <h3>Edit CFD Setting</h3>, 
              bodyContent: <CfdSettingsModal row={row} />,
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
