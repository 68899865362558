import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  faRefresh, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  selectIsWithdrawalMethodsLoading,
  selectWithdrawalMethods,
  selectBrandSearchFilter,
  selectWithdrawalMethodSearchFilter,
  selectCountriesIncludedSearchFilter,
  selectWithdrawalMethodsTotalCount,
} from '../../redux/withdrawalMethods/withdrawalMethodsSelectors';
import {
  fetchWithdrawalMethods, setBrandFilterCreator, setCountriesIncludedFilterCreator, setWithdrawalMethodFilterCreator, 
} from '../../redux/withdrawalMethods/withdrawalMethodsActions';
import { WithdrawalMethodsColumns } from '../../DataTableSchemas/WithdrawalMethodsColumns';
import styles from './WithdrawalMethods.module.css';
import { DatatableFilters } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';

function WithdrawalMethods() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);

  const paginationStorageName = 'DataTable_withdrawalMethods/listing_pagination';
  const filtersStorageName = 'DataTable_withdrawalMethods/filters'; 
  const filterIdStorageName = 'DataTable_withdrawalMethods/filters_id';
  const withdrawalMethodsStorageFiltersJSON = localStorage.getItem(filtersStorageName);

  // templates variables
  const withdrawalMethods = useSelector(selectWithdrawalMethods);
  const isWithdrawalMethodsLoading = useSelector(selectIsWithdrawalMethodsLoading);

  // filters variables
  const brandSearchFilter = useSelector(selectBrandSearchFilter);
  const countriesIncludedSearchFilter = useSelector(selectCountriesIncludedSearchFilter);
  const withdrawalMethodSearchFilter = useSelector(selectWithdrawalMethodSearchFilter);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectWithdrawalMethodsTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [withdrawalMethodsQuery, setWithdrawalMethodsQuery] = useState({});

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setCRMFilters = (filter) => {
    const { brand, currency, country } = filter;

    setWithdrawalMethodsQuery({
      brand,
      'withdrawal method': currency,
      'countries included': country,
    });

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const handleClear = () => {
    dispatch(setBrandFilterCreator([]));
    dispatch(setWithdrawalMethodFilterCreator([]));
    dispatch(setCountriesIncludedFilterCreator([]));

    setWithdrawalMethodsQuery({});

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const refresh = async () => {
    dispatch(fetchWithdrawalMethods(page, rowsPerPage, {
      brands: withdrawalMethodsQuery.brand,
      name: withdrawalMethodsQuery['withdrawal method'],
      included_countries: withdrawalMethodsQuery['countries included'],
    }));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      brand: filters.brand || [],
      currency: filters['withdrawal method'] || [],
      country: filters['countries included'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        brand: filters.brand || [],
        currency: filters['withdrawal method'] || [],
        country: filters['countries included'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (withdrawalMethodsStorageFiltersJSON) {
      const filters = JSON.parse(withdrawalMethodsStorageFiltersJSON);
      setWithdrawalMethodsQuery(filters || {});

      dispatch(setBrandFilterCreator(filters.brand || []));
      dispatch(setWithdrawalMethodFilterCreator(filters['withdrawal method'] || []));
      dispatch(setCountriesIncludedFilterCreator(filters['countries included'] || []));
    }
  };

  useEffect(() => {
    localStorage.setItem(filtersStorageName, JSON.stringify(withdrawalMethodsQuery));
    dispatch(fetchWithdrawalMethods(page, rowsPerPage, {
      brands: withdrawalMethodsQuery.brand,
      name: withdrawalMethodsQuery['withdrawal method'],
      included_countries: withdrawalMethodsQuery['countries included'],
    }));
  }, [
    page, rowsPerPage, withdrawalMethodsQuery,
  ]);
  
  useEffect(() => {
    setStoredPagination();
    setStoredFilterData();
  }, []);

  const withdrawalMethodsCol = WithdrawalMethodsColumns({
    withdrawalMethodsQuery,
    setWithdrawalMethodsQuery,
    brandSearchFilter,
    countriesIncludedSearchFilter,
    withdrawalMethodSearchFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Withdrawal Methods</h3>

        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {crmFilters && (
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              /> 
              )}

              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>

              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={withdrawalMethodsCol}
                  data={withdrawalMethods}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isWithdrawalMethodsLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default WithdrawalMethods;
