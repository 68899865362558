import {
  GET_STOCK_SETTINGS,
  GET_STOCK_SETTING,
  ADD_STOCK_SETTING,
  EDIT_STOCK_SETTING,
  DELETE_STOCK_SETTING,
  TOGGLE_STOCK_SETTING_STATE,
  ERROR_STOCK_SETTING_STATE,
} from './stockSettingTypes';
  
const initialState = {
  stockSettings: [],
  stockSetting: {},
  stockSettingsFetched: false,
  stockSettingAdded: false,
  stockSettingFetched: false,
  stockSettingEditted: false,
  stockSettingDeleted: false,
  stockSettingError: false,
};
  
// eslint-disable-next-line default-param-last
const stockSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCK_SETTINGS:
      return {
        ...state,
        stockSettings: action.payload,
        stockSettingsFetched: true,
      };
    case GET_STOCK_SETTING:
      return {
        ...state,
        stockSetting: action.payload,
        stockSettingFetched: true,
      };
    case ADD_STOCK_SETTING:
      return {
        ...state,
        stockSettings: [state.stockSettings, action.payload],
        stockSettingAdded: true,
      };
    case EDIT_STOCK_SETTING:
      return {
        ...state,
        stockSetting: action.payload,
        stockSettingEditted: true,
      };
    case DELETE_STOCK_SETTING:
      return {
        ...state,
        stockSettings: state.stockSettings.filter((item) => item._id !== action.payload),
        stockSettingDeleted: true,
      };
    case TOGGLE_STOCK_SETTING_STATE:
      return {
        ...state,
        stockSettingsFetched: false,
        stockSettingAdded: false,
        stockSettingFetched: false,
        stockSettingEditted: false,
        stockSettingDeleted: false,
        stockSettingError: false,
      };
    case ERROR_STOCK_SETTING_STATE:
      return {
        ...state,
        stockSettingError: true,
      };
    default:
      return state;
  }
};
  
export default stockSettingReducer;
