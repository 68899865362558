import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { readNotification, displayAllNotificationsById } from '../../redux/notifications/notificationActions';
import UserPlaceholder from '../../assets/images/user-placeholder.jpg';

function Notifications() {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.crmUser.crmUserInfo);
  const allNotification = useSelector((state) => state.notification.allUserNotifications);

  
  useEffect(() => {
    if (userData && userData._id) dispatch(displayAllNotificationsById(userData._id));
  }, [userData]);

  const redirectToPage = (url, id) => {
    dispatch(readNotification(id, userData._id));
    history.push(url);
  };

  return (
    <div className="notifications-page content-wrapper right-content-wrapper">
      <div className="content-box">
        <div className="d-flex justify-content-between dashboard-localhost-add">
          <h3>Notifications</h3>
        </div>
        <div className="notifications-section">
          {allNotification.length ? (
            allNotification.map((notif) => {
              const formattedDate = moment(notif.createdAt).format('LLL');
              const profileImageUrl = notif?.user?.profileImage
                ? `${process.env.REACT_APP_SERVER_URL}/images/${notif?.user?.profileImage}`
                : UserPlaceholder;

              return (
                <div
                  onClick={() => redirectToPage(notif.redirectUrl, notif._id)}
                  className="notification d-flex align-items-center"
                  key={notif._id}
                >
                  <div className="noti-img">
                    <img className="img-fluid" src={profileImageUrl} alt="" />
                  </div>

                  <div className="noti-content">
                    <h6 className="status">{notif.message}</h6>
                    <span
                      className="date"
                      style={{ fontSize: '12px', color: 'lightgrey' }}
                    >
                      {formattedDate}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="empty-container">
              <span>No Notifications Found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
