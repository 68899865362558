export const selectNextMonth = (selectedDate, setSelectedDate) => {
  const nextMonth = selectedDate.getMonth() + 1;
  const nextYear = selectedDate.getFullYear() + 1;
    
  if (nextMonth > 11) {
    return setSelectedDate(new Date(nextYear, 0, 1));
  };

  return setSelectedDate(new Date(selectedDate.getFullYear(), nextMonth, 1));
};

export const selectNextWeek = (selectedDate, setSelectedDate) => {
  const nextWeek = new Date(selectedDate);
  nextWeek.setDate(selectedDate.getDate() + 7);
  return setSelectedDate(nextWeek);
};

export const selectNextDay = (selectedDate, setSelectedDate) => {
  const nextDay = new Date(selectedDate);
  nextDay.setDate(selectedDate.getDate() + 1);
  return setSelectedDate(nextDay);
};

export const selectPrevMonth = (selectedDate, setSelectedDate) => {
  const prevMonth = selectedDate.getMonth() - 1;
  const prevYear = selectedDate.getFullYear() - 1;
    
  if (prevMonth < 0) {
    return setSelectedDate(new Date(prevYear, 11, 1));
  };

  return setSelectedDate(new Date(selectedDate.getFullYear(), prevMonth, 1));
};

export const selectPrevWeek = (selectedDate, setSelectedDate) => {
  const prevWeek = new Date(selectedDate);
  prevWeek.setDate(selectedDate.getDate() - 7);
  return setSelectedDate(prevWeek);
};

export const selectPrevDay = (selectedDate, setSelectedDate) => {
  const prevDay = new Date(selectedDate);
  prevDay.setDate(selectedDate.getDate() - 1);
  return setSelectedDate(prevDay);
};
