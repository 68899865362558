import {
  React, useState, useEffect, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/colors/teal.css';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import socket from '../../services/socket';
import { updateOpenedCfdOrder } from '../../redux/cfdOrder/cfdOrderActions';
import { getFormattedTimeZoneOutput } from '../../helpers/helper';

export function EditOpenOrderModal({ order, hideModal, handleRefresh }) {
  const dispatch = useDispatch();
  const [updatedOrder, setUpdatedOrder] = useState({});
  const [currentPrice, setCurrentPrice] = useState(0);

  const isPendingOrder = order.status === 'pending';

  useEffect(() => {
    const {
      tradeStartPrice, 
      position, 
      timeOpened,
      takeProfitPrice,
      stopLossPrice, 
      tradeType,
    } = order;

    setUpdatedOrder({
      tradeStartPrice,
      position,
      timeOpened: new Date(timeOpened),
      takeProfitPrice,
      stopLossPrice,
      tradeType,
    });
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUpdatedOrder((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setUpdatedOrder({});
    hideModal();
  };

  const updateOrder = async () => {
    await dispatch(updateOpenedCfdOrder(order._id, updatedOrder));
    handleClose();
    setTimeout(() => {
      handleRefresh();
    }, 1500);
  };

  useEffect(() => {
    const uniqueId = Date.now();

    socket.emit('getOpenOrderMarket', { orders: [order], uniqueId });

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setCurrentPrice(data[order._id].marketPrice);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      socket.emit('removeOpenOrdersMarketListener');
      setCurrentPrice(0);
    };
  }, [order]);

  const typeSelectComponent = useCallback(() => {
    if (updatedOrder.tradeType === 0 || updatedOrder.tradeType === 1) {
      return (
        <Select
          className="form-control"
          value={updatedOrder.tradeType}
          onChange={handleChange}
          name="tradeType"
          style={{ height: '40px' }}
        >
          <MenuItem value={1}>Buy</MenuItem>
          <MenuItem value={0}>Sell</MenuItem>
        </Select>
      );
    }

    return (
      <Select
        className="form-control"
        value={updatedOrder.tradeType}
        onChange={handleChange}
        name="tradeType"
        style={{ height: '40px' }}
      >
        <MenuItem value={1}>Buy</MenuItem>
        <MenuItem value={0}>Sell</MenuItem>
        <MenuItem value={2}>Buy Limit</MenuItem>
        <MenuItem value={3}>Buy Stop</MenuItem>
        <MenuItem value={4}>Sell Limit</MenuItem>
        <MenuItem value={5}>Sell Stop</MenuItem>
      </Select>
    );
  }, [updatedOrder]);

  return (
    !!Object.keys(updatedOrder).length && (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Type</label>
          {typeSelectComponent()}
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeStartPrice}
            name="tradeStartPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            name="position"
            value={updatedOrder.position}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Opened</label>
          <br />
          <DatePicker 
            value={updatedOrder.timeOpened}
            onChange={(date) => {
              const query = {
                target: {
                  name: 'timeOpened',
                  value: new Date(date),
                },
              };
              handleChange(query);
            }}
            format="MM/DD/YYYY HH:mm:ss"
            className="bg-dark teal" 
            inputClass="form-control"
            plugins={[
              <TimePicker hideSeconds />,
            ]}
          />
          <div>
            { `Time zone: ${getFormattedTimeZoneOutput()}` }
          </div>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">TP</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Take Profit"
            aria-label=""
            aria-describedby=""
            name="takeProfitPrice"
            value={updatedOrder.takeProfitPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">SL</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Stop Loss"
            aria-label=""
            aria-describedby=""
            name="stopLossPrice"
            value={updatedOrder.stopLossPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Profit (PnL)</label>
          <input
            type="text"
            className="form-control"
            aria-label=""
            aria-describedby=""
            name="pnl"
            disabled
            value={
              !isPendingOrder ? (
                updatedOrder.tradeType === 1 
                  ? (updatedOrder.position * (currentPrice - updatedOrder.tradeStartPrice)).toFixed(4) 
                  : (updatedOrder.position * (updatedOrder.tradeStartPrice - currentPrice)).toFixed(4)
              ) : 0
            }
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Current Price</label>
          <input
            type="text"
            className="form-control"
            aria-label=""
            aria-describedby=""
            name="currentPrice"
            disabled
            value={!isPendingOrder ? currentPrice.toFixed(4) : 0}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={updateOrder} disabled={!currentPrice}>Save</button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
      </div>
    </>
    )
  );
};
