import { GET_CFD_MARKET_TYPES } from './cfdMarketTypes';

const cfdMarketTypesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CFD_MARKET_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export default cfdMarketTypesReducer;  
