import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';
import { InputComponent } from '../InputComponent/InputComponent';
import styles from './SearchSelectComponent.module.css';


const changeIfModified = (prevValue, value) => {
  if (prevValue !== value.value) {
    return value.value;
  } 
  return prevValue;
};

const StyledMenuItemContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderBottom: '1px solid var(--RX-System-OutlineVariant)',
  background: '#232640',
}));


const StyledMenuItem = styled(MenuItem)(() => ({
  color: '#DAE2F9',
  // width: '100%',
  minHeight: '20px',
  fontFamily: 'Play',
  fontSize: '14px !important',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px', /* 142.857% */
  letterSpacing: '0.25px',
  padding: '10px 14.5px',
  backgroundColor: '#232640 !important',
  '&:hover': {
    backgroundColor: '#22253C',
    opacity: '0.92',
  },
  '&.Mui-selected': {
    backgroundColor: '#232934',
    opacity: '0.88',
    '&:hover': {
      backgroundColor: '#262C38',
      opacity: '0.92',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#222731',
    opacity: '0.88',
  },
}));


export function SearchSelectComponent({
  defaultInputValue = '',
  selectedVariant = '',
  isLoading = false,
  variants,
  onChange,
  isMultiple = false,
}) {
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [selectedVariants, setSelectedVariants] = useState(
    isMultiple ? [] : selectedVariant,
  );

  const handleSelect = (selectedVariant) => {
    if (isMultiple) {
      const newSelectedVariants = selectedVariants.includes(selectedVariant.value)
        ? selectedVariants.filter((v) => v !== selectedVariant.value)
        : [...selectedVariants, selectedVariant.value];
      setSelectedVariants(newSelectedVariants);
      if (onChange) {
        onChange(newSelectedVariants);
      }
    } else {
      const newVariant = changeIfModified(selectedVariants, selectedVariant);
      setSelectedVariants(newVariant);
      if (onChange) {
        onChange(newVariant);
      }
    }
  };

  const filteredVariants = variants.filter((variant) => variant.label.toLowerCase().includes(inputValue.toLowerCase()));

  return (
    <div>
      <StyledMenuItemContainer>
        <InputComponent
          value={inputValue}
          setValue={setInputValue}
          placeholder="Search"
          searchIcon
        />
      </StyledMenuItemContainer>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress
            sx={{ color: 'var(--RX-System-Primary)' }}
            size={32}
          />
        </div>
      ) : (
        filteredVariants.map((filteredVariant) => (
          <StyledMenuItem
            key={filteredVariant.value}
            value={filteredVariant.value}
            style={{ gap: '10px' }}
            onClick={() => handleSelect(filteredVariant)}
          >
            {isMultiple
              ? selectedVariants.includes(filteredVariant.value) && <CheckIcon />
              : filteredVariant.value === selectedVariants && <CheckIcon />}
            <ListItemText primary={filteredVariant.label} />
          </StyledMenuItem>
        ))
      )}
    </div>
  );
}
