import React from 'react';
import { Chart } from 'react-google-charts';

export function DonutChart({ data, colors }) {
  const options = {
    backgroundColor: 'transparent',
    chartArea: {
      left: 40, top: 20, width: '60%', height: '70%', 
    },
    pieHole: 0.4,
    is3D: false,
    legend: { 
      position: 'right', 
      textStyle: { 
        color: '#E2E2E9', 
        fontSize: '11px', 
        fontWeight: '500',
        lineHeight: '100px',
        letterSpacing: '0.5px',
        textAlign: 'left',
      }, 
    },
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
}
