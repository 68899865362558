import {
  GET_FOREX_CURRENCIES,
  GET_FOREX_CURRENCY,
  ADD_FOREX_CURRENCY,
  EDIT_FOREX_CURRENCY,
  DELETE_FOREX_CURRENCY,
  TOGGLE_FOREX_CURRENCY_STATE,
  ERROR_FOREX_CURRENCY_STATE,
} from './forexCurrencyTypes';
  
const initialState = {
  forexCurrencies: [],
  forexCurrency: {},
  forexCurrenciesFetched: false,
  forexCurrencyAdded: false,
  forexCurrencyFetched: false,
  forexCurrencyEditted: false,
  forexCurrencyDeleted: false,
  forexCurrencyError: false,
};
  
// eslint-disable-next-line default-param-last
const forexCurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOREX_CURRENCIES:
      return {
        ...state,
        forexCurrencies: action.payload,
        forexCurrenciesFetched: true,
      };
    case GET_FOREX_CURRENCY:
      return {
        ...state,
        forexCurrency: action.payload,
        forexCurrencyFetched: true,
      };
    case ADD_FOREX_CURRENCY:
      return {
        ...state,
        forexCurrencies: [...state.forexCurrencies, action.payload],
        forexCurrencyAdded: true,
      };
    case EDIT_FOREX_CURRENCY:
      return {
        ...state,
        forexCurrencies: state.forexCurrencies.map((currency) => {
          if (currency._id === action.payload._id) return { ...action.payload };
          return currency;
        }),
        forexCurrencyEditted: true,
      };
    case DELETE_FOREX_CURRENCY:
      return {
        ...state,
        forexCurrencies: state.forexCurrencies.filter((item) => item._id !== action.payload),
        forexCurrencyDeleted: true,
      };
    case TOGGLE_FOREX_CURRENCY_STATE:
      return {
        ...state,
        forexCurrenciesFetched: false,
        forexCurrencyAdded: false,
        forexCurrencyFetched: false,
        forexCurrencyEditted: false,
        forexCurrencyDeleted: false,
        forexCurrencyError: false,
      };
    case ERROR_FOREX_CURRENCY_STATE:
      return {
        ...state,
        forexCurrencyError: true,
      };
    default:
      return state;
  }
};
  
export default forexCurrencyReducer;
