import React, { useState } from 'react';
import {
  MonthCalendar, CalendarSubHeader, WeekCalendar, DayCalendar, 
} from './components';
import { EditEventModal } from '../../components';
import styles from './style.module.css';

function Calendar() {
  const [focusedEvent, setFocusedEvent] = useState('');
  const [calendarStyle, setCalendarStyle] = useState('month');
  const [selectedDate, setSelectedDate] = useState(new Date());
    
  const [showEditEventModal, setShowEditEventModal] = useState(false);

  return (
    <div className="content-wrapper right-content-wrapper text-white" style={{ height: '100%' }}>
      <CalendarSubHeader 
        selectedDate={selectedDate} 
        setSelectedDate={setSelectedDate} 
        calendarStyle={calendarStyle}
        setCalendarStyle={setCalendarStyle}
      />

      <div className={styles.calendarWarpper}>
        {calendarStyle === 'month' && (
        <MonthCalendar 
          selectedDate={selectedDate}
          setShowEditEventModal={setShowEditEventModal}
          setFocusedEvent={setFocusedEvent}
          focusedEvent={focusedEvent}
        />
        )}
                
        {calendarStyle === 'week' && (
        <WeekCalendar 
          selectedDate={selectedDate}
          setShowEditEventModal={setShowEditEventModal}
          setFocusedEvent={setFocusedEvent}
          focusedEvent={focusedEvent}
        />
        )}

        {calendarStyle === 'day' && (
        <DayCalendar 
          selectedDate={selectedDate}
          setShowEditEventModal={setShowEditEventModal}
          setFocusedEvent={setFocusedEvent}
          focusedEvent={focusedEvent}
          setSelectedDate={setSelectedDate}
        />
        )}
      </div>

      {showEditEventModal && (
        <EditEventModal 
          showModal={showEditEventModal} 
          setShowModal={setShowEditEventModal}
          focusedEvent={focusedEvent}
        />
      )}
    </div>
  );
}

export default Calendar;
