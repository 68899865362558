import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { updateEventById } from '../../../../redux/event/eventActions';
import { EventList } from '../EventsList/EventsList';

export function CalendarCell({
  day, setShowEditEventModal, setFocusedEvent, focusedEvent, draggableEvent, setDraggableEvent, 
}) {
  const dispatch = useDispatch();
  const {
    date, dayNumber, isCurrentMonth, isToday, events, 
  } = day;

  const allowToDrop = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    date.setHours(new Date(draggableEvent.time).getHours());
    date.setMinutes(new Date(draggableEvent.time).getMinutes());

    const data = {
      name: draggableEvent.name,
      crmUserId: draggableEvent.crmUserId,
      userId: draggableEvent.userId,
      time: new Date(date).valueOf(),
      duration: draggableEvent.duration,
      description: draggableEvent.description,
    };

    dispatch(updateEventById(draggableEvent._id, data));
  };

  return (
    <div 
      className={styles.cell}
      style={{ 
        backgroundColor: isToday ? '#252b48' : '',
        opacity: isCurrentMonth ? '100%' : '',
      }}
      onDragOver={allowToDrop}
      onDrop={handleDrop}
    >
      <div className={styles.cellHeader}>
        <span 
          className={styles.day}
          style={{ 
            opacity: isCurrentMonth ? '100%' : '20%',
            backgroundColor: isToday ? '#316AFF' : '', 
            boxShadow: isToday ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
          }}
        >
          {dayNumber}
        </span>
      </div>
      <EventList 
        events={events} 
        setShowEditEventModal={setShowEditEventModal}
        focusedEvent={focusedEvent}
        setFocusedEvent={setFocusedEvent}
        setDraggableEvent={setDraggableEvent}
      />
    </div>
  );
};
