import { 
  DELETE_USER_ACCOUNT, 
  CREATE_USER_ACCOUNT,
  GET_CFD_ACCOUNTS_QUERY,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_ACCOUNT_PTIONS_BY_USER_ID,
  UPDATE_USER_ACCOUNT,
  MASS_UPDATE_USER_ACCOUNTS,
} from './cfdAccountTypes';

const initialState = {
  allCfdAccounts: [],
  options: [],
  exportFilterdDataToFile: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_PTIONS_BY_USER_ID:
      return {
        ...state,
        options: action.payload,
      };
    case CREATE_USER_ACCOUNT:
      return {
        ...state,
        allCfdAccounts: {
          cfdAccounts: [...state.allCfdAccounts.cfdAccounts, action.payload],
          totalCount: state.allCfdAccounts.totalCount + 1,
        },
      };
    case UPDATE_USER_ACCOUNT:
      return {
        ...state,
        allCfdAccounts: {
          ...state.allCfdAccounts,
          cfdAccounts: state.allCfdAccounts.cfdAccounts.map((account) => {
            if (account._id === action.payload._id) return action.payload;

            return account;
          }),
        },
      };
    case MASS_UPDATE_USER_ACCOUNTS:
      return {
        ...state,
        allCfdAccounts: {
          ...state.allCfdAccounts,
          cfdAccounts: state.allCfdAccounts.cfdAccounts.map((account) => {
            if (action.payload.accountIds.includes(account._id)) {
              return {
                ...account, 
                leverage: action.payload.leverage || 0,
                lotStep: action.payload.lotStep || 0,
                lotSize: action.payload.lotSize || 0,
                commission: action.payload.commission || 0,
                swapLong: action.payload.swapLong || 0,
                swapShort: action.payload.swapShort || 0,
              }; 
            }
  
            return account;
          }),
        },
      };
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        allCfdAccounts: {
          totalCount: state.allCfdAccounts.totalCount - 1,
          cfdAccounts: state?.allCfdAccounts?.cfdAccounts?.filter((item) => item._id !== action.payload._id),
        },
      };
    case GET_CFD_ACCOUNTS_QUERY:
      return {
        ...state,
        allCfdAccounts: action.payload,
      };
    case EXPORT_FILTERED_DATA_TO_FILE:
      return {
        ...state,
        exportFilterdDataToFile: action.payload,
      };  
    default:
      return state;
  }
};
