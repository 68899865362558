import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './EditSmtpPreset.module.css';
import { getCRMUsers } from '../../../redux/crmUser/crmUserActions';
import { editSmtpPreset } from '../../../redux/smtpPresets/smtpPresetsActions';

const mailsData = [
  {
    title: 'Gmail',
    value: 'gmail',
  },
  {
    title: 'Outlook',
    value: 'outlook',
  },
  {
    title: 'Zoho',
    value: 'zoho',
  },
  // {
  //   title: 'Private Email',
  //   value: 'privateemail',
  // },
  // {
  //   title: 'Titan',
  //   value: 'titan',
  // },
];

export function EditSmtpPreset({ preset, hideModal }) {
  const dispatch = useDispatch();

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);

  const [agent, setAgent] = useState(crmUsers[0]?._id);
  const [provider, setProvider] = useState(mailsData[0].value);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (crmUsers && crmUsers.length === 0) {
      dispatch(getCRMUsers());
    }

    if (preset && crmUsers && crmUsers.length !== 0) {
      setAgent(preset.agent);
      setProvider(preset.provider);
      setEmail(preset.email);
      setPassword(preset.password);
      setDescription(preset.description);
    }
  }, [crmUsers, preset]);

  const handleSave = () => {
    dispatch(editSmtpPreset(preset._id, {
      agent, provider, email, password, description, 
    }, hideModal));
  };

  return (
    <div>
      <div className={style.wrapper}>
        <div className={style.col1}>
          <div className={style.item}>
            <span>Agent</span>
            <select name="agent" className={style.agentSelector} onChange={({ target }) => { setAgent(target.value); }}>
              {crmUsers.map((user) => (
                <option key={user._id} selected={user._id === preset.agent} value={user._id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
            </select>
          </div>
          <div className={style.item}>
            <span>Email</span>
            <input
              className={`form-control ${style.emailInput}`}
              onChange={({ target }) => setEmail(target.value)}
              value={email}
            />
          </div>
          <div className={style.item}>
            <span>Description</span>
            <input
              className={`form-control ${style.descriptionInput}`}
              onChange={({ target }) => setDescription(target.value)}
              value={description} 
            />
          </div>
        </div>
        <div className={style.col2}>
          <div className={style.item}>
            <span>Provider</span>
            <select className={style.providerSelector} name="provider" onChange={({ target }) => { setProvider(target.value); }}>
              {mailsData.map((mail) => (
                <option key={mail.value} selected={mail.value === preset.provider} value={mail.value}>
                  {mail.title}
                </option>
              ))}
            </select>
          </div>
          <div className={style.item}>
            <span>Password</span>
            <input
              className={`form-control ${style.passwordInput}`}
              onChange={({ target }) => setPassword(target.value)}
              value={password}
            />
          </div>
        </div>
      </div>
      <div className={style.btnWrapper}>
        <button className="btn btn-primary btn-lg me-1 p-1" type="button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}
